import { Box } from "@mui/material";

import {
  DetailsItem,
  OverflowTooltip,
  CopyToClipboardButton,
  InfoTooltip,
} from "components/atoms";

import type { DetailsItemProps } from "components/atoms/DetailsItem/DetailsItem";

interface EndpointUrlCopyProps extends DetailsItemProps {
  url: string;
}

const EndpointUrlCopy = ({
  url,
  titleVariant = "h5",
}: EndpointUrlCopyProps) => (
  <DetailsItem
    title="Endpoint URL"
    titleVariant={titleVariant}
    icon={
      <InfoTooltip>This is the base endpoint url for requests.</InfoTooltip>
    }
  >
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <OverflowTooltip title={url}>{url}</OverflowTooltip>
      <CopyToClipboardButton
        defaultLabel="Copy endpoint URL"
        contentToCopy={url}
        size="small"
        htmlColor="secondary"
      />
    </Box>
  </DetailsItem>
);

export default EndpointUrlCopy;
