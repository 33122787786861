import { Chip, Grid, Tooltip, useTheme } from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useRoleAssignmentsDelete } from "libs/data/customized/roles";
import { fileAccessRoles } from "libs/utilities/labels-mapping";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { RoleAssignmentList } from "libs/data/models";

interface UserRoleChipProps {
  userRole: RoleAssignmentList;
  loggedInUserId: string;
  onRoleDetailsClick: (role: string) => void;
}

export const UserRoleChip = ({
  userRole,
  loggedInUserId,
  onRoleDetailsClick,
}: UserRoleChipProps) => {
  const theme = useTheme() as AppThemeProps;
  const { projectName } = useParams<{ projectName: string }>();

  const roleAssignmentsDelete = useRoleAssignmentsDelete(
    projectName,
    userRole?.assignee || ""
  );

  const handleDelete = (roleId?: string) => {
    roleAssignmentsDelete(roleId);
  };

  const isAllowedRemoveRole =
    userRole.role === "project-admin" && userRole.assignee === loggedInUserId;

  const roleLabel = useMemo(() => {
    if (userRole) {
      const resource = `| ${userRole.resource_type}: ${userRole.resource}`;

      if (userRole.resource_type !== "project") {
        if (userRole.resource_type === "bucket") {
          return `${
            fileAccessRoles[userRole.role as keyof typeof fileAccessRoles] ??
            userRole.role
          } ${resource}`;
        }

        return `${userRole.role} ${resource}`;
      } else {
        return userRole.role;
      }
    }

    return;
  }, [userRole]);

  return (
    <Grid item key={userRole.id}>
      <Tooltip
        title={
          isAllowedRemoveRole
            ? "You are not allowed to remove yourself as an admin"
            : ""
        }
      >
        <div>
          <Chip
            clickable
            size="small"
            onDelete={
              !isAllowedRemoveRole
                ? () => handleDelete(userRole?.id)
                : () => null
            }
            onClick={() => onRoleDetailsClick(userRole.role)}
            label={roleLabel}
            disabled={isAllowedRemoveRole}
            sx={{ backgroundColor: theme.palette.background.active }}
          />
        </div>
      </Tooltip>
    </Grid>
  );
};
