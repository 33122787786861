import Plus from "@mui/icons-material/AddBoxRounded";
import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/Edit";
import {
  Box,
  Grid,
  IconButton,
  TablePagination,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { IlluRestrictedPermissions } from "assets/images/IlluRestrictedPermissions";
import { useInstanceTypeGroupDelete } from "libs/data/customized/instance-type-groups/useInstanceTypeGroupDelete";
import {
  useInstanceTypeGroupsList,
  useInstanceTypeGroupsUsage,
} from "libs/data/endpoints/instances/instances";
import { DATE_TIME_FORMAT, getTzAwareDate } from "libs/utilities/date-util";
import { explanations } from "libs/utilities/explanations";
import { routes } from "routes";

import {
  Card,
  CopyToClipboardButton,
  DeleteDialog,
  Dialog,
  DialogWarningHeader,
  Divider,
  PrimaryButton,
} from "components/atoms";
import { BaseTable } from "components/molecules";

import { InstanceTypeGroupDetails } from "./InstanceTypeGroupDetails";

import type { BaseColumn } from "components/molecules/BaseTable";
import type { InstanceTypeGroupList, InstanceTypeItem } from "libs/data/models";

export const InstanceTypeGroups = () => {
  const theme = useTheme();
  const history = useHistory();
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const [groupIdToDelete, setGroupIdToDelete] = useState<string | null>(null);

  const [limit, setLimit] = useState(20);
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const [page, setPage] = useState<number>(0);
  const [usagePage, setUsagePage] = useState(0);
  const [usageCursor, setUsageCursor] = useState<string | undefined>(undefined);
  const [rowToEdit, setRowToEdit] = useState("");
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const handleDelete = useInstanceTypeGroupDelete(projectName);
  const baseUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName).projects[
        ":projectName"
      ](projectName).projectSettings.instanceTypeGroups,
    [organizationName, projectName]
  );

  const {
    data: instanceTypeGroups,
    isValidating,
    mutate,
  } = useInstanceTypeGroupsList(projectName, {
    cursor: cursor
      ? (new URL(cursor)?.searchParams?.get("cursor") as string)
      : undefined,
    limit,
  });

  const { data: instanceGroupUsage } = useInstanceTypeGroupsUsage(
    projectName,
    rowToEdit,
    {
      limit: 5,
      cursor: usageCursor
        ? (new URL(usageCursor)?.searchParams?.get("cursor") as string)
        : undefined,
    },
    { swr: { enabled: !!rowToEdit } }
  );

  useEffect(() => {
    mutate();
  }, [mutate]);

  const columns = useMemo(
    () => [
      {
        title: "",
        field: "expand",
        width: "2%",
      },
      {
        title: "Name",
        field: "name",
        width: "20%",
        nowrap: true,
      },
      {
        title: "Created",
        field: "time_created",
        type: "datetime",
        width: "10%",
        nowrap: true,
        render: (rowData: InstanceTypeGroupList) =>
          getTzAwareDate(rowData.time_created).format(DATE_TIME_FORMAT),
        defaultSort: "desc",
      },
      {
        title: "Updated",
        field: "time_updated",
        type: "datetime",
        width: "10%",
        nowrap: true,
        render: (rowData: InstanceTypeGroupList) =>
          rowData.time_updated &&
          getTzAwareDate(rowData.time_updated).format(DATE_TIME_FORMAT),
        defaultSort: "desc",
      },
      {
        disableClick: true,
        width: "2%",
        render: (rowData: InstanceTypeGroupList) => {
          return (
            <Box display="flex" justifyContent="flex-end">
              <CopyToClipboardButton
                defaultLabel="Copy ID"
                contentToCopy={rowData?.id}
                size="medium"
                htmlColor="secondary"
                hoverColor="secondary"
              />
              <Tooltip title="Edit">
                <span>
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      setRowToEdit(rowData.id);
                    }}
                  >
                    <Edit />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Delete">
                <span>
                  <IconButton
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setGroupIdToDelete(rowData?.id);
                    }}
                  >
                    <Trash sx={{ color: theme.palette.text.primary }} />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          );
        },
      },
    ],
    [theme.palette.text.primary]
  );

  const usageColumns = [
    {
      title: "Deployment",
      field: "deployment",
      width: "50%",
      nowrap: true,
    },
    {
      title: "Version",
      field: "version",
      width: "50%",
      nowrap: true,
    },
  ];

  return (
    <Grid item xs={12}>
      <Card>
        <BaseTable
          columns={columns as BaseColumn[]}
          data={instanceTypeGroups?.results}
          isLoading={isValidating}
          hasPagination={false}
          action={
            <PrimaryButton
              startIcon={<Plus />}
              size="small"
              link={`${baseUrl.create.index()}`}
            >
              Create instance type group
            </PrimaryButton>
          }
          expandedRowIndex={instanceTypeGroups?.results?.findIndex(
            (group) => group.id === expandedId
          )}
          clearExpandedRow={() => setExpandedId(null)}
          renderDetailPanel={(rowData: InstanceTypeGroupList) => {
            return (
              <InstanceTypeGroupDetails
                instanceTypes={rowData.instance_types || []}
              />
            );
          }}
          onRowClick={(_e, rowData) => {
            if (rowData?.id !== expandedId) {
              setExpandedId(rowData?.id);
            } else {
              setExpandedId(null);
            }
          }}
        />
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={-1}
          labelDisplayedRows={({ from, to }) => {
            const rows = instanceTypeGroups?.results.length || 0;
            const rowCount = rows < limit ? from + rows - 1 : to;

            return `${from}-${rowCount}`;
          }}
          rowsPerPage={limit}
          page={page}
          onRowsPerPageChange={(event) => {
            setLimit?.(Number(event.target.value || 20));
            setPage(0);
            setCursor(undefined);
          }}
          onPageChange={(_event, newPage: number) => {
            if (newPage > page) setCursor(instanceTypeGroups?.next as string);
            if (newPage < page)
              setCursor(instanceTypeGroups?.previous as string);
            setPage(newPage);
          }}
          nextIconButtonProps={{
            disabled: !instanceTypeGroups?.next,
          }}
        />
        <DeleteDialog
          onClose={() => setGroupIdToDelete(null)}
          onDelete={async () => {
            await handleDelete(groupIdToDelete as string);
            setGroupIdToDelete(null);
            mutate();
          }}
          open={Boolean(groupIdToDelete)}
        >
          Are you sure you want to delete instance type group{" "}
          <b>
            {
              (
                instanceTypeGroups?.results?.find(
                  ({ id }) => id === groupIdToDelete
                ) as InstanceTypeItem
              )?.name
            }
          </b>
          ?
        </DeleteDialog>
      </Card>
      <Dialog
        open={!!rowToEdit}
        onClose={() => setRowToEdit("")}
        Header={
          <DialogWarningHeader title="Do you want to edit this instance type group?" />
        }
        fullWidth
        Actions={
          <PrimaryButton
            onClick={() =>
              history.push(baseUrl[":groupId"](rowToEdit).edit.index())
            }
          >
            Continue to edit
          </PrimaryButton>
        }
      >
        <Box display="flex" flexDirection="column" alignItems="stretch">
          <Box display="flex" justifyContent="center" marginBottom={2}>
            <IlluRestrictedPermissions />
          </Box>
          {instanceGroupUsage?.results?.length ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="stretch"
              gap={2}
            >
              {explanations.instanceGroups.confirmEditDialog}
              <Divider />
              <BaseTable
                data={instanceGroupUsage?.results}
                columns={usageColumns as unknown as BaseColumn[]}
                defaultPageSize={5}
                rowsPerPageOptions={[]}
                hasPagination={false}
              />
              <TablePagination
                rowsPerPageOptions={[5]}
                component="div"
                count={-1}
                labelDisplayedRows={({ from, to }) => {
                  const rows = instanceGroupUsage?.results.length || 0;
                  const rowCount = rows < 5 ? from + rows - 1 : to;

                  return `${from}-${rowCount}`;
                }}
                rowsPerPage={5}
                page={usagePage}
                onPageChange={(_event, newPage: number) => {
                  if (newPage > usagePage)
                    setUsageCursor(instanceGroupUsage?.next as string);
                  if (newPage < usagePage)
                    setUsageCursor(instanceGroupUsage?.previous as string);
                  setUsagePage(newPage);
                }}
                nextIconButtonProps={{
                  disabled: !instanceGroupUsage?.next,
                }}
              />
            </Box>
          ) : null}

          <Typography>
            Are you sure you want to edit this instance type group?
          </Typography>
        </Box>
      </Dialog>
    </Grid>
  );
};
