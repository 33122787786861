import { useMediaQuery } from "@mui/material";
import { createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { env, ENV_NAMES } from "libs/env";

import type { PaletteMode } from "@mui/material";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store/store";

const initialState: { mode: PaletteMode } = { mode: env.get(ENV_NAMES.THEME) };

export const themeMode = createSlice({
  name: "themeMode",
  initialState,
  reducers: {
    toggleThemeMode: (state, action: PayloadAction<PaletteMode>) => {
      state.mode = action.payload;
      env.set(ENV_NAMES.THEME, state.mode);
    },
  },
});

export const useGetThemeMode = () => {
  const themeMode = useSelector((store: RootState) => store.themeMode);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", {
    noSsr: true,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    const storedTheme = env.get(ENV_NAMES.THEME);
    const darkModeEnabled = env.get(ENV_NAMES.DARK_MODE_ENABLED);
    if (!storedTheme && darkModeEnabled) {
      dispatch(toggleThemeMode(prefersDarkMode ? "dark" : "light"));
    }
  }, [themeMode?.mode, prefersDarkMode, dispatch]);

  return themeMode;
};

export const { toggleThemeMode } = themeMode.actions;

export default themeMode.reducer;
