import * as Yup from "yup";

import {
  FIELD_WEBHOOK_CALLBACKURL,
  FIELD_WEBHOOK_DESCRIPTION,
  FIELD_WEBHOOK_HEADER,
  FIELD_WEBHOOK_LABELS,
  FIELD_WEBHOOK_NAME,
  FIELD_WEBHOOK_OBJECT_NAME,
  FIELD_WEBHOOK_OBJECT_SELECTION,
  FIELD_WEBHOOK_EVENT,
  FIELD_WEBHOOK_INCLUDE_RESULT,
  FIELD_WEBHOOK_OBJECT_VERSION,
  FIELD_WEBHOOK_RETRY,
  FIELD_WEBHOOK_TIMEOUT,
} from "libs/constants/fields";
import { WebhookCreateEvent } from "libs/data/models";
import validators from "libs/utilities/validators";

export const WEBHOOK_CREATE_VALUES = {
  [FIELD_WEBHOOK_NAME]: "",
  [FIELD_WEBHOOK_DESCRIPTION]: "",
  [FIELD_WEBHOOK_OBJECT_SELECTION]: "deployment",
  [FIELD_WEBHOOK_OBJECT_NAME]: "",
  [FIELD_WEBHOOK_OBJECT_VERSION]: null,
  [FIELD_WEBHOOK_CALLBACKURL]: "",
  [FIELD_WEBHOOK_HEADER]: [],
  [FIELD_WEBHOOK_EVENT]: WebhookCreateEvent.deployment_request_completed,
  [FIELD_WEBHOOK_LABELS]: {},
  [FIELD_WEBHOOK_TIMEOUT]: 10,
  [FIELD_WEBHOOK_RETRY]: false,
  [FIELD_WEBHOOK_INCLUDE_RESULT]: false,
};

export const webHookCreateSchema = Yup.object().shape({
  [FIELD_WEBHOOK_NAME]: Yup.string()
    .required(validators.required.message(FIELD_WEBHOOK_NAME))
    .matches(
      validators.name.pattern,
      validators.name.message(FIELD_WEBHOOK_NAME)
    ),
  [FIELD_WEBHOOK_CALLBACKURL]: Yup.string()
    .required()
    .matches(validators.callbackUrl.pattern, validators.callbackUrl.message),
});

export const webHooksTimeOutDurations = [
  {
    label: "10 seconds",
    value: 10,
  },
  {
    label: "20 seconds",
    value: 20,
  },
  {
    label: "30 seconds",
    value: 30,
  },
];

export const deploymentEventTriggers = {
  started: WebhookCreateEvent.deployment_request_started,
  finished: WebhookCreateEvent.deployment_request_finished,
  completed: WebhookCreateEvent.deployment_request_completed,
  failed: WebhookCreateEvent.deployment_request_failed,
};

export const pipelineEventTriggers = {
  started: WebhookCreateEvent.pipeline_request_started,
  finished: WebhookCreateEvent.pipeline_request_finished,
  completed: WebhookCreateEvent.pipeline_request_completed,
  failed: WebhookCreateEvent.pipeline_request_failed,
};

export const eventTriggerLabels = {
  started: "Request started",
  finished: "Request finished",
  completed: "Request completed (successful)",
  failed: "Request failed",
};

export const experimentEventTriggerLabels = {
  started: "Run started",
  finished: "Run finished",
  completed: "Run completed (successful)",
  failed: "Run failed",
};
