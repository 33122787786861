import Trash from "@mui/icons-material/DeleteRounded";
import PersonAdd from "@mui/icons-material/PersonAddRounded";
import {
  Link,
  Box,
  useTheme,
  Tooltip,
  IconButton,
  Typography,
  Grid,
} from "@mui/material";
import { differenceWith, filter, isEqual } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useForm, FormProvider } from "react-hook-form";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { FIELD_ASSIGNEE, FIELD_ROLE } from "libs/constants/fields";
import { useProjectUserDelete } from "libs/data/customized/projects";
import { useRoleAssignmentsCreate } from "libs/data/customized/roles";
import { useOrganizationUser } from "libs/data/customized/user";
import { useOrganizationUsersList } from "libs/data/endpoints/organizations/organizations";
import {
  useProjectsGet,
  useProjectUsersList,
} from "libs/data/endpoints/projects/projects";
import { useRolesList } from "libs/data/endpoints/roles/roles";
import { useGoogleAnalytics } from "libs/hooks";
import { roleGroups } from "libs/utilities/labels-mapping";
import { isArrayHasData } from "libs/utilities/utils";

import { Loader, Dialog, PrimaryButton, DeleteDialog } from "components/atoms";
import { BaseTable } from "components/molecules";
import {
  RoleAssignmentDialog,
  RoleDetailsDialog,
  UserRoleAssignments,
} from "components/organisms";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { BaseColumn, BaseRow } from "components/molecules/BaseTable";
import type {
  OrganizationUserDetail,
  ProjectUserList,
  RoleAssignmentCreateAssigneeType,
  RoleList,
} from "libs/data/models";

const ProjectUsersOverview = () => {
  useGoogleAnalytics();
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const theme = useTheme() as AppThemeProps;

  const match = useRouteMatch();
  const history = useHistory();
  const formMethods = useForm({
    mode: "onBlur",
  });
  const [defaultRolesList, setDefaultRolesList] = useState<RoleList[]>([]);
  const [groupedRoles, setGroupedRoles] = useState<
    { label: string; options: any[] }[]
  >([]);
  const [defaultOrgUserList, setDefaultOrgUserList] = useState<
    OrganizationUserDetail[]
  >([]);
  const [currentUser, setCurrentUser] = useState("");
  const [addProjectUserDialogOpen, setAddProjectUserDialogOpen] =
    useState(false);
  const [roleAssignmentUser, setRoleAssignmentUser] =
    useState<ProjectUserList | null>(null);
  const [roleAssignmentDialogOpen, setRoleAssignmentDialogOpen] =
    useState(false);
  const [currentRole, setCurrentRole] = useState("");
  const [projectUsers, setProjectUsers] =
    useState<ProjectUserList[] | undefined>(undefined);
  const [showRoleDialog, setShowRoleDialog] = useState(false);
  const [userToDelete, setUserToDelete] =
    useState<ProjectUserList | null>(null);
  const { isAdmin } = useOrganizationUser(organizationName);
  const { data: project } = useProjectsGet(projectName);
  const { data: projectRoles } = useRolesList(projectName);
  const { data: _projectUsers, error: projectUsersError } = useProjectUsersList(
    projectName,
    {
      user_type: "user",
    }
  );

  // It appears that AdvancedTable is modifying the objects within projectUsers using the tableData property
  // By stripping this property upon the page load, the detail panel behaves as expected
  useEffect(() => {
    if (_projectUsers && !projectUsers) {
      setProjectUsers(stripTableData(_projectUsers));
    } else if (
      JSON.stringify(stripTableData(_projectUsers)) !==
      JSON.stringify(stripTableData(projectUsers))
    ) {
      setProjectUsers(_projectUsers);
    }
  }, [_projectUsers, projectUsers, setProjectUsers]);

  const { data: organizationUsers, error: organizationUsersError } =
    useOrganizationUsersList(organizationName);

  const roleAssignmentCreate = useRoleAssignmentsCreate(
    projectName,
    currentUser
  );

  const projectUserDelete = useProjectUserDelete(projectName);

  const stripTableData = (data: any) =>
    data?.map((user: any) => {
      const { _tableData, ...cleanUser } = user;

      return cleanUser;
    });

  const isLoadingProjectUsers =
    projectUsers === undefined && !projectUsersError;

  const isLoadingOrganizationUsers =
    organizationUsers === undefined && !organizationUsersError;

  const onRoleDetailsClick = useCallback(
    (role: any) => {
      setCurrentRole(role);
      setShowRoleDialog(!showRoleDialog);
    },
    [setCurrentRole, setShowRoleDialog, showRoleDialog]
  );

  useEffect(() => {
    if (organizationUsers) {
      let defaultOrganizationUsers: OrganizationUserDetail[] = [];

      // filter out org users already exist in the project
      if (projectUsers?.length) {
        defaultOrganizationUsers = filter(organizationUsers, ({ id }) => {
          return projectUsers?.every((user) => user.id !== id);
        });
      }
      setDefaultOrgUserList(defaultOrganizationUsers);
    }
  }, [setDefaultOrgUserList, organizationUsers, projectUsers]);

  useEffect(() => {
    if (projectRoles) {
      const projectRolesWithoutFileRoles = projectRoles.filter(
        ({ name }) => !name.includes("files")
      );

      setDefaultRolesList(projectRolesWithoutFileRoles);
    }
  }, [setDefaultRolesList, projectRoles]);

  useEffect(() => {
    if (isArrayHasData(defaultRolesList)) {
      const usedRoles: any = [];
      const labeledRoles = roleGroups.map((label) => {
        usedRoles.push(
          ...filter(defaultRolesList, ({ name }: { name: string }) =>
            name.includes(label)
          )
        );

        return {
          label,
          options: filter(defaultRolesList, ({ name }: { name: string }) =>
            name.includes(label)
          ),
        };
      });
      labeledRoles.push({
        label: "other",
        options: differenceWith(defaultRolesList, usedRoles, isEqual),
      });
      setGroupedRoles(labeledRoles);
    }
  }, [defaultRolesList]);

  const onAddUser = (data: any) => {
    const userId =
      defaultOrgUserList?.find(
        ({ email }) => email === data[FIELD_ASSIGNEE].value
      )?.id || "";
    const newRole = {
      assignee_type: "user" as RoleAssignmentCreateAssigneeType,
      [FIELD_ASSIGNEE]: userId,
      [FIELD_ROLE]: data[FIELD_ROLE].value,
    };
    setCurrentUser(userId);
    roleAssignmentCreate(newRole, true);
    setAddProjectUserDialogOpen(false);
  };

  const handleAddUser = () => {
    // if no other organization users to add in the project, then invite new user to organization first, then that user can be added to the project
    if (defaultOrgUserList.length) {
      setAddProjectUserDialogOpen(true);
    } else {
      history.push(`/organizations/${project?.organization_name}/team/create`);
    }
  };

  const changeCurrentUser = (user: ProjectUserList) => {
    if (roleAssignmentUser?.id !== user?.id) {
      setRoleAssignmentUser(user);
    }
  };

  const userListOptions = useMemo(
    () =>
      defaultOrgUserList.map(({ email }) => ({
        value: email,
        label: email,
      })),
    [defaultOrgUserList]
  );

  const groupedRoleOptions = useMemo(
    () =>
      groupedRoles
        .map(({ label, options }) =>
          options.map(({ name }) => ({
            label: name,
            value: name,
            group: label,
          }))
        )
        .flat(),
    [groupedRoles]
  );

  const columns = useMemo(
    () => [
      {
        title: "",
        field: "expand",
        width: "2%",
      },
      {
        title: "Email",
        field: "email",
        defaultSort: "asc",
        nowrap: true,
        render: ({ email }: { email: string }) => (
          <Link
            variant="h5"
            sx={{
              color: `${theme.palette.table.nameColumn} !important`,
            }}
          >
            {email}
          </Link>
        ),
      },
      {
        title: "First name",
        field: "name",
        nowrap: true,
      },
      {
        title: "Last name",
        field: "surname",
        nowrap: true,
      },
      {
        width: "2%",
        render: (rowData: ProjectUserList) => {
          return (
            <div className="actions_container">
              <Tooltip title="Delete">
                <span>
                  <IconButton
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setUserToDelete(rowData);
                    }}
                  >
                    <Trash />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          );
        },
      },
    ],
    [theme.palette.table.nameColumn]
  );

  return (
    <Grid item xs={12}>
      <BreadcrumbsItem to={match.url}>Project members</BreadcrumbsItem>
      {isLoadingOrganizationUsers || isLoadingProjectUsers ? (
        <Loader />
      ) : (
        <Box mt={spacing[16]}>
          <BaseTable
            columns={columns as BaseColumn[]}
            data={projectUsers}
            onRowClick={(_e, rowData: BaseRow) => {
              if (rowData?.id !== roleAssignmentUser?.id) {
                changeCurrentUser(rowData as ProjectUserList);
              } else {
                setRoleAssignmentUser(null);
              }
            }}
            expandedRowIndex={projectUsers?.findIndex(
              (user) => user.id === roleAssignmentUser?.id
            )}
            clearExpandedRow={() => setRoleAssignmentUser(null)}
            action={
              <PrimaryButton
                startIcon={<PersonAdd />}
                disabled={!isAdmin}
                onClick={handleAddUser}
              >
                Add project member
              </PrimaryButton>
            }
            renderDetailPanel={(rowData: any) => {
              return (
                <UserRoleAssignments
                  currentUser={rowData}
                  onRoleDetailsClick={onRoleDetailsClick}
                  setRoleAssignmentDialogOpen={() =>
                    setRoleAssignmentDialogOpen(!roleAssignmentDialogOpen)
                  }
                />
              );
            }}
          />
        </Box>
      )}
      <FormProvider {...formMethods}>
        <Dialog
          Actions={
            <PrimaryButton onClick={formMethods.handleSubmit(onAddUser)}>
              Add
            </PrimaryButton>
          }
          onClose={() => setAddProjectUserDialogOpen(false)}
          open={addProjectUserDialogOpen}
          title="Add project member"
        >
          <Box my={2}>
            <AutoCompleteSelectHookForm
              name={FIELD_ASSIGNEE}
              label="User"
              options={userListOptions}
              defaultValue={userListOptions[0]}
              isSearchable
            />
          </Box>
          <Box my={2}>
            <AutoCompleteSelectHookForm
              name={FIELD_ROLE}
              label="Role"
              options={groupedRoleOptions}
              defaultValue={groupedRoleOptions?.[0]}
              groupBy={(option) => option.group || ""}
              isSearchable
            />
          </Box>
        </Dialog>
      </FormProvider>
      {!!roleAssignmentUser && (
        <RoleAssignmentDialog
          currentUser={roleAssignmentUser}
          isServiceUser={false}
          toggleDialog={() =>
            setRoleAssignmentDialogOpen(!roleAssignmentDialogOpen)
          }
          isOpen={roleAssignmentDialogOpen}
        />
      )}
      <RoleDetailsDialog
        currentRole={currentRole}
        open={showRoleDialog}
        toggleDialog={() => setShowRoleDialog(!showRoleDialog)}
      />
      <DeleteDialog
        open={Boolean(userToDelete)}
        onClose={() => setUserToDelete(null)}
        onDelete={async () => {
          try {
            await projectUserDelete(userToDelete?.id);
          } finally {
            setUserToDelete(null);
          }
        }}
      >
        <Typography marginTop={spacing[16]} marginBottom={spacing[16]}>
          Are you sure you want to remove this member from the project?
        </Typography>
        <Typography marginBottom={spacing[16]} variant="h4">
          {userToDelete?.email}
        </Typography>
      </DeleteDialog>
    </Grid>
  );
};

export default ProjectUsersOverview;
