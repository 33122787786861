import { Grid } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { useParams, useRouteMatch } from "react-router-dom";

import { CustomToggle } from "components/atoms/CustomToggle";
import { EmptyCustomMetricsCard } from "components/organisms/MetricsLayout/EmptyCustomMetricsCard";
import { FIELD_DATES, FIELD_NAME } from "libs/constants/fields";
import { useCustomMetrics } from "libs/data/customized/custom-metrics/useCustomMetrics";
import { useRequestMetrics } from "libs/data/customized/metrics";
import { useDeploymentVersionRequestsGet } from "libs/data/endpoints/deployment-requests/deployment-requests";
import { getTzAwareDate } from "libs/utilities/date-util";
import { getStoredChartType } from "libs/utilities/metrics-helper";
import { metricGraphIds } from "pages/organizations/:organizationName/projects/:projectName/monitoring/metrics/constants";
import { TRAINING_DEPLOYMENT } from "pages/organizations/:organizationName/projects/:projectName/training/constants";

import { NoData } from "components/atoms";
import { MetricsLayout } from "components/organisms";

const options = [
  {
    title: "CPU utilization",
    metric: [metricGraphIds.cpuUtilization],
    isDynamicUnitPeriod: true,
  },
  {
    title: "GPU utilization",
    metric: [metricGraphIds.gpuUtilization],
    isDynamicUnitPeriod: true,
  },
  {
    title: "Memory utilization",
    metric: [metricGraphIds.memoryUtilization],
    isDynamicUnitPeriod: true,
  },
];

type RequestMetricProps = {
  deploymentName: string;
  versionName: string;
  requestId: string;
};

export const Metrics = ({
  deploymentName,
  versionName,
  requestId,
}: RequestMetricProps) => {
  const [forceRefresh, setForceRefresh] = useState(false);

  const { projectName } = useParams<{ projectName: string }>();

  const match = useRouteMatch();
  const type =
    deploymentName === TRAINING_DEPLOYMENT ? "training" : "deployment";

  const { data: requestDetails } = useDeploymentVersionRequestsGet(
    projectName,
    deploymentName,
    versionName,
    requestId
  );

  const methods = useForm({
    defaultValues: {
      dates: {
        startDate: getTzAwareDate(requestDetails?.time_created),
        endDate: getTzAwareDate(requestDetails?.time_completed),
      },
    },
  });

  const runName = useMemo(
    () => requestDetails?.request_data?.[FIELD_NAME] ?? "",
    [requestDetails]
  );

  const dates: any = useWatch({ control: methods.control, name: FIELD_DATES });

  const {
    isCustom,
    isLoading,
    setCustom,
    options: customOptions,
  } = useCustomMetrics(
    requestDetails?.id && `deployment_request_id:${requestDetails?.id}`
  );

  const optionsWithChartType = useMemo(
    () => getStoredChartType(type, isCustom ? customOptions : options),
    [customOptions, isCustom, type]
  );

  const { metricGraphs, isMetricGraphsLoading } = useRequestMetrics(
    requestDetails ? [requestId] : [],
    runName,
    dates.startDate,
    dates.endDate,
    forceRefresh,
    optionsWithChartType
  );

  const onGraphRefresh = () => {
    setForceRefresh(!forceRefresh);
  };

  // apply time range after receiving the request details
  // use `now` as endDate if the request is ongoing
  // use start and end dates if it's finished
  useEffect(() => {
    if (requestDetails?.time_started) {
      methods.setValue(FIELD_DATES, {
        startDate: getTzAwareDate(requestDetails?.time_started),
        endDate: getTzAwareDate(requestDetails?.time_completed),
      });
    }
    // adding methods dependency can cause rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestDetails]);

  const notStartedYet =
    requestDetails?.time_created && !requestDetails.time_started;
  const noMetrics = metricGraphs?.length === 0;

  return (
    <FormProvider {...methods}>
      <BreadcrumbsItem to={match.url}>Metrics</BreadcrumbsItem>
      {!notStartedYet && (
        <MetricsLayout
          graphs={metricGraphs || []}
          enableCrosshair
          onGraphRefresh={onGraphRefresh}
          isLoading={isMetricGraphsLoading || isLoading}
        >
          <CustomToggle
            label="Filter graphs:"
            isCustom={isCustom}
            setCustom={setCustom}
          />
        </MetricsLayout>
      )}
      {notStartedYet && (
        <Grid container display="flex" justifyContent="center">
          <NoData
            text={`${
              type === "training" ? "Training" : "Request"
            } hasn't started`}
          />
        </Grid>
      )}
      {!notStartedYet && noMetrics && <EmptyCustomMetricsCard type={type} />}
    </FormProvider>
  );
};
