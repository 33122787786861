import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { FIELD_TERMS_CONDITIONS } from "libs/constants/fields";
import { COOKIE_NAMES, useCustomCookies } from "libs/cookies";
import { oauthSignUp } from "libs/data/endpoints/authorize/authorize";
import { ENV_NAMES, env } from "libs/env";
import { getTzAwareDate } from "libs/utilities/date-util";
import { setError } from "store/features";

import type { AxiosError } from "axios";
import type { OauthSignUpProvider } from "libs/data/models";

export const useSignUp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [, setCookie] = useCustomCookies();
  const history = useHistory();
  const dispatch = useDispatch();

  const signUp = async (provider: OauthSignUpProvider) => {
    setIsLoading(true);
    const payload = env.get(ENV_NAMES.ON_PREMISE)
      ? { provider, [FIELD_TERMS_CONDITIONS]: true }
      : { provider };

    try {
      const response = await oauthSignUp(payload);
      const expires = new Date(getTzAwareDate().add(5, "minutes").toDate());
      if (response?.state_key) {
        setCookie(COOKIE_NAMES.STATE_KEY, response?.state_key, {
          expires,
        });
        setCookie(
          COOKIE_NAMES.NEW_OAUTH_USER,
          JSON.stringify({
            userCreated: true,
            userActivated: false,
          })
        );
      }
      if (response?.url) {
        window.location.replace(response?.url);
      }
    } catch (e) {
      dispatch(setError((e as AxiosError)?.message));
      history.push("/sign-in");
    } finally {
      setIsLoading(false);
    }
  };

  return { signUp, isLoading };
};
