import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";

import { spacing } from "assets/styles/theme";

import { SearchBarCustomStyle } from "components/atoms";

import { NodeDeploymentPipelineReadonly } from "../SharedDeploymentPipelineComponents";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { DeploymentList } from "libs/data/models";

export type SidebarLeftDeploymentsTabProps = {
  deployments: DeploymentList[] | undefined;
};

export const SidebarLeftDeploymentsTab = ({
  deployments,
}: SidebarLeftDeploymentsTabProps) => {
  const theme = useTheme() as AppThemeProps;
  const [query, setQuery] = useState("");

  return (
    <>
      <Typography
        variant="body2"
        color="textPrimary"
        style={{
          textAlign: "center",
          marginBottom: spacing[16],
        }}
      >
        Drag and drop any of your deployments onto the canvas to use in your
        pipeline.
      </Typography>

      <SearchBarCustomStyle
        theme={theme}
        fullWidth
        value={query}
        onChange={(value) => setQuery(value)}
      />

      <Box
        display="flex"
        flexDirection="column"
        overflow="auto"
        mt={spacing[20]}
      >
        {deployments
          ?.filter((deployment) => new RegExp(query).test(deployment.name))
          ?.map((deployment) => (
            <NodeDeploymentPipelineReadonly
              key={deployment.id}
              object={deployment}
              objectType="deployment"
              style={{ marginBottom: spacing[16], zIndex: "100" }}
            />
          ))}
      </Box>
    </>
  );
};
