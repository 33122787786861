import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { AutoCompleteSelect } from "components/atoms/AutoCompleteSelect";
import { useProjectsGet } from "libs/data/endpoints/projects/projects";
import {
  setProject,
  toggleTasksManager,
  useGetCurrentOrganization,
  useGetCurrentProject,
  useGetProjects,
} from "store/features";

import { CharAvatar, ConditionalWrapper } from "components/atoms";

import "./SidebarProjectName.scss";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

interface SidebarProjectNameProps {
  toggleSidebar: () => void;
  sidebarIsOpen: boolean;
}

export const SidebarProjectName = ({
  toggleSidebar,
  sidebarIsOpen,
}: SidebarProjectNameProps) => {
  const [selectedProject, setSelectedProject] = useState("");
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme() as AppThemeProps;

  const dispatch = useDispatch();

  const currentProject = useGetCurrentProject();
  const organizationName = useGetCurrentOrganization()?.name;
  const projectName = useMemo(() => currentProject?.name, [currentProject]);
  const projects = useGetProjects();

  const { data: selectedProjectData } = useProjectsGet(selectedProject, {
    swr: { enabled: !!selectedProject },
  });

  useEffect(() => {
    if (selectedProjectData) dispatch(setProject(selectedProjectData));
  }, [selectedProjectData, dispatch]);

  const handleClick = (name?: string) => {
    if (name) setSelectedProject(name);

    history.push(
      `/organizations/${organizationName}/projects/${
        name || projectName
      }/dashboard`
    );
    toggleSidebar();
    dispatch(toggleTasksManager());
  };

  const projectOptions = useMemo(() => {
    return projects?.map((project) => ({
      label: project.name,
      value: project.name,
    }));
  }, [projects]);

  return (
    <>
      {location.pathname.includes("/projects") && (
        <List className="sidebar-project-select">
          <ConditionalWrapper
            condition={!sidebarIsOpen}
            wrapper={(children) => (
              <Tooltip title="Project" placement="right">
                {children}
              </Tooltip>
            )}
          >
            <ListItem
              className="sidebar-project-select__listItem"
              button
              sx={{
                backgroundColor: theme.palette.tertiary.main,
                ":hover": { backgroundColor: theme.palette.tertiary.main },
              }}
            >
              <ListItemIcon
                className="sidebar-project-select__icon"
                onClick={() => handleClick(projectName)}
              >
                <CharAvatar text={projectName || ""} />
              </ListItemIcon>
              {sidebarIsOpen && (
                <>
                  {projects?.length && projects.length > 1 ? (
                    <Tooltip
                      placement="right"
                      title={
                        <Typography variant="h3">{projectName}</Typography>
                      }
                    >
                      <Box width={"100%"}>
                        <AutoCompleteSelect
                          options={projectOptions || []}
                          value={{
                            label: projectName ?? "",
                            value: projectName ?? "",
                          }}
                          onChange={(option) =>
                            option?.value && handleClick(option.value as string)
                          }
                          styles={{
                            "& .MuiAutocomplete-endAdornment": {
                              border: 0,
                            },
                            "& .MuiInputBase-root": {
                              color: theme.palette.primary.contrastText,
                            },
                          }}
                        />
                      </Box>
                    </Tooltip>
                  ) : (
                    <div className="sidebar-project-select__projectNameBlock">
                      <Typography
                        onClick={() => handleClick(projectName)}
                        variant="h5"
                        noWrap
                        sx={{
                          color: theme.palette.sidebarMenu.organizationText,
                        }}
                      >
                        {projectName}
                      </Typography>
                    </div>
                  )}
                </>
              )}
            </ListItem>
          </ConditionalWrapper>
        </List>
      )}
    </>
  );
};
