import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion as MUIAccordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from "@mui/material";

import { borderRadius, spacing } from "assets/styles/theme";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { ReactNode } from "react";

type AccordionProps = {
  title: string;
  titleVariant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  children: ReactNode;
};

export const Accordion = ({
  title,
  titleVariant = "h6",
  children,
}: AccordionProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <MUIAccordion
      disableGutters
      sx={{
        borderColor: theme.palette.neutrals[200],
        boxShadow: "none",
        borderStyle: "solid",
        borderWidth: "1px",
        background: "transparent",
        borderRadius: borderRadius[4],
        "&.MuiAccordion-root:before": { display: "none" },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          "& .MuiAccordionSummary-content": { flexGrow: 0 },
          "& svg": { fill: theme.palette.text.primary },
        }}
      >
        <Typography
          variant={titleVariant}
          sx={{
            flexGrow: 0,
            marginRight: spacing[8],
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: `${spacing[20]} ${spacing[36]}` }}>
        {children}
      </AccordionDetails>
    </MUIAccordion>
  );
};
