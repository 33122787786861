import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { COOKIE_NAMES, useCustomCookies } from "libs/cookies";
import { oauthSignIn } from "libs/data/endpoints/authorize/authorize";
import { ENV_NAMES, env } from "libs/env";
import { getTzAwareDate } from "libs/utilities/date-util";
import {
  setEmail,
  setError,
  setOauthKey,
  setProvider,
  setUrl,
  useAuthentication,
} from "store/features";

import type { AxiosError } from "axios";

export type HandleLoginPayload = { email: string; remember_me: boolean };

export const useHandleLogin = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [, setCookie] = useCustomCookies();
  const [isLoading, setIsLoading] = useState(false);
  const { error } = useAuthentication();

  const handleLogin = async ({ email, remember_me }: HandleLoginPayload) => {
    setIsLoading(true);
    env.set(ENV_NAMES.REMEMBER_ME, remember_me);
    dispatch(setEmail(email));

    try {
      const { provider, url, state_key } = await oauthSignIn({ email });
      setIsLoading(false);
      dispatch(setProvider(provider));
      dispatch(setUrl(url));
      dispatch(setOauthKey(state_key));
      if (error) {
        dispatch(setError(null));
      }
      if (provider === "ubiops" || !env.get(ENV_NAMES.OAUTH_ENABLED)) {
        history.push("/sign-in/password");
      } else {
        if (state_key) {
          const expires = new Date(getTzAwareDate().add(5, "minutes").toDate());
          setCookie(COOKIE_NAMES.STATE_KEY, state_key, {
            expires,
          });
        }
        // @ts-ignore
        window.location = url;
      }
    } catch (e: unknown) {
      const error = e as AxiosError;
      dispatch(setError(error.message));
      setIsLoading(false);
    }
  };

  return { handleLogin, isLoading };
};
