import {
  INT_TYPE,
  DOUBLE_TYPE,
  BOOLEAN_TYPE,
  STRING_TYPE,
  ARRAY_STRING_TYPE,
  ARRAY_INT_TYPE,
  ARRAY_DOUBLE_TYPE,
  FILE_TYPE,
  DICT_TYPE,
  ARRAY_FILE_TYPE,
} from "libs/constants/constants";
import { environmentDependencyFiles } from "pages/organizations/:organizationName/projects/:projectName/environments/constants";
import { FILE_REF } from "pages/organizations/:organizationName/projects/:projectName/storage/constants";
import { TRAINING_DEPLOYMENT } from "pages/organizations/:organizationName/projects/:projectName/training/constants";

import type { EnvironmentRevisionsFileUploadBody } from "libs/data/models";
import type { TestContext } from "yup";

export const validateJSON = (value: string) => {
  try {
    JSON.parse(value);

    return true;
  } catch (e) {
    return false;
  }
};

const validators = {
  name: {
    pattern: /^[a-z0-9][a-z0-9-]*[a-z0-9]$/,
    message: (field = "field") =>
      `The ${field} can only consist of lower case letters, numbers and dashes(-) and should at least be 2 characters. It cannot start or end with "-".`,
    value:
      (resource = "resource") =>
      (value?: string) => {
        if (resource === "deployment" && value === TRAINING_DEPLOYMENT)
          return "This deployment name is reserved and cannot be used";
        else if (value === "create")
          return `Calling your ${resource} "create" is not recommended and will lead to conflicts with our tools. If you still want to proceed, you can do so from another UbiOps tool (Swagger, CLI, client library...).`;

        return;
      },
    reservedNameYup:
      (resource = "resource") =>
      (value: string, ctx: TestContext<unknown>) => {
        if (resource === "deployment" && value === TRAINING_DEPLOYMENT)
          return ctx.createError({
            message: "This deployment name is reserved and cannot be used",
          });
        else if (value === "create") {
          return ctx.createError({
            message: `Calling your ${resource} "create" is not recommended and will lead to conflicts with our tools. If you still want to proceed, you can do so from another UbiOps tool (Swagger, CLI, client library...).`,
          });
        }

        return true;
      },
  },
  fieldMetricName: {
    pattern: /^custom.[a-z0-9][a-z0-9-_]*[a-z0-9]$/,
    message: () =>
      `Metric name can only consist of lower case letters, numbers, dashes(-) and underscores(_) and should start with "custom.". It cannot end with a _ or -.`,
  },
  fieldName: {
    pattern: /^[a-z0-9][a-z0-9-_]*[a-z0-9]$/,
    message: (field = "field") =>
      `The ${field} can only consist of lower case letters, numbers, dashes(-) and underscores(_) and should at least be 2 characters. It cannot start or end with a _ or -.`,
  },
  envVarName: {
    pattern: /^[a-zA-Z]([a-zA-Z0-9_]+)?[a-zA-Z0-9]$/,
    message: `Environment variable name can only consist of letters, numbers and underscores and should only start with a letter and at least be 2 characters. It cannot start or end with a _`,
  },
  labelKey: {
    pattern: /^(?![-.])([a-zA-Z0-9-_.]+)[^-_.\s]$/,
    message:
      "The label key can only consist of letters, numbers, dashes(-), underscores(_) and dots(.) and should at least be 1 character. It cannot start with a - or ., or end with a _, - or .",
  },
  labelValue: {
    pattern: /^(?![-_.])([a-zA-Z0-9-_.]+)[^-_.\s]$/,
    message:
      "The label value can only consist of letters, numbers, dashes(-), underscores(_) and dots(.) and should at least be 1 character. It cannot start or end with a _, - or .",
  },
  uniqueKeys: {
    message: "The label with the same key already exists.",
  },
  domain: {
    pattern:
      // eslint-disable-next-line no-useless-escape
      /^((https?:\/\/)|(\/)|(..\/))(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
    message: "Please enter a valid domain.",
  },
  publicUrl: {
    pattern: /^(ftp|http|https):\/\/[^ "]+$/,
    message: "Please enter a valid url",
  },
  callbackUrl: {
    pattern:
      // eslint-disable-next-line no-useless-escape
      /^(https:\/\/|http:\/\/)([-a-zA-Z0-9@:%_+.~#?&//=/\]\[]){2,256}\.([-a-zA-Z0-9@:%_+.~#?&//=/]*)$/,
    message: "Invalid URL",
  },
  notFound: {
    message: (field = "field") =>
      `No ${field} found. Please create a ${field} first.`,
  },
  fields: {
    message: (field = "field") =>
      `The ${field} should not be empty for structured type!`,
  },
  required: {
    message: (field = "field") => `The ${field} is required.`,
  },
  email: {
    pattern:
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+[a-zA-Z0-9]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
    message:
      "Invalid email address. Please also make sure there are no whitespaces.",
  },
  phone: {
    pattern: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    message: "Invalid phone number",
  },
  configuration: {
    message: (field = "fields", regex: string) =>
      `The ${field} is invalid (${regex}).`,
  },
  json: {
    validate: (value: string) => validateJSON(value) || "Invalid JSON format",
  },
  password_requirements: {
    minLength: 8,
    message: `The password should contain at least 8 characters.`,
  },
  password_confirmation: {
    message: "The password confirmation should match the password.",
  },
  positive_number: {
    message: "This value must be greater than or equal to 0.",
  },
  minimum_timeout: {
    message: "Minimum allowed value for timeout is 10 (seconds)",
  },
  maximum_timeout: {
    message: (timeout: number) =>
      `Maximum allowed value for timeout is ${timeout} (seconds)`,
  },
  minimum_memory: {
    message: "Memory allocation must be greater than or equal to 256",
  },
  maximum_memory: {
    message: "Memory allocation must be less than or equal to 1048576",
  },
  retention_type: {
    message: "This value must be an integer.",
  },
  minimum_retention: {
    message: "Retention time must be more than or equal to 3600",
  },
  maximum_retention: {
    message: "Retention time must be less than or equal to 2419200",
  },
  maximum_credits: {
    message: "The value exceeds your credit limit.",
  },
  minimum_object_required: {
    message: (object = "object") =>
      `You must create at least one ${object} to create a request schedule`,
  },
  minimum_queue_size: {
    message: (number: number) =>
      `Minimum allowed value for queue size is ${number}`,
  },
  maximum_queue_size: {
    message: (number: number) =>
      `Maximum allowed value for queue size is ${number}`,
  },
  minimum_instance_processes: {
    message: (number: number) =>
      `Minimum allowed value for instance processes is ${number}`,
  },
  maximum_instance_processes: {
    message: (number: number) =>
      `Maximum allowed value for instance processes is ${number}`,
  },
  [INT_TYPE]: {
    validate: (value: string) =>
      value === "null" ||
      (!isNaN(parseInt(value)) && value.match(/^-?\d+$/)) ||
      "This value must be an integer.",
  },
  [DOUBLE_TYPE]: {
    validate: (value: string) =>
      value === "null" ||
      !isNaN(parseFloat(value)) ||
      "This value must be a double.",
  },
  [BOOLEAN_TYPE]: {
    validate: (value: unknown) =>
      [
        "TRUE",
        "FALSE",
        "True",
        "False",
        "true",
        "false",
        "1",
        "0",
        "t",
        "f",
        "y",
        "n",
        "yes",
        "no",
      ].includes(value as string) || "This value must be a boolean.",
  },
  [STRING_TYPE]: {
    validate: () => true,
  },
  [FILE_TYPE]: {
    validate: (value: string) =>
      value.match(/^[a-z0-9-]+$/) || "You must provide a file.",
  },
  [ARRAY_STRING_TYPE]: {
    validate: () => true,
  },
  [ARRAY_INT_TYPE]: {
    validate: () => true,
  },
  [ARRAY_DOUBLE_TYPE]: {
    validate: () => true,
  },
  [ARRAY_FILE_TYPE]: {
    validate: (value: string) => {
      if (value) {
        try {
          const array = JSON.parse(value);

          return (
            array.every((filePath: string) => filePath.startsWith(FILE_REF)) ||
            "Every file in the array should start with ubiops-file://"
          );
        } catch (e) {
          return "Invalid array";
        }
      }
    },
  },
  [DICT_TYPE]: {
    validate: (value: string) => {
      if (value) {
        try {
          JSON.parse(value);
        } catch (e) {
          return "Invalid dictionary format";
        }
      }

      return;
    },
  },
};

export const environmentDependenciesValidator = (fileName: string) => {
  return environmentDependencyFiles.some(
    (fileNames) => fileName.endsWith(".zip") || fileName === fileNames
  );
};

export const yupValidators = {
  uniqueKeys: {
    message: validators.uniqueKeys.message,
    test: (values: string[]) => (value?: unknown) => {
      const string = value as string | undefined;

      return !string || !values.includes(string);
    },
  },
  fileExtension: {
    message:
      "Your requirements must be contained within a requirements.txt, ubiops.yaml or a zip file.",

    test: (value?: unknown) => {
      const body = value as EnvironmentRevisionsFileUploadBody | undefined;

      if (body?.file) {
        const file = body.file as File;
        const fileName: string = file?.name ?? "";

        return environmentDependenciesValidator(fileName);
      }

      return true;
    },
  },
};

export default validators;
