import { Chip, useTheme } from "@mui/material";

import { colorStringByStatus } from "assets/styles/theme/utils/colorStringByStatus";
import { useChipColorByStatus } from "libs/hooks/useColorByStatus";

import type { ChipProps } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme.d";

interface AuditEventChipProps extends ChipProps {
  action: string;
}

export const AuditEventChip = ({ action, ...props }: AuditEventChipProps) => {
  const theme = useTheme() as AppThemeProps;

  const chipBackground = useChipColorByStatus();

  const hoverBackground =
    action === "create"
      ? theme.palette.success.dark
      : action === "update"
      ? theme.palette.warning.dark
      : action === "delete"
      ? theme.palette.error.dark
      : action === "info"
      ? theme.palette.info.dark
      : theme.palette.neutrals[400];

  return (
    <Chip
      size="small"
      label={action}
      color={colorStringByStatus(action)}
      {...props}
      sx={{
        fontWeight: 400,
        background: chipBackground(action),
        "&:hover": {
          backgroundColor: hoverBackground,
          color: theme.palette.neutrals[0],
        },
      }}
    />
  );
};
