import Plus from "@mui/icons-material/AddBoxRounded";
import { Typography, Box, Grid } from "@mui/material";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

import { CurvedArrow } from "assets/images/CurvedArrow";
import { IlluEmptyCustomMetrics } from "assets/images/IlluEmptyCustomMetrics";
import { IlluExperiment } from "assets/images/IlluExperiment";
import { IlluTrigger } from "assets/images/IlluTrigger";
import { spacing } from "assets/styles/theme";
import { DOC_LINKS } from "libs/constants/documentation-links";
import { PIPELINE_PERMISSIONS } from "libs/constants/permissions";
import { BaseUrlContext } from "libs/contexts";
import { useDeviceDetect } from "libs/hooks";
import { useGetCurrentOrganization } from "store/features";
import { useGetPermissions } from "store/features/permissions";

import { ExternalLink, PrimaryButton, SecondaryButton } from "components/atoms";

const contentList = [
  {
    Icon: IlluExperiment,
    title: "Create an experiment",
    subtitle:
      "Define the training setup that you will use for your training code.",
  },
  {
    Icon: IlluTrigger,
    title: "Trigger training runs",
    subtitle:
      "Create multiple training runs for your experiment. These runs are the actual code  executions on a specific dataset.",
  },
  {
    Icon: IlluEmptyCustomMetrics,
    title: "Evaluate runs",
    subtitle: "Compare the results of your training runs.",
  },
];

export const ExperimentEmptyOverview = () => {
  const { isMobile } = useDeviceDetect();
  const history = useHistory();
  const baseUrl = useContext(BaseUrlContext);
  const [projectPermissions] = useGetPermissions();
  const organization = useGetCurrentOrganization();

  const createEnabled =
    !!projectPermissions[PIPELINE_PERMISSIONS["create"]] &&
    organization?.status === "active";

  return (
    <Grid
      container
      item
      xs={12}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      gap={2}
      paddingRight={spacing[40]}
      paddingLeft={spacing[12]}
    >
      <Box>
        <Typography align="center" variant="h2">
          Create your first experiment
        </Typography>
      </Box>
      <Grid item xs={12} md={5}>
        <Typography align="center" variant="body1">
          Before you can train your model you first need to create an
          experiment. Experiments define, among other things, the{" "}
          <ExternalLink
            launchIcon={false}
            style={{ marginRight: spacing[4] }}
            href={DOC_LINKS.ENVIRONMENTS}
          >
            environment
          </ExternalLink>{" "}
          your training code will run in and what instance type will be used.
          After creating an experiment you can initiate{" "}
          <ExternalLink launchIcon={false} href={DOC_LINKS.TRAINING_RUNS}>
            training runs
          </ExternalLink>
        </Typography>
      </Grid>
      <Box
        display="flex"
        justifyContent="space-evenly"
        flexWrap="wrap"
        marginY={spacing[2]}
        gap={4}
      >
        {contentList.map(({ Icon, title, subtitle }, key) => (
          <Box key={key} display="flex" alignItems="flex-start" gap={2}>
            {!isMobile && key === 1 && (
              <Box
                display="flex"
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
              >
                <CurvedArrow />
              </Box>
            )}
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              width={spacing[300]}
              gap={1}
            >
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{
                  height: spacing[200],
                  width: spacing[256],
                  marginBottom: spacing[8],
                }}
              >
                <Icon />
              </Box>{" "}
              <Box display="flex" flexDirection="column">
                <Box>
                  <Typography
                    textAlign="center"
                    variant="h3"
                    style={{ marginBottom: spacing[16] }}
                  >
                    {title}
                  </Typography>
                </Box>
                <Box>
                  <Typography align="center" variant="body1">
                    {subtitle} <br />
                  </Typography>
                </Box>
              </Box>
            </Box>
            {!isMobile && key === 1 && (
              <Box
                display="flex"
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
              >
                <CurvedArrow />
              </Box>
            )}
          </Box>
        ))}
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <Box>
          {!!createEnabled && (
            <PrimaryButton
              startIcon={<Plus />}
              onClick={() => history.push(`${baseUrl}/experiments/create`)}
            >
              Create your first experiment
            </PrimaryButton>
          )}
        </Box>
        <SecondaryButton href={DOC_LINKS.TRAINING_TUTORIAL} target="_blank">
          Starter tutorial
        </SecondaryButton>
        <Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="h6">Need some inspiration?</Typography>
            <ExternalLink href={DOC_LINKS.TUTORIALS}>
              Check our tutorials
            </ExternalLink>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};
