import { Checkbox, Tooltip, Typography } from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { useDeviceDetect } from "libs/hooks";
import { renderTimestamp } from "libs/utilities/date-util";
import { formatStatusLabel } from "libs/utilities/statuses";

import { StatusIcon, TableLink } from "components/atoms";

import { RequestsRowActions } from "./RequestsRowActions";
import { getObjectLink } from "../RequestResultsDialog/utils";

import type { Query, RequestParameter, RequestRow } from "./types";

interface RequestsColumnsProps {
  allowGet: boolean;
  allowCreate: boolean;
  allowDelete: boolean;
  allowLogs: boolean;
  allowUpdate: boolean;
  logsUrl: string;
  requestParameters: RequestParameter;
  selectedRequests: string[];
  setSelectedRequest: (rowData: RequestRow) => void;
  setSelectedRequests: (ids: string[]) => void;
  setIsRequestResultsDialogOpen: (isOpen: boolean) => void;
  setIsCancelRequestDialogOpen: (isOpen: boolean) => void;
  setIsDeleteDialogOpen: (isOpen: boolean) => void;
  query: Query;
  setQuery?: (query: Query) => void;
  refresh: () => void;
  tableOptions?: {
    withoutMultiSelect?: boolean;
    withoutDeleteButton?: boolean;
    withoutCancelButton?: boolean;
  };
  displayDeployment?: boolean;
  hasRetryButton: boolean;
}

export const useRequestsColumns = ({
  allowGet,
  allowCreate,
  allowDelete,
  allowLogs,
  allowUpdate,
  logsUrl,
  requestParameters,
  setSelectedRequest,
  setSelectedRequests,
  selectedRequests,
  setIsRequestResultsDialogOpen,
  setIsCancelRequestDialogOpen,
  setIsDeleteDialogOpen,
  tableOptions,
  query,
  setQuery,
  refresh,
  displayDeployment = false,
  hasRetryButton,
}: RequestsColumnsProps) => {
  const { projectName, organizationName } =
    useParams<{ organizationName: string; projectName: string }>();
  const { isMobile } = useDeviceDetect();

  const isPipelineMode =
    displayDeployment && query?.filters?.object_type === "pipeline";

  const columns = useMemo(
    () =>
      [
        {
          title: "Status",
          field: "status",
          disableSort: false,
          sorting: false,
          width: "10%",
          editable: "never",
          render: (rowData: { status: string }) => (
            <StatusIcon
              label={rowData ? formatStatusLabel(rowData.status) : ""}
              status={rowData?.status}
            />
          ),
        },
        displayDeployment && {
          title: isPipelineMode ? "Pipeline" : "Deployment",
          field: isPipelineMode ? "pipeline" : "deployment",
          width: "10%",
          render: (rowData: { deployment: string; pipeline: string }) => {
            const rowDataToDisplay = isPipelineMode
              ? rowData.pipeline
              : rowData.deployment;
            const entityLink = getObjectLink(
              isPipelineMode ? "pipeline" : "deployment",
              organizationName,
              projectName,
              rowDataToDisplay
            );

            return allowGet ? (
              <Tooltip title={rowData.deployment}>
                <TableLink
                  to={entityLink}
                  variant="bold"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                    width: "100%",
                    maxWidth: isMobile ? "70px" : "100%",
                  }}
                >
                  {rowDataToDisplay}
                </TableLink>
              </Tooltip>
            ) : (
              <Tooltip title={rowDataToDisplay}>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                    width: "100%",
                    maxWidth: isMobile ? "70px" : "100%",
                  }}
                >
                  {rowDataToDisplay}
                </Typography>
              </Tooltip>
            );
          },
        },
        {
          title: "Request ID",
          field: "id",
          sorting: false,
          editable: "never",
          nowrap: true,
          width: "20%",
          render: (rowData: { id: string }) => (
            <Tooltip title={rowData.id}>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "block",
                  width: "100%",
                  maxWidth: isMobile ? "100px" : "100%",
                }}
              >
                {rowData.id}
              </Typography>
            </Tooltip>
          ),
        },
        !isMobile && {
          title: "Created",
          field: "time_created",
          type: "datetime",
          nowrap: true,
          enableSorting: true,
          width: "10%",
          getSortDirection: () => query?.orderDirection || "desc",
          isSortingActive: () => true,
          setSorting: () => {
            if (query?.orderDirection === "asc") {
              setQuery?.({
                ...query,
                orderDirection: undefined,
              });
            } else if (query?.orderDirection === "desc") {
              setQuery?.({
                ...query,
                orderDirection: "asc",
              });
            } else {
              setQuery?.({
                ...query,
                orderDirection: "asc",
              });
            }
          },
          render: (rowData: any) => renderTimestamp(rowData, "time_created"),
        },
        !isMobile && {
          title: "Started",
          field: "time_started",
          sorting: false,
          type: "datetime",
          width: "10%",
          nowrap: true,
          render: (rowData: any) => renderTimestamp(rowData, "time_started"),
        },
        !isMobile && {
          title: "Completed",
          field: "time_completed",
          sorting: false,
          type: "datetime",
          width: "10%",
          nowrap: true,
          render: (rowData: any) => renderTimestamp(rowData, "time_completed"),
        },
        {
          sorting: false,
          disableClick: true,
          nowrap: true,
          align: "right",
          width: isMobile ? "55%" : "20%",
          cellStyle: { fontSize: "0.9em" },
          render: (rowData: RequestRow) => (
            <RequestsRowActions
              rowData={rowData}
              allowDelete={allowDelete}
              tableOptions={tableOptions}
              allowGet={allowGet}
              requestParameters={requestParameters}
              setSelectedRequest={setSelectedRequest}
              setIsRequestResultsDialogOpen={setIsRequestResultsDialogOpen}
              setIsCancelRequestDialogOpen={setIsCancelRequestDialogOpen}
              setIsDeleteDialogOpen={setIsDeleteDialogOpen}
              allowLogs={allowLogs}
              allowUpdate={allowUpdate}
              allowCreate={allowCreate}
              logsUrl={logsUrl}
              refresh={refresh}
              hasRetryButton={hasRetryButton}
            />
          ),
        },
      ].filter(Boolean),
    [
      displayDeployment,
      isPipelineMode,
      isMobile,
      organizationName,
      projectName,
      allowGet,
      query,
      setQuery,
      allowDelete,
      tableOptions,
      requestParameters,
      setSelectedRequest,
      setIsRequestResultsDialogOpen,
      setIsCancelRequestDialogOpen,
      setIsDeleteDialogOpen,
      allowLogs,
      allowUpdate,
      allowCreate,
      logsUrl,
      refresh,
      hasRetryButton,
    ]
  );
  if (tableOptions?.withoutMultiSelect) return columns;

  const multiSelectColumn = {
    title: "",
    field: "",
    type: "checkbox",
    nowrap: true,
    width: "2%",
    render: (rowData: RequestRow) => (
      <td
        style={{
          paddingLeft: 5,
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Checkbox
          checked={Boolean(
            rowData?.id && selectedRequests.includes(rowData.id)
          )}
          onChange={(_event, checked) => {
            if (checked && rowData?.id) {
              setSelectedRequests([...selectedRequests, rowData?.id]);
            } else {
              setSelectedRequests(
                selectedRequests.filter((id) => id !== rowData?.id)
              );
            }
          }}
        ></Checkbox>
      </td>
    ),
  };

  return [multiSelectColumn, ...columns];
};
