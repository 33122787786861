/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * Programmatic interface to UbiOps
 * OpenAPI spec version: v2.1
 */
import useSwr from "swr";

import { orvalAxios } from "../../axios/index";

import type { ErrorType } from "../../axios/index";
import type {
  AuditList,
  EnvironmentVariableCreateBody,
  EnvironmentVariableList,
  Logs,
  LogsCreate,
  ProjectAuditEventsListParams,
  ProjectCreate,
  ProjectDetail,
  ProjectList,
  ProjectRequestsListParams,
  ProjectResourceUsage,
  ProjectUpdate,
  ProjectUsage,
  ProjectUserCreate,
  ProjectUserList,
  ProjectUsersListParams,
  ProjectsListParams,
  ProjectsUsageGetParams,
  QuotaDetail,
  RequestsOverview,
} from "../../models";
import type { Key, SWRConfiguration } from "swr";

// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * 
### Description
List all projects to which the user making request has access. The projects in organizations to which the user belongs are shown.

### Response Structure
A list of details of the projects
- `id`: Unique identifier for the project (UUID)
- `name`: Name of the project
- `creation_date`: Time the project was created
- `advanced_permissions`: A boolean to enable/disable advanced permissions for the project
- `organization_name`: Name of the organization in which the project is created

### Optional Parameters
These parameters should be given as query parameters
- `organization`: Name of the organization whose projects should be obtained

#### Response Examples
```
[
  {
    "id": "e988ddc0-3ef1-42d2-ab30-9f810a5e7063",
    "name": "project-1",
    "creation_date": "2018-10-26",
    "advanced_permissions": false,
    "organization_name": "organization-1"
  },
  {
    "id": "e6a85cd7-94cc-44cf-9fa0-4b462d5a71ab",
    "name": "project-2",
    "creation_date": "2019-06-20",
    "advanced_permissions": false,
    "organization_name": "organization-2"
  }
]
```

 * @summary List projects
 */
export const projectsList = (
  params?: ProjectsListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ProjectList[]>(
    { url: `/projects`, method: "get", params },
    options
  );
};

export const getProjectsListKey = (params?: ProjectsListParams) =>
  [`/projects`, ...(params ? [params] : [])] as const;

export type ProjectsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectsList>>
>;
export type ProjectsListQueryError = ErrorType<unknown>;

/**
 * @summary List projects
 */
export const useProjectsList = <TError = ErrorType<unknown>>(
  params?: ProjectsListParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof projectsList>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getProjectsListKey(params) : null));
  const swrFn = () => projectsList(params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create a new project with the provided name.

**Only the organization admins have permission to make this request.** When a new project is created, the current organization admins are assigned project-admin role for the created project.

### Required Parameters
- `name`: Name of the project. The name is globally unique. It can only consist of lowercase letters, numbers and dashes (-), and must start with a lowercase letter.
- `organization_name`: Name of the organization in which the project is going to be created

### Optional Parameters
- `advanced_permissions`: A boolean to enable/disable advanced permissions for the project. It defaults to False.
- `credits`: Maximum usage of credits, calculated by multiplying the credit rate of a deployment instance type by the number of hours they are running. It defaults to null, meaning that there are no limits.
- `cors_origins`: List of origins from which the requests are allowed for the project

#### Request Examples
```
{
  "name": "project-1",
  "organization_name": "organization-1"
}
```

### Response Structure
Details of the created project
- `id`: Unique identifier for the project (UUID)
- `name`: Name of the project
- `creation_date`: Time the project was created
- `advanced_permissions`: A boolean to enable/disable advanced permissions for the project
- `organization_name`: Name of the organization in which the project is created
- `credits`: Maximum usage of credits, calculated by multiplying the credit rate of a deployment instance type by the number of hours they are running
- `suspended`: A boolean indicating whether the project is suspended due to going over the credits limit
- `suspended_reason`: Description explaining why the project is suspended
- `cors_origins`: List of origins from which the requests are allowed for the project

#### Response Examples
```
{
  "id": "e988ddc0-3ef1-42d2-ab30-9f810a5e7063",
  "name": "project-1",
  "creation_date": "2018-10-26",
  "advanced_permissions": false,
  "organization_name": "organization-1",
  "credits": null,
  "suspended": false,
  "suspended_reason": null,
  "cors_origins": []
}
```

 * @summary Create projects
 */
export const projectsCreate = (
  projectCreate: ProjectCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ProjectDetail>(
    {
      url: `/projects`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: projectCreate,
    },
    options
  );
};

/**
 * 
### Description
Get the details of a single project. The user making the request must have appropriate permissions.

### Response Structure
Details of a project
- `id`: Unique identifier for the project (UUID)
- `name`: Name of the project
- `creation_date`: Time the project was created
- `advanced_permissions`: A boolean to enable/disable advanced permissions for the project
- `organization_name`: Name of the organization in which the project is created
- `credits`: Maximum usage of credits, calculated by multiplying the credit rate of a deployment instance type by the number of hours they are running
- `suspended`: A boolean indicating whether the project is suspended due to going over the credits limit
- `suspended_reason`: Description explaining why the project is suspended
- `cors_origins`: List of origins from which the requests are allowed for the project

#### Response Examples
```
{
  "id": "e988ddc0-3ef1-42d2-ab30-9f810a5e7063",
  "name": "project-1",
  "creation_date": "2018-10-26",
  "advanced_permissions": false,
  "organization_name": "organization-1",
  "credits": 10000,
  "suspended": false,
  "suspended_reason": null,
  "cors_origins": []
}
```

 * @summary Get details of a project
 */
export const projectsGet = (
  projectName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ProjectDetail>(
    { url: `/projects/${projectName}`, method: "get" },
    options
  );
};

export const getProjectsGetKey = (projectName: string) =>
  [`/projects/${projectName}`] as const;

export type ProjectsGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectsGet>>
>;
export type ProjectsGetQueryError = ErrorType<unknown>;

/**
 * @summary Get details of a project
 */
export const useProjectsGet = <TError = ErrorType<unknown>>(
  projectName: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof projectsGet>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getProjectsGetKey(projectName) : null));
  const swrFn = () => projectsGet(projectName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Update the name of a single project. The user making the request must have appropriate permissions.

### Optional Parameters
- `name`: New project name
- `advanced_permissions`: A boolean to enable/disable advanced permissions for the project
- `credits`: Maximum usage of credits, calculated by multiplying the credit rate of a deployment instance type by the number of hours they are running
- `suspend`: A boolean to suspend and activate projects. If the project is already suspended by UbiOps, it is not possible to suspend/activate the project.
- `cors_origins`: List of origins from which the requests are allowed for the project

#### Request Examples
```
{
  "name": "project-name-example"
}
```

```
{
  "name": "project-name-example",
  "cors_origins": ["https://test.com"]
}
```

### Response Structure
Details of a project
- `id`: Unique identifier for the project (UUID)
- `name`: Name of the project
- `creation_date`: Time the project was created
- `advanced_permissions`: A boolean to enable/disable advanced permissions for the project
- `organization_name`: Name of the organization in which the project is created
- `credits`: Maximum usage of credits, calculated by multiplying the credit rate of a deployment instance type by the number of hours they are running
- `suspended`: A boolean indicating whether the project is suspended due to going over the credits limit
- `suspended_reason`: Description explaining why the project is suspended
- `cors_origins`: List of origins from which the requests are allowed for the project

#### Response Examples
```
{
  "id": "e988ddc0-3ef1-42d2-ab30-9f810a5e7063",
  "name": "project-1",
  "creation_date": "2018-10-26",
  "advanced_permissions": false,
  "organization_name": "organization-1",
  "credits": 10000,
  "suspended": false,
  "suspended_reason": null,
  "cors_origins": ["https://test.com"]
}
```

 * @summary Update a project
 */
export const projectsUpdate = (
  projectName: string,
  projectUpdate: ProjectUpdate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ProjectDetail>(
    {
      url: `/projects/${projectName}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: projectUpdate,
    },
    options
  );
};

/**
 * 
### Description
Delete a project. The user making the request must have appropriate permissions.

**When project is deleted, all the deployments and pipelines defined in it are also deleted.**

 * @summary Delete a project
 */
export const projectsDelete = (
  projectName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    { url: `/projects/${projectName}`, method: "delete" },
    options
  );
};

/**
 * 
### Description
List all audit events in a project including all objects

### Optional Parameters
The following parameters should be given as query parameters:
- `action`: Type of action. It can be one of: create, update, delete, info.
- `limit`: The maximum number of audit events given back, default is 50
- `offset`: The number which forms the starting point of the audit events given back. If offset equals 2, then the first 2 events will be omitted from the list.

### Response Structure
A list of details of the audit events in the project
- `id`: Unique identifier for the audit event (UUID)
- `date`: The date when the action was performed
- `action`: Type of action. It can be one of: create, update, delete, info. *info* action denotes that the action does not fall into create, update or delete categories.
- `user`: Email of the user who performed the action
- `event`: Description of the event
- `object_type`: Type of the object on which the action was performed
- `object_name`: Name of the object on which the action was performed. If the object is deleted at the time of listing audit events, this field is empty.

#### Response Examples
```
[
  {
    "id": "54c1ea23-5773-4821-8fd7-1b577cc301bc",
    "date": "2020-05-23T11:53:02.873+00:00",
    "action": "create",
    "user": "user@example.com",
    "event": "Created project test-project",
    "object_type": "project",
    "object_name": "test-project"
  },
  {
    "id": "764e254c-7402-4445-ac79-009d08b21caa",
    "date": "2020-05-23T11:57:20.072+00:00",
    "action": "create",
    "user": "user@example.com",
    "event": "Created deployment deployment-1",
    "object_type": "deployment",
    "object_name": "deployment-1"
  }
]
```

 * @summary List audit events in a project
 */
export const projectAuditEventsList = (
  projectName: string,
  params?: ProjectAuditEventsListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<AuditList[]>(
    { url: `/projects/${projectName}/audit`, method: "get", params },
    options
  );
};

export const getProjectAuditEventsListKey = (
  projectName: string,
  params?: ProjectAuditEventsListParams
) => [`/projects/${projectName}/audit`, ...(params ? [params] : [])] as const;

export type ProjectAuditEventsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectAuditEventsList>>
>;
export type ProjectAuditEventsListQueryError = ErrorType<unknown>;

/**
 * @summary List audit events in a project
 */
export const useProjectAuditEventsList = <TError = ErrorType<unknown>>(
  projectName: string,
  params?: ProjectAuditEventsListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof projectAuditEventsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getProjectAuditEventsListKey(projectName, params) : null);
  const swrFn = () =>
    projectAuditEventsList(projectName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
List the environment variables defined for the project. These are the variables that are inherited by all deployments in this project.

### Response Structure
A list of variables described by the following fields:
- `id`: Unique identifier for the environment variable
- `name`: Variable name
- `value`: Variable value (will be null for secret variables)
- `secret`: Boolean that indicates if this variable contains sensitive information

#### Response Examples
```
[
  {
    "id": "4c15a27e-25ea-4be0-86c7-f4790389d061",
    "name": "database_schema",
    "value": "public",
    "secret": false
  },
  {
    "id": "06c2c8be-507e-4fae-981d-54e94f22dab0",
    "name": "database_password",
    "value": null,
    "secret": true
  }
]
```

 * @summary List project environment variables
 */
export const projectEnvironmentVariablesList = (
  projectName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentVariableList[]>(
    { url: `/projects/${projectName}/environment-variables`, method: "get" },
    options
  );
};

export const getProjectEnvironmentVariablesListKey = (projectName: string) =>
  [`/projects/${projectName}/environment-variables`] as const;

export type ProjectEnvironmentVariablesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectEnvironmentVariablesList>>
>;
export type ProjectEnvironmentVariablesListQueryError = ErrorType<unknown>;

/**
 * @summary List project environment variables
 */
export const useProjectEnvironmentVariablesList = <TError = ErrorType<unknown>>(
  projectName: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof projectEnvironmentVariablesList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getProjectEnvironmentVariablesListKey(projectName) : null);
  const swrFn = () =>
    projectEnvironmentVariablesList(projectName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create an environment variable for the project. This variable will be inherited by all deployments in this project.

### Required Parameters
- `name`: The name of the variable. The variable will have this name when accessed from your deployment code. The variable name should contain only letters and underscores, and not start or end with an underscore.
- `value`: The value of the variable as a string. It may be an empty string ("").
- `secret`: If this variable contains sensitive information, set this to true to hide it from other users.

#### Request Examples
```
{
  "name": "database_schema",
  "value": "public",
  "secret": false
}
```

```
{
  "name": "database_password",
  "value": "password",
  "secret": true
}
```

### Response Structure
A list of variables described by the following fields:
- `id`: Unique identifier for the environment variable
- `name`: Variable name
- `value`: Variable value (will be null for secret variables)
- `secret`: Boolean that indicates if this variable contains sensitive information

#### Response Examples
```
{
  "id": "4c15a27e-25ea-4be0-86c7-f4790389d061",
  "name": "database_schema",
  "value": "public",
  "secret": false
}
```

```
{
  "id": "06c2c8be-507e-4fae-981d-54e94f22dab0",
  "name": "database_password",
  "value": null,
  "secret": true
}
```

 * @summary Create project environment variable
 */
export const projectEnvironmentVariablesCreate = (
  projectName: string,
  environmentVariableCreateBody: EnvironmentVariableCreateBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentVariableList>(
    {
      url: `/projects/${projectName}/environment-variables`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: environmentVariableCreateBody,
    },
    options
  );
};

/**
 * 
### Description
Retrieve details of a project environment variable.

### Response Structure
A list of variables described by the following fields:
- `id`: Unique identifier for the environment variable
- `name`: Variable name
- `value`: Variable value (will be null for secret variables)
- `secret`: Boolean that indicates if this variable contains sensitive information

#### Response Examples
```
[
  {
    "id": "4c15a27e-25ea-4be0-86c7-f4790389d061",
    "name": "database_schema",
    "value": "public",
    "secret": false
  }
]
```

 * @summary Get project environment variable
 */
export const projectEnvironmentVariablesGet = (
  projectName: string,
  id: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentVariableList>(
    {
      url: `/projects/${projectName}/environment-variables/${id}`,
      method: "get",
    },
    options
  );
};

export const getProjectEnvironmentVariablesGetKey = (
  projectName: string,
  id: string
) => [`/projects/${projectName}/environment-variables/${id}`] as const;

export type ProjectEnvironmentVariablesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectEnvironmentVariablesGet>>
>;
export type ProjectEnvironmentVariablesGetQueryError = ErrorType<unknown>;

/**
 * @summary Get project environment variable
 */
export const useProjectEnvironmentVariablesGet = <TError = ErrorType<unknown>>(
  projectName: string,
  id: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof projectEnvironmentVariablesGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(projectName && id);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getProjectEnvironmentVariablesGetKey(projectName, id) : null);
  const swrFn = () =>
    projectEnvironmentVariablesGet(projectName, id, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Update an environment variable for the projects

### Required Parameters
- `name`: The name of the variable. The variable will have this name when accessed from your deployment code. The variable name should contain only letters and underscores, and not start or end with an underscore.
- `value`: The value of the variable as a string. It may be an empty string.
- `secret`: If this variable contains sensitive information, set this to true to hide it from other users. Can be updated from false to true, but not from true to false (i.e. secrets will stay secrets).

#### Request Examples
```
{
  "name": "database_schema",
  "value": "new+schema",
  "secret": false
}
```

### Response Structure
A list of variables described by the following fields:
- `id`: Unique identifier for the environment variable
- `name`: Variable name
- `value`: Variable value (will be null for secret variables)
- `secret`: Boolean that indicates if this variable contains sensitive information

#### Response Examples
```
{
  "id": "4c15a27e-25ea-4be0-86c7-f4790389d061",
  "name": "database_schema",
  "value": "new_schema",
  "secret": false
}
```

 * @summary Update project environment variable
 */
export const projectEnvironmentVariablesUpdate = (
  projectName: string,
  id: string,
  environmentVariableCreateBody: EnvironmentVariableCreateBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<EnvironmentVariableList>(
    {
      url: `/projects/${projectName}/environment-variables/${id}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: environmentVariableCreateBody,
    },
    options
  );
};

/**
 * 
### Description
Delete an environment variable of the project

 * @summary Delete project environment variable
 */
export const projectEnvironmentVariablesDelete = (
  projectName: string,
  id: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/projects/${projectName}/environment-variables/${id}`,
      method: "delete",
    },
    options
  );
};

/**
 * 
### Description
Retrieve the logs of all objects in a project, including deployments, pipelines and requests. Using filters you can filter the logs on the objects and information of your choice.

### Optional Parameters
- `filters`: A dictionary containing information to filter logs on. It may contain zero or more of the following fields:
    - `deployment_name`: name of a deployment
    - `deployment_version`: name of a deployment version. If this field is present in the request, deployment_name must also be given. The deployment versions are only meaningful in combination with the deployments they are defined for.
    - `deployment_version_revision_id`: the UUID of a deployment version revision. It does not have to be given in combination with the deployment and version name.
    - `instance_id`: the UUID of an instance. It does not have to be given in combination with the deployment and version name.
    - `process_id`: id of the process in the deployment instance. It does not have to be given in combination with the deployment and version name.
    - `environment_name`: name of an environment
    - `environment_build_id`: the UUID of an environment build. It does not have to be given in combination with the environment name.
    - `pipeline_name`: name of a pipeline
    - `pipeline_version`: name of a pipeline version. If this field is present in the request, pipeline_name must also be given. The pipeline versions are only meaningful in combination with the pipelines they are defined for.
    - `pipeline_object_name`: name of a pipeline object. If this field is present in the request, pipeline_name and pipeline_version must also be given. The pipeline objects are only meaningful in combination with the pipeline versions they are defined in.
    - `deployment_request_id`: the UUID of a deployment request
    - `pipeline_request_id`: the UUID of a pipeline request
    - `webhook_name`: name of a webhook
    - `system`: whether the log was generated by the system or user code (true / false)
    - `level`: the level of the log (info / error)

Any combination of filters may be given in the request. For example, if only a deployment_name is provided, all logs for that deployment are returned. These logs can contain information from all the pipelines that deployment is referenced in. If the filters dictionary is empty, all logs for all objects in the project are returned.
Either `date` or `id` should be provided, as they both refer to a starting point of the logs. If no `date` or `id` is specified, the API will use the current time as a starting point and try to fetch the logs starting from now minus date range seconds into the past.

- `date`: Starting date for the logs. If it is not provided and the `id` parameter is not set, the most recent logs are returned. It should be provided in ISO 8601 format. The results are inclusive of the given date.
- `id`: identifier for log lines. If specified, it will act as a starting point for the interval in which to query the logs. This can be useful when making multiple queries to obtain consecutive logs. 

    It will include the log having the log id equal to the id value in the response, regardless of whether the date_range is positive or negative.
- `limit`: Limit for the logs response. If specified, it will limit the total number of logs returned from the query to the specified number. Defaults to 50, the maximum is 500.
- `date_range`: The date range parameter sets the interval of time in which to query the logs, specified in seconds. It may be a positive or a negative value. 

    If it is positive, logs starting from the specified date / log id (both inclusive) plus date range seconds towards the present time are returned. 

    Otherwise, logs starting from the specified date / log id (both inclusive) minus date range seconds towards the past are returned. 

    The default value is -21600 (6 hours). The maximum value is -/+ 86400 seconds (24 hours).

#### Request Examples
```
{
  "filters": {
    "deployment_name": "deployment-1",
    "deployment_version": "v1"
  },
  "date": "2020-01-01T00:00:00.000000Z"
}
```

```
{
  "filters": {
    "pipeline_name": "pipeline-1",
    "pipeline_version": "v1"
  },
  "id": "41d7a7c5cd025e3501a00000",
  "date_range": -100
}
```

```
{
  "filters": {
    "pipeline_name": "pipeline-1",
    "pipeline_version": "v1",
    "deployment_name": "deployment-1",
    "deployment_version": "v1"
  },
  "date": "2020-01-01T00:00:00.000000Z",
  "date_range": -86400,
  "limit": 5
}
```

### Response Structure
A list of log details
- `id`: Unique UUID of the log line
- `log`: Log line text
- `date`: Time the log line was created

The following fields will be returned on response if they are set for the log line:

- `deployment_name`:  The deployment which the log is related to
- `deployment_version`:  The deployment version which the log is related to
- `deployment_version_revision_id`: The UUID of the deployment version revision
- `environment_name`:  The environment which the log is related to
- `environment_build_id`: The UUID of the environment build
- `pipeline_name`: The pipeline which the log is related to
- `pipeline_version`: The pipeline version which the log is related to
- `pipeline_object_name`: The pipeline object which the log is related to
- `deployment_request_id`:  The deployment request the log is related to
- `pipeline_request_id`:  The pipeline request the log is related to
- `system`:  Whether the log was generated by the system (true / false)
- `level`: The level of the log (info / error)

#### Response Examples
Logs for a specific deployment and version
```
[
  {
    "id": "5dcad12ba76a2c6e4331f180",
    "deployment_name": "deployment-1",
    "deployment_version": "v1",
    "date": "2020-01-01T00:00:00.000000Z",
    "log": "[Info] Prediction result 0.14981"
  },
  {
    "id": "5dcad12ba76a2c6e4331f181",
    "deployment_name": "deployment-1",
    "deployment_version": "v1",
    "deployment_request_id": "ee63f938-ba81-438e-8482-9ac76037895f",
    "pipeline_name": "pipeline-2",
    "pipeline_version": "v2",
    "pipeline_object_name": "deployment-1-v1-object",
    "pipeline_request_id": "8bb6ed79-8606-4acf-acd2-90507130523c",
    "date": "2020-01-01T00:00:01.000000Z",
    "log": "[Error] Deployment call result (failed)"
  }
]
```

Logs for a specific pipeline
```
[
  {
    "id": "5dcad12ba76a2c6e4331f192",
    "deployment_name": "deployment-2",
    "deployment_version": "v2",
    "deployment_request_id": "6ee941d3-9905-49f5-95b4-cd9c4c23bb03",
    "pipeline_name": "pipeline-1",
    "pipeline_version": "v1",
    "pipeline_object_name": "deployment-2-v2-object",
    "pipeline_request_id": "4f75b10d-6012-47ab-ae68-cc9e69f35841",
    "date": "2020-01-01T00:00:00.000000Z",
    "log": "[Info] Deployment call result (success): 0.2316"
  }
]
```

 * @summary List logs for a project
 */
export const projectsLogList = (
  projectName: string,
  logsCreate: LogsCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<Logs[]>(
    {
      url: `/projects/${projectName}/logs`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: logsCreate,
    },
    options
  );
};

/**
 * 
### Description
List the quotas defined for a project. Project members can see quotas.

### Response Structure
- `resource`: The resource for the quota
- `quota`: Limit of how much the resource can be used in the project

#### Response Examples
```
[
  {
    "resource": "memory_standard",
    "quota": 274877906944
  },
  {
    "resource": "cpu_standard",
    "quota": 62
  },
  {
    "resource": "accelerator_standard",
    "quota": 0
  }
]
```

 * @summary List quotas
 */
export const quotasList = (
  projectName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<QuotaDetail[]>(
    { url: `/projects/${projectName}/quotas`, method: "get" },
    options
  );
};

export const getQuotasListKey = (projectName: string) =>
  [`/projects/${projectName}/quotas`] as const;

export type QuotasListQueryResult = NonNullable<
  Awaited<ReturnType<typeof quotasList>>
>;
export type QuotasListQueryError = ErrorType<unknown>;

/**
 * @summary List quotas
 */
export const useQuotasList = <TError = ErrorType<unknown>>(
  projectName: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof quotasList>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getQuotasListKey(projectName) : null));
  const swrFn = () => quotasList(projectName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
List the deployment/pipeline requests of the given project

### Required Parameters
- `object_type`: The type of the object. It can be either deployment or pipeline.

### Optional Parameters
- `status`: Status of the request, one of the following 'failed', 'completed' or 'cancelled', defaults to 'completed'
- `limit`: The maximum number of requests given back, defaults to 50
- `offset`: The number which forms the starting point of the requests given back, defaults to 0. If offset equals 2, then the first 2 requests will be omitted from the list.
- `start_date`: Start date of the interval for which the requests are retrieved, looking at the creation date of the request. *Only available* for completed/failed/cancelled requests.
- `end_date`: End date of the interval for which the requests are retrieved, looking at the creation date of the request. *Only available* for completed/failed/cancelled requests.
- `search_id`: A string to search inside request ids. It will filter all request ids that contain this string. *Only available* for completed/failed/cancelled requests.

### Response Structure
A list of dictionaries containing the details of the deployment/pipeline requests with the following fields:
- `id`: Unique identifier for the request
- `deployment`: Name of the deployment the request was made to. If the request wasn't made to a deployment, it's set to null.
- `pipeline`: Name of the pipeline the request was made to. If the request wasn't made to a pipeline, it's set to null.
- `version`: Name of the version the request was made to
- `status`: Status of the request
- `time_created`: Datetime when the request is created
- `time_started`: Datetime when the request starts to be processed
- `time_completed`: Datetime when the request is completed
- `input_size`: Size of the request data
- `output_size`: Size of the result

#### Response Examples
```
[
  {
    "id": "69eca481-8576-49e8-8e20-ea56f2005bcb",
    "deployment": "deployment-1",
    "pipeline": null,
    "version": "v1",
    "status": "completed",
    "time_created": "2020-03-28T20:00:26.613+00:00",
    "time_started": "2020-03-28T20:01:33.123+00:00",
    "time_completed": "2020-03-28T20:03:12.985+00:00",
    "input_size": 10,
    "output_size": null
  },
  {
    "id": "2521378e-263e-4e2e-85e9-a96254b36536",
    "deployment": "deployment-1",
    "pipeline": null,
    "version": "v1",
    "status": "completed",
    "time_created": "2020-03-28T20:00:26.613+00:00",
    "time_started": "2020-03-28T20:00:41.276+00:00",
    "time_completed": "2020-03-28T20:00:42.241+00:00",
    "input_size": 10,
    "output_size": 10
  }
]
```

 * @summary List requests in project
 */
export const projectRequestsList = (
  projectName: string,
  params: ProjectRequestsListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<RequestsOverview[]>(
    { url: `/projects/${projectName}/requests`, method: "get", params },
    options
  );
};

export const getProjectRequestsListKey = (
  projectName: string,
  params: ProjectRequestsListParams
) =>
  [`/projects/${projectName}/requests`, ...(params ? [params] : [])] as const;

export type ProjectRequestsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectRequestsList>>
>;
export type ProjectRequestsListQueryError = ErrorType<unknown>;

/**
 * @summary List requests in project
 */
export const useProjectRequestsList = <TError = ErrorType<unknown>>(
  projectName: string,
  params: ProjectRequestsListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof projectRequestsList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getProjectRequestsListKey(projectName, params) : null));
  const swrFn = () => projectRequestsList(projectName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
List the total number of resources used in a project

### Response Structure
A list containing the number of
- deployments
- deployment_versions
- pipelines
- pipeline_versions
- buckets
- environments

currently defined in the project.

#### Response Examples
```
{
  "deployments": 30,
  "deployment_versions": 47,
  "pipelines": 2,
  "pipeline_versions": 4,
  "buckets": 2,
  "environments": 2
}
```

 * @summary List resource usage of a project
 */
export const projectsResourceUsage = (
  projectName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ProjectResourceUsage>(
    { url: `/projects/${projectName}/resources`, method: "get" },
    options
  );
};

export const getProjectsResourceUsageKey = (projectName: string) =>
  [`/projects/${projectName}/resources`] as const;

export type ProjectsResourceUsageQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectsResourceUsage>>
>;
export type ProjectsResourceUsageQueryError = ErrorType<unknown>;

/**
 * @summary List resource usage of a project
 */
export const useProjectsResourceUsage = <TError = ErrorType<unknown>>(
  projectName: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof projectsResourceUsage>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getProjectsResourceUsageKey(projectName) : null));
  const swrFn = () => projectsResourceUsage(projectName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Get credits usage for the project

### Optional Parameters
- `start_date`: Start date for the usage data to be returned. If omitted, results are generated for current subscription period.
- `end_date`: End date for the usage data to be returned. If omitted, results are generated for current subscription period.
- `interval`: Interval for the usage data. It can be 'day' or 'month'. It defaults to 'month'.

If no **start_date** or **end_date** is given, the current subscription period is used, e.g. if the usage details are requested on 01-12-2020 and the subscription started on 20-11-2020, the results will contain data from 20-11-2020 to 20-12-2020.
When **start_date** and **end_date** are given, this month period is used, e.g. if 12-11-2020 is given as start date and 12-12-2020 as end date, the results will contain data from 12-11-2020 to 12-12-2020.

### Response Structure
- `interval`: Interval for the usage data
- `data_project`: A list of dictionaries containing the project usage for the given date range
- `data_deployments`: A list of dictionaries containing the usage of each deployment in the project for the given date range
- `data_deployments_deleted`: A list of dictionaries containing the usage corresponds to deleted deployments in the project for the given date range

#### Response Examples
2019-08-01 as start date and 2019-09-01 as end date
```
{
  "interval": "month",
  "data_project": [
    {
      "start_date": "2019-08-01T00:00:00Z",
      "end_date": "2019-09-01T00:00:00Z",
      "value": 500
    }
  ],
  "data_deployments": [
     {
      "deployment_id": "4326da55-06ca-4664-badb-03156355451a",
      "deployment_name": "deployment-1",
      "data": [
        {
          "start_date": "2019-08-01T00:00:00Z",
          "end_date": "2019-09-01T00:00:00Z",
          "value": 300
        }
      ]
    }
  ],
  "data_deleted_deployments": [
    {
      "start_date": "2019-08-01T00:00:00Z",
      "end_date": "2019-09-01T00:00:00Z",
      "value": 200
    }
  ]
}
```

 * @summary Get resource usage
 */
export const projectsUsageGet = (
  projectName: string,
  params?: ProjectsUsageGetParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ProjectUsage>(
    { url: `/projects/${projectName}/usage`, method: "get", params },
    options
  );
};

export const getProjectsUsageGetKey = (
  projectName: string,
  params?: ProjectsUsageGetParams
) => [`/projects/${projectName}/usage`, ...(params ? [params] : [])] as const;

export type ProjectsUsageGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectsUsageGet>>
>;
export type ProjectsUsageGetQueryError = ErrorType<unknown>;

/**
 * @summary Get resource usage
 */
export const useProjectsUsageGet = <TError = ErrorType<unknown>>(
  projectName: string,
  params?: ProjectsUsageGetParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof projectsUsageGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getProjectsUsageGetKey(projectName, params) : null));
  const swrFn = () => projectsUsageGet(projectName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
List users and their details in a project. The user making the request must also be a project user.

### Response Structure
List of users
- `id`: Unique identifier for the user (UUID)
- `email`: Email of the user
- `name`: Name of the user
- `surname`: Surname of the user

#### Response Examples
```
[
  {
    "id": "54977bc3-2c3b-4d8f-97c7-aff89a95bf21",
    "email": "user@example.com",
    "name": "user",
    "surname": "name"
  },
  {
    "id": "abe2e406-fae5-4bcf-a3bc-956d756e4ecb",
    "email": "user2@example.com",
    "name": "user",
    "surname": "name"
  }
]
```

 * @summary List users in a project
 */
export const projectUsersList = (
  projectName: string,
  params?: ProjectUsersListParams,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ProjectUserList[]>(
    { url: `/projects/${projectName}/users`, method: "get", params },
    options
  );
};

export const getProjectUsersListKey = (
  projectName: string,
  params?: ProjectUsersListParams
) => [`/projects/${projectName}/users`, ...(params ? [params] : [])] as const;

export type ProjectUsersListQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectUsersList>>
>;
export type ProjectUsersListQueryError = ErrorType<unknown>;

/**
 * @summary List users in a project
 */
export const useProjectUsersList = <TError = ErrorType<unknown>>(
  projectName: string,
  params?: ProjectUsersListParams,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof projectUsersList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getProjectUsersListKey(projectName, params) : null));
  const swrFn = () => projectUsersList(projectName, params, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Add a user to a project. The user making the request must be admin of the organization. The user can later be assigned roles in the project, such as project-admin, project-viewer etc.

### Required Parameters
- `user_id`: UUID of the user

#### Request Examples
```
{
  "user_id": "bd3e25a3-f77a-4cb5-92df-a7e614106e95"
}
```

### Response Structure
Details of the added user
- `id`: Unique identifier for the user (UUID)
- `email`: Email of the user
- `name`: Name of the user
- `surname`: Surname of the user

#### Response Examples
```
{
  "id": "332d7432-2742-4177-99a9-139e91e0110c",
  "email": "test@example.com",
  "name": "user",
  "surname": "name"
}
```

 * @summary Add user to a project
 */
export const projectUsersCreate = (
  projectName: string,
  projectUserCreate: ProjectUserCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ProjectUserList>(
    {
      url: `/projects/${projectName}/users`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: projectUserCreate,
    },
    options
  );
};

/**
 * 
### Description
Get the details of a user in a project. The user making the request must also be a project user.

### Response Structure
Details of the user
- `id`: Unique identifier for the user (UUID)
- `email`: Email of the user
- `name`: Name of the user
- `surname`: Surname of the user

#### Response Examples
```
{
  "id": "332d7432-2742-4177-99a9-139e91e0110c",
  "email": "test@example.com",
  "name": "user",
  "surname": "name"
}
```

 * @summary Get user in a project
 */
export const projectUsersGet = (
  projectName: string,
  userId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ProjectUserList>(
    { url: `/projects/${projectName}/users/${userId}`, method: "get" },
    options
  );
};

export const getProjectUsersGetKey = (projectName: string, userId: string) =>
  [`/projects/${projectName}/users/${userId}`] as const;

export type ProjectUsersGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof projectUsersGet>>
>;
export type ProjectUsersGetQueryError = ErrorType<unknown>;

/**
 * @summary Get user in a project
 */
export const useProjectUsersGet = <TError = ErrorType<unknown>>(
  projectName: string,
  userId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof projectUsersGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!(projectName && userId);
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getProjectUsersGetKey(projectName, userId) : null));
  const swrFn = () => projectUsersGet(projectName, userId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Delete a user from a project. The user making the request must be admin of the organization.

**When a user is deleted from a project, all his roles defined in the scope of the project are also deleted.**

 * @summary Delete user from a project
 */
export const projectUsersDelete = (
  projectName: string,
  userId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    { url: `/projects/${projectName}/users/${userId}`, method: "delete" },
    options
  );
};
