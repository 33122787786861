import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { CONTINUOUS_REQUEST_DELAY } from "libs/constants/constants";
import {
  FIELD_DESCRIPTION,
  FIELD_TRAINING_SCRIPT,
} from "libs/constants/fields";
import { useDeploymentVersionRequestsGet } from "libs/data/endpoints/deployment-requests/deployment-requests";
import { useInterval } from "libs/hooks";
import {
  calculateDuration,
  DATE_TIME_FORMAT,
  getTzAwareDate,
} from "libs/utilities/date-util";
import { formatStatusLabel } from "libs/utilities/statuses";

import { Alert, Card, DetailsItem, Link, StatusIcon } from "components/atoms";
import { DescriptionBlock } from "components/molecules";
import { RequestLogs } from "components/organisms";

import {
  destructFilePath,
  mapStorageLinkToAppLink,
} from "../../../../storage/utils";
import { TRAINING_DEPLOYMENT } from "../../../constants";

export const RunDetailsGeneral = () => {
  const { organizationName, projectName, experimentName, runId } =
    useParams<{
      organizationName: string;
      projectName: string;
      experimentName: string;
      runId: string;
    }>();
  const { data: runDetails, mutate } = useDeploymentVersionRequestsGet(
    projectName,
    TRAINING_DEPLOYMENT,
    experimentName,
    runId
  );

  const isRunning = useMemo(
    () =>
      runDetails?.status === "processing" ||
      runDetails?.status === "cancelled_pending",
    [runDetails?.status]
  );

  const isPending = useMemo(
    () => runDetails?.status === "pending",
    [runDetails?.status]
  );
  const scriptPath = useMemo(
    () => runDetails?.request_data?.[FIELD_TRAINING_SCRIPT] ?? "",
    [runDetails]
  );

  useInterval(
    () => {
      if (isRunning || isPending) mutate();
    },
    [isRunning, isPending, mutate],
    CONTINUOUS_REQUEST_DELAY,
    false
  );

  return (
    <>
      {runDetails?.error_message && (
        <Alert severity="error">Error: {runDetails?.error_message}</Alert>
      )}
      <Grid container spacing={2} height="100%">
        <Grid item xs={12} md={6}>
          <Card>
            <DetailsItem title="Status">
              <StatusIcon
                label={runDetails?.status}
                status={formatStatusLabel(runDetails?.status)}
              />
            </DetailsItem>

            <DetailsItem title="Created">
              <Typography>
                {runDetails?.time_created
                  ? getTzAwareDate(runDetails?.time_created).format(
                      DATE_TIME_FORMAT
                    )
                  : "--"}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Started">
              <Typography>
                {runDetails?.time_started
                  ? getTzAwareDate(runDetails?.time_started).format(
                      DATE_TIME_FORMAT
                    )
                  : "--"}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Completed">
              <Typography>
                {runDetails?.time_completed
                  ? getTzAwareDate(runDetails?.time_completed).format(
                      DATE_TIME_FORMAT
                    )
                  : "--"}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Duration">
              <Typography>
                {runDetails?.time_started && runDetails?.time_completed
                  ? calculateDuration(
                      runDetails?.time_started,
                      runDetails?.time_completed
                    )
                  : "--"}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Script location">
              {scriptPath && (
                <Tooltip title={scriptPath}>
                  <Box>
                    <Link
                      to={{
                        ...location,
                        state: {
                          selectedFile: destructFilePath(scriptPath)?.file,
                        },
                        pathname: mapStorageLinkToAppLink(
                          scriptPath,
                          organizationName,
                          projectName
                        ),
                      }}
                      style={{
                        display: "inline-block",
                        width: "100%",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {scriptPath}
                    </Link>
                  </Box>
                </Tooltip>
              )}
            </DetailsItem>
            <DetailsItem title="Output artefact">
              {runDetails?.result?.artifact && (
                <Tooltip title={runDetails?.result?.artifact}>
                  <Link
                    to={{
                      ...location,
                      state: {
                        selectedFile: destructFilePath(
                          runDetails.result.artifact
                        )?.file,
                      },
                      pathname: mapStorageLinkToAppLink(
                        runDetails.result.artifact,
                        organizationName,
                        projectName
                      ),
                    }}
                    style={{
                      display: "inline-block",
                      width: "100%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {runDetails?.result?.artifact}
                  </Link>
                </Tooltip>
              )}
            </DetailsItem>
          </Card>
        </Grid>

        <DescriptionBlock
          description={runDetails?.request_data?.[FIELD_DESCRIPTION]}
        />
        <RequestLogs
          deploymentName={TRAINING_DEPLOYMENT}
          projectName={projectName}
          requestId={runDetails?.id ?? ""}
          versionName={experimentName}
          requestDetails={runDetails}
        />
      </Grid>
    </>
  );
};
