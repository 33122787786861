import { Card, CardContent, Typography, Grid } from "@mui/material";

import { DeploymentStepsIllustration } from "assets/images/DeploymentSteps";
import { Programmer } from "assets/images/Programmer";
import { spacing } from "assets/styles/theme";
import { useDeviceDetect } from "libs/hooks";

import { PrimaryButton } from "components/atoms";

const content = {
  deploymentCreate: {
    title: "Let's get started",
    description: (
      <Typography variant="h6" paragraph>
        Create a new deployment using the form below or load an example and see
        how it works.
      </Typography>
    ),
  },
  versionCreate: {
    title: "On to the version",
    description: (
      <Typography variant="h6" paragraph>
        {`We've created the deployment now, here we make the version. Normally you
          would upload the code here, but we already did it for you! Just `}
        <Typography component="span" variant="h5" color="secondary">
          click create
        </Typography>
      </Typography>
    ),
  },
};

type FirstDeploymentCardProps = {
  onAction?: () => void;
  versionCreate?: boolean;
};

const FirstDeploymentCard = ({
  onAction,
  versionCreate,
}: FirstDeploymentCardProps) => {
  const { isMobile } = useDeviceDetect();

  return (
    <Grid
      item
      xs={12}
      md={7}
      container
      margin="auto"
      marginBottom={spacing[20]}
    >
      <Card>
        <Typography paddingTop={spacing[8]} variant="h2" align="center">
          {versionCreate
            ? content.versionCreate.title
            : content.deploymentCreate.title}
        </Typography>
        <CardContent
          sx={{
            height: "200px",
            gap: 2,
            display: "flex",
          }}
        >
          {!isMobile && (
            <Grid item xs={6} height="100%" padding={spacing[12]}>
              {versionCreate ? <Programmer /> : <DeploymentStepsIllustration />}
            </Grid>
          )}

          <Grid
            item
            xs={12}
            md={6}
            container
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            padding={spacing[12]}
          >
            {versionCreate
              ? content.versionCreate.description
              : content.deploymentCreate.description}

            {onAction && (
              <PrimaryButton onClick={onAction}>Load example</PrimaryButton>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default FirstDeploymentCard;
