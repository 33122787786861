import Plus from "@mui/icons-material/AddBoxRounded";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/Edit";
import { Grid, IconButton, Tooltip, useTheme } from "@mui/material";
import { upperCase } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

import { spacing } from "assets/styles/theme";
import { DOC_LINKS } from "libs/constants/documentation-links";
import {
  FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORTS,
  FIELD_STATIC_IP,
} from "libs/constants/fields";
import { useOrganizationsFeaturesGet } from "libs/data/endpoints/organizations/organizations";
import { explanations } from "libs/utilities/explanations";
import { protocols } from "libs/utilities/labels-mapping";
import { getRandomId } from "libs/utilities/utils";
import { useGetCurrentOrganization } from "store/features";

import { ExternalLink, InfoAlert, PrimaryButton } from "components/atoms";
import { BaseTable, FormSection } from "components/molecules";

import { salesContact } from "./constants";

import type { BaseColumn, BaseRow } from "../BaseTable";
import type { DeploymentVersionPort } from "libs/data/models";

interface DeploymentVersionPortWithId extends DeploymentVersionPort {
  id: string;
}

export const PortForwarding = () => {
  const { setValue, watch, register, unregister } = useFormContext();
  const staticIpEnabled = watch(FIELD_STATIC_IP);
  const formPorts = watch(FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORTS);

  const [editRowId, setEditRowId] = useState<string | undefined>(undefined);
  const [editedRow, setEditedRow] = useState<BaseRow | undefined>(undefined);
  const [createMode, setCreateMode] = useState(false);
  const [ports, setPorts] = useState(formPorts ?? []);

  const theme = useTheme();
  const organization = useGetCurrentOrganization();

  const { data: features } = useOrganizationsFeaturesGet(
    organization?.name || ""
  );

  const rows = useMemo(
    () =>
      ports?.map((port: DeploymentVersionPortWithId) => ({
        ...port,
        id: port.id ?? getRandomId(),
      })),
    [ports]
  );

  const disabledForOrganization =
    organization?.subscription === "free" || !features?.feature_port_forwarding;
  const hidePorts = disabledForOrganization || staticIpEnabled;

  //remove ports if static ip is enabled
  useEffect(() => {
    if (staticIpEnabled && formPorts?.length) {
      setValue(FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORTS, []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formPorts, staticIpEnabled]);

  // set initial ports
  useEffect(() => {
    if (ports.length === 0 && formPorts?.length) setPorts(formPorts);
  }, [ports.length, formPorts]);

  useEffect(() => {
    register(FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORTS);

    return () => {
      unregister(FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORTS);
    };
  }, [register, unregister]);

  const columns = [
    {
      title: "Deployment port",
      field: "deployment_port",
      width: "20%",
      editable: true,
    },
    {
      title: "Public port",
      field: "public_port",
      width: "20%",
      editable: true,
    },
    {
      title: "Protocol",
      field: "protocol",
      width: "20%",
      editable: true,
      lookup: protocols,
      options: Object.entries(protocols).map(([value, label]) => ({
        label,
        value,
      })),
      initialEditValue: "tcp",
      render: (rowData: DeploymentVersionPort) => upperCase(rowData.protocol),
    },
    {
      width: "4%",
      field: "",
      nowrap: true,
      render: (rowData: DeploymentVersionPortWithId) => {
        return (
          <Grid
            className="actions_container"
            alignItems="flex-end"
            paddingLeft={editRowId === rowData?.id ? spacing[12] : undefined}
          >
            <Tooltip title={editRowId === rowData?.id ? "Save" : "Edit"}>
              <span>
                {editRowId === rowData?.id ? (
                  <IconButton
                    color="inherit"
                    onClick={() => onSave(rowData.id)}
                  >
                    <CheckIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    color="secondary"
                    onClick={() => {
                      setEditRowId(rowData?.id);
                      setEditedRow(rowData);
                    }}
                  >
                    <Edit />
                  </IconButton>
                )}
              </span>
            </Tooltip>
            <Tooltip
              style={{
                paddingLeft: editRowId === rowData?.id ? spacing[4] : undefined,
              }}
              title={editRowId === rowData?.id ? "Cancel" : "Delete"}
            >
              <span>
                {editRowId === rowData?.id ? (
                  <IconButton
                    color="inherit"
                    onClick={() => {
                      setEditRowId(undefined);
                      setEditedRow(undefined);
                      setCreateMode(false);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    color="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const newPorts = rows.filter(
                        (port: DeploymentVersionPortWithId) =>
                          port.id !== rowData.id
                      );
                      setValue(
                        FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORTS,
                        newPorts
                      );
                      setPorts(newPorts);
                    }}
                  >
                    <Trash />
                  </IconButton>
                )}
              </span>
            </Tooltip>
          </Grid>
        );
      },
    },
  ];

  const onSave = (id: string) => {
    const newPorts = createMode
      ? [{ ...editedRow, id: getRandomId() }, ...rows]
      : rows.map((port: DeploymentVersionPortWithId) =>
          port.id === id ? editedRow : port
        );

    setValue(FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORTS, newPorts);
    setPorts(newPorts);
    setEditRowId(undefined);
    setEditedRow(undefined);
    setCreateMode(false);
  };

  return (
    <FormSection
      title="Port forwarding"
      description={explanations.deployments.versions.portForwarding}
    >
      <>
        <InfoAlert style={{ width: "100%" }}>
          <Grid>
            {disabledForOrganization && (
              <>
                <Grid item>
                  This feature is not included in your current subscription.
                </Grid>
                <ExternalLink
                  color={`${theme.palette.secondary.main} !important`}
                  href={encodeURI(
                    `mailto:${salesContact.email}?subject=${salesContact.subject}&body=${salesContact.body}`
                  )}
                >
                  Talk to our team to upgrade and gain access
                </ExternalLink>
              </>
            )}
            {!disabledForOrganization && staticIpEnabled && (
              <>
                <Grid item>
                  This feature is not compatible with static IP addresses.
                  Please disable it to use port forwarding.
                </Grid>
              </>
            )}
            {!disabledForOrganization && (
              <>
                <Grid item>
                  This feature is only compatible with dedicated instance types.
                </Grid>
                <ExternalLink
                  color={`${theme.palette.secondary.main} !important`}
                  href={DOC_LINKS.PORT_FORWARDING}
                >
                  Read more about the supported instance types here
                </ExternalLink>
              </>
            )}
          </Grid>
        </InfoAlert>
        {!hidePorts && (
          <BaseTable
            columns={columns as BaseColumn[]}
            data={
              createMode ? [{ id: "new-row", protocol: "tcp" }, ...rows] : rows
            }
            hasSearchField={false}
            hasPagination={false}
            editRowId={editRowId}
            editedRow={editedRow}
            setEditedRow={setEditedRow}
            action={
              <PrimaryButton
                startIcon={<Plus />}
                onClick={() => {
                  setCreateMode(true);
                  setEditRowId("new-row");
                  setEditedRow({ id: "new-row", protocol: "tcp" });
                }}
              >
                Add port
              </PrimaryButton>
            }
          />
        )}
      </>
    </FormSection>
  );
};
