import { Box, Typography, useTheme } from "@mui/material";
import { useMemo } from "react";
import { useEdges } from "reactflow";

import { spacing } from "assets/styles/theme";

import { OverflowTooltip } from "components/atoms";

import type { EdgeDataType } from "./types";
import type { AppThemeProps } from "assets/styles/theme/theme.d";

export const IncomingEdges = ({
  objectId,
  pipelineObjectName,
}: {
  objectId: string;
  pipelineObjectName?: string;
}) => {
  const edges = useEdges<EdgeDataType>();
  const theme = useTheme() as AppThemeProps;

  const incomingEdges = useMemo(
    () => edges.filter((edge) => edge.target === objectId),
    [edges, objectId]
  );

  return (
    <>
      {incomingEdges.map(({ data }, index) => (
        <Box
          key={index}
          display="flex"
          justifyContent="space-between"
          margin={`${spacing[8]} 0`}
        >
          <Box maxWidth="50%">
            <OverflowTooltip
              component={Typography}
              // Todo: fix typing
              // @ts-expect-error
              variant="subtitle2"
              color="textSecondary"
              style={{ marginRight: spacing[16] }}
              title={
                <Typography
                  style={{ color: theme.palette.primary.contrastText }}
                >
                  {pipelineObjectName}
                </Typography>
              }
            >
              {pipelineObjectName}
            </OverflowTooltip>
          </Box>
          {!!data?.sources?.length && (
            <Typography variant="subtitle2" color="textSecondary">
              {data.sources.map((source) => source.source_name).join(", ")}
            </Typography>
          )}
        </Box>
      ))}
    </>
  );
};
