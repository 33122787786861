export const ENV_NAMES = {
  NAME: "name",
  HOST: "host",
  REMEMBER_ME: "remember_me",
  ACCESS_TOKEN: "access_token",
  ACTIVATION_TOKEN: "activation_token",
  LAST_LOGIN: "last_login",
  LAST_SEEN_ORGANIZATION: "last_seen_organization",
  LAST_SEEN_PROJECT: "last_seen_project",
  REDIRECT_URL: "redirect_url",
  REFRESH_TOKEN: "refresh_token",
  EVALUATION_TABLE_COLUMNS: "evaluation_table_columns",
  // Variables from env-config.js
  BILLING_ENABLED: "billing_enabled",
  FILES_PROXY_ENABLED: "files_proxy_enabled",
  FILES_PROXY_HOST: "files_proxy_host",
  GOOGLE_ANALYTICS_ENABLED: "GOOGLE_ANALYTICS_ENABLED",
  GOOGLE_ANALYTICS_TOKEN: "google_analytics_token",
  HOTJAR_ENABLED: "HOTJAR_ENABLED",
  METRICS_AGGREGATION_ENABLED: "metrics_aggregation_enabled",
  OAUTH_ENABLED: "oauth_enabled",
  ON_PREMISE: "on_premise",
  PRODUCT_FRUITS_ENABLED: "PRODUCT_FRUITS_ENABLED",
  REQUESTS_PAGE_ENABLED: "requests_page_enabled",
  REQUESTS_PAGE_URL: "requests_page_url",
  REQUIRES_CONSENT: "REQUIRES_CONSENT",
  SENTRY_ENABLED: "SENTRY_ENABLED",
  SENTRY_DSN: "sentry_dsn",
  TRACKING_ENABLED: "TRACKING_ENABLED",
  TRAINING_ENABLED: "TRAINING_ENABLED",
  ENVIRONMENTS_ENABLED: "ENVIRONMENTS_ENABLED",
  USER_MONITORING_GRAPHS: "USER_MONITORING_GRAPHS",
  PROMO_CODE: "PROMO_CODE",
  THEME: "THEME",
  DARK_MODE_ENABLED: "DARK_MODE_ENABLED",
  BRAND_THEME: "BRAND_THEME",
};
