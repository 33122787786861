import { useTheme } from "@mui/material";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

export const IlluGirl = () => {
  const theme = useTheme() as AppThemeProps;

  return (
    <svg
      width="164"
      height="157"
      viewBox="0 0 164 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.52588e-05 78.3959C1.52588e-05 112.023 21.3141 140.675 51.1681 151.577C55.7097 153.239 60.3977 154.469 65.17 155.252C78.2989 157.405 91.7598 156.183 104.286 151.7C134.329 140.887 155.799 112.139 155.785 78.3794C155.785 35.3794 120.91 0.505941 77.8923 0.505941C61.6346 0.482296 45.7805 5.56839 32.5707 15.0454C12.8499 29.1742 1.52588e-05 52.2839 1.52588e-05 78.3959Z"
        fill={theme.palette.svgs.moonWhite}
      />
      <path
        d="M51.2711 151.617C55.8233 153.264 60.5223 154.485 65.3058 155.261C78.4656 157.396 91.9582 156.184 104.514 151.738L100.908 137.426L100.691 136.567L93.3326 136.595L56.3673 136.726L55.8941 138.109L51.2711 151.617Z"
        fill={theme.palette.svgs.royalBlue}
      />
      <path
        d="M151.997 114.567C151.739 114.832 151.503 115.116 151.29 115.418L118.183 114.608L114.321 107.55L102.908 111.987L108.532 125.145C108.986 126.208 109.773 127.095 110.774 127.672C111.776 128.25 112.937 128.488 114.085 128.349L151.49 123.831C152.372 124.948 153.574 125.769 154.935 126.187C156.295 126.604 157.751 126.598 159.108 126.168C160.465 125.738 161.659 124.905 162.531 123.78C163.403 122.655 163.912 121.291 163.989 119.87C164.067 118.448 163.711 117.037 162.967 115.823C162.223 114.61 161.128 113.651 159.826 113.075C158.524 112.5 157.078 112.334 155.68 112.6C154.281 112.866 152.997 113.552 151.998 114.566L151.997 114.567Z"
        fill={theme.palette.svgs.brownSkin}
      />
      <path
        d="M107.437 74.2687L91.9433 69.1912L87.3928 62.9507L65.2485 64.2611L61.9973 71.5434L50.1893 77.1228L52.0041 110.823L55.1085 142.361C71.0719 134.095 100.864 140.397 101.125 140.5L107.437 74.2687Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M104.126 119.883L119.885 113.578L119.818 113.298C119.75 113.014 113.043 84.9869 112.152 80.4829C111.225 75.7905 107.835 74.4931 107.691 74.4404L107.586 74.4016L101.349 76.2999L98.6102 97.821L104.126 119.883Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M70.0982 140.975C69.7287 140.966 69.3592 140.987 68.993 141.036L47.0776 116.209L49.6268 108.58L38.6136 103.228L32.7879 116.298C32.3172 117.353 32.2018 118.533 32.4588 119.66C32.7159 120.787 33.3317 121.801 34.2137 122.548L62.9586 146.903C62.7396 148.309 62.954 149.749 63.5732 151.03C64.1924 152.312 65.1872 153.375 66.4251 154.077C67.6631 154.78 69.0856 155.089 70.5034 154.963C71.9213 154.837 73.2673 154.283 74.3624 153.374C75.4575 152.465 76.2499 151.244 76.6341 149.873C77.0183 148.502 76.9762 147.047 76.5132 145.701C76.0503 144.355 75.1886 143.182 74.0427 142.338C72.8967 141.494 71.5209 141.018 70.0982 140.975Z"
        fill={theme.palette.svgs.brownSkin}
      />
      <path
        d="M48.8229 118.626L57.9648 97.1223L54.3992 82.1439L50.5573 77.2904C50.2759 77.1701 49.9727 77.1094 49.6667 77.1123C49.3606 77.1151 49.0586 77.1814 48.7795 77.3069C47.3617 77.8771 46.1566 79.6249 45.1933 82.5014L36.6255 106.905L48.8229 118.626Z"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M96.2562 54.9826V38.7711C96.2657 35.9183 95.7131 33.0916 94.6301 30.4524C93.5471 27.8132 91.9549 25.4131 89.9443 23.3892C87.9337 21.3653 85.5441 19.7572 82.9121 18.6568C80.28 17.5563 77.457 16.9851 74.6041 16.9756C71.7513 16.9662 68.9245 17.5187 66.2852 18.6017C63.6459 19.6847 61.2457 21.2769 59.2218 23.2874C57.1978 25.2979 55.5897 27.6874 54.4892 30.3194C53.3887 32.9514 52.8175 35.7744 52.808 38.6271C52.8079 38.6751 52.8079 38.7231 52.808 38.7711V54.9826C52.8089 55.7562 53.1166 56.498 53.6637 57.045C54.2108 57.5921 54.9525 57.8998 55.7262 57.9007H93.338C94.1117 57.8998 94.8535 57.5921 95.4005 57.045C95.9476 56.498 96.2554 55.7562 96.2562 54.9826Z"
        fill={theme.palette.svgs.royalBlue}
      />
      <path
        d="M78.9968 57.523C70.2004 57.523 63.0695 50.3923 63.0695 41.5962C63.0695 32.8 70.2004 25.6694 78.9968 25.6694C87.7932 25.6694 94.9241 32.8 94.9241 41.5962C94.9241 50.3923 87.7932 57.523 78.9968 57.523Z"
        fill={theme.palette.svgs.brownSkin}
      />
      <path
        d="M101.397 40.068C101.392 35.512 99.5797 31.1441 96.358 27.9226C93.1363 24.701 88.7683 22.8889 84.2122 22.8837H80.9695C76.4134 22.8889 72.0454 24.701 68.8238 27.9226C65.6022 31.1441 63.79 35.5121 63.785 40.068V40.3922H70.6374L72.9746 33.8471L73.442 40.3922H76.983L78.1621 37.0904L78.3984 40.3922H101.397V40.068Z"
        fill={theme.palette.svgs.royalBlue}
      />
      <path
        d="M80.5618 59.7238C80.8835 59.2882 81.0756 58.7706 81.116 58.2306C81.1564 57.6906 81.0435 57.1501 80.7902 56.6715C77.3543 50.1363 72.5437 38.0605 78.929 30.6133L79.3869 30.0788H60.8484V57.9108L77.6892 60.8821C77.8596 60.9123 78.0324 60.9275 78.2055 60.9277C78.6652 60.9277 79.1184 60.8187 79.5278 60.6095C79.9372 60.4003 80.2912 60.097 80.5606 59.7245L80.5618 59.7238Z"
        fill={theme.palette.svgs.royalBlue}
      />
    </svg>
  );
};
