import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Typography, Grid, Chip, Box, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Skeleton from "react-loading-skeleton";

import { spacing } from "assets/styles/theme";
import { predefinedLabels } from "libs/utilities/labels-mapping";
import { capitalize } from "libs/utilities/utils";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { ArrayField } from "react-hook-form";

type LabelsPreselectProps = {
  onLabelClick: (index: number, key?: string, value?: string) => void;
  preselectFields: Partial<ArrayField<Record<string, string>, "id">>[];
  preselectAppend: (
    value:
      | Partial<Record<string, unknown>>
      | Partial<Record<string, unknown>>[],
    shouldFocus?: boolean | undefined
  ) => void;
  type?: string;
  loading?: boolean;
};

export const LabelsPreselect = ({
  onLabelClick,
  preselectFields = [],
  preselectAppend,
  type,
  loading,
}: LabelsPreselectProps) => {
  const { register } = useFormContext();
  const theme = useTheme() as AppThemeProps;
  useEffect(() => {
    preselectAppend(predefinedLabels);
  }, [preselectAppend, type]);

  const handleClick =
    (index: number, item?: Partial<ArrayField<Record<string, string>, "id">>) =>
    () => {
      onLabelClick(index, item?.key, item?.value);
    };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography
          variant="subtitle2"
          style={{ color: theme.palette.neutrals[400] }}
        >
          Suggested labels:
        </Typography>
      </Grid>
      {loading ? (
        <Box
          display="flex"
          flexWrap="wrap"
          rowGap={spacing[4]}
          columnGap={spacing[8]}
        >
          <Skeleton borderRadius={20} height={30} width={130} />
          <Skeleton borderRadius={20} height={30} width={130} />
          <Skeleton borderRadius={20} height={30} width={130} />
          <Skeleton borderRadius={20} height={30} width={130} />
        </Box>
      ) : (
        <>
          {preselectFields.map((item, index) => (
            <Grid item key={item.id}>
              <Chip
                deleteIcon={<AddCircleRoundedIcon />}
                size="small"
                label={
                  <Typography variant="body2" component="span">
                    <Typography
                      display="inline"
                      variant="subtitle1"
                      component="span"
                    >
                      <strong>{capitalize(item?.key ?? "")}:</strong>{" "}
                    </Typography>
                    {item.value}
                  </Typography>
                }
                onDelete={handleClick(index, item)}
                onClick={handleClick(index, item)}
                sx={{
                  backgroundColor: theme.palette.background.active,
                  padding: "0px 5px",
                  "& .MuiChip-deleteIcon": {
                    color: theme.palette.primary.main,
                  },
                }}
              />
              <input
                type="hidden"
                name={`preselectLabels[${index}].key`}
                defaultValue={item.key}
                ref={register()}
              />
              <input
                type="hidden"
                name={`preselectLabels[${index}].value`}
                defaultValue={item.value}
                ref={register()}
              />
            </Grid>
          ))}{" "}
        </>
      )}
    </Grid>
  );
};
