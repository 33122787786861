import { Grid, TablePagination } from "@mui/material";
import { stringifyUrl } from "query-string";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  useDeploymentRequestsList,
  useDeploymentVersionRequestsList,
} from "libs/data/endpoints/deployment-requests/deployment-requests";
import {
  usePipelineRequestsList,
  usePipelineVersionRequestsList,
} from "libs/data/endpoints/pipeline-requests/pipeline-requests";
import { useProjectRequestsList } from "libs/data/endpoints/projects/projects";

import { NoneRequestRetention } from "./NoneRequestRetention";
import { RequestsDownloadDialog } from "./RequestsDownloadDialog";
import RequestsTable from "./RequestsTable";
import { RequestsToolbar } from "./RequestsToolbar";
import { STATUS_FILTERS } from "./constants";
import { getRequestsParams } from "./utils";

import type { Filter } from "./RequestsToolbar";
import type { Query, RequestParameter, RequestsRows } from "./types";
import type {
  DeploymentVersionDetail,
  PipelineVersionDetail,
  ProjectRequestsListParams,
} from "libs/data/models";

const Requests = ({
  logsUrl,
  allowCreate = false,
  allowGet = false,
  allowLogs = false,
  allowDelete = false,
  allowUpdate = false,
  isProjectLevel = false,
  requestParameters,
  version,
  tableOptions,
  displayDeploymentName,
  hasRetryButton,
}: RequestsProps) => {
  const [requests, setRequests] = useState<RequestsRows | undefined>(undefined);
  const history = useHistory();
  const { projectName, deploymentName, pipelineName, versionName } =
    useParams<{
      projectName: string;
      deploymentName: string | undefined;
      pipelineName: string | undefined;
      versionName: string;
    }>();

  const urlFilters: {
    status?: string;
    type?: "deployment" | "pipeline";
  } = useMemo(
    () => Object.fromEntries(new URLSearchParams(history.location.search)),
    [history.location.search]
  );

  const [query, setQuery] = useState<Query>({
    pageSize: 20,
    page: 0,
    filters: {
      status: urlFilters?.status || STATUS_FILTERS[0].value,
      object_type: urlFilters?.type ?? "deployment",
    },
  });

  const [isDownloadRequestDialogOpen, setIsDownloadRequestDialogOpen] =
    useState(false);

  const baseUrl = useCallback(
    (objectName?: string, objectVersion?: string) => {
      const type = requestParameters?.type ?? "deployment";
      const resourceName =
        requestParameters?.resourceName ??
        deploymentName ??
        pipelineName ??
        objectName;
      const version =
        requestParameters?.version ?? versionName ?? objectVersion;

      return requestParameters?.type || objectName
        ? `/projects/${projectName}${
            resourceName ? `/${type}s/${resourceName}` : ""
          }${version ? `/versions/${version}` : ""}`
        : "";
    },
    [projectName, versionName, deploymentName, pipelineName, requestParameters]
  );

  const params = getRequestsParams(requestParameters, query);
  const fetchAllHook =
    requestParameters?.type === "deployment"
      ? useDeploymentRequestsList
      : usePipelineRequestsList;
  const fetchVersionHook =
    requestParameters?.type === "deployment"
      ? useDeploymentVersionRequestsList
      : usePipelineVersionRequestsList;
  const fetchHook = version ? fetchVersionHook : fetchAllHook;

  const fetchOptions = {
    swr: {
      refreshInterval: 3000,
      dedupingInterval: 0,
    },
  };

  const name = requestParameters?.resourceName || "";
  const versionNumber = requestParameters?.version ?? versionName;

  const args = version
    ? [projectName, name, versionNumber, params, fetchOptions]
    : [projectName, name, params, fetchOptions];

  // argument list has different length and properties based on the fetch type
  // @ts-ignore
  const { data: requestData, isValidating, mutate } = fetchHook(...args);
  const {
    data: projectRequests,
    isValidating: isProjectValidating,
    mutate: mutateProjectRequests,
  } = useProjectRequestsList(projectName, params as ProjectRequestsListParams, {
    swr: {
      enabled: !name,
      refreshInterval: 3000,
      dedupingInterval: 0,
    },
  });

  const refresh = useCallback(() => {
    if (!name) {
      mutateProjectRequests();
    } else {
      mutate();
    }
  }, [mutate, mutateProjectRequests, name]);

  useEffect(() => {
    if (!isValidating && requestData) {
      setRequests(requestData);
    }

    if (!isProjectValidating && projectRequests) {
      setRequests(projectRequests);
    }
  }, [isValidating, isProjectValidating, projectRequests, requestData]);

  const updateFilters = (filters: Filter) => {
    setQuery({
      ...query,
      page: 0,
      filters,
    });
    const newQuery = { status: filters.status, type: filters.object_type };
    history.push(
      stringifyUrl({
        url: history.location.pathname,
        query: newQuery,
      })
    );
  };

  const showRequests = version?.request_retention_mode !== "none";

  return !showRequests ? (
    <NoneRequestRetention
      pipelineName={pipelineName}
      deploymentName={deploymentName}
      requestParameters={requestParameters}
      versionName={versionName}
    />
  ) : (
    <Grid
      container
      item
      xs={12}
      display="flex"
      flexDirection="column"
      height="100%"
    >
      <RequestsToolbar
        selectedFilters={query?.filters}
        search={query?.search}
        setSearch={(search: string | undefined) =>
          setQuery({
            ...query,
            page: 0,
            search,
          })
        }
        setSelectedFilters={(filters: Filter) => updateFilters(filters)}
        requests={requests}
        isProjectLevel={isProjectLevel}
        refresh={refresh}
        openDownloadDialog={() => setIsDownloadRequestDialogOpen(true)}
      />
      <RequestsTable
        baseUrl={baseUrl}
        logsUrl={logsUrl}
        requests={requests}
        allowCreate={allowCreate}
        allowGet={allowGet}
        allowLogs={allowLogs}
        allowDelete={allowDelete}
        allowUpdate={allowUpdate}
        requestParameters={requestParameters}
        tableOptions={tableOptions}
        query={query}
        setQuery={setQuery}
        refresh={refresh}
        displayDeploymentName={displayDeploymentName}
        hasRetryButton={hasRetryButton}
      />
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        nextIconButtonProps={{
          disabled: Boolean(
            query?.pageSize && (requests || []).length < query?.pageSize
          ),
        }}
        count={-1}
        labelDisplayedRows={({ from, to }) => `${from}-${to}`}
        rowsPerPage={query?.pageSize || 20}
        page={query?.page || 0}
        onRowsPerPageChange={(event) =>
          setQuery({
            ...query,
            pageSize: Number(event.target.value),
          })
        }
        onPageChange={(_event, page: number) =>
          setQuery({
            ...query,
            page,
          })
        }
      />
      <RequestsDownloadDialog
        isOpen={isDownloadRequestDialogOpen}
        onClose={() => setIsDownloadRequestDialogOpen(false)}
        requests={requests || []}
      />
    </Grid>
  );
};

type RequestsProps = {
  logsUrl: string;
  allowCreate?: boolean;
  allowGet?: boolean;
  allowLogs?: boolean;
  allowDelete?: boolean;
  allowUpdate?: boolean;
  isProjectLevel?: boolean;
  requestParameters: RequestParameter;
  version?: DeploymentVersionDetail | PipelineVersionDetail | null;
  tableOptions?: {
    withoutMultiSelect?: boolean;
    withoutDeleteButton?: boolean;
    withoutCancelButton?: boolean;
  };
  displayDeploymentName?: boolean;
  hasRetryButton: boolean;
};

export default Requests;
