/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * Programmatic interface to UbiOps
 * OpenAPI spec version: v2.1
 */
import useSwr from "swr";

import { orvalAxios } from "../../axios/index";

import type { ErrorType } from "../../axios/index";
import type {
  Success,
  TOTPCreate,
  TOTPList,
  TOTPUpdate,
  TOTPWithSecretList,
  UserActivate,
  UserCreatePassword,
  UserDetail,
  UserPendingCreate,
  UserPendingDetail,
  UserRequestPassword,
  UserSignUpQuestions,
  UserUpdate,
} from "../../models";
import type { Key, SWRConfiguration } from "swr";

// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * 
### Description
Get the details of the user that makes the request

### Response Structure
Details of the user
- `id`: Unique identifier for the user (UUID)
- `email`: Email of the user
- `name`: Name of the user
- `surname`: Surname of the user
- `registration_date`: Date when the user was registered
- `newsletter`: A boolean indicating whether to subscribe to the newsletters
- `authentication`: Authentication method of the user. It can be 'google', 'microsoft' or 'ubiops'.
- `phone`: Phone number of the user

#### Response Examples
```
{
  "id": "4740a13a-70ae-4b7a-a461-8231eb2c0594",
  "email": "test@example.com",
  "name": "User name",
  "surname": "User surname",
  "registration_date": "2020-01-10 10:06:25.632+00:00",
  "newsletter": False,
  "authentication": "ubiops",
  "phone": "+999999999"
}
```

 * @summary Get user details
 */
export const userGet = (options?: SecondParameter<typeof orvalAxios>) => {
  return orvalAxios<UserDetail>({ url: `/user`, method: "get" }, options);
};

export const getUserGetKey = () => [`/user`] as const;

export type UserGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof userGet>>
>;
export type UserGetQueryError = ErrorType<unknown>;

/**
 * @summary Get user details
 */
export const useUserGet = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof userGet>>, TError> & {
    swrKey?: Key;
    enabled?: boolean;
  };
  request?: SecondParameter<typeof orvalAxios>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getUserGetKey() : null));
  const swrFn = () => userGet(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create a new user with the given details. After creation, an email is send to the email address to activate the account. The password needs to be at least 8 characters long.

### Required Parameters
- `email`: Email of the user
- `password`: Password of the user

### Optional Parameters
- `name`: Name of the user
- `surname`: Surname of the user
- `phone`: Phone number of the user

#### Request Examples
```
{
  "email": "test@example.com",
  "password": "secret-password",
  "name": "User name",
  "surname": "User surname",
  "phone": "+999999999"
}
```

```
{
  "email": "test@example.com",
  "password": "secret-password"
}
```

### Response Structure
Details of the created user
- `email`: Email of the user
- `name`: Name of the user
- `surname`: Surname of the user
- `phone`: Phone number of the user

#### Response Examples
```
{
  "email": "test@example.com",
  "name": "User name",
  "surname": "User surname",
  "phone": "+999999999"
}
```

 * @summary Create a new user
 */
export const userCreate = (
  userPendingCreate: UserPendingCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<UserPendingDetail>(
    {
      url: `/user`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: userPendingCreate,
    },
    options
  );
};

/**
 * 
### Description
Update the details of the user making the request.

When updating the password, the parameter `previous_password` with the value of the previous password is also required. The password needs to be at least 8 characters long.

### Optional Parameters
- `email`: Email of the user
- `password`: Password of the user
- `previous_password`: Previous password of the user (This field is only required when updating the password)
- `name`: Name of the user
- `surname`: Surname of the user
- `newsletter`: A boolean indicating whether to subscribe to the newsletters
- `phone`: Phone number of the user

#### Request Examples
```
{
  "email": "new_test@example.com"
  "newsletter": True
}
```

```
{
  "password": "new-secret-password",
  "previous_password": "previous-secret-password"
}
```

```
{
  "name": "New user name",
  "surname": "New user surname"
}
```

### Response Structure
Details of the user
- `id`: Unique identifier for the user (UUID)
- `email`: Email of the user
- `name`: Name of the user
- `surname`: Surname of the user
- `registration_date`: Date when the user was registered
- `newsletter`: A boolean indicating whether to subscribe to the newsletters
- `authentication`: Authentication method of the user. It can be 'google', 'microsoft' or 'ubiops'.
- `phone`: Phone number of the user

#### Response Examples
```
{
  "id": "4740a13a-70ae-4b7a-a461-8231eb2c0594",
  "email": "new_test@example.com",
  "type": "user",
  "name": "New user name",
  "surname": "New user surname",
  "registration_date": "2020-01-10 10:06:25.632+00:00",
  "newsletter": false,
  "authentication": "ubiops",
  "phone": "+999999999"
}
```

 * @summary Update user details
 */
export const userUpdate = (
  userUpdate: UserUpdate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<UserDetail>(
    {
      url: `/user`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: userUpdate,
    },
    options
  );
};

/**
 * 
### Description
Delete the user that makes the request

 * @summary Delete user
 */
export const userDelete = (options?: SecondParameter<typeof orvalAxios>) => {
  return orvalAxios<void>({ url: `/user`, method: "delete" }, options);
};

/**
 * 
### Description
List the 2FA devices for the current user. Devices will only appear in this list after they have been confirmed.

### Response Structure
A list of details of the devices
- `name`: Name of the device
- `config_url`: String representation of the secret shared between the device and the platform
- `confirmed`: Boolean value. Indicates whether the device is verified or not.

#### Response Examples
```
[
    {
        "name": "device_name",
        "config_url": "generated_secret_url",
        "confirmed": false
    }
]
```


 * @summary List 2FA devices
 */
export const devicesList = (options?: SecondParameter<typeof orvalAxios>) => {
  return orvalAxios<TOTPWithSecretList[]>(
    { url: `/user/2fa`, method: "get" },
    options
  );
};

export const getDevicesListKey = () => [`/user/2fa`] as const;

export type DevicesListQueryResult = NonNullable<
  Awaited<ReturnType<typeof devicesList>>
>;
export type DevicesListQueryError = ErrorType<unknown>;

/**
 * @summary List 2FA devices
 */
export const useDevicesList = <TError = ErrorType<unknown>>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof devicesList>>, TError> & {
    swrKey?: Key;
    enabled?: boolean;
  };
  request?: SecondParameter<typeof orvalAxios>;
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false;
  const swrKey =
    swrOptions?.swrKey ?? (() => (isEnabled ? getDevicesListKey() : null));
  const swrFn = () => devicesList(requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Setup a 2FA device for the current user. After creation of the first device, the user has to authenticate with a 2FA token in order to log in.

### Required Parameters
- `name`: Name of the device

### Response Structure
Details of the device
- `name`: Name of the device
- `config_url`: String representation of the secret shared between the device and the platform
- `confirmed`: Boolean value. Indicates whether the device is verified or not.

#### Response Examples
```
{
    "name": "device_name",
    "config_url": "generated_secret_url",
    "confirmed": false
}
```


 * @summary Create new 2FA device
 */
export const devicesCreate = (
  tOTPCreate: TOTPCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<TOTPWithSecretList>(
    {
      url: `/user/2fa`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: tOTPCreate,
    },
    options
  );
};

/**
 * 
### Description
Retrieve the details of a 2FA device.

### Response Structure
A list of details of the devices
- `name`: Name of the device
- `confirmed`: Boolean value. Indicates whether the device is verified or not.

#### Response Examples
```
{
  "name": "device-name",
  "confirmed": false
}
```


 * @summary Get details of 2FA device
 */
export const devicesGet = (
  deviceName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<TOTPList>(
    { url: `/user/2fa/${deviceName}`, method: "get" },
    options
  );
};

export const getDevicesGetKey = (deviceName: string) =>
  [`/user/2fa/${deviceName}`] as const;

export type DevicesGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof devicesGet>>
>;
export type DevicesGetQueryError = ErrorType<unknown>;

/**
 * @summary Get details of 2FA device
 */
export const useDevicesGet = <TError = ErrorType<unknown>>(
  deviceName: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof devicesGet>>, TError> & {
      swrKey?: Key;
      enabled?: boolean;
    };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!deviceName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getDevicesGetKey(deviceName) : null));
  const swrFn = () => devicesGet(deviceName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Update a device. The name of the device can be updated.

### Required Parameters
- `name`: New name of the device

### Optional Parameters
- `token`: Token generated by your authentication application. If this parameter is passed, the device is tried to be verified.

### Response Structure
A list of details of the devices
- `name`: (Updated) Name of the device
- `config_url`: String representation of the secret shared between the device and the platform
- `confirmed`: Boolean value. Indicates whether the device is verified or not.


If the provided token is invalid, the device is not verified
```
{
  "error": "Invalid 2FA token"
}
```

#### Response Examples
```
[
    {
        "name": "device_name",
        "config_url": "generated_secret_url",
        "confirmed": false
    }
]
```


 * @summary Update 2FA device
 */
export const devicesUpdate = (
  deviceName: string,
  tOTPUpdate: TOTPUpdate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<TOTPList>(
    {
      url: `/user/2fa/${deviceName}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: tOTPUpdate,
    },
    options
  );
};

/**
 * 
### Description
Delete a 2FA device. After the last device of user is deleted, the user is no longer required to authenticate with a 2FA token.

 * @summary Delete 2FA device
 */
export const devicesDelete = (
  deviceName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    { url: `/user/2fa/${deviceName}`, method: "delete" },
    options
  );
};

/**
 * 
### Description
Activate your account by giving the activation token received in the e-mail

### Response Structure
Details of the user
- `id`: Unique identifier for the user (UUID)
- `email`: Email of the user
- `name`: Name of the user
- `surname`: Surname of the user
- `registration_date`: Date when the user was registered
- `newsletter`: A boolean indicating whether to subscribe to the newsletters
- `authentication`: Authentication method of the user. It can be 'google', 'microsoft' or 'ubiops'.
- `phone`: Phone number of the user

#### Response Examples
```
{
  "id": "4740a13a-70ae-4b7a-a461-8231eb2c0594",
  "email": "new_test@example.com",
  "name": "New user name",
  "surname": "New user surname",
  "registration_date": "2020-01-10 10:06:25.632+00:00",
  "newsletter": false,
  "authentication": "ubiops",
  "phone": "+999999999"
}
```

 * @summary Activate user account
 */
export const activateAccount = (
  token: string,
  userActivate: UserActivate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<UserDetail>(
    {
      url: `/user/activate/${token}`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: userActivate,
    },
    options
  );
};

/**
 * 
### Description
Request a reset of password via e-mail. The token is valid for 60 minutes.

### Required Parameters
- `email`: Email of the user

#### Request Examples
```
{
  "email": "test@example.com"
}
```

### Response Structure
- `success`: A boolean indicating whether password reset was successful

 * @summary Request a password reset
 */
export const requestResetPassword = (
  userRequestPassword: UserRequestPassword,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<Success>(
    {
      url: `/user/reset-password`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: userRequestPassword,
    },
    options
  );
};

/**
 * 
### Description
Create a new password after validation of the token that was send in the e-mail. The password needs to be at least 8 characters long.

### Required Parameters
- `new_password`: The new password

#### Request Examples
```
{
  "new_password": "new_secure_password",
}
```

### Response Structure
- `success`: A boolean indicating whether password reset was successful

 * @summary Create a new password after resetting
 */
export const resetPassword = (
  token: string,
  userCreatePassword: UserCreatePassword,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<Success>(
    {
      url: `/user/reset-password/${token}`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: userCreatePassword,
    },
    options
  );
};

/**
 * 
### Description
Store questions answered by our clients upon sign-up

### Required Parameters
- `industry`: Type of organization
- `role`: Role in organization
- `signup_reason`: Reason of signing up to UbiOps

#### Request Examples
```
{
  "industry": "FinTech/Financial Services",
  "role": "Developer",
  "signup_reason": "Take a look around"
}
```

### Response Structure
- `success`: A boolean indicating whether password reset was successful

 * @summary Give in sign up questions
 */
export const userQuestions = (
  userSignUpQuestions: UserSignUpQuestions,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<Success>(
    {
      url: `/user/sign-up-questions`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: userSignUpQuestions,
    },
    options
  );
};
