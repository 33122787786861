import PipelinesIcon from "@mui/icons-material/AccountTreeRounded";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CircleIcon from "@mui/icons-material/FiberManualRecord";
import FolderIcon from "@mui/icons-material/Folder";
import DownloadIcon from "@mui/icons-material/GetApp";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import IntegrationInstructionsRounded from "@mui/icons-material/IntegrationInstructionsRounded";
import GoPreviousIcon from "@mui/icons-material/KeyboardArrowLeft";
import GoNextIcon from "@mui/icons-material/KeyboardArrowRight";
import UserIcon from "@mui/icons-material/PersonRounded";
import DeploymentsIcon from "@mui/icons-material/WidgetsRounded";
import {
  Grid,
  IconButton,
  Box,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useMemo } from "react";

import { spacing } from "assets/styles/theme";
import { AUDIT_LOGS_LIMIT } from "libs/constants/constants";
import { RootUrlContext } from "libs/contexts";
import {
  FULL_DATE_TIME_FORMAT,
  getTzAwareDate,
} from "libs/utilities/date-util";
import { downloadCSV } from "libs/utilities/download-helper";
import { auditEventsActions } from "libs/utilities/labels-mapping";

import {
  OverflowTooltip,
  TableLink,
  ToggleButtonGroup,
  SecondaryButton,
} from "components/atoms";
import { BaseTable } from "components/molecules";

import { AuditEventChip } from "./AuditEventChip";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { BaseColumn } from "components/molecules/BaseTable";
import type {
  AuditList,
  DeploymentAuditEventsListAction,
} from "libs/data/models";

export interface AuditProps {
  action?: DeploymentAuditEventsListAction;
  auditLogs: AuditList[];
  loading: boolean;
  offset: number;
  projectName: string;
  setAction: (filter?: DeploymentAuditEventsListAction) => void;
  setOffset: (offset: number) => void;
  showObjectInfo?: boolean;
}

export const Audit = ({
  action,
  auditLogs,
  loading,
  offset,
  projectName,
  setAction,
  setOffset,
  showObjectInfo = true,
  ...props
}: AuditProps) => {
  const rootUrl = useContext(RootUrlContext);
  const theme = useTheme() as AppThemeProps;

  const onNextPage = () => {
    setOffset(offset + AUDIT_LOGS_LIMIT);
  };

  const onPreviousPage = () => {
    setOffset(Math.max(0, offset - AUDIT_LOGS_LIMIT));
  };

  const handleExportToCsv = () => {
    if (auditLogs?.length) {
      const filteredData = auditLogs.map((request: AuditList) =>
        Object.fromEntries(
          Object.entries(request).filter(([key]) => key !== "tableData")
        )
      );

      downloadCSV({
        data: filteredData,
        fileName: `${projectName}-audit-events`,
      });
    }
  };

  const auditColumns = useMemo(
    () =>
      [
        {
          title: "Date",
          field: "date",
          type: "datetime",
          width: 200,
          render: (rowData: AuditList) => (
            <Typography variant="body1">
              {getTzAwareDate(rowData.date).format(
                `${FULL_DATE_TIME_FORMAT}.SSS Z`
              )}
            </Typography>
          ),
        },
        {
          title: "",
          width: 250,
          field: "object_type",
          render: (rowData: AuditList) => {
            let Icon;
            let link;
            switch (rowData.object_type) {
              case "deployment":
                Icon = DeploymentsIcon;
                break;

              case "pipeline":
                Icon = PipelinesIcon;
                break;

              case "import":
                Icon = ImportExportIcon;
                break;

              case "export":
                Icon = ImportExportIcon;
                break;

              case "bucket":
                Icon = FolderIcon;
                break;

              case "environment":
                Icon = IntegrationInstructionsRounded;
                break;

              case "metric":
                Icon = AssessmentIcon;
                break;

              default:
                Icon = CircleIcon;
                break;
            }

            switch (rowData.object_type) {
              case "project":
                link = `${rootUrl}/project-settings/general`;
                break;

              case "webhook":
                link = `${rootUrl}/monitoring/webhooks/${rowData.object_name}`;
                break;

              case "import":
                link = `${rootUrl}/imports-exports/imports`;
                break;

              case "export":
                link = `${rootUrl}/imports-exports/exports`;
                break;

              case "bucket":
                link = `${rootUrl}/storage/${rowData.object_type}s/${rowData.object_name}`;
                break;

              case "environment":
                link = `${rootUrl}/environments/custom/${rowData.object_name}`;
                break;
              case "instance_type_group":
                link = `${rootUrl}/project-settings/instance-type-groups`;
                break;
              default:
                link = `${rootUrl}/${rowData.object_type}s/${rowData.object_name}/general`;
                break;
            }

            const isDeleteEvent = rowData.action === "delete";
            const isInstanceTypeGroup =
              rowData.object_type === "instance_type_group";

            return (
              <Box textAlign="left" maxWidth={spacing[200]}>
                {showObjectInfo && (
                  <TableLink to={link} style={{ fontSize: "1em" }}>
                    <Grid
                      container
                      component="span"
                      spacing={1}
                      alignItems="center"
                      wrap="nowrap"
                    >
                      <Tooltip title={rowData.object_type}>
                        <Grid item component={Icon} />
                      </Tooltip>
                      <OverflowTooltip title={rowData.object_name}>
                        <Typography
                          variant="body1"
                          style={{
                            fontStyle: isDeleteEvent ? "italic" : "none",
                          }}
                        >
                          {rowData.object_name ||
                            (isDeleteEvent && "deleted") ||
                            (isInstanceTypeGroup && "Instance type group")}
                        </Typography>
                      </OverflowTooltip>
                    </Grid>
                  </TableLink>
                )}
                <Typography variant="body1">
                  <Grid
                    container
                    component="span"
                    spacing={1}
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <Tooltip title="User">
                      <Grid item component={UserIcon} />
                    </Tooltip>
                    <OverflowTooltip title={rowData.user}>
                      <Typography variant="body1">{rowData.user}</Typography>
                    </OverflowTooltip>
                  </Grid>
                </Typography>
              </Box>
            );
          },
          align: "center",
        },
        {
          title: "Event",
          field: "event",
          render: (rowData: AuditList) => (
            <Box display="flex" alignItems="center" columnGap={spacing[4]}>
              <Box minWidth={"60px"}>
                <AuditEventChip
                  action={rowData.action}
                  onClick={() => setAction(rowData.action)}
                />
              </Box>
              <Typography variant="body1">{rowData.event}</Typography>
            </Box>
          ),
        },
      ] as BaseColumn[],
    [rootUrl, setAction, showObjectInfo]
  );

  return (
    <>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={spacing[8]}
      >
        <Box>
          <Typography variant="subtitle2" gutterBottom={true}>
            Filter by action:
          </Typography>
          <Grid container spacing={1}>
            <ToggleButtonGroup
              items={auditEventsActions}
              onChange={(value) =>
                setAction(value as DeploymentAuditEventsListAction)
              }
              value={action}
            />
          </Grid>
        </Box>
        <SecondaryButton
          startIcon={<DownloadIcon />}
          onClick={handleExportToCsv}
        >
          Export to CSV
        </SecondaryButton>
      </Grid>
      <BaseTable
        title=""
        columns={auditColumns}
        data={auditLogs}
        hasSearchBar={false}
        hasPagination={false}
        {...props}
      />
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        component={Box}
        mt={1}
      >
        <Grid item component={Tooltip} title="Previous page">
          <span>
            <IconButton
              onClick={onPreviousPage}
              style={{
                color: theme.palette.primary.main,
              }}
              disabled={offset < 20 || loading}
            >
              <GoPreviousIcon fontSize="small" />
            </IconButton>
          </span>
        </Grid>
        <Grid item component={Tooltip} title="Next page">
          <span>
            <IconButton
              onClick={onNextPage}
              style={{
                color: theme.palette.primary.main,
              }}
              disabled={
                !auditLogs || auditLogs.length <= AUDIT_LOGS_LIMIT || loading
              }
            >
              <GoNextIcon fontSize="small" />
            </IconButton>
          </span>
        </Grid>
      </Grid>
    </>
  );
};
