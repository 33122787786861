import AdjustOutlinedIcon from "@mui/icons-material/AdjustOutlined";
import CrossIcon from "@mui/icons-material/CancelOutlined";
import CheckIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import { Tooltip, Typography, useTheme } from "@mui/material";
import { AlertCircle } from "react-feather";

import { formatStatusLabel } from "libs/utilities/statuses";

import Icon from "../Icon/Icon";

import type { IconProps } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme.d";

import "./StatusIcon.scss";

const failedStatuses = ["failed", "unavailable", "cancelled"];

const successStatuses = ["completed", "available", "success", "active"];

const holdStatuses = ["confirmation"];

const renderStatusIcon = (status: statusValues | string) =>
  successStatuses.includes(status)
    ? CheckIcon
    : failedStatuses.includes(status)
    ? CrossIcon
    : holdStatuses.includes(status)
    ? PauseCircleOutlineIcon
    : AdjustOutlinedIcon;

type statusValues =
  | "failed"
  | "unavailable"
  | "cancelled"
  | "completed"
  | "available"
  | "success"
  | "confirmation";

type StatusIconProps = {
  label?: string;
  status: statusValues | string;
  animation?: boolean;
  errorMessage?: string | null;
  displayError?: boolean;
  color?: "inherit" | "primary" | "secondary" | "action" | "disabled" | "error";
  onClick?: () => void;
} & IconProps;

export const StatusIcon = ({
  label,
  status,
  animation = true,
  errorMessage = undefined,
  displayError = false,
  ...props
}: StatusIconProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <div className="status-icon">
      {displayError && errorMessage && (
        <Tooltip
          title={
            <>
              Last building failed:
              <br /> &quot;{errorMessage}&quot;
            </>
          }
          placement="bottom-start"
          sx={{
            backgroundColor: theme.palette.error.main,
            color: theme.palette.neutrals[0],
            fontSize: "0.8em",
          }}
        >
          <div className="status-icon__alert">
            <Icon
              className="alert-triangle"
              status="error"
              component={AlertCircle}
              size={20}
              {...props}
            />
          </div>
        </Tooltip>
      )}
      <Icon
        component={renderStatusIcon(status)}
        status={status}
        className={[
          "status-icon__icon",
          animation && `status-icon__icon--${status}`,
        ]
          .filter((x) => x)
          .join(" ")}
        {...props}
      />
      <Typography className="status-icon__label">
        {formatStatusLabel(label)}
      </Typography>
    </div>
  );
};
