import { Box, useTheme } from "@mui/material";
import { FileUploader } from "react-drag-drop-files-adjusted";

import { getBorders } from "assets/styles/theme";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { CSSProperties, ReactNode } from "react";

interface DropSelectFileProps {
  children?: ReactNode;
  handleChange?: (file: File) => void;
  name?: string;
  padding?: number;
  types?: string[];
  style?: CSSProperties;
  multiple?: boolean;
}

export const DropSelectFile = ({
  children,
  handleChange,
  padding,
  style = {},
  multiple = false,
  ...props
}: DropSelectFileProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <FileUploader
      dropMessageStyle={{
        background: "unset",
        border: getBorders(theme).thick,
      }}
      handleChange={handleChange}
      multiple={multiple}
      {...props}
    >
      <Box
        border={getBorders(theme).dotted}
        borderRadius="5px"
        padding={padding === undefined ? 5 : padding}
        style={style}
      >
        {children}
      </Box>
    </FileUploader>
  );
};
