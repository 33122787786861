import { useTheme } from "@mui/material";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

export const ScalingStrategyDefault = ({ fill }: { fill: string }) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <svg
      width="167"
      height="169"
      viewBox="0 0 167 169"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.824951"
        y="0.975189"
        width="164.75"
        height="164.75"
        stroke={theme.palette.charts.grid}
      />
      <path d="M0.324707 55.0637H166.075" stroke={theme.palette.charts.grid} />
      <path d="M0.324707 28.265H166.075" stroke={theme.palette.charts.grid} />
      <path d="M0.324707 81.8614H166.075" stroke={theme.palette.charts.grid} />
      <path d="M0.324707 108.659H166.075" stroke={theme.palette.charts.grid} />
      <path d="M0.324707 135.457H166.075" stroke={theme.palette.charts.grid} />
      <path
        d="M28.208 0.475098L28.208 166.225"
        stroke={theme.palette.charts.grid}
      />
      <path
        d="M55.5746 0.475098L55.5746 166.225"
        stroke={theme.palette.charts.grid}
      />
      <path
        d="M82.9418 0.475098L82.9418 166.225"
        stroke={theme.palette.charts.grid}
      />
      <path
        d="M110.308 0.475098L110.308 166.225"
        stroke={theme.palette.charts.grid}
      />
      <path
        d="M137.676 0.475098L137.676 166.225"
        stroke={theme.palette.charts.grid}
      />
      <path
        d="M1.78748 165.25H32.5C34.7091 165.25 36.5 163.459 36.5 161.25V111.725C36.5 109.516 38.2909 107.725 40.5 107.725H106.012C108.222 107.725 110.012 105.934 110.012 103.725V84.9127C110.012 82.7036 111.803 80.9127 114.012 80.9127H133.312C135.522 80.9127 137.312 79.1219 137.312 76.9127V58.1002C137.312 55.8911 139.103 54.1002 141.312 54.1002H165.1"
        stroke={fill}
        strokeWidth="6"
      />
      <path
        d="M28 162V121.526V103.744C28 100.982 30.2386 98.7438 33 98.7438H43.4003H92.2828C95.0442 98.7438 97.2828 96.5053 97.2828 93.7438V79.3254C97.2828 76.564 99.5213 74.3254 102.283 74.3254H125.473C128.234 74.3254 130.473 72.0868 130.473 69.3254V51C130.473 48.2386 132.711 46 135.473 46H163"
        stroke={theme.palette.primary.light}
        strokeWidth="6"
        strokeDasharray="12 4"
      />
    </svg>
  );
};
