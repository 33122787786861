/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * Programmatic interface to UbiOps
 * OpenAPI spec version: v2.1
 */
import { orvalAxios } from "../../axios/index";

import type {
  CustomTokenObtainPair,
  CustomTokenRefresh,
  OauthComplete,
  OauthCompleteDetail,
  OauthSignIn,
  OauthSignInDetail,
  OauthSignUp,
  OauthSignUpDetail,
  SingleUseToken,
  SingleUseTokenCreate,
} from "../../models";

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
  T
>() => T extends Y ? 1 : 2
  ? A
  : B;

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<
    { [Q in P]: T[P] },
    { -readonly [Q in P]: T[P] },
    P
  >;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object
        ? NonReadonly<NonNullable<T[P]>>
        : T[P];
    }
  : DistributeReadOnlyOverUnions<T>;

// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * 
### Description
Request an access token and a refresh token. The user must have a registered email and password.

These tokens should be used with a Bearer prefix when authenticating (instead of Token prefix).

### Required Parameters
- `email`: Email of the user
- `password`: Password of the user

### Optional Parameters
- `token`: 2FA token for the user if 2FA is enabled for the user

### Response Structure
Access and refresh tokens
- `refresh`: Refresh token
- `access`: Access token

#### Response Examples
```
{
  "refresh": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTU3MzkxOTExNCwianRpIjoiZTViOTA0ZDNkNGJiNDJmMmFlZWE4YzIzNzljYmQ1ODciLCJ1c2VyX2lkIjoiYjlmZWYxNjgtODlmOC00MThjLTk3NjktNDBjMDkxMDEwMDNiIn0.-a-qAxW8aHN76ACe1YCN3wBZ-OaHFGKIiPzPDKZfPuY",
  "access": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNTczODMyNzc0LCJqdGkiOiIxYzRmMjZjNDYxMDc0MWFlODAxMzQyMGVjZmJlY2I3NCIsInVzZXJfaWQiOiJiOWZlZjE2OC04OWY4LTQxOGMtOTc2OS00MGMwOTEwMTAwM2IifQ.gJ1OLEPvR8UIneql2BUJuCmdoxnCTJx0Q7uuhHPbqUU"
}
```

 * @summary Request new access/refresh tokens
 */
export const authorizeCreate = (
  customTokenObtainPair: CustomTokenObtainPair,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/authorize`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: customTokenObtainPair,
    },
    options
  );
};

/**
 * 
### Description
Request a new access token with a valid refresh token. When the access token of the user expires, they can request a new access token with their refresh token. If the access token of the user is still valid, the current access token is returned.

These tokens should be used with a Bearer prefix when authenticating (instead of Token prefix).

### Required Parameters
- `refresh`: Current valid refresh token of the user

### Response Structure
Access and refresh tokens
- `access`: A new access token

#### Response Examples
```	
{
  "access": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNTczODMyNzc0LCJqdGkiOiIxYzRmMjZjNDYxMDc0MWFlODAxMzQyMGVjZmJlY2I3NCIsInVzZXJfaWQiOiJiOWZlZjE2OC04OWY4LTQxOGMtOTc2OS00MGMwOTEwMTAwM2IifQ.gJ1OLEPvR8UIneql2BUJuCmdoxnCTJx0Q7uuhHPbqUU"
}
```

 * @summary Request new access token
 */
export const authorizeRefreshToken = (
  customTokenRefresh: NonReadonly<CustomTokenRefresh>,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/authorize/refresh-token`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: customTokenRefresh,
    },
    options
  );
};

/**
 * 
### Description
Request a one time use access token. The user must have a registered email and password.

These tokens can be used *once* to authenticate a request by sending them as a query parameter `authtoken`.

### Response Structure
- `token`: Single use access token.

#### Response Examples
```
{
  "token": "285-nppbkeh6"
}
```

 * @summary Request new single use token
 */
export const authorizeSingleUseToken = (
  singleUseTokenCreate: SingleUseTokenCreate,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<SingleUseToken>(
    {
      url: `/authorize/single-use-token`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: singleUseTokenCreate,
    },
    options
  );
};

/**
 * 
Complete the Oath sign-in/sign-up process for the user. If it's a sign-up process, an account for the user will be created, but the user still needs to activate their account and accept the terms and conditions.

### Required Parameters
- `code`: A valid code generated by the authorization server
- `provider`: The provider with which the user wants to sign-in/sign-up

### Optional Parameters
- `state_key`: Key of the state generated in the beginning of the Oauth process. It must be provided if state is mandatory for the given provider.
- `state_value`: Value of the state generated in the beginning of the Oauth process. It must be provided if state is mandatory for the given provider.

### Response Structure
Access and refresh tokens, or an activation token
- `refresh`: Refresh token
- `access`: Access token
- `activation`: Activation token

#### Response Examples

Successful sign in response
```	
{
  "refresh": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTYwNDU2NzE5MSwianRpIjoiNmI3MGQxNzRkNTQ2NDdhYzg0NjRkNmY4ZDVmMjhlYmUiLCJ1c2VyX2lkIjoiMjFjZWIwNDEtNTdkNy00ZjZjLTkxZTgtYTA5MDVkZThmNmIyIn0.Vnj8IvrP1J5QiUGimG1Uoi0YVblXkHLL4q0jNHKIz1Q",
  "access": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjA0NDkxNTkxLCJqdGkiOiI5NzU4NzE1YWYwOTE0ZDdhYjZjOTc3NmFiZDBjOGE2NyIsInVzZXJfaWQiOiIyMWNlYjA0MS01N2Q3LTRmNmMtOTFlOC1hMDkwNWRlOGY2YjIifQ.uH7jeLQprR2AptffoMsVRm7LZ7BjknnmV6pvhEsKXDU",
  "activation": None
}
```

Successful sign up response
```	
{
  "refresh": None,
  "access": None,
  "activation": nvr9mqxy4n0wdkge7j0qm0jcdjkjnj02czp2c08hai2vkzl045om58brdkflr84x
}
```

 * @summary Complete sign-in/sign-up with Oauth
 */
export const oauthComplete = (
  oauthComplete: OauthComplete,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<OauthCompleteDetail>(
    {
      url: `/oauth/complete`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: oauthComplete,
    },
    options
  );
};

/**
 * 
### Description
Sign in with Oauth. The user must have already registered to UbiOps.

### Required Parameters
- `email`: Email of the user

### Response Structure
Details of the Oauth sign-in for the user
- `provider`: The provider that the user used when registering
- `url`: Redirect uri which will be used after the sign-in process of the provider
- `state_key`: A key value for the state. If, for the provider that the user has used when registering, state is mandatory, this field is returned to be validated later.

#### Response Examples
```	
{
  "provider": "google",
  "url": "https://accounts.google.com/o/oauth2/auth?client_id=client_id&redirect_uri=https://redirect_uri&response_type=code&scope=openid+email+profile&state=PrfKuQilhZfK1kacZMKTr8XIr0zFWTL5",
  "state_key": "ZHKP7tp2YpXzGEUjAAsX9nlEDpNi8p09SCmJfGCzmjbeTKe0268nZVsFVT9zxFZC"
}
```

 * @summary Sign in with Oauth
 */
export const oauthSignIn = (
  oauthSignIn: OauthSignIn,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<OauthSignInDetail>(
    {
      url: `/oauth/sign-in`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: oauthSignIn,
    },
    options
  );
};

/**
 * 
### Description
Sign up with Oauth.

### Required Parameters
- `provider`: The provider with which the user wants to register

### Response Structure
Details of the Oauth sign-up for the user
- `url`: Redirect uri which will be used after the sign-up process of the provider
- `state_key`: A key value for the state. If, for the provider that the user has used when registering, state is mandatory, this field is returned to be validated later.

#### Response Examples
```	
{
  "url": "https://accounts.google.com/o/oauth2/auth?client_id=client_id&redirect_uri=https://redirect_uri&response_type=code&scope=openid+email+profile&state=PrfKuQilhZfK1kacZMKTr8XIr0zFWTL5",
  "state_key": "ZHKP7tp2YpXzGEUjAAsX9nlEDpNi8p09SCmJfGCzmjbeTKe0268nZVsFVT9zxFZC"
}
```

 * @summary Sign up with Oauth
 */
export const oauthSignUp = (
  oauthSignUp: OauthSignUp,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<OauthSignUpDetail>(
    {
      url: `/oauth/sign-up`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: oauthSignUp,
    },
    options
  );
};
