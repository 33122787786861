/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * Programmatic interface to UbiOps
 * OpenAPI spec version: v2.1
 */

export type DeploymentVersionCreateScalingStrategy =
  typeof DeploymentVersionCreateScalingStrategy[keyof typeof DeploymentVersionCreateScalingStrategy];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeploymentVersionCreateScalingStrategy = {
  default: "default",
  moderate: "moderate",
} as const;
