import Plus from "@mui/icons-material/AddBoxRounded";
import { Box, Grid } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";

import { IlluEmptyCustomMetrics } from "assets/images/IlluEmptyCustomMetrics";
import { spacing } from "assets/styles/theme";
import { explanations } from "libs/utilities/explanations";
import { routes } from "routes";

import { Card, PrimaryButton } from "components/atoms";

type EmptyCustomMetricsCardProps = {
  type: "deployment" | "pipeline" | "training";
};
export const EmptyCustomMetricsCard = ({
  type,
}: EmptyCustomMetricsCardProps) => {
  const history = useHistory();
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const createUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .monitoring.metrics.create.index();

  return (
    <Grid item xs={12}>
      <Card height="500px">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={4}
          padding={spacing[40]}
        >
          <IlluEmptyCustomMetrics />
          {explanations.customMetrics.emptyMetrics(type)}
          <PrimaryButton
            startIcon={<Plus />}
            onClick={() => history.push(createUrl)}
          >
            Create custom metric
          </PrimaryButton>
        </Box>
      </Card>
    </Grid>
  );
};
