/* eslint-disable no-constant-condition */
import { useTheme } from "@mui/material";
import { useCallback } from "react";

import {
  disabledStatuses,
  errorStatuses,
  infoStatuses,
  successStatuses,
  primaryStatuses,
  warningStatuses,
  secondaryStatuses,
} from "assets/styles/theme/utils/statusConstants";
import { useGetThemeMode } from "store/features/themeMode";

import type { AppThemeProps } from "../../assets/styles/theme/theme";

export const useColorByStatus = () => {
  const theme = useTheme() as AppThemeProps;

  return useCallback(
    (status: string) => {
      if (successStatuses.includes(status)) {
        return theme.palette.success.main;
      } else if (infoStatuses.includes(status)) {
        return theme.palette.info.main;
      } else if (secondaryStatuses.includes(status)) {
        return theme.palette.pendingStatus;
      } else if (primaryStatuses.includes(status)) {
        return theme.palette.primary.main;
      } else if (errorStatuses.includes(status)) {
        return theme.palette.error.main;
      } else if (warningStatuses.includes(status)) {
        return theme.palette.warning.main;
      } else if (disabledStatuses.includes(status)) {
        return theme.palette.action.disabled;
      } else {
        return "inherit";
      }
    },
    [theme]
  );
};

export const useBackgroundColorByStatus = () => {
  const theme = useTheme() as AppThemeProps;

  const themeMode = useGetThemeMode();

  return useCallback(
    (status: string) => {
      if (successStatuses.includes(status)) {
        return theme.palette.success[themeMode.mode];
      } else if (infoStatuses.includes(status)) {
        return theme.palette.info[themeMode.mode];
      } else if (secondaryStatuses.includes(status)) {
        return theme.palette.secondary.light;
      } else if (errorStatuses.includes(status)) {
        return theme.palette.error[themeMode.mode];
      } else if (warningStatuses.includes(status)) {
        return theme.palette.warning[themeMode.mode];
      } else if (disabledStatuses.includes(status)) {
        return theme.palette.action.disabled;
      } else {
        return "inherit";
      }
    },
    [theme, themeMode]
  );
};

export const useChipColorByStatus = () => {
  const theme = useTheme() as AppThemeProps;

  return useCallback(
    (status: string) => {
      if (successStatuses.includes(status)) {
        return theme.palette.success.main;
      } else if (infoStatuses.includes(status)) {
        return theme.palette.info.main;
      } else if (secondaryStatuses.includes(status)) {
        return theme.palette.secondary.contrastText;
      } else if (errorStatuses.includes(status)) {
        return theme.palette.error.main;
      } else if (warningStatuses.includes(status)) {
        return theme.palette.warning.main;
      } else if (disabledStatuses.includes(status)) {
        return theme.palette.action.disabled;
      } else {
        return "inherit";
      }
    },
    [theme]
  );
};
