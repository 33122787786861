/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * Programmatic interface to UbiOps
 * OpenAPI spec version: v2.1
 */

export type DeploymentVersionListRequestRetentionMode =
  typeof DeploymentVersionListRequestRetentionMode[keyof typeof DeploymentVersionListRequestRetentionMode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DeploymentVersionListRequestRetentionMode = {
  none: "none",
  metadata: "metadata",
  full: "full",
} as const;
