import { CircularProgress, useTheme } from "@mui/material";
import "./FullScreenLoader.scss";
import { rgba } from "polished";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

type FullScreenLoaderProps = { displayed: boolean };

export const FullScreenLoader = ({ displayed }: FullScreenLoaderProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <>
      {displayed && (
        <div
          className="full-screen-loader"
          style={{ background: rgba(theme.palette.neutrals[0], 0.5) }}
        >
          <div className="full-screen-loader__loader">
            <CircularProgress color="secondary" />
          </div>
        </div>
      )}
    </>
  );
};
