import { Grid, Typography } from "@mui/material";
import { useContext, useMemo } from "react";
import { useParams } from "react-router-dom";

import { CONTINUOUS_REQUEST_DELAY } from "libs/constants/constants";
import { RootUrlContext } from "libs/contexts";
import { useDeploymentVersionRequestsGet } from "libs/data/endpoints/deployment-requests/deployment-requests";
import { useDeploymentsGet } from "libs/data/endpoints/deployments/deployments";
import { useInterval } from "libs/hooks";
import {
  FULL_DATE_TIME_FORMAT,
  getTzAwareDate,
} from "libs/utilities/date-util";
import { formatStatusLabel } from "libs/utilities/statuses";
import { routes } from "routes";

import {
  Alert,
  Card,
  DetailsItem,
  Link,
  NoData,
  StatusIcon,
} from "components/atoms";
import { RequestResultsData } from "components/molecules";
import { getCreatedByFromOrigin, RequestLogs } from "components/organisms";

import type { InputOutputFieldDetail } from "libs/data/models";

export const RequestDetailsGeneral = () => {
  const rootUrl = useContext(RootUrlContext);

  const {
    organizationName,
    projectName,
    deploymentName,
    versionName,
    requestId,
  } =
    useParams<{
      organizationName: string;
      projectName: string;
      deploymentName: string;
      versionName: string;
      requestId: string;
    }>();
  const { data: requestDetails, mutate } = useDeploymentVersionRequestsGet(
    projectName,
    deploymentName,
    versionName,
    requestId
  );

  const { data: deploymentDetails } = useDeploymentsGet(
    projectName,
    deploymentName
  );

  const isRunning = useMemo(
    () =>
      requestDetails?.status === "processing" ||
      requestDetails?.status === "cancelled_pending",
    [requestDetails?.status]
  );

  const isPending = useMemo(
    () => requestDetails?.status === "pending",
    [requestDetails?.status]
  );

  useInterval(
    () => {
      if (isRunning || isPending) mutate();
    },
    [isRunning, isPending, mutate],
    CONTINUOUS_REQUEST_DELAY,
    false
  );

  const { createdBy, url: createdByUrl } = getCreatedByFromOrigin(
    // @ts-ignore
    {
      ...(requestDetails?.origin ?? {}),
      type: "deployment",
    },
    rootUrl
  );

  const baseUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .deployments[":deploymentName"](deploymentName);

  return (
    <>
      {requestDetails?.error_message && (
        <Alert severity="error">Error: {requestDetails?.error_message}</Alert>
      )}
      <Grid container spacing={2} height="100%">
        <Grid item xs={12} md={6}>
          <Card>
            <DetailsItem title="Deployment">
              <Link to={baseUrl.general.index()}>
                {requestDetails?.deployment}
              </Link>
            </DetailsItem>
            <DetailsItem title="Version">
              <Link
                to={baseUrl.versions[":versionName"](
                  versionName
                ).general.index()}
              >
                {requestDetails?.version}
              </Link>
            </DetailsItem>
            <DetailsItem title="Timeout">
              <Typography>{requestDetails?.timeout}</Typography>
            </DetailsItem>
            <DetailsItem title="Status">
              <StatusIcon
                label={requestDetails?.status}
                status={formatStatusLabel(requestDetails?.status)}
              />
            </DetailsItem>

            <DetailsItem title="Created">
              <Typography>
                {requestDetails?.time_created
                  ? getTzAwareDate(requestDetails?.time_created).format(
                      FULL_DATE_TIME_FORMAT
                    )
                  : "--"}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Started">
              <Typography>
                {requestDetails?.time_started
                  ? getTzAwareDate(requestDetails?.time_started).format(
                      FULL_DATE_TIME_FORMAT
                    )
                  : "--"}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Completed">
              <Typography>
                {requestDetails?.time_completed
                  ? getTzAwareDate(requestDetails?.time_completed).format(
                      FULL_DATE_TIME_FORMAT
                    )
                  : "--"}
              </Typography>
            </DetailsItem>

            <DetailsItem title="Created by">
              {createdByUrl ? (
                <Link to={createdByUrl}>{createdBy}</Link>
              ) : (
                createdBy
              )}
            </DetailsItem>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card title="Results">
            {requestDetails?.result ? (
              <RequestResultsData
                error={!!requestDetails?.error_message}
                data={requestDetails?.result}
                fields={
                  deploymentDetails?.output_fields as InputOutputFieldDetail[]
                }
                organizationName={organizationName}
                projectName={projectName}
              />
            ) : (
              <NoData />
            )}
          </Card>
        </Grid>
        <RequestLogs
          deploymentName={deploymentName}
          projectName={projectName}
          requestDetails={requestDetails}
          requestId={requestDetails?.id ?? ""}
          versionName={versionName}
        />
      </Grid>
    </>
  );
};
