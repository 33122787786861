import Plus from "@mui/icons-material/AddBoxRounded";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Trash from "@mui/icons-material/DeleteRounded";
import Edit from "@mui/icons-material/Edit";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Lock } from "react-feather";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import {
  projectEnvironmentVariablesCreate,
  projectEnvironmentVariablesDelete,
  projectEnvironmentVariablesUpdate,
  useProjectEnvironmentVariablesList,
} from "libs/data/endpoints/projects/projects";
import { useGoogleAnalytics } from "libs/hooks";
import { secretValues } from "libs/utilities/labels-mapping";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";
import validators from "libs/utilities/validators";

import {
  Icon,
  WarningText,
  DeleteDialog,
  PrimaryButton,
} from "components/atoms";
import { BaseTable } from "components/molecules";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { BaseColumn, BaseRow } from "components/molecules/BaseTable";
import type {
  InheritedEnvironmentVariableList,
  EnvironmentVariableCreate,
} from "libs/data/models";

export const ProjectEnvVars = () => {
  useGoogleAnalytics();
  const { projectName } = useParams<{ projectName: string }>();
  const theme = useTheme() as AppThemeProps;
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItemToDelete, setSelectedItemToDelete] =
    useState<InheritedEnvironmentVariableList | undefined>(undefined);
  const [editRowId, setEditRowId] = useState<string | undefined>(undefined);
  const [editedRow, setEditedRow] = useState<BaseRow | undefined>(undefined);
  const [createMode, setCreateMode] = useState(false);

  const [hasChanged, setHasChanged] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const dispatch = useDispatch();

  const {
    data: environmentVariables,
    mutate,
    isValidating,
  } = useProjectEnvironmentVariablesList(projectName);

  const onSave = () => {
    setIsLoading(false);
    setIsUpdating(true);
    setEditRowId(undefined);
    setEditedRow(undefined);
    setCreateMode(false);
  };

  const createEnvVar = useCallback(
    (data: EnvironmentVariableCreate) => {
      setIsLoading(true);
      projectEnvironmentVariablesCreate(projectName, data)
        .then(() => {
          dispatch(
            createSuccessNotification(
              "Environment variable was added to project."
            )
          );
          mutate();
        })
        .catch((e) => dispatch(createErrorNotification(e.message)))
        .finally(onSave);
    },
    [dispatch, mutate, projectName]
  );

  const updateEnvVar = useCallback(
    (id: string, data: EnvironmentVariableCreate) => {
      setIsLoading(true);
      projectEnvironmentVariablesUpdate(projectName, id, data)
        .then(() => {
          dispatch(
            createSuccessNotification("Environment variable was updated.")
          );
          mutate();
        })
        .catch((e) => dispatch(createErrorNotification(e.message)))
        .finally(onSave);
    },
    [dispatch, mutate, projectName]
  );

  const deleteEnvVar = (data: InheritedEnvironmentVariableList) => {
    if (data?.id) {
      setIsLoading(true);
      projectEnvironmentVariablesDelete(projectName, data?.id)
        .then(() => {
          dispatch(
            createSuccessNotification(
              "Environment variable was removed from project."
            )
          );
          mutate();
        })
        .catch((e) => dispatch(createErrorNotification(e.message)))
        .finally(onSave);
    }
  };

  const handleSave = useCallback(() => {
    const oldData = environmentVariables?.find((data) => data.id === editRowId);
    if (createMode) {
      createEnvVar(editedRow as unknown as EnvironmentVariableCreate);

      return;
    }
    if (!oldData || !editedRow) return;
    else {
      const formattedNewData = {
        name: editedRow.name,
        value: editedRow.value,
        secret: editedRow.secret,
      };
      updateEnvVar(oldData.id, formattedNewData);
    }
  }, [
    createEnvVar,
    createMode,
    editRowId,
    editedRow,
    environmentVariables,
    updateEnvVar,
  ]);

  const columns = useMemo(
    () => [
      {
        title: "Name",
        field: "name",
        width: "30%",
        nowrap: true,
        editable: true,
        validate: (rowData: InheritedEnvironmentVariableList) => {
          if (!validators.envVarName.pattern.test(rowData.name)) {
            return (
              <WarningText
                component="span"
                variant="h3"
                color={theme.palette.error.main}
                icon={ErrorOutline}
              >
                {validators.envVarName.message}
              </WarningText>
            );
          }

          return false;
        },
      },
      {
        title: "Value",
        field: "value",
        width: "30%",
        nowrap: true,
        editable: true,
        render: (rowData: InheritedEnvironmentVariableList) => (
          <>
            {rowData.secret ? (
              <Icon component={Lock} status="none" />
            ) : (
              <Typography className="env-vars-overview__value">
                {rowData.value}
              </Typography>
            )}
          </>
        ),
      },
      {
        title: "Secret",
        field: "secret",
        width: "10%",
        editable: true,
        lookup: secretValues,
        options: Object.entries(secretValues).map(([value, label]) => ({
          label,
          value,
        })),
        initialEditValue: "false",
        render: (rowData: InheritedEnvironmentVariableList) =>
          secretValues?.[rowData?.secret ? "true" : "false"],
      },
      {
        width: "30%",
        field: "",
        nowrap: true,
        render: (rowData: InheritedEnvironmentVariableList) => {
          return (
            <Grid
              className="actions_container"
              alignItems="flex-end"
              paddingLeft={editRowId === rowData?.id ? spacing[12] : undefined}
            >
              <Tooltip title={editRowId === rowData?.id ? "Save" : "Edit"}>
                <span>
                  {editRowId === rowData?.id ? (
                    <IconButton
                      color="inherit"
                      disabled={isLoading}
                      onClick={() => {
                        handleSave();
                      }}
                    >
                      <CheckIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      color="secondary"
                      disabled={isLoading}
                      onClick={() => {
                        setEditRowId(rowData?.id);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  )}
                </span>
              </Tooltip>
              <Tooltip
                style={{
                  paddingLeft:
                    editRowId === rowData?.id ? spacing[4] : undefined,
                }}
                title={editRowId === rowData?.id ? "Cancel" : "Delete"}
              >
                <span>
                  {editRowId === rowData?.id ? (
                    <IconButton
                      color="inherit"
                      disabled={isLoading}
                      onClick={() => {
                        setEditRowId(undefined);
                        setCreateMode(false);
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      color="primary"
                      disabled={isLoading}
                      onClick={(e) => {
                        e.preventDefault();

                        e.stopPropagation();
                        setSelectedItemToDelete(rowData);
                        setDeleteDialogOpen(true);
                      }}
                    >
                      <Trash />
                    </IconButton>
                  )}
                </span>
              </Tooltip>
            </Grid>
          );
        },
      },
    ],
    [editRowId, handleSave, theme.palette.error.main, isLoading]
  );

  // @ts-expect-error not all code paths return a value
  useEffect(() => {
    // Display a warning if either the row has been edited,
    // a new row has been created or a row has been deleted
    if (isUpdating) {
      setHasChanged(true);

      // Remove warning state after the 30 seconds have passed
      const timeoutId = setTimeout(() => {
        setHasChanged(false);
        setIsUpdating(false);
      }, 30000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isUpdating]);

  useEffect(() => {
    if (!editRowId) {
      setEditedRow(undefined);
    }
    if (editRowId && editRowId !== editedRow?.id) {
      const data = environmentVariables?.find(
        ({ id }: { id: string }) => id === editRowId
      );
      setEditedRow(data || { id: "new-row", secret: false });
    }
  }, [editRowId, editedRow?.id, environmentVariables]);

  const rows = createMode
    ? [{ id: "new-row", secret: false }, ...(environmentVariables || [])]
    : environmentVariables;

  return (
    <>
      {hasChanged && (
        <Box mb={spacing[16]}>
          <WarningText color={theme.palette.text.primary}>
            All deployment versions in your project will restart now and your
            changes will be available in ~30 seconds
          </WarningText>
        </Box>
      )}
      <BaseTable
        columns={columns as unknown as BaseColumn[]}
        data={rows}
        isLoading={isValidating}
        editRowId={editRowId}
        editedRow={editedRow}
        setEditedRow={setEditedRow}
        action={
          <Grid container spacing={2}>
            <Grid item>
              <PrimaryButton
                startIcon={<Plus />}
                onClick={() => {
                  setCreateMode(true);
                  setEditRowId("new-row");
                }}
              >
                Create variable
              </PrimaryButton>
            </Grid>
          </Grid>
        }
      />
      <DeleteDialog
        onDelete={() => {
          if (selectedItemToDelete) {
            deleteEnvVar(selectedItemToDelete);
          }
          setDeleteDialogOpen(false);
        }}
        onClose={() => setDeleteDialogOpen(false)}
        open={deleteDialogOpen}
      >
        This will delete the selected variable. Are you sure?
      </DeleteDialog>
    </>
  );
};
