import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import Cancel from "@mui/icons-material/Cancel";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";

import { spacing } from "assets/styles/theme";
import { AutoCompleteMultipleSelect } from "components/atoms/AutoCompleteSelect";

import { Card } from "components/atoms";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type { InstanceTypeCreate } from "libs/data/models";

interface InstanceGroupPriorityLevelsProps {
  priorityGroups: InstanceTypeCreate[][];
  instanceOptions: AutocompleteSelectOption[];
  availableInstanceOptions: AutocompleteSelectOption[];
  onInstanceTypesChange: (change: InstanceTypeCreate[]) => void;
}

const reorderDragList = (
  list: InstanceTypeCreate[][],
  startIndex: number,
  endIndex: number
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const InstanceGroupPriorityLevels = ({
  priorityGroups,
  instanceOptions,
  availableInstanceOptions,
  onInstanceTypesChange,
}: InstanceGroupPriorityLevelsProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <DragDropContext
      onDragEnd={(dragChange) => {
        const destination = dragChange.destination?.index;
        const source = dragChange.source?.index;
        if ((destination || destination === 0) && destination !== source) {
          const newList = reorderDragList(priorityGroups, source, destination);
          const newValues = newList.map((group, idx) =>
            group.map(({ id }) => ({
              id,
              priority: idx,
            }))
          );
          onInstanceTypesChange(newValues.flat());
        }
      }}
    >
      <Droppable droppableId="droppable">
        {(provided) => (
          <Box {...provided.droppableProps} ref={provided.innerRef}>
            {(priorityGroups || [[]])?.map((_instanceTypes, index) => (
              <Draggable
                key={`${index}`}
                draggableId={String(`${index}`)}
                index={index}
              >
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    sx={{ marginBottom: spacing[16] }}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Card
                      key={`${index}`}
                      sx={{
                        overflow: "visible",
                        background: theme.palette.draggable.background,
                        filter: "drop-shadow(0px 4px 3px rgba(0,0,0,0.25))",
                        border: "1px solid rgba(0, 0, 0, 0)",
                      }}
                      contentStyle={{
                        paddingBottom: spacing[8],
                        paddingTop: spacing[8],
                        paddingLeft: spacing[2],
                        marginBottom: `-${spacing[12]}`,
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          marginRight: spacing[16],
                          marginBottom: spacing[8],
                          minWidth: "22%",
                          display: "flex",
                        }}
                      >
                        <DragIndicatorIcon sx={{ marginRight: spacing[8] }} />
                        <Typography variant="h4">
                          priority {index + 1}
                        </Typography>
                      </Box>
                      <Box width="75%" paddingRight={spacing[4]}>
                        <AutoCompleteMultipleSelect
                          label="Instance types"
                          options={availableInstanceOptions || []}
                          value={
                            instanceOptions?.filter(({ value }) =>
                              priorityGroups[index].some(
                                (instance: InstanceTypeCreate) =>
                                  instance.id === value
                              )
                            ) || []
                          }
                          onChange={(change) => {
                            const newValues = priorityGroups.map((group, idx) =>
                              idx === index
                                ? change?.map(({ value }) => ({
                                    id: value,
                                    priority: idx,
                                  }))
                                : group.map(({ id }) => ({
                                    id,
                                    priority: idx,
                                  }))
                            );
                            onInstanceTypesChange(
                              newValues.flat() as InstanceTypeCreate[]
                            );
                          }}
                        />
                      </Box>
                      <Tooltip title="Delete">
                        <IconButton
                          aria-label="Delete"
                          onClick={() => {
                            const newValues = priorityGroups.map((group, idx) =>
                              idx === index
                                ? []
                                : group.map(({ id }) => ({
                                    id,
                                    priority: idx,
                                  }))
                            );
                            onInstanceTypesChange(newValues.flat());
                          }}
                          style={{
                            marginBottom: "10px",
                            position: "absolute",
                            right: "-3rem",
                          }}
                        >
                          <Cancel fontSize="medium" />
                        </IconButton>
                      </Tooltip>
                    </Card>
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};
