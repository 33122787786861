import WarningIcon from "@mui/icons-material/Warning";
import { Box, Typography, useTheme } from "@mui/material";

import { spacing } from "assets/styles/theme";

import type { Variant } from "@mui/material/styles/createTypography";
import type { AppThemeProps } from "assets/styles/theme/theme.d";

type WarningTextProps = {
  children: React.ReactNode;
  variant?: Variant;
  color?: string;
  iconColor?: string;
  component?: any; // couldn't figure out the type
  icon?: React.ElementType;
  textAlign?: string;
};

export const WarningText = ({
  children,
  variant = "body2",
  color,
  iconColor,
  component,
  textAlign = "flex-start",
  icon: Icon = WarningIcon,
}: WarningTextProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Box display="flex" justifyContent={textAlign}>
      <Typography
        variant={variant}
        style={{ color: color || theme.palette.warning.main }}
        component={component}
        className="warning__text"
        sx={{
          display: "flex",
          alignItems: "center",
          color: color || theme.palette.warning.main,
        }}
      >
        <Icon
          fontSize="small"
          className="warning__icon"
          style={{
            marginRight: spacing[8],
            color: iconColor || theme.palette.warning.main,
          }}
        />
        <Typography>{children}</Typography>
      </Typography>
    </Box>
  );
};
