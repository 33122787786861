import { Box, Grid, Typography } from "@mui/material";
import { useMemo } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useRouteMatch, useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { usePipelineVersionsGet } from "libs/data/endpoints/pipelines/pipelines";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { DATE_TIME_FORMAT, getTzAwareDate } from "libs/utilities/date-util";
import { explanations } from "libs/utilities/explanations";
import {
  requestRetentionModes,
  requestRetentionTimes,
} from "libs/utilities/labels-mapping";
import { formatLabels } from "libs/utilities/labels-util";

import {
  DetailsItem,
  Card,
  CardHeader,
  OverflowTooltip,
  CopyToClipboardButton,
} from "components/atoms";
import { EndpointUrlCopy, LabeledTitle } from "components/molecules";

import type { PipelineVersionRouteParams } from "../types";

export const PipelineVersionInfo = () => {
  const match = useRouteMatch();
  const { projectName, pipelineName, versionName } =
    useParams<PipelineVersionRouteParams>();

  const { data: pipelineVersion } = usePipelineVersionsGet(
    projectName,
    pipelineName,
    versionName,
    {
      swr: {
        revalidateOnFocus: true,
        revalidateOnMount: true,
      },
    }
  );
  const labels = useMemo(
    () =>
      pipelineVersion?.labels ? formatLabels(pipelineVersion?.labels) : [],
    [pipelineVersion?.labels]
  );

  const endpointUrl = `${env.get(
    ENV_NAMES.HOST
  )}/projects/${projectName}/pipelines/${pipelineName}/versions/${versionName}/requests`;

  return (
    <>
      <BreadcrumbsItem to={match.url}>Details</BreadcrumbsItem>

      {pipelineVersion && (
        <Card>
          <DetailsItem title="ID">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <OverflowTooltip title={pipelineVersion.id}>
                {pipelineVersion.id}
              </OverflowTooltip>
              <CopyToClipboardButton
                defaultLabel="Copy ID"
                contentToCopy={pipelineVersion.id}
                size="small"
              />
            </Box>
          </DetailsItem>
          <DetailsItem title="Created">
            <Typography>
              {getTzAwareDate(pipelineVersion.creation_date).format(
                DATE_TIME_FORMAT
              )}
            </Typography>
          </DetailsItem>
          <DetailsItem title="Edited">
            <Typography>
              {getTzAwareDate(pipelineVersion.last_updated).format(
                DATE_TIME_FORMAT
              )}
            </Typography>
          </DetailsItem>
          <EndpointUrlCopy url={endpointUrl} />
          <DetailsItem
            title="Request retention mode"
            tooltip={explanations.deployments.versions.retentionMode}
          >
            <Typography>
              {requestRetentionModes.find(
                ({ value }) => value === pipelineVersion.request_retention_mode
              )?.label || "-"}
            </Typography>
          </DetailsItem>

          <DetailsItem
            title="Request retention time"
            tooltip={explanations.deployments.versions.retentionTime}
          >
            <Typography>
              {pipelineVersion?.request_retention_mode === "none"
                ? "-"
                : requestRetentionTimes.find(
                    ({ value }) =>
                      value === pipelineVersion.request_retention_time
                  )?.label ||
                  `${pipelineVersion.request_retention_time} seconds`}
            </Typography>
          </DetailsItem>

          <Grid container style={{ paddingTop: spacing[24] }}>
            <Grid container spacing={3} item>
              <Grid item>
                <CardHeader
                  header={
                    <LabeledTitle
                      variant="h5"
                      title="Description"
                      labels={labels}
                    />
                  }
                />
              </Grid>
            </Grid>

            <Typography variant="body2" style={{ paddingTop: spacing[4] }}>
              {pipelineVersion.description
                ? pipelineVersion.description.replace(/\\n/g, "\n")
                : "No description available"}
            </Typography>
          </Grid>
        </Card>
      )}
    </>
  );
};
