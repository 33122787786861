import { Box, Button, useTheme } from "@mui/material";
import moment from "moment";
import { useState } from "react";

import { borderRadius, spacing } from "assets/styles/theme";

import { Dialog, PrimaryButton } from "components/atoms";

import type { DateRange } from "./types";
import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { Moment } from "moment";

type MonthPickerProps = {
  open: boolean;
  onClose: () => void;
  selectedMonth: DateRange | undefined | null;
  onChange: ({
    start_date,
    end_date,
  }: {
    start_date: Moment;
    end_date: Moment;
  }) => void;
};
export const MonthPicker = ({
  open,
  onClose,
  onChange,
  selectedMonth,
}: MonthPickerProps) => {
  const [selectedMonths, setSelectedMonths] = useState<{
    start_date: Moment | undefined;
    end_date: Moment | undefined;
  }>({
    start_date: selectedMonth?.start_date,
    end_date: selectedMonth?.end_date,
  });
  const [hoveredMonth, setHoveredMonth] =
    useState<Moment | undefined>(undefined);
  const theme = useTheme() as AppThemeProps;
  const months = [
    moment().utc().subtract(11, "M").startOf("M"),
    moment().utc().subtract(10, "M").startOf("M"),
    moment().utc().subtract(9, "M").startOf("M"),
    moment().utc().subtract(8, "M").startOf("M"),
    moment().utc().subtract(7, "M").startOf("M"),
    moment().utc().subtract(6, "M").startOf("M"),
    moment().utc().subtract(5, "M").startOf("M"),
    moment().utc().subtract(4, "M").startOf("M"),
    moment().utc().subtract(3, "M").startOf("M"),
    moment().utc().subtract(2, "M").startOf("M"),
    moment().utc().subtract(1, "M").startOf("M"),
    moment().utc().startOf("M"),
  ];

  const handleSelect = () => {
    onChange(selectedMonths as { start_date: Moment; end_date: Moment });
    onClose();
  };

  const handleChange = (month: Moment) => {
    if (selectedMonths?.start_date && selectedMonths?.end_date)
      setSelectedMonths({ start_date: month, end_date: undefined });
    if (selectedMonths?.start_date && !selectedMonths?.end_date) {
      if (month.diff(selectedMonths?.start_date)) {
        setSelectedMonths({ ...selectedMonths, end_date: month.add(1, "M") });
      } else
        setSelectedMonths({
          start_date: month,
          end_date: selectedMonths?.start_date.add(1, "M"),
        });
    }
  };

  const handleClose = () => {
    setSelectedMonths({
      start_date: selectedMonth?.start_date,
      end_date: selectedMonth?.end_date,
    });
    onClose();
  };

  const startAndEndAreSelected =
    selectedMonths?.start_date && selectedMonths?.end_date;

  const isBetweenSelectedDates = (month: Moment) => {
    return (
      startAndEndAreSelected &&
      month.isBetween(
        selectedMonths?.start_date,
        selectedMonths?.end_date,
        "M",
        "[)"
      )
    );
  };

  const isEndMonth = (month: Moment) => {
    return (
      startAndEndAreSelected &&
      month.get("M") === (selectedMonths?.end_date as Moment).get("M") - 1
    );
  };

  const isStartMonth = (month: Moment) => {
    return month.get("M") === selectedMonths?.start_date?.get("M");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      title="Select month(s)"
      fullWidth={false}
      dialogContentStyles={{ width: spacing[400] }}
      Actions={
        <>
          <PrimaryButton
            disabled={!selectedMonths?.start_date || !selectedMonths?.end_date}
            onClick={handleSelect}
          >
            Filter
          </PrimaryButton>
        </>
      }
    >
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          margin: "auto",
          rowGap: 10,
        }}
      >
        {months.map((month) => {
          const label = moment(month).format("MMM");

          return (
            <Button
              key={label}
              onClick={() => handleChange(month)}
              onMouseOver={() => setHoveredMonth(month)}
              disabled={
                !selectedMonths?.end_date &&
                month.isBefore(selectedMonths?.start_date)
              }
              sx={{
                height: spacing[32],
                width: spacing[80],
                minWidth: "25px",
                padding: 0,
                backgroundColor:
                  isBetweenSelectedDates(month) ||
                  month.isSame(selectedMonths?.start_date)
                    ? theme.palette.primary.main
                    : "inherit",
                color:
                  isBetweenSelectedDates(month) ||
                  month.isSame(selectedMonths?.start_date)
                    ? theme.palette.primary.contrastText
                    : theme.palette.primary.main,
                borderTopLeftRadius: isStartMonth(month) ? borderRadius[15] : 0,
                borderTopRightRadius: isEndMonth(month) ? borderRadius[15] : 0,
                borderBottomLeftRadius: isStartMonth(month)
                  ? borderRadius[15]
                  : 0,
                borderBottomRightRadius: isEndMonth(month)
                  ? borderRadius[15]
                  : 0,
                borderTop:
                  !selectedMonths?.end_date &&
                  month.isBetween(selectedMonths?.start_date, hoveredMonth)
                    ? "1px solid" + theme.palette.primary.main
                    : 0,
                borderBottom:
                  !selectedMonths?.end_date &&
                  month.isBetween(selectedMonths?.start_date, hoveredMonth)
                    ? "1px solid" + theme.palette.primary.main
                    : 0,
                "&:hover": {
                  border: "1px solid" + theme.palette.primary.main,
                  borderRadius: borderRadius[15],
                  borderLeft: !selectedMonths?.end_date
                    ? 0
                    : "1px solid" + theme.palette.primary.main,
                  borderTopLeftRadius: !selectedMonths?.end_date
                    ? 0
                    : borderRadius[15],
                  borderBottomLeftRadius: !selectedMonths?.end_date
                    ? 0
                    : borderRadius[15],
                  color: theme.palette.primary.main,
                },
              }}
            >
              {label}
            </Button>
          );
        })}
      </Box>
    </Dialog>
  );
};
