import { useState } from "react";
import { useParams } from "react-router-dom";

import { useDeploymentsList } from "libs/data/endpoints/deployments/deployments";
import { useEnvironmentsList } from "libs/data/endpoints/environments/environments";
import { usePipelinesList } from "libs/data/endpoints/pipelines/pipelines";

import { EntityType } from "./types";

import type { ImportDetail } from "libs/data/models";

export type Variable = {
  value: string | null;
};

export type EntityTypeNames = { [key: string]: string };

export const defaultNewNamesLayer = {
  [EntityType.deployments]: {} as EntityTypeNames,
  [EntityType.pipelines]: {} as EntityTypeNames,
  [EntityType.environments]: {} as EntityTypeNames,
};

export type NewNamesLayer = typeof defaultNewNamesLayer;

export const useImportConflicts = (imports: ImportDetail | null) => {
  const { projectName } = useParams<{ projectName: string }>();
  const { data: deployments } = useDeploymentsList(projectName);
  const { data: pipelines } = usePipelinesList(projectName);
  const { data: environments } = useEnvironmentsList(projectName);
  const [newNamesLayer, setNewNamesLayer] = useState({
    [EntityType.deployments]: {},
    [EntityType.pipelines]: {},
    [EntityType.environments]: {},
  });

  // const [newEnvVarsLayer, setNewEnvVarsLayer] = useState({});

  // this is the entire project of that organisation
  const project = {
    [EntityType.deployments]: deployments,
    [EntityType.pipelines]: pipelines,
    [EntityType.environments]: environments,
  };

  const isEntityNameConflict =
    (entityType: EntityType) => (entityName: string) => {
      const displayedName =
        (newNamesLayer[entityType] as EntityTypeNames)?.[entityName] ||
        entityName;
      // this gets the entities in the existing project:
      const entitiesInProject = project[entityType];
      const isSelected = imports?.[entityType]?.[entityName];

      const isNameConflict = entitiesInProject?.some(
        (entity) => entity.name === displayedName
      );

      return Boolean(isSelected && entitiesInProject && isNameConflict);
    };

  const isMissingEnvironmentVariables =
    (entityType: EntityType) => (entityName: string) => {
      const selectedEntity = imports?.[entityType]?.[entityName];
      const variables = Object.values(
        selectedEntity?.environment_variables || {}
      ) as Variable[];

      const versionVariables = Object.values(
        (selectedEntity?.versions || {}) as { [key: string]: any }
      ).flatMap(
        (ver) => Object.values(ver?.environment_variables || {}) as Variable[]
      );

      return [...variables, ...versionVariables].some((variable) =>
        Boolean(!variable.value)
      );
    };

  const missingVariables = (entityType: EntityType) => (entityName: string) => {
    const selectedEntity = imports?.[entityType]?.[entityName];
    const envVarNames: [string, Variable][] = Object.entries(
      selectedEntity?.environment_variables || {}
    );

    return envVarNames.filter((envVar) => !envVar[1].value);
  };

  const missingVersionVariables =
    (entityType: EntityType) => (entityName: string) => {
      const selectedEntity = imports?.[entityType]?.[entityName];

      const versionEnvVarNames: [string, Variable][] = Object.entries(
        (selectedEntity?.versions || {}) as { [key: string]: any }
      ).flatMap(
        ([ver, rest]) =>
          Object.entries(rest?.environment_variables || {}).map(
            ([name, variable]) => [ver + " " + name, variable]
          ) as [string, Variable][]
      );

      return versionEnvVarNames.filter((envVar) => !envVar[1].value);
    };

  const resolveNameConflict =
    (entityType: string) => (oldName: string, newName: string) => {
      setNewNamesLayer({
        ...newNamesLayer,
        [entityType]: {
          ...newNamesLayer[entityType as EntityType],
          [oldName]: newName,
        },
      });
    };

  const isAnyConflict = () => {
    const entityTypes = Object.keys(newNamesLayer);

    return entityTypes.some((type) => {
      const entities = imports?.[type as EntityType] || {};
      const entityNames = Object.keys(entities);

      return entityNames.some((name) =>
        isEntityNameConflict(type as EntityType)(name)
      );
    });
  };

  return {
    isEntityNameConflict,
    isMissingEnvironmentVariables,
    missingVersionVariables,
    newNamesLayer,
    resolveNameConflict,
    isAnyConflict,
    missingVariables,
  };
};
