import { Box, useTheme } from "@mui/material";

import { getBorders } from "assets/styles/theme";
import { DialogHeaderTitle } from "components/atoms/Dialog/DialogHeaderTitle";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { ReactNode } from "react";

interface DialogHeaderProps {
  header?: ReactNode;
  title?: string;
}

export const DialogHeader = ({ header, title }: DialogHeaderProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Box
      borderBottom={getBorders(theme).primary}
      paddingTop={2}
      paddingBottom={1}
    >
      {header ? header : <DialogHeaderTitle>{title}</DialogHeaderTitle>}
    </Box>
  );
};
