import { useTheme } from "@mui/material";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

export const GetStartedIllustration = () => {
  const theme = useTheme() as AppThemeProps;

  return (
    <svg
      width="156"
      height="106"
      viewBox="0 0 156 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_8289_51074)">
        <path
          d="M94.8119 59.4272C95.078 59.4272 95.3332 59.5328 95.5214 59.7207C95.7095 59.9086 95.8152 60.1634 95.8152 60.4291V70.1211H115.061C115.327 70.1211 115.582 70.2267 115.77 70.4146C115.958 70.6025 116.064 70.8573 116.064 71.123C116.064 71.3887 115.958 71.6436 115.77 71.8315C115.582 72.0193 115.327 72.1249 115.061 72.1249H95.8152V105.642H93.8086V60.4291C93.8086 60.1634 93.9143 59.9086 94.1025 59.7207C94.2906 59.5328 94.5458 59.4272 94.8119 59.4272Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M41.2543 105.415L37.6365 105.515C37.0869 105.543 36.5433 105.391 36.0885 105.081C35.6956 104.789 35.5154 104.395 35.5944 103.999L36.3063 100.426C36.4405 99.7521 37.2676 99.2426 38.2728 99.2148L40.2708 99.1597C41.276 99.132 42.13 99.5952 42.3013 100.261L43.2095 103.789C43.3103 104.18 43.1522 104.584 42.776 104.897C42.3391 105.231 41.8046 105.413 41.2543 105.415Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M63.2601 105.313L59.6422 105.412C59.0927 105.441 58.549 105.288 58.0942 104.979C57.7014 104.687 57.5211 104.293 57.6001 103.897L58.3121 100.324C58.4462 99.6499 59.2734 99.1404 60.2786 99.1127L62.2765 99.0576C63.2818 99.0299 64.1358 99.493 64.307 100.159L65.2153 103.687C65.3161 104.078 65.1579 104.482 64.7818 104.795C64.3448 105.129 63.8103 105.311 63.2601 105.313Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M28.6923 74.9822C28.527 74.911 28.391 74.7857 28.3068 74.6269C28.2225 74.4681 28.1951 74.2853 28.229 74.1088C28.263 73.9323 28.3562 73.7727 28.4934 73.6563C28.6305 73.5399 28.8033 73.4738 28.9833 73.4689L70.1137 72.3348C70.2174 72.3319 70.3206 72.3494 70.4175 72.3864C70.5144 72.4234 70.6031 72.479 70.6785 72.5502C70.7538 72.6214 70.8144 72.7067 70.8568 72.8012C70.8991 72.8958 70.9224 72.9978 70.9253 73.1013C70.9308 73.3104 70.8531 73.5132 70.7091 73.6651C70.5652 73.817 70.3667 73.9057 70.1574 73.9117L29.0269 75.0457C28.912 75.0492 28.7978 75.0275 28.6923 74.9822Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M43.8703 61.3858L32.1133 61.71L33.2094 101.347L44.9664 101.023L43.8703 61.3858Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M44.1702 60.6738L41.9102 66.8215L41.9122 94.8302L44.666 95.6188L44.1702 60.6738Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M37.9318 51.0271C35.7323 51.0894 34.0541 54.8905 34.0945 56.3738L34.1687 59.0591L42.1331 58.8395L42.0589 56.1542C42.0172 54.6709 40.1315 50.9681 37.9318 51.0271Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M32.267 78.125C31.856 78.1358 31.4575 77.9835 31.1589 77.7013C30.8603 77.4192 30.686 77.0303 30.6742 76.62L30.5533 72.2498C30.542 71.8393 30.6944 71.4411 30.977 71.1428C31.2597 70.8446 31.6494 70.6706 32.0605 70.6593C32.4716 70.6479 32.8704 70.8001 33.1691 71.0824C33.4678 71.3646 33.642 71.7538 33.6533 72.1643L33.7742 76.5345C33.7851 76.9448 33.6325 77.3428 33.3499 77.6409C33.0674 77.9391 32.6779 78.1132 32.267 78.125Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M35.251 61.8039L35.1953 58.9157L35.9538 58.897L36.0095 61.7758L36.697 103.736L35.9383 103.757"
          fill={theme.palette.primary.main}
        />
        <path
          d="M45.2192 68.5578L31.5852 68.9338C30.283 67.348 30.1647 60.5922 32.8824 59.3064L33.9482 57.4994L42.165 57.0988L43.1253 59.0015C44.4771 60.1502 45.1742 66.8638 45.2192 68.5578Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M40.0868 65.3178L36.639 65.4128C35.9736 65.4312 35.408 64.5693 35.3782 63.4917C35.3484 62.4142 35.8656 61.5224 36.5309 61.5041L39.9787 61.409C40.644 61.3906 41.2097 62.2526 41.2394 63.3301C41.2692 64.4076 40.7521 65.2994 40.0868 65.3178Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M33.411 84.8077L26.3039 89.8208L25.0624 101.124L33.3531 98.3577L33.411 84.8077Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M66.5656 60.9004L54.8086 61.2246L55.9047 100.862L67.6617 100.538L66.5656 60.9004Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M55.0918 60.8062L57.5109 66.8932L58.2368 94.8926L55.5043 95.7522L55.0918 60.8062Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M72.4453 49.8954C73.2964 49.8954 73.9863 48.9716 73.9863 47.832C73.9863 46.6924 73.2964 45.7686 72.4453 45.7686C71.5942 45.7686 70.9043 46.6924 70.9043 47.832C70.9043 48.9716 71.5942 49.8954 72.4453 49.8954Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M60.3967 50.5481C58.1971 50.6104 56.519 54.4115 56.5593 55.8948L56.6336 58.5801L64.598 58.3605L64.5237 55.6752C64.482 54.1919 62.5963 50.4891 60.3967 50.5481Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M62.6748 62.7079L62.6191 59.8197L63.3777 59.8008L63.4333 62.6798L64.1208 104.64L63.3621 104.661"
          fill={theme.palette.primary.main}
        />
        <path
          d="M67.685 68.0787L54.051 68.4546C53.8133 67.0198 54.1208 60.3243 55.3482 58.8273L56.414 57.0202L64.6308 56.6197L65.5911 58.5224C68.0171 58.7198 68.8656 65.2993 67.685 68.0787Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M62.5701 65.4416L59.1223 65.5367C58.457 65.555 57.8876 64.5578 57.8532 63.314C57.8188 62.0703 58.3322 61.0432 58.9975 61.0248L62.4453 60.9298C63.1107 60.9114 63.68 61.9086 63.7144 63.1524C63.7488 64.3962 63.2354 65.4233 62.5701 65.4416Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M67.1473 81.9103L74.0178 87.2418L74.7413 98.5898L66.5859 95.4486L67.1473 81.9103Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M65.228 29.3498C57.8558 29.3498 49.3833 21.1806 41.8872 25.062C19.1911 36.8138 22.3153 17.149 15.7949 9.83548L16.009 9.40674C20.9453 28.2089 25.4116 58.1695 41.9858 24.5633C46.1376 16.145 59.7638 29.1574 67.7846 28.7735L67.7985 29.2888C66.9515 29.3296 66.0946 29.35 65.228 29.3498Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M82.9228 30.5056C77.0459 34.9503 65.3526 33.5462 61.7238 41.1596C50.7368 64.2111 41.3373 46.6514 31.7175 44.7525L31.6289 44.2816C41.259 45.0792 51.5886 43.8417 61.5008 40.7026C70.4586 37.8662 78.4505 33.6466 84.6123 28.5049L84.935 28.9073C84.2845 29.4505 83.6137 29.9833 82.9228 30.5056Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M18.9961 29.4937C19.8471 29.4937 20.5371 28.5699 20.5371 27.4303C20.5371 26.2907 19.8471 25.3669 18.9961 25.3669C18.145 25.3669 17.4551 26.2907 17.4551 27.4303C17.4551 28.5699 18.145 29.4937 18.9961 29.4937Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M66.7909 12.735C67.6419 12.735 68.3319 11.8112 68.3319 10.6716C68.3319 9.53197 67.6419 8.60815 66.7909 8.60815C65.9398 8.60815 65.2499 9.53197 65.2499 10.6716C65.2499 11.8112 65.9398 12.735 66.7909 12.735Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M33.1044 37.8217C32.3999 38.2904 31.6788 37.1748 32.3969 36.7269C33.1014 36.2582 33.8224 37.3738 33.1044 37.8217Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M59.1902 1.20757C58.4857 1.6763 57.7646 0.560678 58.4827 0.112758C59.1872 -0.355948 59.9082 0.759678 59.1902 1.20757Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M74.5656 22.916L74.1174 22.8178L74.2155 22.3701L73.9167 22.3047L73.8184 22.7524L73.3702 22.6544L73.3047 22.9528L73.7529 23.051L73.6548 23.4986L73.9536 23.564L74.0519 23.1162L74.5001 23.2144L74.5656 22.916Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M33.4816 7.91274C34.5599 7.91274 35.434 7.0399 35.434 5.96321C35.434 4.88651 34.5599 4.01367 33.4816 4.01367C32.4034 4.01367 31.5293 4.88651 31.5293 5.96321C31.5293 7.0399 32.4034 7.91274 33.4816 7.91274Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M47.7619 3.49581C45.5624 3.55819 43.8842 7.35927 43.9245 8.84254L43.9988 11.5279L51.9632 11.3083L51.8889 8.62294C51.8472 7.13968 49.9616 3.43689 47.7619 3.49581Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M52.5043 105.181L48.8865 105.28C48.337 105.309 47.7933 105.156 47.3385 104.847C46.9456 104.555 46.7654 104.161 46.8444 103.764L47.5563 100.192C47.6905 99.5178 48.5176 99.0083 49.5228 98.9806L51.5208 98.9255C52.526 98.8978 53.3801 99.3609 53.5513 100.027L54.4595 103.554C54.5603 103.946 54.4022 104.35 54.026 104.663C53.5891 104.997 53.0546 105.178 52.5043 105.181Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M54.1164 20.5909L42.3594 20.915L44.5751 101.038L56.3321 100.713L54.1164 20.5909Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M49.4131 59.3747L49.3574 56.4865L50.1159 56.4678L50.1716 59.3466L50.8591 101.306L50.1004 101.327"
          fill={theme.palette.primary.main}
        />
        <path
          d="M56.431 27.9234L40.5008 28.3626C40.223 26.6862 40.5823 18.863 42.0164 17.1139L42.4094 15.026L53.4089 14.7227L53.9844 16.7576C55.5639 18.0997 56.3784 25.9441 56.431 27.9234Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M53.7565 14.9693L42.4056 15.2823C41.9867 15.2939 41.6407 15.0742 41.6329 14.7917C41.6319 14.751 41.6378 14.7104 41.6506 14.6718L43.4137 9.24792C43.4892 9.01566 43.7897 8.84509 44.1405 8.83542L51.668 8.62787C52.0188 8.6182 52.3283 8.77195 52.4165 8.99969L54.4767 14.3181C54.5821 14.5901 54.3387 14.8732 53.9331 14.9504C53.8749 14.9614 53.8158 14.9677 53.7565 14.9693Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M43.5258 59.0066C43.4233 58.9628 43.3265 58.9068 43.2374 58.8399C43.1097 58.7505 43.0042 58.633 42.929 58.4966C42.8538 58.3601 42.8108 58.2083 42.8034 58.0527L42.391 43.1379C42.374 42.5205 42.9794 42.0011 43.7405 41.9801L53.902 41.6999C54.6633 41.6787 55.2962 42.1642 55.3134 42.7816L55.7258 57.6965C55.7271 57.8522 55.6926 58.0062 55.625 58.1465C55.5574 58.2869 55.4586 58.41 55.336 58.5063C55.0646 58.7271 54.7263 58.8497 54.3762 58.8542L44.2149 59.1343C43.9786 59.1422 43.7435 59.0987 43.5258 59.0066Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M50.0308 14.0095L46.583 14.1046C45.9177 14.123 45.3551 13.3724 45.3291 12.4316C45.3031 11.4909 45.8233 10.7104 46.4886 10.6921L49.9364 10.597C50.6017 10.5787 51.1643 11.3293 51.1903 12.27C51.2163 13.2107 50.6961 13.9912 50.0308 14.0095Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M131.529 102.47H127.515V73.3469C130.872 73.1066 133.395 72.0801 133.395 70.85C133.395 69.4416 130.088 68.2998 126.007 68.2998C121.927 68.2998 118.619 69.4416 118.619 70.85C118.619 72.0919 121.191 73.126 124.597 73.3533V102.47H120.583C120.196 102.47 119.825 102.624 119.551 102.897C119.278 103.17 119.124 103.541 119.124 103.927C119.124 104.314 119.278 104.685 119.551 104.958C119.825 105.231 120.196 105.385 120.583 105.385H131.529C131.916 105.385 132.287 105.231 132.561 104.958C132.834 104.685 132.988 104.314 132.988 103.927C132.988 103.541 132.834 103.17 132.561 102.897C132.287 102.624 131.916 102.47 131.529 102.47Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M123.473 87.6849L122.155 89.1194L115.986 84.7247L117.932 82.6074L123.473 87.6849Z"
          fill={theme.palette.svgs.brownSkin}
        />
        <path
          d="M120.102 91.471C120.115 91.7918 120.192 92.1068 120.328 92.3978C120.463 92.6889 120.656 92.9501 120.893 93.1664L120.951 93.2203L122.68 91.3389L125.201 88.5949L123.34 86.8887L123.004 87.2552L122.969 87.293L121.294 88.0841L120.939 88.2526L120.878 88.2809L120.745 89.7135V89.7149C120.526 89.9504 120.357 90.227 120.247 90.5285C120.136 90.8299 120.087 91.1503 120.102 91.471Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M130.303 101.996L128.437 102.561L125.363 95.6453L128.117 94.8101L130.303 101.996Z"
          fill={theme.palette.svgs.brownSkin}
        />
        <path
          d="M125.289 105.405L125.312 105.482L131.327 103.657L130.593 101.244L130.118 101.388L130.068 101.403L127.832 101.198L127.766 101.192L126.919 102.358C126.299 102.546 125.778 102.973 125.472 103.545C125.167 104.116 125.101 104.785 125.289 105.405Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M114.918 65.7832C114.918 65.7832 103.007 77.2669 110.537 80.8215H112.864C113.37 79.1795 114.147 77.6336 115.165 76.2479C115.384 75.9606 116.208 76.019 116.437 75.7389C117.834 74.029 119.348 72.5605 120.394 72.6189L120.144 80.5534L119.739 83.3836C119.526 84.8467 125.527 97.6215 125.527 97.6215L129.298 96.1414C129.298 96.1414 125.369 85.6195 125.323 84.1026L128.472 67.4237L114.918 65.7832Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M113.924 66.3495C113.985 66.436 114.064 66.5078 114.156 66.5597C114.308 66.6454 114.473 66.7064 114.644 66.7403C117.325 67.3482 126.086 68.6083 128.36 68.0072C128.64 67.9344 128.822 67.832 128.881 67.6972C129.072 67.2713 129.047 65.4721 128.935 63.2996C128.915 62.8925 128.466 62.4518 128.439 62.0259C128.42 61.7065 128.823 61.406 128.802 61.0812C128.653 58.8844 128.462 56.6458 128.334 55.2307C128.253 54.3209 128.197 53.7522 128.197 53.7522L129.581 45.0418L129.516 44.9353L129.443 44.8989L124.982 42.694L124.739 42.0741C124.669 41.8956 124.547 41.7422 124.388 41.6339C124.229 41.5255 124.042 41.4671 123.85 41.4662L120.912 41.4541C120.759 41.4533 120.609 41.4888 120.473 41.5577C120.337 41.6266 120.219 41.727 120.13 41.8503L119.334 42.9514L114.07 46.3208L114.09 46.3828L116.56 53.8883L116.572 54.4732V54.4746L116.664 59.1782L116.409 59.8157L116.697 60.8615C116.697 60.8615 116.564 62.0839 115.856 62.9923C115.213 63.8171 114.395 64.6769 114.026 65.3751V65.3764C113.82 65.7619 113.752 66.0961 113.924 66.3495Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M109.965 80.1389L110.601 80.9423C110.601 80.9423 116.457 85.0329 116.658 85.4151L117.583 86.2895L119.822 83.8995L118.658 82.4422C117.726 81.2759 116.522 80.3555 115.152 79.7616L113.367 78.1807L109.965 80.1389Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M131.057 69.0623L129.99 58.7562L129.709 54.5145L132.693 53.9756C132.693 53.9756 132.575 57.0085 132.966 58.7197C133.352 60.4083 133.017 68.8361 132.994 69.0614C133.213 69.2571 133.367 69.5147 133.437 69.8C133.506 70.0853 133.487 70.3849 133.382 70.6592C133.277 70.9335 133.092 71.1696 132.85 71.3362C132.607 71.5028 132.32 71.5921 132.026 71.5922C131.732 71.5923 131.445 71.5033 131.203 71.337C130.961 71.1706 130.775 70.9347 130.67 70.6605C130.564 70.3863 130.545 70.0867 130.614 69.8013C130.683 69.5159 130.838 69.2583 131.057 69.0623Z"
          fill={theme.palette.svgs.brownSkin}
        />
        <path
          d="M127.504 53.4544L128.335 55.2307L128.625 55.8493L129.407 59.9356L129.797 60.3494L130.133 61.685L130.291 61.6526L133.139 61.0637L133.969 60.8925C134.156 60.5812 134.23 60.215 134.178 59.8557C134.125 59.4965 133.951 59.1662 133.683 58.9208C133.626 58.8696 133.579 58.8197 133.58 58.7725C133.627 55.9301 133.453 50.74 131.881 48.6038C131.382 47.9259 131.082 47.2507 130.646 46.779C130.645 46.7777 130.645 46.7763 130.643 46.7763L129.582 45.0418L129.518 44.9353L129.445 44.8989L127.504 53.4544Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M122.611 40.7241C124.77 40.7241 126.52 38.9763 126.52 36.8203C126.52 34.6643 124.77 32.9165 122.611 32.9165C120.451 32.9165 118.701 34.6643 118.701 36.8203C118.701 38.9763 120.451 40.7241 122.611 40.7241Z"
          fill={theme.palette.svgs.brownSkin}
        />
        <path
          d="M127.254 35.9801C127.368 35.3241 127.458 34.4737 127.13 33.7176C126.852 33.0814 126.159 32.4848 125.388 32.5653C125.425 32.4217 125.421 32.2708 125.377 32.1293C125.332 31.9878 125.25 31.8614 125.138 31.764C124.887 31.5673 124.595 31.43 124.283 31.3625C123.558 31.1499 122.808 30.9294 122.033 30.9253C121.164 30.9206 120.393 31.2181 119.921 31.7426L119.909 31.7595L119.768 32.0972C119.749 32.1425 119.72 32.1831 119.684 32.2164C119.648 32.2496 119.605 32.2747 119.558 32.2898C119.511 32.3052 119.461 32.3101 119.412 32.3041C119.363 32.2981 119.316 32.2814 119.275 32.2551C119.229 32.227 119.176 32.2113 119.123 32.2095C119.069 32.2078 119.016 32.2201 118.968 32.2452C118.92 32.2703 118.88 32.3074 118.851 32.3528C118.823 32.3982 118.806 32.4502 118.804 32.5039L118.796 32.648L118.593 32.5672C118.543 32.5467 118.487 32.5401 118.433 32.5481C118.378 32.5562 118.327 32.5785 118.285 32.6129C118.242 32.6472 118.209 32.6924 118.19 32.7436C118.17 32.7948 118.165 32.8503 118.174 32.9043L118.201 33.0757C118.157 33.0678 118.113 33.0688 118.069 33.0785C118.026 33.0883 117.985 33.1066 117.949 33.1324C117.898 33.1702 117.86 33.2234 117.84 33.2843C117.821 33.3452 117.821 33.4107 117.842 33.4712C117.999 33.9196 118.268 34.3208 118.623 34.637C118.783 34.7808 118.961 34.902 119.154 34.9972C119.194 35.0173 119.236 35.0364 119.279 35.0545C120.837 35.7215 122.504 36.0986 124.197 36.1671C124.037 36.4722 123.934 36.8037 123.892 37.1456C123.869 37.3397 123.899 37.5363 123.98 37.7143C124.061 37.8922 124.189 38.0447 124.351 38.1551C124.421 38.1951 124.494 38.2287 124.57 38.2556C124.643 38.2823 124.713 38.3149 124.781 38.3529C124.88 38.4127 124.961 38.4974 125.017 38.5986C125.072 38.6999 125.1 38.8139 125.097 38.9293C125.095 39.0448 125.061 39.1574 125.001 39.2559C124.941 39.3543 124.856 39.4351 124.754 39.4901C124.754 39.4901 125.045 39.8448 125.091 39.8388C125.277 39.7968 125.444 39.6979 125.57 39.5559C126.417 38.735 126.983 37.5326 127.254 35.9801Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M144.37 96.325L144.539 92.51C146.311 91.5645 148.286 91.0652 150.295 91.0552C147.531 93.3119 147.876 97.662 146.002 100.697C145.41 101.639 144.614 102.436 143.672 103.029C142.729 103.621 141.666 103.995 140.559 104.122L138.246 105.536C137.929 103.769 137.999 101.954 138.451 100.217C138.904 98.4795 139.728 96.8608 140.868 95.4722C141.514 94.6996 142.256 94.0113 143.075 93.4237C143.629 94.8824 144.37 96.325 144.37 96.325Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M156 105.717C156 105.754 155.993 105.791 155.979 105.825C155.964 105.86 155.944 105.891 155.917 105.917C155.891 105.944 155.86 105.965 155.825 105.979C155.791 105.993 155.754 106 155.717 106H0.283343C0.208196 106 0.136126 105.97 0.0829888 105.917C0.0298517 105.864 0 105.792 0 105.717C0 105.642 0.0298517 105.57 0.0829888 105.517C0.136126 105.464 0.208196 105.434 0.283343 105.434H155.717C155.754 105.434 155.791 105.441 155.825 105.455C155.86 105.47 155.891 105.49 155.917 105.517C155.944 105.543 155.964 105.574 155.979 105.609C155.993 105.643 156 105.68 156 105.717Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M66.8568 77.1567C66.4458 77.1676 66.0473 77.0152 65.7487 76.7331C65.4501 76.4509 65.2758 76.062 65.264 75.6517L65.1431 71.2815C65.1323 70.8713 65.285 70.4737 65.5676 70.1758C65.8502 69.878 66.2396 69.7043 66.6504 69.693C67.0611 69.6816 67.4596 69.8336 67.7582 70.1154C68.0569 70.3972 68.2313 70.7859 68.2431 71.196L68.364 75.5662C68.3748 75.9766 68.2223 76.3745 67.9397 76.6726C67.6572 76.9708 67.2677 77.1449 66.8568 77.1567Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M135.241 93.1287C132.885 93.1287 130.304 93.0399 127.496 92.8621C112.972 91.9411 94.1603 88.7393 74.5246 83.8466C54.8891 78.9539 36.7759 72.9545 23.5219 66.9541C17.0655 64.031 12.1102 61.2641 8.79393 58.7301C5.28282 56.0474 3.74702 53.709 4.229 51.7802C5.16811 48.0224 13.4835 47.2003 20.2939 47.1724L20.2962 47.7188C10.9437 47.7572 5.42624 49.2466 4.76006 51.9125C3.91145 55.308 10.8323 60.609 23.7479 66.4564C36.9727 72.4436 55.0526 78.4314 74.6572 83.3165C94.2616 88.2014 113.039 91.3977 127.531 92.3167C141.683 93.2143 150.287 91.7816 151.135 88.386C151.84 85.5668 147.195 81.4138 138.057 76.6917L138.309 76.2064C144.948 79.6375 152.656 84.5587 151.666 88.5184C151.184 90.4472 148.728 91.7908 144.366 92.5118C141.88 92.9227 138.826 93.1287 135.241 93.1287Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M10.2263 89.5488C8.3572 89.5488 7.14206 89.0851 6.60423 88.1631C5.54362 86.345 7.31994 83.1549 11.8837 78.6815L12.2671 79.0715C8.0278 83.227 6.1846 86.358 7.07703 87.8881C7.64176 88.8558 9.3224 89.2027 11.9383 88.8907C14.5352 88.5811 17.9766 87.6584 22.1671 86.1483C30.794 83.0393 41.6175 77.7951 52.6435 71.3816C63.6692 64.9683 73.5766 58.1539 80.5408 52.1938C81.8784 51.0488 83.0924 49.9473 84.149 48.9202C85.536 47.6047 86.8155 46.1807 87.9755 44.6618C89.5375 42.5438 90.0648 40.9127 89.5001 39.9448C88.557 38.3282 84.5952 38.4802 78.3438 40.3734L78.1851 39.8504C84.8921 37.8196 88.8582 37.7586 89.973 39.6698C90.6657 40.8572 90.1419 42.6458 88.4162 44.9859C87.2385 46.5289 85.9392 47.9754 84.5308 49.3117C83.466 50.3469 82.2434 51.4562 80.897 52.6087C73.9084 58.5897 63.9723 65.4244 52.9189 71.8537C41.8653 78.2834 31.01 83.5426 22.3529 86.6624C18.1234 88.1866 14.6413 89.1189 12.0032 89.4332C11.4137 89.5067 10.8204 89.5453 10.2263 89.5488Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M110.11 67.1116C108.758 67.0847 101.493 66.8765 100.301 66.9277C100.234 66.6921 97.9754 69.5823 97.9205 69.6002C97.8839 69.6388 97.8572 69.6856 97.8426 69.7367C97.8281 69.7879 97.8261 69.8417 97.837 69.8938C97.8478 69.9458 97.8711 69.9945 97.9049 70.0356C97.9386 70.0767 97.9818 70.109 98.0308 70.1298C98.1636 70.2104 109.876 69.994 110.018 70.0222C110.079 70.0213 110.139 70.003 110.19 69.9695C110.242 69.9361 110.283 69.8888 110.308 69.8331L110.387 69.658C110.395 69.3585 110.493 67.8277 110.405 67.5659C110.427 67.5168 110.436 67.4631 110.431 67.4096C110.427 67.3561 110.409 67.3045 110.38 67.2594C110.351 67.2144 110.311 67.1773 110.264 67.1515C110.217 67.1257 110.164 67.112 110.11 67.1116Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          opacity="0.17"
          d="M110.388 69.6577C110.319 69.8227 110.231 70.0299 110.019 70.0223L98.1556 70.1552C98.0995 70.1546 98.0445 70.1396 97.9959 70.1116C97.9473 70.0836 97.9067 70.0436 97.878 69.9955C97.8493 69.9473 97.8335 69.8926 97.8321 69.8366C97.8307 69.7805 97.8438 69.7251 97.8701 69.6756L110.388 69.6577Z"
          fill={theme.palette.svgs.black}
        />
        <path
          d="M100.357 66.8924C100.23 66.6719 97.0582 51.0011 96.7934 50.8701L94.628 48.2249L94.4345 48.0796C94.1654 47.932 93.5198 48.0318 93.5779 48.4423C94.0866 50.8777 97.3587 64.7489 97.4304 66.9115L97.8243 69.6665C97.8313 69.7134 97.8481 69.7583 97.8736 69.7983C97.899 69.8383 97.9326 69.8726 97.9721 69.8988C98.0117 69.9251 98.0563 69.9428 98.1031 69.9508C98.1498 69.9588 98.1978 69.9569 98.2438 69.9452C98.3567 69.9331 98.4645 69.8917 98.5565 69.8253C98.6486 69.7589 98.7217 69.6697 98.7687 69.5665C98.7982 69.4693 100.506 66.9489 100.357 66.8924Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          opacity="0.17"
          d="M94.6271 48.225L98.7688 69.567C98.6302 69.917 97.9821 70.1623 97.8312 69.7031C97.8286 69.7025 93.5824 48.4553 93.578 48.4426C93.5328 47.9313 94.3789 47.9151 94.6271 48.225Z"
          fill={theme.palette.svgs.black}
        />
        <path
          d="M94.752 49.0977L96.8553 52.0254L99.2162 63.2218L98.1187 64.1778L94.752 49.0977Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M108.752 68.6214L109.334 68.6451C109.343 68.4749 109.371 68.3062 109.418 68.1423C109.505 67.727 109.584 67.2333 109.221 67.2352C108.974 67.2115 108.891 67.523 108.847 67.8618C108.836 68.1171 108.804 68.3711 108.752 68.6214Z"
          fill={theme.palette.svgs.royalBlue}
        />
        <path
          d="M105.414 67.8401L105.806 67.8547L105.996 67.8619C105.992 67.6543 106.089 67.2262 105.854 67.1206C105.816 67.1045 105.776 67.0964 105.735 67.0969C105.514 67.0841 105.449 67.2517 105.429 67.4448C105.416 67.5614 105.421 67.6853 105.418 67.7818C105.418 67.8018 105.416 67.8219 105.414 67.8401Z"
          fill={theme.palette.svgs.royalBlue}
        />
        <path
          d="M104.252 67.7927L104.646 67.8091L104.834 67.8164C104.83 67.5777 104.956 67.0568 104.573 67.0495C104.219 67.0294 104.265 67.4794 104.256 67.7344C104.256 67.7544 104.254 67.7745 104.252 67.7927Z"
          fill={theme.palette.svgs.royalBlue}
        />
        <path
          d="M103.09 67.7469L103.482 67.7615L103.672 67.7688C103.668 67.532 103.794 67.011 103.411 67.0037C103.057 66.9818 103.103 67.4318 103.093 67.6886C103.093 67.7087 103.092 67.7287 103.09 67.7469Z"
          fill={theme.palette.svgs.royalBlue}
        />
        <path
          d="M101.928 67.6999L102.322 67.7163L102.51 67.7236C102.506 67.485 102.632 66.9658 102.249 66.9567C101.895 66.9367 101.94 67.3866 101.931 67.6416C101.931 67.6617 101.93 67.6817 101.928 67.6999Z"
          fill={theme.palette.svgs.royalBlue}
        />
        <path
          d="M100.766 67.654L101.158 67.6685L101.348 67.6758C101.344 67.4572 101.45 67 101.169 66.9217H101.167C101.141 66.914 101.114 66.9103 101.087 66.9107C101.054 66.9078 101.02 66.9109 100.988 66.9199H100.986C100.742 66.9836 100.777 67.3661 100.769 67.5957C100.769 67.6157 100.767 67.6358 100.766 67.654Z"
          fill={theme.palette.svgs.royalBlue}
        />
        <path
          d="M107.58 67.5303L108.162 67.554C108.162 67.5322 108.162 67.5085 108.164 67.4812C108.178 67.3426 108.181 67.203 108.171 67.064C108.151 66.911 108.085 66.7908 107.901 66.7871C107.713 66.7762 107.638 66.8964 107.609 67.0513C107.602 67.0776 107.597 67.1044 107.595 67.1314V67.1332C107.58 67.2753 107.589 67.4283 107.58 67.5303Z"
          fill={theme.palette.svgs.royalBlue}
        />
        <path
          d="M106.418 67.483L107 67.5067C107.012 67.3503 107.016 67.1934 107.011 67.0367C106.994 66.8764 106.93 66.7452 106.739 66.7416C106.546 66.7288 106.471 66.86 106.442 67.0221C106.414 67.1788 106.429 67.3664 106.418 67.483Z"
          fill={theme.palette.svgs.royalBlue}
        />
        <path
          d="M105.256 67.4372L105.429 67.4445L105.838 67.4609C105.836 67.3734 105.852 67.2459 105.854 67.1202C105.856 67.0825 105.855 67.0448 105.851 67.0073C105.836 66.8379 105.776 66.6976 105.577 66.694C105.374 66.683 105.303 66.8233 105.278 66.9945C105.252 67.1475 105.267 67.3242 105.256 67.4372Z"
          fill={theme.palette.svgs.royalBlue}
        />
        <path
          d="M107.592 68.576L108.174 68.5979C108.172 68.3611 108.296 67.8401 107.913 67.8328C107.53 67.8091 107.614 68.3374 107.592 68.576Z"
          fill={theme.palette.svgs.royalBlue}
        />
        <path
          d="M106.43 68.5285L107.012 68.5522C107.01 68.3136 107.134 67.7926 106.751 67.7853C106.368 67.7635 106.452 68.2917 106.43 68.5285Z"
          fill={theme.palette.svgs.royalBlue}
        />
        <path
          d="M105.268 68.4829L105.85 68.5048C105.848 68.3354 105.912 68.022 105.808 67.8545C105.785 67.8174 105.753 67.7871 105.714 67.7669C105.675 67.7467 105.632 67.7373 105.589 67.7397C105.529 67.7334 105.469 67.7482 105.419 67.7816C105.233 67.9037 105.286 68.2898 105.268 68.4829Z"
          fill={theme.palette.svgs.royalBlue}
        />
        <path
          d="M104.105 68.4356L104.687 68.4593C104.686 68.2899 104.748 67.9784 104.647 67.809C104.625 67.7713 104.592 67.7406 104.553 67.72C104.514 67.6995 104.47 67.6899 104.427 67.6924C104.367 67.6859 104.307 67.7007 104.257 67.7343C104.071 67.86 104.124 68.2444 104.105 68.4356Z"
          fill={theme.palette.svgs.royalBlue}
        />
        <path
          d="M102.943 68.3901L103.525 68.412C103.523 68.2426 103.587 67.9293 103.483 67.7617C103.461 67.7246 103.428 67.6943 103.39 67.6741C103.351 67.6539 103.308 67.6445 103.264 67.6469C103.205 67.6406 103.145 67.6555 103.095 67.6888C102.909 67.8109 102.962 68.197 102.943 68.3901Z"
          fill={theme.palette.svgs.royalBlue}
        />
        <path
          d="M101.781 68.3429L102.363 68.3665C102.361 68.1971 102.423 67.8857 102.323 67.7162C102.301 67.6784 102.268 67.6475 102.229 67.6269C102.19 67.6064 102.146 67.5969 102.102 67.5997C102.043 67.5931 101.982 67.608 101.933 67.6416C101.747 67.7672 101.799 68.1516 101.781 68.3429Z"
          fill={theme.palette.svgs.royalBlue}
        />
        <path
          d="M100.619 68.2974L101.201 68.3192C101.199 68.1498 101.261 67.8383 101.159 67.6689C101.137 67.6317 101.104 67.6013 101.066 67.581C101.027 67.5608 100.984 67.5515 100.94 67.5542C100.88 67.5478 100.82 67.5627 100.771 67.5961C100.584 67.7199 100.637 68.1043 100.619 68.2974Z"
          fill={theme.palette.svgs.royalBlue}
        />
        <path
          d="M107.19 66.6134L111.625 57.2466L113.302 53.3394L110.667 51.8408C110.667 51.8408 109.769 54.7407 108.831 56.2249C107.905 57.6895 105.416 65.7494 105.364 65.9699C105.092 66.0817 104.86 66.2734 104.7 66.5195C104.54 66.7655 104.458 67.0544 104.465 67.3479C104.473 67.6414 104.57 67.9257 104.742 68.1632C104.915 68.4006 105.156 68.5801 105.434 68.6778C105.711 68.7755 106.011 68.7868 106.295 68.7103C106.579 68.6338 106.833 68.473 107.023 68.2492C107.214 68.0255 107.332 67.7493 107.361 67.4572C107.391 67.1651 107.331 66.8709 107.19 66.6134Z"
          fill={theme.palette.svgs.brownSkin}
        />
        <path
          d="M107.165 57.9413L110.519 59.962L111.28 58.8139L111.786 58.5529L113.883 54.9576L115.737 53.0716L118.186 45.2534L114.999 45.7309L114.995 45.7322C114.428 46.0329 113.92 46.5705 113.223 47.0443C111.03 48.5372 109.138 53.3747 108.237 56.0719C108.222 56.1161 108.161 56.147 108.091 56.1771C107.757 56.3198 107.482 56.5732 107.313 56.8946C107.145 57.216 107.092 57.5857 107.165 57.9413Z"
          fill={theme.palette.secondary.main}
        />
      </g>
      <defs>
        <clipPath id="clip0_8289_51074">
          <rect width="156" height="106" fill={theme.palette.svgs.paperWhite} />
        </clipPath>
      </defs>
    </svg>
  );
};
