import styled from "@emotion/styled";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Tab,
  Tabs,
  Typography,
  useTheme,
  Card,
} from "@mui/material";
import { useEffect, useState } from "react";

import { getBorders, spacing } from "assets/styles/theme";
import {
  COOKIE_NAMES,
  allCookies,
  requiredCookies,
  useCustomCookies,
} from "libs/cookies";
import { explanations } from "libs/utilities/explanations";

import { ExternalLink } from "components/atoms";
import { BaseTable } from "components/molecules";

import { cookieList, getLink } from "./constants";

import type { AppThemeProps } from "assets/styles/theme/theme";
import type { BaseColumn } from "components/molecules/BaseTable";

const TAB_VALUES = {
  declaration: "declaration",
  about: "about",
};

type CookieBannerProps = {
  enableCookies: () => void;
};

export const CookieBanner = ({ enableCookies }: CookieBannerProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedTab, setSelectedTab] = useState(TAB_VALUES.declaration);
  const [selectedCategoryTab, setSelectedCategoryTab] =
    useState<keyof typeof cookieList>("necessary");
  const theme = useTheme() as AppThemeProps;

  const [cookies, setCookie] = useCustomCookies();
  const isAccepted = cookies?.[COOKIE_NAMES.COOKIE_CONSENT]?.necessary;
  const [showCookieBanner, setShowCookieBanner] = useState(!isAccepted);

  const tabStyles = {
    "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
      color: "#ffffff",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.cookieBanner.tertiary,
    },
  };

  useEffect(() => setShowCookieBanner(!isAccepted), [isAccepted]);

  const columns = [
    {
      title: "Name",
      field: "name",
      nowrap: true,
      width: "5%",
    },
    {
      title: "Provider",
      field: "provider",
      nowrap: false,
      width: "10%",
      render: ({ provider }: { provider: keyof typeof getLink }) => {
        const link = getLink(provider);

        return link ? (
          <ExternalLink launchIcon={false} href={link}>
            {provider}
          </ExternalLink>
        ) : (
          <Typography variant="body2">{provider}</Typography>
        );
      },
    },
    {
      title: "Purpose",
      field: "purpose",
      nowrap: true,
      width: "70%",
    },
    {
      title: "Expiry",
      field: "expiry",
      nowrap: true,
      width: "10%",
    },
    {
      title: "Type",
      field: "type",
      nowrap: true,
      width: "5%",
    },
  ];

  const handleNecessaryCookies = () => {
    const nextYear = new Date();
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    setCookie("UbiopsCookieConsent", JSON.stringify(requiredCookies), {
      path: "/",
      expires: nextYear,
    });
    setShowCookieBanner(false);
  };

  const handleAllCookies = () => {
    const nextYear = new Date();
    nextYear.setFullYear(nextYear.getFullYear() + 1);
    setCookie("UbiopsCookieConsent", JSON.stringify(allCookies), {
      path: "/",
      expires: nextYear,
    });
    setShowCookieBanner(false);
    enableCookies();
  };

  if (!showCookieBanner) return null;

  return (
    <S_Box>
      <Box padding={spacing[8]} marginRight={spacing[16]}>
        <img src={theme.logoVerticalWhite} height={spacing[560]} />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        width={"55%"}
        gap={spacing[12]}
      >
        <Typography variant="h6">This website uses cookies</Typography>
        {explanations.cookies.general}
        <Box display="flex" gap={spacing[12]}>
          <Button
            variant="contained"
            style={{
              backgroundColor: theme.palette.cookieBanner.primary,
            }}
            onClick={handleNecessaryCookies}
          >
            Use necessary cookies only
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: theme.palette.cookieBanner.secondary,
            }}
            onClick={handleAllCookies}
          >
            Allow all cookies
          </Button>
          <Button
            variant="text"
            endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={() => setIsExpanded(!isExpanded)}
            style={{
              color: theme.palette.primary.contrastText,
            }}
          >
            {isExpanded ? "Hide details" : "Show details"}
          </Button>
        </Box>
        {isExpanded && (
          <Box height={spacing[400]}>
            <Tabs
              onChange={(_e, value) => setSelectedTab(value)}
              value={selectedTab}
              sx={tabStyles}
            >
              <Tab label="Cookie declaration" value={TAB_VALUES.declaration} />
              <Tab label="About" value={TAB_VALUES.about} />
            </Tabs>
            {selectedTab === TAB_VALUES.about && (
              <S_Card theme={theme}>{explanations.cookies.about}</S_Card>
            )}
            {selectedTab === TAB_VALUES.declaration && (
              <S_Card theme={theme}>
                <Box display="flex" gap={spacing[4]}>
                  <Tabs
                    orientation="vertical"
                    value={selectedCategoryTab}
                    onChange={(_e, value) => setSelectedCategoryTab(value)}
                    sx={{
                      width: spacing[112],
                      borderRight: "1px solid",
                      borderColor: theme.palette.cookieBanner.primary,
                      ...tabStyles,
                    }}
                  >
                    {Object.keys(cookieList).map((cat) => (
                      <Tab label={cat} key={cat} value={cat} />
                    ))}
                  </Tabs>
                  <Box
                    height={spacing[310]}
                    style={{ overflow: "auto", flex: "1 1 0" }}
                  >
                    <BaseTable
                      columns={columns as BaseColumn[]}
                      data={cookieList[selectedCategoryTab]}
                      hasSearchBar={false}
                      hasPagination={false}
                      tableStyles={{
                        height: spacing[300],
                        overflow: "auto",
                      }}
                    />
                  </Box>
                </Box>
              </S_Card>
            )}
          </Box>
        )}
      </Box>
    </S_Box>
  );
};

const S_Box = styled(Box)`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 32px;
  z-index: 100;
  background: linear-gradient(to right, #151f32, #26385a);
  & * {
    text-transform: none !important;
    color: #ffffff !important;
  }
`;

const S_Card = styled(Card)`
  background-color: unset;
  border: ${(props) => getBorders(props.theme as AppThemeProps).tertiary};
  padding: 16px;
`;
