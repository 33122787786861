import { Box, useTheme } from "@mui/material";

import { getBorders, spacing } from "assets/styles/theme";

import type { BoxProps } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme.d";

export const ListItem = (props: BoxProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Box
      borderTop={getBorders(theme).tertiary}
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: `${spacing[48]}`,
      }}
      {...props}
    />
  );
};
