/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * Programmatic interface to UbiOps
 * OpenAPI spec version: v2.1
 */

export type ProjectInstanceListStatus =
  typeof ProjectInstanceListStatus[keyof typeof ProjectInstanceListStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProjectInstanceListStatus = {
  pending: "pending",
  initialising: "initialising",
  running: "running",
  stopping: "stopping",
} as const;
