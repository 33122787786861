import { Box, useTheme } from "@mui/material";

import { getBorders } from "assets/styles/theme";

import type { BoxProps } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme.d";

export const DialogActions = (props: BoxProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Box
      marginBottom={2}
      paddingTop={3}
      borderTop={getBorders(theme).primary}
      {...props}
    />
  );
};
