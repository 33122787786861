import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Box, Grid } from "@mui/material";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";

import { spacing } from "assets/styles/theme";
import { CONTINUOUS_REQUEST_DELAY } from "libs/constants/constants";
import { useInterval } from "libs/hooks";
import { LOADED } from "libs/utilities/request-statuses";
import { formatStatusLabel } from "libs/utilities/statuses";
import { Logs } from "pages/organizations/:organizationName/projects/:projectName/logs";
import { loadLogs } from "pages/organizations/:organizationName/projects/:projectName/logs/utils";
import { TRAINING_DEPLOYMENT } from "pages/organizations/:organizationName/projects/:projectName/training/constants";
import {
  MAX_LOG_ROWS,
  LOG_LIMIT,
} from "pages/organizations/:organizationName/projects/:projectName/training/experiments/:experimentName/:runId/constants";
import { useGetOrganizationFeatures } from "store/features";

import {
  Card,
  Dialog,
  DialogHeader,
  DialogHeaderTitle,
  Loader,
  NoData,
  SecondaryButton,
  StatusIcon,
  Switch,
} from "components/atoms";

import type {
  DeploymentRequestSingleDetail,
  LogsCreate,
} from "libs/data/models";
import type { LogRecord } from "pages/organizations/:organizationName/projects/:projectName/logs/types";

type RequestLogsProps = {
  deploymentName: string;
  versionName: string;
  projectName: string;
  requestId: string;
  requestDetails?: DeploymentRequestSingleDetail;
};
export const RequestLogs = ({
  deploymentName,
  projectName,
  versionName,
  requestId,
  requestDetails,
}: RequestLogsProps) => {
  const [isLogsDialogOpen, setIsLogsDialogOpen] = useState(false);
  const [autoLoading, setAutoLoading] = useState(true);
  const [jumpToStart, setJumpToStart] = useState(false);
  const [logs, setLogs] = useState<LogRecord[]>([]);
  const organizationFeatures = useGetOrganizationFeatures();

  const isRun = deploymentName === TRAINING_DEPLOYMENT;

  const isRunning = useMemo(
    () =>
      requestDetails?.status === "processing" ||
      requestDetails?.status === "cancelled_pending",
    [requestDetails?.status]
  );

  const isCompleted = useMemo(
    () => !!requestDetails?.time_completed,
    [requestDetails?.time_completed]
  );
  const isPending = useMemo(
    () => requestDetails?.status === "pending",
    [requestDetails?.status]
  );

  const logsAreExpired = useMemo(
    () =>
      moment().subtract(
        organizationFeatures?.max_retention_logs || 0,
        "seconds"
      ) > moment(requestDetails?.time_started),
    [organizationFeatures, requestDetails?.time_started]
  );

  const loadRequestLogs = useCallback(
    async (params: LogsCreate, stopLoading = true) => {
      const newLogs = await loadLogs(projectName, {
        ...params,
        filters: {
          deployment_name: deploymentName,
          deployment_version: versionName,
          deployment_request_id: requestId,
        },
        limit: LOG_LIMIT,
      });
      if (newLogs.length) setLogs(newLogs);
      if (stopLoading) setAutoLoading(false);
    },
    [versionName, deploymentName, projectName, requestId]
  );

  const loadPreviousRequestLogs = useCallback(async () => {
    const newLogs = await loadLogs(projectName, {
      id: logs?.[0]?.id,
      date_range: -86400,
      filters: {
        deployment_name: deploymentName,
        deployment_version: versionName,
        deployment_request_id: requestId,
      },
      limit: LOG_LIMIT,
    });

    // remove the newest logs if we have too much rows
    if (newLogs?.length) {
      const appendedLogs = [...newLogs, ...logs];
      setLogs(
        appendedLogs.length > MAX_LOG_ROWS
          ? appendedLogs.slice(0, MAX_LOG_ROWS)
          : appendedLogs
      );
    }
  }, [versionName, deploymentName, logs, projectName, requestId]);

  const loadNextRequestLogs = useCallback(async () => {
    const newLogs = await loadLogs(projectName, {
      id: logs?.[logs?.length - 1]?.id,
      date_range: 86400,
      filters: {
        deployment_name: deploymentName,
        deployment_version: versionName,
        deployment_request_id: requestId,
      },
      limit: LOG_LIMIT,
    });

    // remove the oldest logs if we have too much rows
    if (newLogs?.length) {
      const appendedLogs = [...logs, ...newLogs];
      setLogs(
        appendedLogs.length > MAX_LOG_ROWS
          ? appendedLogs.slice(LOG_LIMIT)
          : appendedLogs
      );
    }
  }, [versionName, logs, deploymentName, projectName, requestId]);

  const loadFirstLogs = useCallback(async () => {
    if (!requestDetails?.time_started) return;

    const newLogs = await loadLogs(projectName, {
      date_range: 86400,
      date: requestDetails?.time_started,
      filters: {
        deployment_name: deploymentName,
        deployment_version: versionName,
        deployment_request_id: requestId,
      },
      limit: LOG_LIMIT,
    });

    if (newLogs?.length) {
      setLogs(newLogs);
      setJumpToStart(true);
    }
  }, [
    requestDetails?.time_started,
    projectName,
    deploymentName,
    versionName,
    requestId,
  ]);

  const loadLastLogs = useCallback(async () => {
    if (!requestDetails?.time_completed) return;
    const newLogs = await loadLogs(projectName, {
      date_range: -86400,
      date: requestDetails?.time_completed,
      filters: {
        deployment_name: deploymentName,
        deployment_version: versionName,
        deployment_request_id: requestId,
      },
      limit: LOG_LIMIT,
    });

    if (newLogs?.length) {
      setLogs(newLogs);
      setJumpToStart(false);
    }
  }, [
    requestDetails?.time_completed,
    projectName,
    deploymentName,
    versionName,
    requestId,
  ]);

  useEffect(() => {
    // fetch last 100 logs if the request has completed status
    const date = requestDetails?.time_completed;
    if (logs.length === 0 && date) {
      loadRequestLogs({
        date,
      });
    }
  }, [loadRequestLogs, logs, requestDetails]);

  useInterval(
    () => {
      if (autoLoading)
        loadRequestLogs(
          {
            date: moment().toISOString(),
          },
          false
        );
      if (autoLoading && isCompleted) {
        setAutoLoading(false);
        setJumpToStart(false);
      }
    },
    [autoLoading, loadRequestLogs, isCompleted],
    CONTINUOUS_REQUEST_DELAY,
    false
  );

  return (
    <>
      <Grid item xs={12}>
        <Card
          title="Logs"
          actions={
            <Grid display="flex" item>
              <Switch
                checked={autoLoading && requestDetails?.status !== "completed"}
                onChange={() => setAutoLoading(!autoLoading)}
                disabled={requestDetails?.status === "completed"}
                label="Auto load logs"
                labelPlacement="start"
              />
              <SecondaryButton
                startIcon={<FullscreenIcon />}
                onClick={() => setIsLogsDialogOpen(true)}
                disabled={isPending}
                style={{
                  flex: "none",
                  marginLeft: spacing[4],
                }}
              >
                Full screen
              </SecondaryButton>
              <SecondaryButton
                onClick={!jumpToStart ? loadFirstLogs : loadLastLogs}
                disabled={isPending || autoLoading}
                style={{
                  flex: "none",
                  marginLeft: spacing[8],
                }}
              >
                {jumpToStart ? "Jump to end" : "Jump to start"}
              </SecondaryButton>
            </Grid>
          }
          contentStyle={{
            maxHeight: "50vh",
            minHeight: "200px",
            height: "100%",
            overflow: "hidden",
          }}
        >
          {logs?.length ? (
            <Logs
              logSet={{ logs: logs }}
              autoLoading={autoLoading}
              logsStatus={LOADED}
              refresh={false}
              displayNoLogsMessage={false}
              scrollToTop={jumpToStart}
              scrollToBottom={!jumpToStart}
              onOlderClick={
                autoLoading || jumpToStart ? undefined : loadPreviousRequestLogs
              }
              onNewerClick={
                autoLoading || !jumpToStart ? undefined : loadNextRequestLogs
              }
              isBuildingLogs
            />
          ) : isRunning ? (
            <Loader />
          ) : (
            (isPending || logs.length === 0) && (
              <Box display="flex" width="100%" justifyContent="center">
                <NoData
                  text={
                    logsAreExpired
                      ? `Sorry, the logs for this ${
                          isRun ? "run" : "request"
                        } appear to have expired.
                    Need a longer retention time for your logs? Please contact our sales team.`
                      : "No logs"
                  }
                />
              </Box>
            )
          )}
        </Card>
      </Grid>

      <Dialog
        open={isLogsDialogOpen}
        onClose={() => setIsLogsDialogOpen(false)}
        fullWidth
        maxWidth={false}
        dialogBodyStyles={{
          paddingTop: 0,
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "200px",
          maxHeight: "80vh",
        }}
        Header={
          <DialogHeader
            header={
              <Box display="flex" justifyContent="space-between">
                <DialogHeaderTitle>logs</DialogHeaderTitle>
                <StatusIcon
                  label={requestDetails?.status}
                  status={formatStatusLabel(requestDetails?.status)}
                />
              </Box>
            }
          />
        }
      >
        {logs?.length ? (
          <Logs
            logSet={{ logs: logs }}
            autoLoading={false}
            logsStatus={LOADED}
            refresh={false}
            displayNoLogsMessage={false}
            onOlderClick={autoLoading ? undefined : loadPreviousRequestLogs}
            onNewerClick={autoLoading ? undefined : loadNextRequestLogs}
            isBuildingLogs
          />
        ) : (
          <Loader />
        )}
      </Dialog>
    </>
  );
};
