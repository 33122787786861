/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * Programmatic interface to UbiOps
 * OpenAPI spec version: v2.1
 */

export type EnvironmentListStatus =
  typeof EnvironmentListStatus[keyof typeof EnvironmentListStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EnvironmentListStatus = {
  unavailable: "unavailable",
  available: "available",
  building: "building",
} as const;
