import styled from "@emotion/styled";
import PipelineIcon from "@mui/icons-material/AccountTreeRounded";
import DeploymentIcon from "@mui/icons-material/WidgetsRounded";
import { Box, Typography, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useReactFlow } from "reactflow";

import { spacing } from "assets/styles/theme";
import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import {
  FIELD_ERROR_POLICY,
  FIELD_OBJECT_NAME,
  FIELD_VERSION,
} from "libs/constants/fields";
import { useDeploymentVersionsList } from "libs/data/endpoints/deployments/deployments";
import {
  usePipelineVersionsList,
  usePipelinesGet,
} from "libs/data/endpoints/pipelines/pipelines";

import { Loader } from "components/atoms";

import { NodeDeploymentPipelineSidebar } from "./NodeDeploymentPipelineSidebar";
import { NodeHandle } from "./NodeHandle";
import { NodeNameEditableTitle } from "./NodeNameEditable";
import { DetailsContainer, NodeContainer, NodeDetails } from "./styles";
import { ActionButtons } from "../ActionButtons";
import { NodeDeleteDialog } from "../NodeDeleteDialog";
import { NodeErrorPolicyDialog } from "../NodeErrorPolicyDialog";
import { useIsSidebarRightOpen, useSidebarRightState } from "../SidebarRight";

import type { EdgeDataType, NodeDataType } from "../types";
import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type { NodeProps } from "reactflow";

const OriginalDeploymentText = styled(Typography)`
  display: flex;
  cursor: auto;
  max-width: 20rem;
`;

export type FormValuesType = {
  [FIELD_OBJECT_NAME]?: string;
  [FIELD_VERSION]?: AutocompleteSelectOption;
  [FIELD_ERROR_POLICY]?: AutocompleteSelectOption;
};

export type NodeDeploymentPipelineProps = NodeProps<NodeDataType>;

export const NodeDeploymentPipeline = ({
  id,
  data: {
    isReadonly,
    pipelineObject,
    organizationName,
    projectName,
    pipelineName,
    versionName,
  },
  isConnectable,
  selected,
}: NodeDeploymentPipelineProps) => {
  const { setNodes, setEdges, getNode } =
    useReactFlow<NodeDataType, EdgeDataType>();
  const methods = useForm<FormValuesType>({ mode: "all" });
  const objectIsDeployment = pipelineObject.reference_type === "deployment";

  const theme = useTheme() as AppThemeProps;

  const { data: deploymentVersions } = useDeploymentVersionsList(
    (objectIsDeployment && projectName) || "",
    pipelineObject.reference_name
  );

  const { data: pipelineVersions } = usePipelineVersionsList(
    (!objectIsDeployment && projectName) || "",
    pipelineObject.reference_name
  );

  const { data: pipelineDetails } = usePipelinesGet(projectName, pipelineName);

  const versionOptions = useMemo(() => {
    const versions = objectIsDeployment ? deploymentVersions : pipelineVersions;

    let modifiedVersions = [
      { label: "(Default version)", value: null },
      ...(versions?.map((version) => ({
        label: version.version,
        value: version.version,
      })) || []),
    ];

    if (!objectIsDeployment && pipelineObject.reference_name === pipelineName) {
      modifiedVersions = modifiedVersions.filter(
        (version) =>
          version.value !== versionName &&
          (versionName !== pipelineDetails?.default_version ||
            version.value !== null)
      );
    }

    return modifiedVersions;
  }, [
    objectIsDeployment,
    deploymentVersions,
    pipelineVersions,
    pipelineObject.reference_name,
    pipelineName,
    versionName,
    pipelineDetails?.default_version,
  ]);

  const isSidebarOpen = useIsSidebarRightOpen();
  const [isInfoSidebarOpen, setIsInfoSidebarOpen] = useSidebarRightState(id);

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isErrorPolicyDialogOpen, setIsErrorPolicyDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const isEditable =
    !isReadonly &&
    (!isInfoSidebarOpen || !isDeleteDialogOpen || !isSidebarOpen);

  const updateVersion = (versionName: string | null | undefined) => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          node.selected = false;
          node.data = {
            ...node.data,
            pipelineObject: {
              ...node.data.pipelineObject,
              version: versionName || null,
            },
          };
        }

        return node;
      })
    );
  };

  const onSubmit = (data: FormValuesType) => {
    const newName = data[FIELD_OBJECT_NAME];
    const newErrorPolicy = data[FIELD_ERROR_POLICY]?.value as string;

    setIsEditing(false);

    const updateRelatedEdges = () => {
      setEdges((edges) =>
        edges.map((edge) => {
          if (!edge.data) {
            return edge;
          }

          if (newName) {
            edge.data = {
              ...edge.data,
              sources: (edge.data?.sources || []).map((source) => {
                const oldName = getNode(id)?.data.pipelineObject.name;

                if (source.source_name !== oldName) {
                  return source;
                }

                return {
                  ...source,
                  source_name: newName,
                };
              }),
            };
          }

          return edge;
        })
      );
    };

    const updateNode = () => {
      setNodes((nodes) =>
        nodes.map((node) => {
          if (node.id === id) {
            node.selected = false;
            node.data = {
              ...node.data,
              pipelineObject: {
                ...node.data.pipelineObject,
                ...(newName !== undefined ? { name: newName } : {}),
                ...(newErrorPolicy !== undefined
                  ? { configuration: { on_error: newErrorPolicy } }
                  : null),
              },
            };
          }

          return node;
        })
      );
    };

    updateRelatedEdges();
    updateNode();
  };

  const defaultVersion = useMemo(
    () =>
      pipelineObject.version
        ? {
            label: pipelineObject.version,
            value: pipelineObject.version,
          }
        : versionOptions[0],
    [pipelineObject.version, versionOptions]
  );

  return (
    <>
      <NodeDeleteDialog
        id={id}
        pipelineObjectName={pipelineObject?.name}
        pipelineObjectType={pipelineObject.reference_type}
        isOpen={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
      />
      <NodeErrorPolicyDialog
        pipelineObject={pipelineObject}
        isOpen={isErrorPolicyDialogOpen}
        onClose={() => setIsErrorPolicyDialogOpen(false)}
        onSubmit={onSubmit}
      />

      <NodeDeploymentPipelineSidebar
        id={id}
        organizationName={organizationName}
        projectName={projectName}
        pipelineName={pipelineName}
        pipelineVersionName={versionName}
        pipelineObject={pipelineObject}
        isOpen={isInfoSidebarOpen}
        onClose={() => setIsInfoSidebarOpen(false)}
        onClickDelete={() => setIsDeleteDialogOpen(true)}
      />

      <Box display="flex" justifyContent="flex-end" height={35} mb={spacing[8]}>
        {isEditable && (selected || isInfoSidebarOpen) && (
          <ActionButtons
            onDeleteClicked={() => setIsDeleteDialogOpen(true)}
            onInfoClicked={() => setIsInfoSidebarOpen(true)}
            onErrorPolicyClicked={() => setIsErrorPolicyDialogOpen(true)}
          />
        )}
      </Box>

      <NodeContainer
        backgroundColor={theme.palette.pipelineDiagram.nodeBackground}
        hoverColor={theme.palette.pipelineDiagram.nodeHover}
        outlineColor={theme.palette.specials.blue.bright}
        className={selected || isInfoSidebarOpen ? "nodrag" : undefined}
        isSelected={selected || isInfoSidebarOpen}
      >
        <NodeHandle
          type="target"
          isConnectable={isConnectable}
          isDeployment={objectIsDeployment}
        />

        <DetailsContainer
          color={
            objectIsDeployment
              ? theme.palette.pipelineDiagram.deploymentAccent
              : theme.palette.pipelineDiagram.pipelineAccent
          }
        >
          {objectIsDeployment ? (
            <DeploymentIcon
              style={{
                marginRight: spacing[16],
                color: theme.palette.pipelineDiagram.deploymentAccent,
                width: spacing[48],
                height: spacing[48],
              }}
            />
          ) : (
            <PipelineIcon
              style={{
                marginRight: spacing[16],
                color: theme.palette.pipelineDiagram.pipelineAccent,
                width: spacing[48],
                height: spacing[48],
              }}
            />
          )}

          <NodeDetails>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                style={{ width: 200 }}
              >
                <NodeNameEditableTitle
                  name={pipelineObject.name}
                  fieldName={FIELD_OBJECT_NAME}
                  isEditable={isEditable}
                  isEditing={isEditing}
                  setIsEditing={setIsEditing}
                />

                <OriginalDeploymentText
                  variant="body2"
                  className="ellipsis"
                  color={theme.palette.neutrals[500]}
                >
                  {pipelineObject.reference_name}
                </OriginalDeploymentText>

                {!(pipelineVersions || deploymentVersions) ? (
                  <Loader />
                ) : (
                  <AutoCompleteSelectHookForm
                    maxMenuHeight={200}
                    disabled={!isEditable}
                    name={FIELD_VERSION}
                    options={versionOptions}
                    defaultValue={defaultVersion}
                    onChange={(value: AutocompleteSelectOption) =>
                      updateVersion((value?.value as string) || null)
                    }
                  />
                )}
              </form>
            </FormProvider>
          </NodeDetails>
        </DetailsContainer>

        <NodeHandle
          type="source"
          isConnectable={isConnectable}
          isDeployment={objectIsDeployment}
        />
      </NodeContainer>
    </>
  );
};
