import { useTheme } from "@mui/material";

import { Chip } from "components/atoms";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

type DateChipProps = { dates: string; handleClose: () => void };
export const DateChip = ({ dates, handleClose }: DateChipProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Chip
      label={dates}
      onDelete={handleClose}
      style={{
        background: theme.palette.chip.date,
      }}
    />
  );
};
