import { ReactComponent as AWS } from "assets/images/bucket-logo-aws.svg";
import { ReactComponent as Azure } from "assets/images/bucket-logo-azure.svg";
import { ReactComponent as GCS } from "assets/images/bucket-logo-gcs.svg";
import {
  ARRAY_STRING_TYPE,
  ARRAY_INT_TYPE,
  ARRAY_DOUBLE_TYPE,
  INT_TYPE,
  DOUBLE_TYPE,
  BOOLEAN_TYPE,
  STRING_TYPE,
  FILE_TYPE,
  FILES_READER,
  FILES_WRITER,
  FILES_READER_RESTRICTED,
  FILES_WRITER_RESTRICTED,
  ARRAY_FILE_TYPE,
  DICT_TYPE,
} from "libs/constants/constants";

export type SelectOption = {
  label: string;
  value: string;
};

export const fieldTypes = [
  { label: "Integer", value: INT_TYPE },
  { label: "String", value: STRING_TYPE },
  { label: "Double precision", value: DOUBLE_TYPE },
  { label: "Boolean", value: BOOLEAN_TYPE },
  { label: "File", value: FILE_TYPE },
  { label: "Dictionary", value: DICT_TYPE },
  { label: "Array of integers", value: ARRAY_INT_TYPE },
  { label: "Array of doubles", value: ARRAY_DOUBLE_TYPE },
  { label: "Array of strings", value: ARRAY_STRING_TYPE },
  { label: "Array of files", value: ARRAY_FILE_TYPE },
];

export const fieldTypesLabels = {
  [INT_TYPE]: "Integer",
  [STRING_TYPE]: "String",
  [DOUBLE_TYPE]: "Double precision",
  [BOOLEAN_TYPE]: "Boolean",
  [FILE_TYPE]: "File",
  [DICT_TYPE]: "Dictionary",
  [ARRAY_INT_TYPE]: "Array of integers",
  [ARRAY_DOUBLE_TYPE]: "Array of doubles",
  [ARRAY_STRING_TYPE]: "Array of strings",
  [ARRAY_FILE_TYPE]: "Array of files",
};

export const secretValues = {
  true: "Yes",
  false: "No",
};

export const protocols = {
  tcp: "TCP",
  udp: "UDP",
};

export const groupedlabelKeyOptions = [
  { label: "type", value: "type", group: "Suggested values" },
  { label: "application", value: "application", group: "Suggested values" },
  { label: "stage", value: "stage", group: "Suggested values" },
  { label: "environment", value: "environment", group: "Suggested values" },
  { label: "other", value: "other", group: "Suggested values" },
];

export const labelKeyOptions = [
  {
    label: "Suggested values",
    options: [
      { label: "type", value: "type" },
      { label: "application", value: "application" },
      { label: "stage", value: "stage" },
      { label: "environment", value: "environment" },
      { label: "other", value: "other" },
    ],
  },
];

export const auditEventsActions = [
  { title: "create" },
  { title: "update" },
  { title: "delete" },
  { title: "info" },
];

export const predefinedLabels = [
  {
    key: "stage",
    value: "production",
  },
  {
    key: "stage",
    value: "testing",
  },
];

export const roleGroups = [
  "project",
  "deployment",
  "pipeline",
  "service-user",
  "environment",
  "files",
];

// sign-up questions
export const roles = [
  {
    label: "AI/ML engineer",
    value: "AI/ML/MLOps engineer",
  },
  {
    label: "CEO/CTO/Director",
    value: "CEO/CTO/Director",
  },
  {
    label: "Data engineer",
    value: "Data engineer",
  },
  {
    label: "Data scientist",
    value: "Data scientist",
  },
  {
    label: "Developer",
    value: "Developer",
  },
  {
    label: "Head of analytics",
    value: "Head of analytics",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const industries = [
  {
    label: "Agriculture",
    value: "AgriTech/Horticulture",
  },
  {
    label: "Healthcare",
    value: "MedTech/Healthcare",
  },
  {
    label: "Finance",
    value: "FinTech/Financial services",
  },
  {
    label: "Energy",
    value: "Construction/Energy/Manufacturing",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export const reasons = [
  {
    label: "deploy Computer Vision models",
    value: "deploy computer vision/image recognition models",
  },
  {
    label: "deploy generative AI/LLMs",
    value: "deploy generative AI/LLMs",
  },
  {
    label: "deploy Time Series algorithms",
    value: "deploy time series/long-running algorithms",
  },
  {
    label: "deploy something else",
    value: "deploy something else",
  },
  {
    label: "train or finetune models",
    value: "train or fine-tune models",
  },
  {
    label: "take a look around",
    value: "take a look around",
  },
];

export const requestRetentionModes = [
  { label: "None", value: "none" },
  { label: "Metadata", value: "metadata" },
  { label: "Full (Metadata + request in- and output)", value: "full" },
];

export const requestRetentionTimes = [
  { label: "1 hour (3600)", value: 3600 },
  { label: "1 day (86400)", value: 86400 },
  { label: "1 week (604800)", value: 604800 },
  { label: "1 month (2419200)", value: 2419200 },
  { label: "1 year (31536000)", value: 31536000 },
];

export const getPossibleRequestRetentionTimes = (value: number) =>
  requestRetentionTimes.filter((obj) => obj.value <= value);

export const getLabel = (
  list: { value: string | number; label: string }[],
  valueToFind: string | number
) =>
  list.find(({ value }) => value === valueToFind)?.label ||
  valueToFind?.toString();

export const defaultLanguageOption = {
  label: "Python 3.8",
  value: "python3.8",
};

export const projectType = [
  {
    label: "Project",
    value: "project",
  },
];

export const resourceTypes = [
  {
    label: "Project",
    value: "project",
  },
  {
    label: "Deployment",
    value: "deployment",
  },
  {
    label: "Pipeline",
    value: "pipeline",
  },
  { label: "Bucket", value: "bucket" },
  { label: "Environment", value: "environment" },
];

export const externalBucketTypes = [
  { label: "S3", value: "amazon_s3", icon: AWS },
  { label: "GCS", value: "google_cloud_storage", icon: GCS },
  { label: "Azure", value: "azure_blob_storage", icon: Azure },
];

export const bucketAutoDeleteValues = [
  { label: "Never", value: null },
  { label: "1 Week", value: 604800 },
  { label: "2 Weeks", value: 2 * 604800 },
  { label: "3 Weeks", value: 3 * 604800 },
  { label: "1 Month", value: 4 * 604800 },
  { label: "3 Months", value: 13 * 604800 },
  { label: "6 Months", value: 26 * 604800 },
  { label: "1 Year", value: 52 * 604800 },
];

export const fileAccessRoleOptions: SelectOption[] = [
  { label: "read only", value: FILES_READER },
  { label: "read only restricted", value: FILES_READER_RESTRICTED },
  {
    label: "read and write",
    value: FILES_WRITER, // files-writer also have files-get permissions
  },
  {
    label: "read and write restricted",
    value: FILES_WRITER_RESTRICTED,
  },
];

export const fileAccessRoles = {
  [FILES_READER]: "read only",
  [FILES_WRITER]: "read and write",
  [FILES_READER_RESTRICTED]: "read only restricted",
  [FILES_WRITER_RESTRICTED]: "read and write restricted",
};
