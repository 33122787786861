import { Tabs, Tab, Card, Box, useTheme } from "@mui/material";
import { useFormContext, useWatch } from "react-hook-form";

import { getBorders } from "assets/styles/theme";
import { TAB_JSON_EDITOR, TAB_VISUAL_EDITOR } from "libs/constants/constants";
import { VISUAL_EDITOR, JSON_EDITOR } from "libs/utilities/input-parser";
import validators from "libs/utilities/validators";

import { Textarea } from "components/atoms";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { InputOutputFieldList } from "libs/data/models";
import type { ChangeEvent } from "react";

type EditorsViewProps = {
  onEditorChange: (
    _e: ChangeEvent<unknown>,
    value: "visualEditor" | "jsonEditor"
  ) => void;
  disabled?: boolean;
  editorFieldName: string;
  jsonAreaName: string;
  formFields: InputOutputFieldList[] | undefined;
  children: React.ReactNode;
};

export const EditorsView = ({
  onEditorChange,
  disabled = false,
  editorFieldName,
  jsonAreaName,
  formFields,
  children,
}: EditorsViewProps) => {
  const theme = useTheme() as AppThemeProps;
  const { register } = useFormContext();

  const editorField = useWatch({
    name: editorFieldName,
    defaultValue: VISUAL_EDITOR,
  });

  return (
    <Card variant="outlined">
      <input
        type="hidden"
        name={editorFieldName}
        ref={register}
        defaultValue={editorField}
      />
      <Tabs
        value={editorField}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={onEditorChange}
        variant="fullWidth"
      >
        <Tab
          sx={{ borderBottom: getBorders(theme).secondary }}
          label="Visual editor"
          id={VISUAL_EDITOR}
          aria-controls={VISUAL_EDITOR}
          value={VISUAL_EDITOR}
          disabled={disabled}
        />
        <Tab
          sx={{ borderBottom: getBorders(theme).secondary }}
          label="JSON editor"
          id={JSON_EDITOR}
          aria-controls={JSON_EDITOR}
          value={JSON_EDITOR}
          disabled={disabled}
        />
      </Tabs>
      <Box p={2}>
        <div
          hidden={editorField !== VISUAL_EDITOR}
          id={VISUAL_EDITOR}
          aria-labelledby={TAB_VISUAL_EDITOR}
        >
          {children}
        </div>
        <div
          hidden={editorField !== JSON_EDITOR}
          id={JSON_EDITOR}
          aria-labelledby={TAB_JSON_EDITOR}
        >
          <Textarea
            name={jsonAreaName}
            rules={validators.json}
            defaultValue={JSON.stringify(formFields, null, 1)}
            maxRows={40}
          />
        </div>
      </Box>
    </Card>
  );
};
