import { Button, useTheme } from "@mui/material";

import { spacing } from "assets/styles/theme";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

export type TertiaryButton = {
  children: string;
  isSelected?: boolean;
  onClick?: () => void;
  key: string;
};

export const TertiaryButton = ({
  children,
  isSelected,
  key,
  onClick,
}: TertiaryButton) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Button
      variant="contained"
      key={key}
      onClick={onClick}
      sx={{
        background: isSelected
          ? theme.palette.specials.blue.bright
          : theme.palette.buttons.tertiaryBackground,
        color: theme.palette.buttons.tertiaryColor,
        minWidth: spacing[96],
        boxShadow:
          "0px 3px 1px -2px rgba(0,0,0,0.1),0px 2px 2px 0px rgba(0,0,0,0.1),0px 1px 5px 0px rgba(0,0,0,0.01)",
        "&:hover": {
          background: isSelected
            ? theme.palette.info.main
            : theme.palette.specials.blue.bright,
          color: theme.palette.buttons.tertiaryColor,
        },
      }}
    >
      {children}
    </Button>
  );
};
