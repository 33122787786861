/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * Programmatic interface to UbiOps
 * OpenAPI spec version: v2.1
 */
import useSwr from "swr";

import { orvalAxios } from "../../axios/index";

import type { ErrorType } from "../../axios/index";
import type {
  ServiceUserCreateBody,
  ServiceUserList,
  ServiceUserTokenDetail,
  ServiceUserTokenList,
  ServiceUserTokenReset,
} from "../../models";
import type { Key, SWRConfiguration } from "swr";

// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P
) => any
  ? P
  : never;

/**
 * 
### Description
List service users defined in a project

### Response Structure
List of details of the service users:
- `id`: Unique identifier for the service user (UUID)
- `email`: Email of the service user
- `name`: Name of the service user
- `creation_date`: Date when the service user was created
- `expiry_date`: Date when the service user account will expire (UTC)

#### Response Examples
```
[
  {
    "id": "537bca64-5ab6-43eb-a7ef-1638bc30b6ed",
    "email": "537bca64-5ab6-43eb-a7ef-1638bc30b6ed.project1@serviceuser.ubiops.com",
    "name": "service-user-1",
    "creation_date": "2020-03-24T09:16:27.504+00:00",
    "expiry_date": "2021-03-24T00:00:00.000+00:00"
  },
  {
    "id": "13a9ba27-6888-4528-826e-8e1002eab13d",
    "email": "13a9ba27-6888-4528-826e-8e1002eab13d.project1@serviceuser.ubiops.com",
    "name": "service-user-2",
    "creation_date": "2020-03-26T12:18:43.123+00:00",
    "expiry_date": null
  }
]
```

 * @summary List service users
 */
export const serviceUsersList = (
  projectName: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ServiceUserList[]>(
    { url: `/projects/${projectName}/service-users`, method: "get" },
    options
  );
};

export const getServiceUsersListKey = (projectName: string) =>
  [`/projects/${projectName}/service-users`] as const;

export type ServiceUsersListQueryResult = NonNullable<
  Awaited<ReturnType<typeof serviceUsersList>>
>;
export type ServiceUsersListQueryError = ErrorType<unknown>;

/**
 * @summary List service users
 */
export const useServiceUsersList = <TError = ErrorType<unknown>>(
  projectName: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof serviceUsersList>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled = swrOptions?.enabled !== false && !!projectName;
  const swrKey =
    swrOptions?.swrKey ??
    (() => (isEnabled ? getServiceUsersListKey(projectName) : null));
  const swrFn = () => serviceUsersList(projectName, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Create a new service user. A unique email is generated for the service user. Additionally, a token for this service user is generated. This token can be used to authorize requests sent to our API. It is possible to set an expiry date for this token.
In addition, allowed cors origins can be configured for the service user. The service user will be allowed to make a deployment or pipeline request from these origins.

The token is **ONLY** returned on creation and will not be accessible afterwards.

### Optional Parameters
- `name`: Name of the service user
- `expiry_date`: Date when the service user account expires (UTC). If null is passed, the account will never expire.

#### Request Examples
```
{
  "name": "service-user-1"
}
```

```
{
  "name": "service-user-1",
  "expiry_date": "2020-01-01T00:00:00.000Z"
}
```

### Response Structure
Details of the created service user
- `id`: Unique identifier for the service user (UUID)
- `email`: Email of the service user
- `token`: The API token for the created service user
- `name`: Name of the service user
- `creation_date`: Date when the service user was created
- `expiry_date`: Date when the service user account will expire (UTC)

#### Response Examples
```
{
  "id": "13a9ba27-6888-4528-826e-8e1002eab13d",
  "email": "13a9ba27-6888-4528-826e-8e1002eab13d.project1@serviceuser.ubiops.com",
  "token": "e962d9190348af7fa8d233d75cff7385b4335f81",
  "name": "service-user-1",
  "creation_date": "2020-03-24T09:16:27.504+00:00",
  "expiry_date": "2021-03-24T00:00:00.000+00:00"
}
```

 * @summary Create a new service user
 */
export const serviceUsersCreate = (
  projectName: string,
  serviceUserCreateBody: ServiceUserCreateBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ServiceUserTokenDetail>(
    {
      url: `/projects/${projectName}/service-users`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: serviceUserCreateBody,
    },
    options
  );
};

/**
 * 
### Description
Retrieve details of a service user

### Response Structure
Details of the service user
- `id`: Unique identifier for the service user (UUID)
- `email`: Email of the service user
- `name`: Name of the service user
- `creation_date`: Date when the service user was created
- `expiry_date`: Date when the service user account will expire (UTC)

#### Response Examples
```
{
  "id": "13a9ba27-6888-4528-826e-8e1002eab13d",
  "email": "13a9ba27-6888-4528-826e-8e1002eab13d.project1@serviceuser.ubiops.com",
  "name": "new-service-user-name",
  "creation_date": "2020-03-26T12:18:43.123+00:00",
  "expiry_date": null
}
```

 * @summary Retrieve details of a service user
 */
export const serviceUsersGet = (
  projectName: string,
  serviceUserId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ServiceUserList>(
    {
      url: `/projects/${projectName}/service-users/${serviceUserId}`,
      method: "get",
    },
    options
  );
};

export const getServiceUsersGetKey = (
  projectName: string,
  serviceUserId: string
) => [`/projects/${projectName}/service-users/${serviceUserId}`] as const;

export type ServiceUsersGetQueryResult = NonNullable<
  Awaited<ReturnType<typeof serviceUsersGet>>
>;
export type ServiceUsersGetQueryError = ErrorType<unknown>;

/**
 * @summary Retrieve details of a service user
 */
export const useServiceUsersGet = <TError = ErrorType<unknown>>(
  projectName: string,
  serviceUserId: string,
  options?: {
    swr?: SWRConfiguration<
      Awaited<ReturnType<typeof serviceUsersGet>>,
      TError
    > & { swrKey?: Key; enabled?: boolean };
    request?: SecondParameter<typeof orvalAxios>;
  }
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {};

  const isEnabled =
    swrOptions?.enabled !== false && !!(projectName && serviceUserId);
  const swrKey =
    swrOptions?.swrKey ??
    (() =>
      isEnabled ? getServiceUsersGetKey(projectName, serviceUserId) : null);
  const swrFn = () =>
    serviceUsersGet(projectName, serviceUserId, requestOptions);

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(
    swrKey,
    swrFn,
    swrOptions
  );

  return {
    swrKey,
    ...query,
  };
};

/**
 * 
### Description
Update the name, expiry date and cors allowed origins of a service user. The new value for the cors_allowed_origin will replace the old value.
Leave as an empty list to remove the previous list of allowed origins.

It is not possible to update a service user whose expiry date has been reached.

### Optional Parameters
- `name`: Name of the service user
- `expiry_date`: Date when the service user account will expire (UTC). If null is passed, the account will never expire.

#### Request Examples

```
{
  "name": "new-service-user-name",
}
```

```
{
  "expiry_date": "2020-01-01T00:00:00.000Z"
}
```

### Response Structure
Details of the updated service user
- `id`: Unique identifier for the service user (UUID)
- `email`: Email of the service user
- `name`: Name of the service user
- `creation_date`: Date when the service user was created
- `expiry_date`: Date when the service user account will expire (UTC)

#### Response Examples
```
{
  "id": "13a9ba27-6888-4528-826e-8e1002eab13d",
  "email": "13a9ba27-6888-4528-826e-8e1002eab13d.project1@serviceuser.ubiops.com",
  "name": "new-service-user-name",
  "creation_date": "2020-03-26T12:18:43.123+00:00",
  "expiry_date": null
}
```

 * @summary Update service user details
 */
export const serviceUsersUpdate = (
  projectName: string,
  serviceUserId: string,
  serviceUserCreateBody: ServiceUserCreateBody,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ServiceUserList>(
    {
      url: `/projects/${projectName}/service-users/${serviceUserId}`,
      method: "patch",
      headers: { "Content-Type": "application/json" },
      data: serviceUserCreateBody,
    },
    options
  );
};

/**
 * 
### Description
Delete a service user from a project

 * @summary Delete service user
 */
export const serviceUsersDelete = (
  projectName: string,
  serviceUserId: string,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<void>(
    {
      url: `/projects/${projectName}/service-users/${serviceUserId}`,
      method: "delete",
    },
    options
  );
};

/**
 * 
### Description
Reset the token of a service user. The old token will be deleted and a new one will be created for the service user. No data should be sent in the body of the request.

It is not possible to reset the token of a service user whose expiry date has been reached.

### Response Structure
Details of the new token for the service user
- `token`: The new API token for the service user

#### Response Examples
```
{
  "token": "e962d9190348af7fa8d233d75cff7385b4335f81"
}
```

 * @summary Reset the token of a service user
 */
export const serviceUsersToken = (
  projectName: string,
  serviceUserId: string,
  serviceUserTokenReset: ServiceUserTokenReset,
  options?: SecondParameter<typeof orvalAxios>
) => {
  return orvalAxios<ServiceUserTokenList>(
    {
      url: `/projects/${projectName}/service-users/${serviceUserId}/token`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: serviceUserTokenReset,
    },
    options
  );
};
