import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

import { borderRadius, spacing } from "assets/styles/theme";
import { ProjectInstanceListStatus } from "libs/data/models";
import { useColorByStatus } from "libs/hooks";
import { toUpperFirstCase } from "libs/utilities/util-functions";
import { routes } from "routes";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { ProjectInstanceList } from "libs/data/models";

type ActiveInstancesProps = {
  data: ProjectInstanceList[];
};
export const ActiveInstances = ({ data }: ActiveInstancesProps) => {
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const theme = useTheme() as AppThemeProps;
  const colorByStatus = useColorByStatus();
  const history = useHistory();
  const redirectUrl =
    routes.organizations[":organizationName"](organizationName).projects[
      ":projectName"
    ](projectName);

  const instanceCounts = useMemo(
    () => ({
      [ProjectInstanceListStatus.running]: data.filter(
        (instance) => instance.status === ProjectInstanceListStatus.running
      ).length,
      [ProjectInstanceListStatus.pending]: data.filter(
        (instance) => instance.status === ProjectInstanceListStatus.pending
      ).length,
      [ProjectInstanceListStatus.stopping]: data.filter(
        (instance) => instance.status === ProjectInstanceListStatus.stopping
      ).length,
      [ProjectInstanceListStatus.initialising]: data.filter(
        (instance) => instance.status === ProjectInstanceListStatus.initialising
      ).length,
    }),
    [data]
  );

  return (
    <Grid
      container
      wrap="nowrap"
      gap={3}
      flexWrap="nowrap"
      overflow="hidden"
      whiteSpace="nowrap"
      id="active-instances-box"
    >
      {Object.entries(instanceCounts).map(([key, value]) => (
        <Box
          key={key}
          style={{
            backgroundColor: colorByStatus(key),
            borderRadius: borderRadius[4],
            height: spacing[96],
            minWidth: spacing[96],
            padding: spacing[8],
            cursor: "pointer",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: theme.palette.success.contrastText,
          }}
          onClick={() =>
            history.push(redirectUrl.monitoring.activeInstances.index())
          }
        >
          <Typography variant="h1">{value}</Typography>
          <Typography variant="body1">{toUpperFirstCase(key)}</Typography>
        </Box>
      ))}
    </Grid>
  );
};
