import { useParams } from "react-router-dom";

import { Metrics } from "components/organisms";

import { TRAINING_DEPLOYMENT } from "../../../constants";

export const RunMetrics = () => {
  const { experimentName, runId } =
    useParams<{ projectName: string; experimentName: string; runId: string }>();

  return (
    <Metrics
      deploymentName={TRAINING_DEPLOYMENT}
      requestId={runId}
      versionName={experimentName}
    />
  );
};
