import { useTheme } from "@emotion/react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Chip, Tooltip } from "@mui/material";

import { spacing } from "assets/styles/theme";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

interface EmptyInfoProps {
  name: string;
  onDelete?: () => void;
  version?: string;
  disabled?: boolean;
  title?: string;
}

export const EntityChip = ({
  name,
  version,
  onDelete,
  disabled,
  title,
}: EmptyInfoProps) => {
  const theme = useTheme() as AppThemeProps;

  const label = version ? `${name} | ${version}` : name;

  return (
    <Tooltip title={disabled && title}>
      <Chip
        sx={{
          margin: spacing[4],
          background: disabled
            ? theme.palette.neutrals[200]
            : theme.palette.specials.purple.darkPastel,
          color: disabled
            ? theme.palette.primary.main
            : theme.palette.primary.contrastText,
        }}
        onDelete={disabled ? undefined : onDelete}
        deleteIcon={
          <CancelIcon
            style={{
              fill: theme.palette.primary.contrastText,
            }}
          />
        }
        label={label}
      />
    </Tooltip>
  );
};
