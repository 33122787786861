import { Typography, Box, useTheme } from "@mui/material";
import { useState } from "react";

import { getShadows, spacing } from "assets/styles/theme";
import { useDeviceDetect, useIsMounted } from "libs/hooks";
import {
  getTzAwareDate,
  DATE_TIME_HUMAN_READABLE,
} from "libs/utilities/date-util";

import { Loader, PrimaryButton, SecondaryButton } from "components/atoms";

import { PageHeaderExitDialog } from "./TopbarExitDialog";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { CSSProperties, FC } from "react";

export type PageHeaderProps = {
  pipelineName?: string;
  versionName?: string;
  errors?: string[];
  lastSaved?: string;
  onSave?: () => Promise<unknown>;
  onExit?: () => unknown | Promise<unknown>;
  style?: CSSProperties;
  className?: string;
};

export const PageHeader: FC<React.PropsWithChildren<PageHeaderProps>> = ({
  pipelineName,
  versionName,
  errors = [],
  lastSaved,
  onSave,
  onExit,
  style,
  className,
}) => {
  const isMounted = useIsMounted();
  const theme = useTheme() as AppThemeProps;
  const { isMobile } = useDeviceDetect();
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const tooltipTitle = `Unable to save pipeline because there ${
    errors.length === 1 ? `is an error` : `are ${errors.length} errors`
  }. Please fix the errors before saving`;

  return (
    <>
      <PageHeaderExitDialog
        isTouched={true}
        isOpen={isExitModalOpen}
        onClose={() => setIsExitModalOpen(false)}
        onExit={onExit}
      />

      <Box
        padding={isMobile ? `0 ${spacing[8]}` : `${spacing[12]} ${spacing[48]}`}
        boxShadow={getShadows(theme).primaryLight}
        height={spacing[64]}
        display="flex"
        alignItems="center"
        component="header"
        flexWrap="wrap"
        style={{
          background: theme.palette.background.paper,
          ...(style || {}),
        }}
        className={className}
      >
        <>
          <Typography variant="h3" style={{ display: "inline-block" }}>
            {`Editing pipeline`}
          </Typography>

          {pipelineName ? (
            <Typography
              variant="h3"
              color="secondary"
              display="inline"
              style={{ marginRight: spacing[4], marginLeft: spacing[4] }}
            >
              {`‘${pipelineName}’`}
            </Typography>
          ) : (
            <Loader
              display="inline-flex"
              size={spacing[24]}
              mx={spacing[8]}
              style={{
                verticalAlign: "middle",
                marginRight: spacing[4],
                marginLeft: spacing[4],
              }}
            />
          )}

          <Typography
            variant="h3"
            style={{
              display: "inline-block",
            }}
          >
            {`version`}
          </Typography>

          {versionName ? (
            <Typography
              variant="h3"
              color="secondary"
              display="inline"
              style={{ marginRight: spacing[32], marginLeft: spacing[4] }}
            >
              {versionName}
            </Typography>
          ) : (
            <Loader
              display="inline-flex"
              size={spacing[24]}
              mx={spacing[8]}
              style={{
                verticalAlign: "middle",
                marginRight: spacing[4],
                marginLeft: spacing[4],
              }}
            />
          )}
        </>

        <Box display="flex" alignItems="center" ml="auto">
          {!!lastSaved && !isMobile && (
            <>
              <Typography
                variant="h5"
                style={{ color: theme.palette.success.main }}
              >
                Last Saved: &nbsp;
              </Typography>
              <Typography
                variant="h5"
                sx={{ color: theme.palette.pipelineDiagram.dateSaved }}
              >
                {getTzAwareDate(lastSaved).format(DATE_TIME_HUMAN_READABLE)}
              </Typography>
            </>
          )}
          <Box ml={spacing[32]} mr={isMobile ? spacing[8] : spacing[32]}>
            <PrimaryButton
              disabled={isLoading || errors.length > 0}
              onClick={() => {
                if (errors.length > 0) return null;

                setIsLoading(true);

                return onSave?.().finally(() => {
                  if (isMounted) setIsLoading(false);
                });
              }}
              style={buttonStyles(isMobile)}
              tooltip={errors.length > 0 ? tooltipTitle : ""}
            >
              <Typography variant="h5">SAVE</Typography>
            </PrimaryButton>
          </Box>
          <Box>
            <SecondaryButton
              onClick={() => setIsExitModalOpen(true)}
              style={buttonStyles(isMobile)}
            >
              <Typography variant="h5">EXIT</Typography>
            </SecondaryButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const buttonStyles = (isMobile: boolean) => ({
  paddingRight: isMobile ? 0 : spacing[48],
  paddingLeft: isMobile ? 0 : spacing[48],
  paddingTop: spacing[8],
  paddingBottom: spacing[8],
});
