import EditIcon from "@mui/icons-material/Edit";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { capitalize } from "lodash";
import { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

import { getBorders, spacing } from "assets/styles/theme";
import { BucketCreateProvider } from "libs/data/models";
import { bucketAutoDeleteValues } from "libs/utilities/labels-mapping";
import { routes } from "routes";

import {
  SecondaryButton,
  Card,
  Icon,
  LabelChip,
  CardHeader,
  CardHeaderTitle,
  JsonPreview,
} from "components/atoms";

import { bucketNameIsDefault } from "../../utils";
import { Icons } from "../Bucket";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { BucketDetail, BucketDetailProvider } from "libs/data/models";

type ConfigurationProps = {
  bucket: BucketDetail | undefined;
};
export const Configuration = ({ bucket }: ConfigurationProps) => {
  const theme = useTheme() as AppThemeProps;
  const history = useHistory();
  const { organizationName, projectName, bucketName } =
    useParams<{
      organizationName: string;
      projectName: string;
      bucketName: string;
    }>();
  const baseUrl = routes.organizations[":organizationName"](organizationName)
    .projects[":projectName"](projectName)
    .storage[":bucketName"](bucketName);

  const data = useMemo(() => {
    const configurationDetails =
      bucket?.provider !== BucketCreateProvider.amazon_s3
        ? bucket?.configuration
        : { advanced_settings: JSON.stringify(bucket.configuration, null, 1) };

    return {
      name: bucket?.name,
      provider: bucket?.provider,
      ...configurationDetails,
      ttl: bucketAutoDeleteValues.find((item) => item.value === bucket?.ttl)
        ?.label,
      description: bucket?.description,
      labels: bucket?.labels,
    };
  }, [bucket]);

  const isDefaultBucket = bucketNameIsDefault(bucketName);

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader
          header={
            <>
              <CardHeaderTitle variant="h2">Configuration</CardHeaderTitle>
              <Box display="flex" marginLeft="auto">
                <SecondaryButton
                  startIcon={<EditIcon />}
                  onClick={() => history.push(baseUrl.edit.index())}
                  disabled={isDefaultBucket}
                >
                  Edit configuration
                </SecondaryButton>
              </Box>
            </>
          }
        />
        <Box padding={`${spacing[12]} 0`}>
          {Object.entries(data ?? {}).map(([key, value]) => (
            <Box
              key={key}
              display="flex"
              alignItems="center"
              gap={4}
              borderBottom={getBorders(theme).tertiary}
              minHeight={spacing[44]}
            >
              <Typography style={{ width: "30%" }} variant="h4">
                {key === "ttl"
                  ? "Auto delete files"
                  : capitalize(key).replace("_", " ")}
              </Typography>
              {key === "labels" ? (
                Object.entries(value ?? {}).map(([labelKey, labelValue]) => (
                  <Box key={labelKey} marginRight={spacing[8]}>
                    <LabelChip key={key} label={labelKey} value={labelValue} />
                  </Box>
                ))
              ) : key === "provider" ? (
                <Icon
                  component={
                    Icons[(value as BucketDetailProvider) ?? "default"]
                  }
                  style={{ height: 30, width: 30 }}
                />
              ) : key === "advanced_settings" ? (
                <JsonPreview value={value as string} />
              ) : (
                <Typography variant="body2">{value as string}</Typography>
              )}
            </Box>
          ))}
        </Box>
      </Card>
    </Grid>
  );
};
