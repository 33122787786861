import { useTheme } from "@mui/material";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

export const ScalingStrategyReserved = ({ fill }: { fill: string }) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <svg
      width="167"
      height="167"
      viewBox="0 0 167 167"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.40002"
        y="0.975006"
        width="164.75"
        height="164.75"
        stroke={theme.palette.charts.grid}
      />
      <path d="M0.900146 55.0635H166.65" stroke={theme.palette.charts.grid} />
      <path d="M0.900146 28.2648H166.65" stroke={theme.palette.charts.grid} />
      <path d="M0.900146 81.8612H166.65" stroke={theme.palette.charts.grid} />
      <path d="M0.900146 108.659H166.65" stroke={theme.palette.charts.grid} />
      <path d="M0.900146 135.457H166.65" stroke={theme.palette.charts.grid} />
      <path
        d="M28.7833 0.474915L28.7833 166.225"
        stroke={theme.palette.charts.grid}
      />
      <path
        d="M56.1499 0.474915L56.1499 166.225"
        stroke={theme.palette.charts.grid}
      />
      <path
        d="M83.5171 0.474915L83.5171 166.225"
        stroke={theme.palette.charts.grid}
      />
      <path
        d="M110.884 0.474915L110.884 166.225"
        stroke={theme.palette.charts.grid}
      />
      <path
        d="M138.251 0.474915L138.251 166.225"
        stroke={theme.palette.charts.grid}
      />
      <rect
        x="1.40002"
        y="0.975006"
        width="164.75"
        height="164.75"
        stroke={theme.palette.charts.grid}
      />
      <path d="M0.900146 55.0635H166.65" stroke={theme.palette.charts.grid} />
      <path d="M0.900146 28.2648H166.65" stroke={theme.palette.charts.grid} />
      <path d="M0.900146 81.8612H166.65" stroke={theme.palette.charts.grid} />
      <path d="M0.900146 108.659H166.65" stroke={theme.palette.charts.grid} />
      <path d="M0.900146 135.457H166.65" stroke={theme.palette.charts.grid} />
      <path
        d="M28.7833 0.474915L28.7833 166.225"
        stroke={theme.palette.charts.grid}
      />
      <path
        d="M56.1499 0.474915L56.1499 166.225"
        stroke={theme.palette.charts.grid}
      />
      <path
        d="M83.5171 0.474915L83.5171 166.225"
        stroke={theme.palette.charts.grid}
      />
      <path
        d="M110.884 0.474915L110.884 166.225"
        stroke={theme.palette.charts.grid}
      />
      <path
        d="M138.251 0.474915L138.251 166.225"
        stroke={theme.palette.charts.grid}
      />
      <path
        d="M28 162V121.526V103.744C28 100.982 30.2386 98.7438 33 98.7438H43.4003H92.2828C95.0442 98.7438 97.2828 96.5053 97.2828 93.7438V79.3254C97.2828 76.564 99.5213 74.3254 102.283 74.3254H125.473C128.234 74.3254 130.473 72.0868 130.473 69.3254V51C130.473 48.2386 132.711 46 135.473 46H163"
        stroke={theme.palette.primary.light}
        strokeWidth="6"
        strokeDasharray="12 4"
      />
      <path
        d="M3.5 163H30.5C32.7091 163 34.5 161.209 34.5 159V138C34.5 135.791 36.2909 134 38.5 134H84.5H133.491C135.7 134 137.491 132.209 137.491 130V112.5C137.491 110.291 139.282 108.5 141.491 108.5H166.5"
        stroke={fill}
        strokeWidth="6"
      />
    </svg>
  );
};
