import { Box, Grid, Typography } from "@mui/material";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useRouteMatch, useParams } from "react-router-dom";

import { useDeploymentsGet } from "libs/data/endpoints/deployments/deployments";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { useGoogleAnalytics } from "libs/hooks";
import { DATE_TIME_FORMAT, getTzAwareDate } from "libs/utilities/date-util";
import { formatLabels } from "libs/utilities/labels-util";
import { DeploymentVersionsOverview } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/DeploymentVersionsOverview";

import {
  DetailsItem,
  Card,
  OverflowTooltip,
  CopyToClipboardButton,
} from "components/atoms";
import { DescriptionBlock, EndpointUrlCopy } from "components/molecules";

import type { DeploymentDetailsRouteParams } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/types";

export const DeploymentGeneral = () => {
  useGoogleAnalytics();

  const match = useRouteMatch();
  const { projectName, deploymentName } =
    useParams<DeploymentDetailsRouteParams>();

  const { data: deployment, error } = useDeploymentsGet(
    projectName,
    deploymentName
  );

  const endpointUrl = `${env.get(
    ENV_NAMES.HOST
  )}/projects/${projectName}/deployments/${deploymentName}/requests`;

  return (
    <>
      <BreadcrumbsItem to={match.url}>General</BreadcrumbsItem>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card>
            <DetailsItem title="ID">
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <OverflowTooltip title={deployment?.id}>
                  {deployment?.id}
                </OverflowTooltip>
                {deployment?.id && (
                  <CopyToClipboardButton
                    defaultLabel="Copy ID"
                    contentToCopy={deployment?.id}
                    size="small"
                    htmlColor="secondary"
                  />
                )}
              </Box>
            </DetailsItem>
            <DetailsItem title="Created">
              <Typography>
                {getTzAwareDate(deployment?.creation_date).format(
                  DATE_TIME_FORMAT
                )}
              </Typography>
            </DetailsItem>
            <DetailsItem title="Edited">
              <Typography>
                {getTzAwareDate(deployment?.last_updated).format(
                  DATE_TIME_FORMAT
                )}
              </Typography>
            </DetailsItem>
            <EndpointUrlCopy url={endpointUrl} />
          </Card>
        </Grid>
        <DescriptionBlock
          description={deployment?.description}
          labels={formatLabels(deployment?.labels ?? {})}
        />
        <Grid item xs={12}>
          {(deployment || error) && <DeploymentVersionsOverview />}
        </Grid>
      </Grid>
    </>
  );
};
