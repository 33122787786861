import Equalizer from "@mui/icons-material/Equalizer";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { Box, Grid, Typography, Tooltip, useTheme } from "@mui/material";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
// eslint-disable-next-line import/no-unassigned-import
import "chart.js/auto";
import { Bar } from "react-chartjs-2";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { EngineeringTeamIllustration } from "assets/images/EngineeringTeam";
import { spacing } from "assets/styles/theme";
import { useGetSpecialColors } from "assets/styles/theme/utils/chartColors";
import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { SubscribeButton } from "components/molecules/SubscribeButton/SubscribeButton";
import {
  FIELD_CREDIT_LIMIT,
  FIELD_CREDIT_LIMIT_ENABLED,
  FIELD_PROJECT,
} from "libs/constants/fields";
import { useProjectUpdate } from "libs/data/customized/projects";
import { useOrganizationsUsageGet } from "libs/data/endpoints/organizations/organizations";
import { useGoogleAnalytics } from "libs/hooks";
import { downloadFile } from "libs/utilities/download-helper";
import { explanations } from "libs/utilities/explanations";
import {
  getBarChartOptions,
  getCompute,
  getBarChartDatasetOptions,
  extractDistinctDatesFromMetrics,
  mapValuesToDates,
  roundAccurately,
} from "libs/utilities/metrics-helper";
import { getRandomId } from "libs/utilities/utils";
import {
  setProject,
  useGetActiveSubscription,
  useGetCurrentOrganization,
  useGetCurrentProject,
  useGetOrganizationFeatures,
  useGetOrganizationResources,
  useGetProjects,
} from "store/features";

import {
  InfoTooltip,
  PrimaryButton,
  Dialog,
  DialogHeaderTitle,
  DialogDescription,
  Loader,
  TextButton,
  DetailsDialogItem,
} from "components/atoms";
import {
  ComputeUsageProgressBar,
  MetricGraph,
  ProjectComputeLimitSliderBar,
} from "components/molecules";

import { limits, resources } from "./constants";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type { DateRange } from "components/molecules/Picker/types";
import type {
  OrganizationProjectUsage,
  ProjectDetail,
  TimeSeriesDataPointList,
} from "libs/data/models";

const getTopTenChartId = (
  dataArray: OrganizationProjectUsage[] | undefined
) => {
  if (!dataArray?.length) return [];

  const calculatedSums = dataArray.map((item) => {
    const value = item.data.reduce((sum, val) => sum + (val.value || 0), 0);

    return { id: item.project_id, value: value };
  });

  calculatedSums.sort((a, b) => b.value - a.value);
  const top10 = calculatedSums.slice(0, 10).map((item) => item.id);

  return top10;
};

const OrganizationUsage = () => {
  useGoogleAnalytics();
  const dispatch = useDispatch();
  const theme = useTheme() as AppThemeProps;
  const chartColors = useGetSpecialColors();

  const { organizationName } = useParams<{ organizationName: string }>();
  const currentOrganization = useGetCurrentOrganization();
  const organizationFeatures = useGetOrganizationFeatures();
  const organizationResources = useGetOrganizationResources();
  const currentSubscription = useGetActiveSubscription();
  const projects = useGetProjects();
  const currentProjectName = useGetCurrentProject()?.name;

  const [isLoading, setIsLoading] = useState(false);
  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] =
    useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [chartDates, setChartDates] = useState<DateRange | null>(null);
  const [isLimitsDialogOpen, setIsLimitsDialogOpen] = useState(false);

  const { data: organizationUsage, mutate } = useOrganizationsUsageGet(
    organizationName,
    chartDates
      ? {
          start_date: chartDates?.start_date.toISOString(),
          end_date: chartDates?.end_date.toISOString(),
          interval:
            chartDates?.end_date.diff(chartDates?.start_date, "M") > 1
              ? "month"
              : "day",
        }
      : { interval: "day" },
    {
      swr: {
        dedupingInterval: 60 * 60,
      },
    }
  );

  const computeFormMethods = useForm({
    mode: "onBlur",
  });
  const { handleSubmit } = computeFormMethods;

  const updateProject = useProjectUpdate(organizationName);

  const dialogDescriptionRef = useRef();

  const dailyUsageGraph = useMemo(() => {
    if (organizationUsage) {
      const firstDataTime = moment(
        organizationUsage.data_organization?.[0]?.start_date
      );
      const lastDataTime = moment(
        organizationUsage.data_organization?.[
          organizationUsage.data_organization.length - 1
        ]?.end_date
      );

      const projectsUsage = organizationUsage.data_deleted_projects.length
        ? [
            ...(organizationUsage?.data_projects || []),
            {
              project_name: "deleted projects",
              data: organizationUsage?.data_deleted_projects,
              project_id: getRandomId(),
            },
          ]
        : organizationUsage.data_projects;

      const datasets = projectsUsage ?? [];
      const topTenIds = getTopTenChartId(datasets);
      const otherDatasets = datasets?.filter(
        (dataset) => !topTenIds?.includes(dataset.project_id)
      );
      const otherData: TimeSeriesDataPointList[] = datasets[0]?.data?.map?.(
        (data, i) => ({
          ...data,
          value: otherDatasets?.reduce(
            (acc, curr) => curr.data?.[i]?.value ?? 0 + acc,
            0
          ),
        })
      );

      const reducedDatasets =
        topTenIds?.length < 10
          ? datasets
          : [
              ...datasets.filter((data) => topTenIds.includes(data.project_id)),
              {
                project_id: "others",
                project_name: "Others",
                data: otherData,
              },
            ];

      const labels = extractDistinctDatesFromMetrics(
        projectsUsage.map(({ data }) => data)
      );

      return {
        data: {
          labels,
          datasets: reducedDatasets?.map((usage, idx) => ({
            data: mapValuesToDates(usage?.data, labels).map((value) =>
              roundAccurately(value || 0, 2)
            ),
            id: usage.project_name,
            ...getBarChartDatasetOptions({
              label: usage?.project_name,
              color: chartColors[idx],
              theme,
            }),
          })),
        },
        options: getBarChartOptions({
          name: "Credit usage (Units)",
          stacked: true,
          crosshair: true,
          precision: 0,
          timeUnit:
            (lastDataTime.diff(firstDataTime, "M") || 0) > 1 ? "month" : "day",
          theme,
          startDate: firstDataTime
            .subtract(1, "day")
            .format("YYYY-MM-DDTHH:mm:ss"),
          endDate: lastDataTime
            .subtract(1, "day")
            .format("YYYY-MM-DDTHH:mm:ss"),
        }),
        title: "Credit usage (Units)",
        chartComponent: Bar,
        defaultDates: { start_date: firstDataTime, end_date: lastDataTime },
      };
    }

    return null;
  }, [organizationUsage, chartColors, theme]);

  useEffect(() => {
    if (currentProjectName) {
      setIsLoading(false);
    }
  }, [currentProjectName]);

  const onComputeLimitUpdate = (data: {
    [x: string]: number | null | undefined;
  }) => {
    updateProject(currentProjectName ?? "", {
      [FIELD_CREDIT_LIMIT]: data[FIELD_CREDIT_LIMIT_ENABLED]
        ? data[FIELD_CREDIT_LIMIT]
        : null,
    });
    setDialogOpen(false);
  };

  const handleProjectClick = (project: AutocompleteSelectOption) => {
    setIsLoading(true);
    const selectedProject = projects?.find(
      ({ name }) => name === project.value
    );
    dispatch(setProject(selectedProject as ProjectDetail));
  };

  const projectOptions = useMemo(
    () =>
      (projects || []).map(({ name }) => ({
        value: name,
        label: name,
      })),
    [projects]
  );

  return (
    <>
      <Grid item container spacing={3} padding={spacing[4]}>
        <Grid item xs={12} sm={5} md={4}>
          <ComputeUsageProgressBar
            usage={getCompute(organizationUsage?.data_organization ?? [])}
            maxUsage={organizationFeatures?.max_credits}
          />
          {resources
            .filter(
              (resource) =>
                !(
                  organizationFeatures?.resource_gpu === undefined &&
                  resource.label === "GPU"
                )
            )
            .map(({ label, resource, limit }) => {
              const resourceUsage =
                organizationResources?.[
                  resource as keyof typeof organizationResources
                ];
              const featureLimit =
                organizationFeatures?.[
                  limit as keyof typeof organizationFeatures
                ];
              const isOverLimit =
                resourceUsage &&
                typeof featureLimit === "number" &&
                resourceUsage >= featureLimit;

              return (
                <Box
                  width="100%"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1}
                  key={resource}
                >
                  <Box display="flex" alignItems="center">
                    <Typography variant="h5">{label}</Typography>
                  </Box>
                  <Box display="flex" alignItems="center">
                    {isOverLimit ? (
                      <Tooltip
                        title={explanations.subscription.resourceExceeded(
                          label
                        )}
                      >
                        <ErrorRoundedIcon
                          fontSize="small"
                          color="secondary"
                          onClick={() => {
                            setIsSubscriptionDialogOpen(true);
                            // @ts-expect-error current is undefined
                            dialogDescriptionRef.current =
                              explanations.subscription.resourceExceeded(label);
                          }}
                        />
                      </Tooltip>
                    ) : null}
                    <Typography variant="h5">
                      <Box component="span" ml={1}>
                        {resource === "resource_gpu"
                          ? organizationFeatures?.[resource]
                            ? "Enabled"
                            : "Disabled"
                          : organizationResources?.[
                              resource as keyof typeof organizationResources
                            ]}
                        {organizationFeatures?.[
                          limit as keyof typeof organizationFeatures
                        ]
                          ? ` / ${
                              organizationFeatures[
                                limit as keyof typeof organizationFeatures
                              ]
                            }`
                          : null}
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          <TextButton
            color="secondary"
            style={{
              textTransform: "none",
              padding: 0,
              textDecoration: "underline",
            }}
            onClick={() => setIsLimitsDialogOpen(true)}
          >
            See all limits
          </TextButton>
          {projects?.length ? (
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" mt={2}>
                <PrimaryButton
                  size="small"
                  startIcon={<Equalizer />}
                  onClick={() => setDialogOpen(true)}
                >
                  Set project credit limits
                </PrimaryButton>
                <InfoTooltip>{explanations.projects.computeLimit}</InfoTooltip>
              </Box>
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={7} md={8}>
          {dailyUsageGraph && (
            <Grid container spacing={2}>
              <MetricGraph
                chartDates={chartDates}
                setChartDates={setChartDates}
                onRefresh={mutate}
                fullPageWidth={true}
                height="369px"
                withDatePicker
                onDownloadData={() => {
                  downloadFile(
                    JSON.stringify(
                      organizationUsage?.data_projects || {},
                      null,
                      2
                    ),
                    "credit_usage.json"
                  );
                }}
                {...dailyUsageGraph}
              />
            </Grid>
          )}
        </Grid>
        <FormProvider {...computeFormMethods}>
          <Dialog
            Actions={
              <PrimaryButton
                onClick={handleSubmit(onComputeLimitUpdate)}
                disabled={!currentProjectName}
              >
                Update
              </PrimaryButton>
            }
            onClose={() => setDialogOpen(false)}
            open={dialogOpen}
            title="Changing credit limit"
          >
            <AutoCompleteSelectHookForm
              name={FIELD_PROJECT}
              placeholder={"Select a project"}
              options={projectOptions}
              onChange={handleProjectClick}
            />
            {!!currentProjectName && (
              <Box mt={2}>
                {isLoading ? <Loader /> : <ProjectComputeLimitSliderBar />}
              </Box>
            )}
          </Dialog>
        </FormProvider>
      </Grid>
      <Dialog
        open={isSubscriptionDialogOpen}
        onClose={() => setIsSubscriptionDialogOpen(false)}
        Header={
          <DialogHeaderTitle
            style={{
              marginTop: spacing[32],
              textAlign: "center",
            }}
          >
            You&apos;ve reached your subscription limit
          </DialogHeaderTitle>
        }
        Actions={
          <Box display="flex" justifyContent="center">
            <SubscribeButton organization={currentOrganization ?? undefined} />
          </Box>
        }
      >
        <Box
          component={EngineeringTeamIllustration}
          display="flex"
          maxWidth="260px"
          margin="10px auto"
        />
        <DialogDescription style={{ margin: "10px 0" }}>
          {dialogDescriptionRef.current}
        </DialogDescription>
      </Dialog>
      <Dialog
        open={isLimitsDialogOpen}
        onClose={() => setIsLimitsDialogOpen(false)}
        title="All limits"
        maxWidth="md"
      >
        <Box width="100%" display="flex" flexDirection="column" rowGap={2}>
          {organizationFeatures &&
            currentSubscription?.subscription &&
            limits(organizationFeatures, currentSubscription?.subscription).map(
              ({ title, value }) => (
                <DetailsDialogItem title={title} key={title}>
                  {value}
                </DetailsDialogItem>
              )
            )}
        </Box>
      </Dialog>
    </>
  );
};

export default OrganizationUsage;
