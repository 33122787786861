import ClearIcon from "@mui/icons-material/Clear";
import DownloadIcon from "@mui/icons-material/GetApp";
import Search from "@mui/icons-material/Search";
import {
  Grid,
  Box,
  Tooltip,
  IconButton,
  TextField,
  InputAdornment,
  useTheme,
} from "@mui/material";
import { useState } from "react";

import { spacing } from "assets/styles/theme";
import { useDeviceDetect } from "libs/hooks";
import { isArrayHasNoData } from "libs/utilities/utils";

import { PrimaryButton, RefreshButton } from "components/atoms";

import { RequestsBulkCancelDialog } from "./RequestsBulkCancelDialog";
import { RequestsFilter } from "./RequestsFilter";

import type { RequestsRows } from "./types";
import type { AppThemeProps } from "assets/styles/theme/theme.d";

export interface Filter {
  status: string;
  start_date?: string;
  end_date?: string;
  object_type?: "deployment" | "pipeline";
}

interface RequestsToolbarProps {
  selectedFilters: Filter;
  setSelectedFilters: (filter: Filter) => void;
  setSearch: (search: string | undefined) => void;
  search: string | undefined;
  requests: RequestsRows | undefined;
  openDownloadDialog: () => void;
  refresh: () => void;
  isProjectLevel?: boolean;
}

export const RequestsToolbar = ({
  selectedFilters,
  setSelectedFilters,
  requests,
  openDownloadDialog,
  setSearch,
  search,
  refresh,
  isProjectLevel = false,
}: RequestsToolbarProps) => {
  const theme = useTheme() as AppThemeProps;
  const { isMobile } = useDeviceDetect();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const isBulkCancelEnabled =
    !isProjectLevel &&
    selectedFilters.status &&
    ["pending", "processing"].includes(selectedFilters.status as string);

  return (
    <Grid container alignItems="flex-start" flexWrap="wrap">
      <Grid item xs={12} container>
        <RequestsFilter
          setFilters={setSelectedFilters}
          defaultValues={selectedFilters}
          isProjectLevel={isProjectLevel}
        >
          <Box ml={1} alignItems="flex-start">
            <RefreshButton onClick={refresh} tooltip="Refresh table" />
            <Tooltip title="Download requests">
              <span>
                <IconButton
                  color="primary"
                  disabled={isArrayHasNoData(requests || [])}
                  onClick={openDownloadDialog}
                >
                  <DownloadIcon sx={{ color: theme.palette.text.primary }} />
                </IconButton>
              </span>
            </Tooltip>{" "}
          </Box>
        </RequestsFilter>
      </Grid>
      <Grid
        display="flex"
        justifyContent={isBulkCancelEnabled ? "space-between" : "flex-end"}
        paddingTop={spacing[20]}
        marginBottom={isBulkCancelEnabled ? spacing[28] : 0}
        item
        xs={12}
      >
        {isBulkCancelEnabled && (
          <PrimaryButton
            disabled={!requests?.length}
            onClick={() => setDialogOpen(true)}
            size="small"
            tooltip={
              !requests?.length &&
              `No ${selectedFilters.status} requests to cancel`
            }
          >
            Cancel all {selectedFilters.status}
          </PrimaryButton>
        )}
        <TextField
          value={search || ""}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: theme.palette.text.secondary }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setSearch(undefined)}>
                  <ClearIcon sx={{ color: theme.palette.text.secondary }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          placeholder="Search"
          id="standard-basic"
          sx={{
            width: isMobile ? 170 : 220,
          }}
          variant="standard"
        />
      </Grid>
      <RequestsBulkCancelDialog
        isOpen={isDialogOpen}
        onClose={() => {
          setDialogOpen(false);
          refresh();
        }}
        onSuccess={refresh}
        requestIds={requests?.map(({ id }) => id)}
        status={selectedFilters.status as string}
      />
    </Grid>
  );
};
