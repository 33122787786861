import { Typography } from "@mui/material";

import { Loader } from "components/atoms";

import { DeploymentRequestDetails } from "./DeploymentRequestDetails";
import { PipelineRequestDetails } from "./PipelineRequestDetails";
import { useRequestResults } from "./useRequestResults";

import type { DeploymentRequestDetailsType } from "./DeploymentRequestDetails";
import type { PipelineResults } from "./PipelineRequestDetails";
import type {
  DeploymentRequestCreateResponseResult,
  InputOutputFieldDetail,
  PipelineRequestCreateResponseResult,
} from "libs/data/models";

export type RequestDetailsProps = {
  organizationName: string;
  projectName: string;
  resourceType?: "deployment" | "pipeline";
  resourceName?: string;
  resourceVersion?: string | null;
  requestId?: string;
  directResult?:
    | DeploymentRequestCreateResponseResult
    | PipelineRequestCreateResponseResult;
  fetchOnlyMetadata?: boolean;
};

export const RequestDetails = ({
  resourceType,
  projectName,
  organizationName,
  resourceName,
  resourceVersion,
  requestId,
  directResult,
  fetchOnlyMetadata = true,
}: RequestDetailsProps) => {
  const { data, resourceData, handleViewHugeResult } = useRequestResults({
    resourceName,
    resourceType,
    resourceVersion,
    requestId,
    directResult,
    fetchOnlyMetadata,
    organizationName,
    projectName,
  });

  if (resourceName === null || resourceVersion === null) {
    return (
      <Typography variant="body1" color="textPrimary">
        This {resourceType || "deployment/pipeline"} has been deleted and the
        object version fields are no longer available.
      </Typography>
    );
  }

  if (!data || !resourceData?.input_fields || !resourceData?.output_fields) {
    return <Loader />;
  }

  if (resourceType === "deployment") {
    return (
      <DeploymentRequestDetails
        organizationName={organizationName}
        projectName={projectName}
        results={data as DeploymentRequestDetailsType}
        inputFields={resourceData?.input_fields as InputOutputFieldDetail[]}
        outputFields={resourceData?.output_fields as InputOutputFieldDetail[]}
        handleViewHugeResult={handleViewHugeResult}
      />
    );
  }

  if (resourceType === "pipeline") {
    return (
      <PipelineRequestDetails
        organizationName={organizationName}
        projectName={projectName}
        results={data as PipelineResults}
        inputFields={resourceData?.input_fields as InputOutputFieldDetail[]}
        outputFields={resourceData?.output_fields as InputOutputFieldDetail[]}
        handleViewHugeResult={handleViewHugeResult}
      />
    );
  }

  return null;
};
