import { Box, useTheme } from "@mui/material";

import { ReactComponent as WarningIcon } from "assets/images/warning.svg";
import { getBorders } from "assets/styles/theme";
import { DialogHeaderTitle } from "components/atoms/Dialog/DialogHeaderTitle";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

interface DialogWarningHeaderProps {
  title: string;
}

export const DialogWarningHeader = ({ title }: DialogWarningHeaderProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Box
      alignItems="center"
      borderBottom={getBorders(theme).primary}
      display="flex"
      justifyContent="center"
      paddingTop={2}
      paddingBottom={1}
    >
      <WarningIcon style={{ marginRight: 10 }} />
      <DialogHeaderTitle>{title}</DialogHeaderTitle>
    </Box>
  );
};
