import { useParams } from "react-router-dom";

import {
  DEPLOYMENT_PERMISSIONS,
  PIPELINE_PERMISSIONS,
  PROJECT_PERMISSIONS,
} from "libs/constants/permissions";
import { useGoogleAnalytics, useLogsUrl } from "libs/hooks";
import { routes } from "routes";
import { useGetPermissions } from "store/features/permissions";

import { Requests } from "components/organisms";

export const MonitoringRequestsPage = () => {
  useGoogleAnalytics();

  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();

  const [permissions] = useGetPermissions();

  const logsUrl = useLogsUrl({
    redirectUrl: routes.organizations[":organizationName"](organizationName)
      .projects[":projectName"](projectName)
      .index(),
  });

  return (
    <Requests
      logsUrl={logsUrl}
      allowLogs={permissions[PROJECT_PERMISSIONS["logs_get"]]}
      allowGet={
        permissions[DEPLOYMENT_PERMISSIONS["version_request_get"]] ||
        permissions[PIPELINE_PERMISSIONS["version_request_get"]]
      }
      allowCreate={
        permissions[DEPLOYMENT_PERMISSIONS["version_request_create"]] ||
        permissions[PIPELINE_PERMISSIONS["version_request_create"]]
      }
      allowDelete={
        permissions[DEPLOYMENT_PERMISSIONS["version_request_delete"]] ||
        permissions[PIPELINE_PERMISSIONS["version_request_delete"]]
      }
      requestParameters={{
        type: "deployment",
      }}
      allowUpdate={
        permissions[DEPLOYMENT_PERMISSIONS["version_request_update"]]
      }
      tableOptions={{
        withoutMultiSelect: true,
        withoutCancelButton: false,
      }}
      displayDeploymentName={true}
      isProjectLevel={true}
      hasRetryButton={false}
    />
  );
};
