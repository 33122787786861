import Plus from "@mui/icons-material/AddBoxRounded";
import { Box, Typography, Grid } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router-dom";

import { CurvedArrow } from "assets/images/CurvedArrow";
import { IlluGetResults } from "assets/images/IlluGetResults";
import { IlluGirl } from "assets/images/IlluGirl";
import { IlluPipeline } from "assets/images/IlluPipeline";
import { IlluPipelineMobile } from "assets/images/IlluPipelineMobile";
import { spacing } from "assets/styles/theme";
import { DOC_LINKS } from "libs/constants/documentation-links";
import { useDeviceDetect } from "libs/hooks";

import { ExternalLink, PrimaryButton, SecondaryButton } from "components/atoms";

const PipelineEmptyOverview = ({ baseUrl, permission }) => {
  const history = useHistory();
  const { isMobile, isTablet } = useDeviceDetect();

  const contentList = [
    {
      Icon: IlluGirl,
      title: "Create a pipeline",
      subtitle: "Give it a name and specify the input of the pipeline",
    },
    {
      Icon: isTablet ? IlluPipelineMobile : IlluPipeline,
      title: "Add & Connect deployments",
      subtitle: "Create workflows by connecting deployments to one another",
    },
    {
      Icon: IlluGetResults,
      title: "Make requests",
      subtitle: "Make requests to your pipelines",
    },
  ];

  return (
    <Grid
      container
      item
      xs={12}
      spacing={4}
      gap={2}
      display="flex"
      flexDirection="row"
      alignItems="center"
      margin="auto"
    >
      <Grid item xs={12}>
        <Typography align="center" variant="h2">
          Create your first pipeline!
        </Typography>
      </Grid>
      <Grid item xs={12} md={5} margin="auto">
        <Typography align="center" variant="body1">
          With pipelines you can build workflows around your deployments.
          Pipelines can receive requests just like deployments. UbiOps will
          manage the data flow. Use pipelines to create A/B tests for instance!
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
        rowGap={4}
      >
        {contentList.map(({ Icon, title, subtitle, link, textLink }, key) => (
          <Grid
            item
            container
            spacing={2}
            key={key}
            display="flex"
            flexWrap="nowrap"
            xs={12}
            md={key === 1 ? 8 : 2}
          >
            {!isTablet && key === 1 && (
              <Box
                display="flex"
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
              >
                <CurvedArrow />
              </Box>
            )}
            <Grid
              key={key}
              item
              xs
              container
              spacing={2}
              display="flex"
              direction="column"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item>
                <Icon />
              </Grid>
              <Grid item>
                <Typography variant="h3">{title}</Typography>
              </Grid>
              <Grid item>
                <Typography align="center" variant="body1">
                  {subtitle}{" "}
                  {link && (
                    <ExternalLink href={link} mr={1}>
                      {textLink}
                    </ExternalLink>
                  )}
                </Typography>
              </Grid>
            </Grid>
            {!isTablet && key === 1 && (
              <Box
                display="flex"
                alignSelf="center"
                alignItems="center"
                justifyContent="center"
              >
                <CurvedArrow />
              </Box>
            )}
          </Grid>
        ))}
      </Grid>
      <Grid
        item
        xs={12}
        container
        spacing={2}
        style={{
          marginTop: !isMobile && spacing[20],
        }}
        display="flex"
        direction="column"
        alignItems="center"
      >
        <Grid item xs={12}>
          {!!permission && (
            <PrimaryButton
              startIcon={<Plus />}
              onClick={() => history.push(`${baseUrl}/create`)}
            >
              Create your first pipeline
            </PrimaryButton>
          )}
        </Grid>
        <Grid item xs={12}>
          <SecondaryButton
            href={DOC_LINKS.STARTER_TUTORIAL_PIPELINE}
            target="_blank"
          >
            Starter tutorial
          </SecondaryButton>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={1} display="flex" alignItems="center">
            <Grid item>
              <Typography display="inline">Need some inspiration?</Typography>
            </Grid>
            <Grid item>
              <ExternalLink href={DOC_LINKS.TUTORIALS} mr={1}>
                Check our tutorials
              </ExternalLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

PipelineEmptyOverview.propTypes = {
  baseUrl: PropTypes.string,
  permission: PropTypes.bool,
};

export default PipelineEmptyOverview;
