import {
  Link,
  CssBaseline,
  Typography,
  Card,
  CardContent,
  Box,
  Avatar,
  useTheme,
} from "@mui/material";
import "./AuthenticationContainer.scss";
import { Helmet } from "react-helmet";

import { LINK_COPYRIGHT } from "libs/constants/documentation-links";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

type AuthenticationContainerProps = {
  title?: string;
  description?: string;
  info?: React.ReactElement;
  children?: React.ReactNode;
};

const AuthenticationContainer = ({
  title,
  description,
  info,
  children,
}: AuthenticationContainerProps) => {
  const theme = useTheme() as AppThemeProps;

  const Logo = theme.logoVertical;

  return (
    <Box
      className="authentication-container"
      sx={{ backgroundColor: theme.palette.background.auth }}
    >
      <Helmet>
        <title>UbiOps | {title}</title>
      </Helmet>
      <CssBaseline />
      <Box className="authentication-container__header">
        <Link href={LINK_COPYRIGHT}>
          <Avatar
            className="authentication-container__logo"
            alt="Ubiops logo"
            variant="square"
            sx={{ backgroundColor: "transparent" }}
          >
            <Logo />
          </Avatar>
        </Link>
        {title && (
          <Typography variant="h2" align="center" gutterBottom>
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="subtitle1" align="center">
            {description}
          </Typography>
        )}
      </Box>
      {info && <Box className="authentication-container__info">{info}</Box>}
      <Card
        variant="outlined"
        className="authentication-container__content"
        sx={{
          backgroundColor: theme.palette.background.default,
        }}
      >
        <CardContent>{children}</CardContent>
      </Card>
    </Box>
  );
};

export default AuthenticationContainer;
