import styled from "@emotion/styled";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Box, useTheme } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { Calendar } from "react-date-range";
import { useFormContext } from "react-hook-form";

import { spacing } from "assets/styles/theme";
import { Dialog } from "components/atoms/Dialog";
import { DATE_FORMAT, getTzAwareDate } from "libs/utilities/date-util";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { PrimaryButton, SecondaryButton } from "components/atoms";

import { DateChip } from "./DateChip";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { CalendarProps } from "react-date-range";

export interface DatePickerProps extends CalendarProps {
  name: string;
}
export const DatePicker = ({
  onChange,
  name,
  minDate,
  date,
}: Omit<DatePickerProps, "onChange"> & {
  onChange: (args: Date | null) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const { register, unregister } = useFormContext();
  const defaultDate = selectedDate || date;
  const theme = useTheme() as AppThemeProps;
  useEffect(() => {
    register({ name });

    return () => unregister(name);
  }, [register, unregister, name]);

  const handleSubmit = () => {
    setOpen(false);
    if (selectedDate) onChange(selectedDate);
  };

  const handleChipDelete = () => {
    setSelectedDate(null);
    onChange(null);
  };

  return (
    <>
      <input hidden name={name} ref={register} />
      <Box display="flex" alignItems="center" gap={spacing[8]} width={"100%"}>
        <SecondaryButton
          onClick={() => setOpen(true)}
          startIcon={<DateRangeIcon />}
          style={{ flexShrink: 0 }}
        >
          Select date
        </SecondaryButton>
        {defaultDate && (
          <DateChip
            dates={moment(defaultDate).format(DATE_FORMAT)}
            handleClose={handleChipDelete}
          />
        )}
      </Box>
      <Dialog
        open={open}
        fullWidth={false}
        onClose={() => setOpen(false)}
        id={"date-picker-dialog"}
        Actions={
          <PrimaryButton onClick={() => handleSubmit()}>Apply</PrimaryButton>
        }
      >
        <Box
          display="flex"
          justifyContent="center"
          marginX={4}
          sx={{ background: "transparent" }}
        >
          <StyledCalendar
            theme={theme}
            onChange={(date) => setSelectedDate(date)}
            minDate={minDate && getTzAwareDate().toDate()}
            date={defaultDate}
          />
        </Box>
      </Dialog>
    </>
  );
};

const StyledCalendar = styled(Calendar)<{ theme: AppThemeProps }>`
  background-color: ${(props) =>
    props.theme.palette.background.paper} !important;

  .rdrDays {
    .rdrDayDisabled {
      background-color: ${(props) =>
        props.theme.palette.background.paper} !important;
      span {
        color: ${(props) => props.theme.palette.text.disabled};
      }
    }
  }
  .rdrDay {
    span {
      color: ${(props) => props.theme.palette.text.primary};
    }
  }

  .rdrDefinedRangesWrapper {
    border-right: solid 1px ${(props) => props.theme.palette.background.active};
  }

  .rdrMonthAndYearWrapper {
    select {
      color: ${(props) => props.theme.palette.text.primary};
      svg {
        color: ${(props) => props.theme.palette.text.primary};
      }
    }
  }

  .rdrNextPrevButton {
    background: ${(props) => props.theme.palette.background.active};
    color: ${(props) => props.theme.palette.text.primary};
    i {
      color: ${(props) => props.theme.palette.text.primary};
    }
  }
`;
