import { Link, useTheme } from "@mui/material";
import { forwardRef } from "react";

import NavLink from "../NavLink/NavLink";

import type { LinkProps } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { Ref } from "react";

interface TableLinkProps {
  to: string;
  variant?: "regular" | "bold";
}

export const TableLink = forwardRef(function CustomLink(
  {
    children,
    variant = "regular",
    color = "primary",
    underline = "none",
    to,
    ...props
  }: TableLinkProps & Omit<LinkProps, "innerRef" | "variant">,
  ref: Ref<HTMLAnchorElement>
) {
  const linkVariant = variant === "bold" ? "h5" : "subtitle2";
  const theme = useTheme() as AppThemeProps;

  return (
    // @ts-ignore
    <Link
      component={NavLink}
      innerRef={ref}
      color={color}
      variant={linkVariant}
      underline={underline}
      to={to}
      className={"TableLink"}
      sx={{
        ":hover": {
          color: theme.palette.secondary.main,
          textDecoration: underline,
        },
        ":active": { color: theme.palette.secondary.main },
      }}
      {...props}
    >
      {children}
    </Link>
  );
});
