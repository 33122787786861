import styled from "@emotion/styled";
import ArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

import { borderRadius, getShadows, spacing } from "assets/styles/theme";
import { useStringToColor } from "assets/styles/theme/utils/useStringToColor";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

type ProjectBoxProps = {
  item: { name: string };
  onClick: () => void;
  link: string;
};

export const ProjectBox = ({ item, onClick, link }: ProjectBoxProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Link to={link}>
      <Box
        position="relative"
        width="14rem"
        height={spacing[144]}
        display="flex"
        flex-direction="column"
        className="cursor-pointer"
      >
        <Project onClick={() => onClick()} color={useStringToColor(item.name)}>
          <Box
            width={"100%"}
            padding={spacing[8]}
            alignSelf="flex-start"
            style={{ flex: 1 }}
          ></Box>

          <Details
            backgroundColor={theme.palette.primary.main}
            color={theme.palette.primary.contrastText}
            boxShadow={getShadows(theme).primary}
          >
            <Typography id="project-box-title" variant="h5" className="name">
              {item.name}
            </Typography>
            <ArrowRightIcon />
          </Details>
        </Project>
      </Box>
    </Link>
  );
};

const Project = styled(Box)<{ color: string }>`
  background-color: ${(props) => props.color};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  border-radius: ${borderRadius[10]};
  margin-top: -${spacing[12]};
  align-items: center;
  z-index: 20;
  align-self: flex-end;
  position: absolute;
  top: ${spacing[12]};
  :hover {
    filter: brightness(70%);
  }
`;

const Details = styled.div<{
  color: string;
  backgroundColor: string;
  boxShadow: string;
}>`
  width: 100%;
  height: 35%;
  background-color: ${(props) => props.backgroundColor};
  border-bottom-right: 8px;
  color: ${(props) => props.color};
  border-bottom-right-radius: ${borderRadius[10]};
  border-bottom-left-radius: ${borderRadius[10]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7em;
  box-shadow: ${(props) => props.boxShadow};
  &name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: -webkit-fill-available;
  }
`;
