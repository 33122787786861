/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * Programmatic interface to UbiOps
 * OpenAPI spec version: v2.1
 */

export type WebhookTestDetailStatus =
  typeof WebhookTestDetailStatus[keyof typeof WebhookTestDetailStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WebhookTestDetailStatus = {
  pending: "pending",
  processing: "processing",
  completed: "completed",
  failed: "failed",
  cancelling: "cancelling",
  cancelled: "cancelled",
} as const;
