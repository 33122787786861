// @ts-nocheck

import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { PageHeader } from "components/molecules/PageLayout";
import { FIELD_WEBHOOK_NAME } from "libs/constants/fields";
import { useWebHookUpdate } from "libs/data/customized/webhooks";
import { useWebhooksGet } from "libs/data/endpoints/webhooks/webhooks";
import { routes } from "routes";

import { FormWrapper } from "components/atoms";
import { PageContainer } from "components/molecules";

import { WebHookBaseForm } from "../WebHookBaseForm";

import type { WebHookCreateProps } from "../types";
import type { FormikHelpers } from "formik";

export const WebHookEdit = () => {
  const { projectName, organizationName, webHookName } =
    useParams<{
      projectName: string;
      organizationName: string;
      webHookName: string;
    }>();
  const history = useHistory();
  const match = useRouteMatch();

  const { data: webhookDetails } = useWebhooksGet(projectName, webHookName);

  const updateWebHook = useWebHookUpdate(projectName, webHookName);

  const handleOnSubmit = async (
    data: WebHookCreateProps,
    actions: FormikHelpers<WebHookCreateProps>
  ) => {
    try {
      const response = await updateWebHook(data);

      if (response)
        history.push(
          routes.organizations[":organizationName"](organizationName)
            .projects[":projectName"](projectName)
            .monitoring.webHooks[":webHookName"](data[FIELD_WEBHOOK_NAME])
            .index()
        );
    } finally {
      actions.setSubmitting(false);
    }
  };

  return (
    <PageContainer>
      <BreadcrumbsItem to={match.url}>Webhooks</BreadcrumbsItem>
      <PageHeader title={`Edit ${webhookDetails?.name}`} />
      <FormWrapper>
        <WebHookBaseForm
          handleOnSubmit={handleOnSubmit}
          defaultValues={webhookDetails as WebHookCreateProps}
          isEditForm
        />
      </FormWrapper>
    </PageContainer>
  );
};
