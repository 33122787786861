import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";

import type { BoxProps } from "@mui/material";
import type { AppThemeProps } from "assets/styles/theme/theme.d";

import "./Notes.scss";

type NotesProps = {
  children: React.ReactNode;
} & BoxProps;

export function Notes({ children, ...props }: NotesProps) {
  const theme = useTheme() as AppThemeProps;

  return (
    <Box
      component="span"
      mt={-1}
      fontSize="0.8em"
      {...props}
      sx={{ color: theme.palette.secondary.main }}
    >
      {children}
    </Box>
  );
}
