import {
  Stepper,
  Step,
  StepLabel,
  Typography,
  Grid,
  Box,
  Container,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useEffect, useState, useContext } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useForm } from "react-hook-form";
import { useParams, useHistory } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { FIELD_NAME, FIELD_EXPIRY_DATE } from "libs/constants/fields";
import { USER_ROLE_PERMISSIONS } from "libs/constants/permissions";
import { BaseUrlContext } from "libs/contexts";
import { useServiceUserCreate } from "libs/data/customized/service-users";
import { useRoleAssignmentsPerObjectList } from "libs/data/endpoints/roles/roles";
import { useGoogleAnalytics } from "libs/hooks";
import { getFormattedDate } from "libs/utilities/date-util";
import { explanations } from "libs/utilities/explanations";
import validators from "libs/utilities/validators";
import { useGetPermissions } from "store/features/permissions";

import {
  CopyToClipboardButton,
  FormTextField,
  PrimaryButton,
  SecondaryButton,
} from "components/atoms";
import { FormSection, DatePicker } from "components/molecules";
import { FormContainer } from "components/organisms";

import { TokenRoles } from "./TokenRoles";

import type {
  ServiceUserCreate,
  ServiceUserTokenDetail,
} from "libs/data/models";

const fields = {
  [FIELD_NAME]: "",
  [FIELD_EXPIRY_DATE]: null,
};

const ApiTokenCreate = () => {
  useGoogleAnalytics();
  const history = useHistory();
  const { projectName, userId } =
    useParams<{ projectName: string; userId?: string }>();
  const baseUrl = useContext(BaseUrlContext);

  const [currentPermissions] = useGetPermissions();

  const [activeStep, setActiveStep] = useState(userId ? 1 : 0);
  const [hasRolePermissions, setHasRolePermissions] = useState(false);
  const [currentServiceUser, setCurrentServieUser] =
    useState<ServiceUserTokenDetail | undefined>(undefined);
  const { data: userRoles } = useRoleAssignmentsPerObjectList(
    projectName,
    {
      assignee: currentServiceUser?.id,
      assignee_type: "user",
    },
    { swr: { enabled: !!currentServiceUser } }
  );

  const methods = useForm({
    mode: "onBlur",
    defaultValues: fields,
  });
  const { setValue } = methods;

  const createServiceUser = useServiceUserCreate(projectName);

  useEffect(() => {
    setHasRolePermissions(
      currentPermissions[USER_ROLE_PERMISSIONS["role_list"]] &&
        currentPermissions[USER_ROLE_PERMISSIONS["assignment_list"]]
    );
  }, [currentPermissions]);

  const onSubmit = async (data: ServiceUserCreate) => {
    const newData = {
      ...data,
      [FIELD_EXPIRY_DATE]: data[FIELD_EXPIRY_DATE] || null,
    };
    const details = await createServiceUser(newData);
    if (details) setCurrentServieUser(details);
    if (hasRolePermissions) {
      setActiveStep(1);
    } else if (!hasRolePermissions) {
      setActiveStep(2);
    }
  };

  const onRolesCreate = () => {
    setActiveStep(2);
  };

  const handleDateChange = (value: Date | null) => {
    if (value) {
      setValue(FIELD_EXPIRY_DATE, getFormattedDate(value));
    }
  };

  return (
    <Grid item xs={12}>
      <BreadcrumbsItem to={`${baseUrl}/api-tokens`}>API tokens</BreadcrumbsItem>
      <Stepper
        sx={{ marginTop: spacing[16] }}
        activeStep={activeStep}
        orientation="horizontal"
      >
        <Step key={0}>
          <StepLabel>Create service user</StepLabel>
        </Step>
        {hasRolePermissions && (
          <Step key={1}>
            <StepLabel>Assign roles</StepLabel>
          </Step>
        )}
        <Step key={2}>
          <StepLabel>Copy API Token</StepLabel>
        </Step>
      </Stepper>
      <Grid padding={spacing[24]}>
        <div hidden={activeStep !== 0}>
          <FormContainer
            onSubmit={onSubmit}
            buttonLabel="Next step"
            formMethods={methods}
          >
            <FormSection
              title="Service user name"
              description={explanations.permissions.serviceUserName}
            >
              <FormTextField
                name={FIELD_NAME}
                id={FIELD_NAME}
                label="Name"
                rules={{
                  required: validators.required.message(FIELD_NAME),
                  pattern: {
                    value: validators.name.pattern,
                    message: validators.name.message(FIELD_NAME),
                  },
                  validate: validators.name.value("API token"),
                }}
                placeholder="Ex: my-service-user-1"
              />
            </FormSection>

            <FormSection
              title="Expiry date"
              description={explanations.permissions.expiry_date}
            >
              <Typography variant="h6">Optional field.</Typography>
              <Grid item xs={6}>
                <DatePicker
                  name={FIELD_EXPIRY_DATE}
                  minDate={new Date()}
                  onChange={handleDateChange}
                />
              </Grid>
            </FormSection>
          </FormContainer>
        </div>

        <div hidden={activeStep !== 1}>
          {activeStep === 1 && (
            <Container maxWidth="md">
              <TokenRoles
                currentUser={currentServiceUser}
                title={currentServiceUser?.name ?? ""}
                isUsedInForm
              />
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <SecondaryButton onClick={onRolesCreate}>
                  {isEmpty(userRoles) ? "Skip" : "Next step"}
                </SecondaryButton>
              </Box>
            </Container>
          )}
        </div>

        <div hidden={activeStep !== 2}>
          <Box display="flex" justifyContent="center">
            {activeStep === 2 && currentServiceUser && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                p={2}
              >
                <Typography margin={spacing[8]} variant="h3">
                  Token generated
                </Typography>
                <Typography margin={spacing[8]}>
                  A token was successfully created for service user{" "}
                  <Typography variant="h5" display="inline" component="span">
                    {currentServiceUser.name}
                  </Typography>
                  .
                </Typography>
                <Typography margin={spacing[8]} color="error">
                  {`Please, copy the token. You won't be able to retrieve it again.`}
                </Typography>
                <Typography margin={spacing[8]} variant="h5">
                  Token {currentServiceUser.token}
                  <CopyToClipboardButton
                    defaultLabel="Copy token to clipboard"
                    contentToCopy={`Token ${currentServiceUser.token}`}
                  />
                </Typography>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <PrimaryButton
                    onClick={() => history.push(`${baseUrl}/api-tokens`)}
                  >
                    Done
                  </PrimaryButton>
                </Box>
              </Box>
            )}
          </Box>
        </div>
      </Grid>
    </Grid>
  );
};

export default ApiTokenCreate;
