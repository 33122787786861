import styled from "@emotion/styled";
import Plus from "@mui/icons-material/AddBoxRounded";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, Typography, useTheme } from "@mui/material";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactFlow } from "reactflow";

import { borderRadius, getShadows, spacing } from "assets/styles/theme";
import { formatInput } from "libs/utilities/input-parser";

import {
  Dialog,
  Icon,
  PrimaryButton,
  SearchBarCustomStyle,
  DialogDescription,
} from "components/atoms";

import { VariableForm } from "../NodeVariable/VariableForm";
import { createNewVariable, DIAGRAM_DRAG_ID_VARIABLE } from "../constants";
import { suggestNewNameForPipelineObject } from "../suggestNewNameForPipelineObject";
import { findEmptyPosition } from "../utils";

import type { EdgeDataType, NewVariableDataType, NodeDataType } from "../types";
import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { PipelineVersionObjectList } from "libs/data/models";
import type { DragEvent } from "react";
import type { Node } from "reactflow";

export const SidebarLeftVariablesTab = ({
  variables,
}: {
  variables: PipelineVersionObjectList[] | undefined;
}) => {
  const [isNewVariableDialogOpen, setIsNewVariableDialogOpen] = useState(false);
  const { getNodes, setNodes } = useReactFlow<NodeDataType, EdgeDataType>();
  const theme = useTheme() as AppThemeProps;
  const { organizationName, projectName, pipelineName, versionName } =
    useParams<{
      organizationName: string;
      projectName: string;
      pipelineName: string;
      versionName: string;
    }>();
  const nodes = getNodes();
  const [query, setQuery] = useState("");

  const nodeName = suggestNewNameForPipelineObject(nodes, "new-variable");
  const newNode = useMemo(
    () =>
      createNewVariable(
        organizationName,
        projectName,
        pipelineName,
        versionName,
        nodeName,
        findEmptyPosition(nodes)
      ),
    [nodeName, nodes, organizationName, pipelineName, projectName, versionName]
  );

  const handleSubmit = (data: NewVariableDataType) => {
    const name = data.name;
    const value = data.field_value || data.file_value;
    const data_type = data.type.value;

    const output_fields = [{ name, data_type }];
    const formattedValue = formatInput(output_fields, { [name]: value });

    if (newNode?.data?.pipelineObject?.configuration) {
      newNode.data.pipelineObject.configuration = {
        ...newNode.data.pipelineObject.configuration,
        output_fields,
        output_values: [{ name, value: formattedValue[name] }],
      };

      newNode.data.pipelineObject.output_fields = output_fields;
      newNode.data.pipelineObject.name = name;
      setIsNewVariableDialogOpen(false);
      setNodes([...nodes, newNode as Node<NodeDataType>]);
    } else {
      // todo: throw a silent error
    }
    setNodes([...nodes, newNode as Node<NodeDataType>]);
  };

  return (
    <>
      <Typography
        variant="body2"
        color="textPrimary"
        style={{
          maxWidth: 400,
          textAlign: "center",
          marginBottom: spacing[24],
        }}
      >
        Drag and drop any of your variables onto the canvas to use in your
        pipeline. Variables allow you to apply changes to the input of a
        deployment.
      </Typography>

      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        overflow="auto"
        mt={spacing[20]}
        alignItems="center"
      >
        <SearchBarCustomStyle
          fullWidth
          value={query}
          onChange={(value) => setQuery(value)}
        />

        <PrimaryButton
          startIcon={<Plus />}
          onClick={() => setIsNewVariableDialogOpen(true)}
          style={{
            maxWidth: "200px",
            marginTop: "20px",
          }}
        >
          Create new variable
        </PrimaryButton>
        {(variables || [])
          ?.filter(
            (variable) =>
              variable && new RegExp(query).test(variable.name ?? "")
          )
          .map(
            (variable, index) =>
              variable && (
                <VariableContainer
                  hoverColor={theme.palette.neutrals[200]}
                  key={index}
                  draggable
                  onDragStart={(event: DragEvent<HTMLDivElement>) => {
                    event.dataTransfer.setData(
                      DIAGRAM_DRAG_ID_VARIABLE,
                      JSON.stringify(variable)
                    );
                  }}
                  display="flex"
                >
                  <Icon
                    component={DragIndicatorIcon}
                    style={{
                      alignSelf: "center",
                      color: theme.palette.neutrals[400],
                    }}
                  />
                  <VariableNode
                    backgroundColor={theme.palette.primary.main}
                    boxShadow={getShadows(theme).primary}
                  >
                    <Typography
                      variant="body1"
                      style={{
                        color: theme.palette.neutrals[0],
                        fontWeight: 600,
                      }}
                    >
                      {variable.name}
                    </Typography>
                  </VariableNode>
                </VariableContainer>
              )
          )}
      </Box>
      <Dialog
        title="Pipeline Variable"
        onClose={() => setIsNewVariableDialogOpen(false)}
        open={isNewVariableDialogOpen}
      >
        <DialogDescription>
          You can use the defined variables as a source for your objects when
          creating a new connection.
        </DialogDescription>
        <VariableForm onSubmit={handleSubmit} node={newNode} isDialog={true} />
      </Dialog>
    </>
  );
};

const VariableNode = styled(Box)<{
  backgroundColor: string;
  boxShadow: string;
}>`
  background: ${(props) => props.backgroundColor};
  height: 40px;
  padding: ${spacing[12]};
  border-radius: ${borderRadius[5]};
  box-shadow: ${(props) => props.boxShadow};
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: 0.9rem;
`;

const VariableContainer = styled(Box)<{ hoverColor: string }>`
  border-radius: ${borderRadius[5]};
  margin-top: ${spacing[16]};
  cursor: pointer;
  width: 100%;
  user-select: none;
  z-index: 100;
  &:hover {
    background-color: ${(props) => props.hoverColor};
    cursor: move;
  }
`;
