import { useTheme } from "@mui/material";

import { BaseDialog } from "./BaseDialog";
import { DialogActions } from "./DialogActions";
import { DialogBody } from "./DialogBody";
import { DialogHeader } from "./DialogHeader";

import type { DialogProps as MuiDialogProps, ModalProps } from "@mui/material";
import type { ClassNameMap } from "@mui/material/styles";
import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { CSSProperties, ReactNode } from "react";

export interface DialogProps
  extends Omit<
    MuiDialogProps,
    | "ref"
    | "open"
    | "onClose"
    | "fullWidth"
    | "container"
    | "scroll"
    | "classes"
    | "title"
  > {
  Actions?: ReactNode;
  container?: string;
  dialogBodyStyles?: CSSProperties;
  dismissible?: boolean;
  Header?: ReactNode;
  open: boolean;
  onClose: Exclude<ModalProps["onClose"], undefined>;
  title?: string;
  dialogDescription?: ReactNode;
  classes?: Partial<ClassNameMap>;
  fullWidth?: boolean;
  dialogContentStyles?: CSSProperties;
}

export const Dialog = ({
  Actions,
  dialogBodyStyles = {},
  dismissible = true,
  open,
  onClose,
  Header,
  title,
  children,
  fullWidth = true,
  sx,
  ...props
}: DialogProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <BaseDialog
      open={open}
      onClose={onClose}
      fullWidth={fullWidth}
      disableBackdropClick={!dismissible}
      disableEscapeKeyDown={!dismissible}
      hideCloseButton={!dismissible}
      {...props}
      sx={{
        ...sx,
        "& .MuiPaper-root.MuiDialog-paper": {
          backgroundColor: theme.palette.background.default,
          backgroundImage: "none",
          "& h2": {
            color: `${theme.palette.text.primary} !important`,
          },
        },
      }}
    >
      {Header ? Header : title && <DialogHeader title={title} />}
      <DialogBody
        style={{
          flex: 1,
          overflowY: "auto",
          overflowX: "hidden",
          ...dialogBodyStyles,
        }}
      >
        {children}
      </DialogBody>
      {!!Actions && <DialogActions>{Actions}</DialogActions>}
    </BaseDialog>
  );
};
