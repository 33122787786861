import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Typography, useTheme } from "@mui/material";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

export interface UploadPackageFieldFileNameProps {
  name: string;
  original: boolean;
}

export const UploadPackageFieldFileName = ({
  name,
  original,
}: UploadPackageFieldFileNameProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Box
      paddingTop={2}
      style={{
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
    >
      {original ? (
        name
      ) : (
        <Typography
          component="span"
          variant={"h5"}
          color={theme.palette.success.main}
        >
          <CheckCircleIcon />
          {name}
        </Typography>
      )}
    </Box>
  );
};
