import { Grid, Box, FormHelperText, Typography } from "@mui/material";
import { useState, useEffect, useCallback, useMemo } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { useForm, useWatch } from "react-hook-form";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { PageHeader } from "components/molecules/PageLayout";
import { PortForwarding } from "components/molecules/PortForwarding";
import {
  FIELD_VERSION,
  FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE,
  FIELD_DEPLOYMENT_VERSION_FILE,
  FIELD_DESCRIPTION,
  FIELD_LABELS,
  FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY,
  FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY,
  FIELD_DEPLOYMENT_VERSION_IDLE_TIME,
  FIELD_RETENTION_TIME,
  FIELD_RETENTION_MODE,
  FIELD_PACKAGE,
  FIELD_EXISTING_PACKAGE,
  FIELD_NEW_PACKAGE,
  FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS,
  FIELD_MAXIMUM_QUEUE_SIZE_BATCH,
  FIELD_STATIC_IP,
  FIELD_DEPLOYMENT_VERSION_FILE_HAS_REQUIREMENTS,
  FIELD_DEPLOYMENT_VERSION_ENVIRONMENT,
  FIELD_GPU_INSTANCE_ENABLED,
  FIELD_SCALING_STRATEGY,
  FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORTS,
  FIELD_INSTANCE_PROCESSES,
} from "libs/constants/fields";
import { useDeploymentVersionsFileUpload } from "libs/data/customized/deployment-versions/useDeploymentVersionsFileUpload";
import { useDeploymentVersionsUpdate } from "libs/data/customized/deployment-versions/useDeploymentVersionsUpdate";
import { useDeploymentVersionsGet } from "libs/data/endpoints/deployments/deployments";
import { useEnvironmentsList } from "libs/data/endpoints/environments/environments";
import { useInstanceTypeGroupsGet } from "libs/data/endpoints/instances/instances";
import { useOrganizationsFeaturesGet } from "libs/data/endpoints/organizations/organizations";
import { useGoogleAnalytics } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import {
  formatLabels,
  formatLabelsForRequest,
} from "libs/utilities/labels-util";
import { getChanges } from "libs/utilities/patch-helper";
import { UNLOADED, LOADING } from "libs/utilities/request-statuses";
import validators from "libs/utilities/validators";
import { DeploymentCreateEnvVars } from "pages/organizations/:organizationName/projects/:projectName/deployments/:deploymentName/DeploymentCreateEnvVars";
import { routes } from "routes";

import { ErrorAlert, InfoAlert, Radio } from "components/atoms";
import {
  FormSection,
  DeploymentVersionAdvancedParameters,
  GeneralFieldsSection,
  PageContainer,
  NetworkSettings,
  DeploymentVersionInstanceType,
  UploadPackageField,
  CodeEnvironmentSelect,
  DependencyInfo,
} from "components/molecules";
import {
  LabelsForm,
  RequestSettings,
  FormContainer,
} from "components/organisms";

import { DeploymentVersionScalingStrategy } from "./DeploymentVersionScalingStrategy";

import type { DeploymentVersionDetailsRouteParams } from "./types";
import type { DeploymentVersionUpdate } from "libs/data/models";

export const DeploymentVersionEdit = () => {
  useGoogleAnalytics();

  const history = useHistory();
  const match = useRouteMatch();
  const { organizationName, projectName, deploymentName, versionName } =
    useParams<DeploymentVersionDetailsRouteParams>();
  const { data: version, error } = useDeploymentVersionsGet(
    projectName,
    deploymentName,
    versionName
  );
  const updateVersion = useDeploymentVersionsUpdate(
    projectName,
    deploymentName,
    versionName
  );
  const { data: environments } = useEnvironmentsList(projectName);
  const { data: organizationFeatures } =
    useOrganizationsFeaturesGet(organizationName);
  const { data: currentInstanceType } = useInstanceTypeGroupsGet(
    projectName,
    version?.[FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE] ?? ""
  );
  const gpuEnabled = organizationFeatures?.resource_gpu ?? false;

  const [correctZipStructure, setCorrectZipStructure] = useState(false);
  const fileUpload = useDeploymentVersionsFileUpload(
    projectName,
    deploymentName
  );
  const methods = useForm({
    mode: "onBlur",
  });
  const { control, setValue, register, errors } = methods;

  const fileHasRequirements = useWatch({
    control,
    name: FIELD_DEPLOYMENT_VERSION_FILE_HAS_REQUIREMENTS,
  });
  const gpuInstanceModeEnabled = useWatch({
    control,
    name: FIELD_GPU_INSTANCE_ENABLED,
  });

  const deploymentPackage = useWatch({ control, name: FIELD_PACKAGE });

  const defaultGPUInstanceModeEnabled = useMemo(() => {
    setValue(
      FIELD_GPU_INSTANCE_ENABLED,
      !!currentInstanceType?.instance_types?.[0].accelerator
    );

    return !!currentInstanceType?.instance_types?.[0].accelerator || 0 > 0;
  }, [currentInstanceType?.instance_types, setValue]);

  // This memo is retrieving the correct environment option to be shown to the user
  const environmentOption = useMemo(() => {
    let environment = environments?.find(
      (env) => env.name === version?.environment
    );

    if (environment?.base_environment && environment?.implicit) {
      environment = environments?.find(
        (env) => env.name === environment?.base_environment
      );
    }

    return (
      environment && {
        explicit: !environment.implicit,
        label: environment.display_name,
        value: environment.name,
      }
    );
  }, [environments, version?.environment]);

  // In case the user toggles deployment package, we should ensure that the default value remains correct
  useEffect(() => {
    if (environmentOption) {
      setValue(FIELD_DEPLOYMENT_VERSION_ENVIRONMENT, environmentOption, {
        shouldDirty: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deploymentPackage]);

  const fileHasUnwantedRequirements =
    deploymentPackage == FIELD_NEW_PACKAGE &&
    !!environmentOption?.explicit &&
    !!fileHasRequirements;

  useEffect(() => {
    if (version) {
      setValue(FIELD_LABELS, formatLabels(version.labels ?? {}));
      setValue(
        FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS,
        version.maximum_queue_size_express
      );
      setValue(
        FIELD_MAXIMUM_QUEUE_SIZE_BATCH,
        version.maximum_queue_size_batch
      );
      setValue(FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORTS, version.ports);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [version]);

  const onSubmit = async (data: any) => {
    const updatedVersion: any = {
      [FIELD_VERSION]: data[FIELD_VERSION],
      [FIELD_RETENTION_MODE]: data[FIELD_RETENTION_MODE].value,
      [FIELD_RETENTION_TIME]: data[FIELD_RETENTION_TIME]?.value,
      [FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE]:
        data[FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE]?.value,
      [FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY]: parseInt(
        data[FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY]
      ),
      [FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY]: parseInt(
        data[FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY]
      ),
      [FIELD_DEPLOYMENT_VERSION_IDLE_TIME]: parseInt(
        data[FIELD_DEPLOYMENT_VERSION_IDLE_TIME]
      ),
      [FIELD_DESCRIPTION]: data[FIELD_DESCRIPTION],
      [FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS]: parseInt(
        data[FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS]
      ),
      [FIELD_MAXIMUM_QUEUE_SIZE_BATCH]: parseInt(
        data[FIELD_MAXIMUM_QUEUE_SIZE_BATCH]
      ),
      [FIELD_LABELS]: formatLabelsForRequest(data[FIELD_LABELS]),
      [FIELD_STATIC_IP]: data[FIELD_STATIC_IP],
      [FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORTS]:
        data[FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORTS],
      [FIELD_SCALING_STRATEGY]: data[FIELD_SCALING_STRATEGY],
      [FIELD_INSTANCE_PROCESSES]: data[FIELD_INSTANCE_PROCESSES],
    };

    if (
      deploymentPackage == FIELD_EXISTING_PACKAGE &&
      data[FIELD_DEPLOYMENT_VERSION_ENVIRONMENT] &&
      data[FIELD_DEPLOYMENT_VERSION_ENVIRONMENT].value !==
        environmentOption?.value
    ) {
      updatedVersion[FIELD_DEPLOYMENT_VERSION_ENVIRONMENT] =
        data[FIELD_DEPLOYMENT_VERSION_ENVIRONMENT].value;
    }

    const redirectUrl = routes.organizations[":organizationName"](
      organizationName
    )
      .projects[":projectName"](projectName)
      .deployments[":deploymentName"](deploymentName)
      .versions[":versionName"](updatedVersion?.version)
      .index();

    const uploadNewPackage =
      version?.id &&
      data[FIELD_PACKAGE] === FIELD_NEW_PACKAGE &&
      data[FIELD_DEPLOYMENT_VERSION_FILE].length === 1;

    const changes = getChanges(
      updatedVersion,
      version as unknown as Record<string, string>
    ) as DeploymentVersionUpdate;

    if (updatedVersion[FIELD_LABELS] !== version?.[FIELD_LABELS]) {
      changes[FIELD_LABELS] = updatedVersion[FIELD_LABELS];
    }

    if (
      JSON.stringify(updatedVersion.ports) !== JSON.stringify(version?.ports)
    ) {
      changes.ports = updatedVersion.ports;
    }

    const response = await updateVersion(changes);

    if (response && uploadNewPackage) {
      const file = data[FIELD_DEPLOYMENT_VERSION_FILE][0];
      await fileUpload(
        {
          version: response.version,
          id: version.id,
          deployment: version.deployment,
        },
        {
          file,
        },
        file.name
      );
    }

    if (response) {
      history.replace(redirectUrl);
    }
  };

  const fileFieldRules = useMemo(() => {
    return {
      required: validators.required.message("version package"),
      validate: {
        correctZipStructure: () => correctZipStructure,
        hasRequirements: () => !fileHasUnwantedRequirements,
      },
    };
  }, [correctZipStructure, fileHasUnwantedRequirements]);

  const parentFileFieldValidationChecker = useCallback(
    // Workaround function to pass props from child to parent
    (flag: any) => setCorrectZipStructure(flag),
    []
  );

  return (
    <>
      <BreadcrumbsItem to={match.url}>Edit</BreadcrumbsItem>
      <PageContainer>
        <PageHeader title={`Edit "${versionName}"`} />
        <InfoAlert style={{ width: "100%" }}>
          Changes affecting a running deployment can take up to one minute to
          take effect.
        </InfoAlert>
        <FormContainer
          onSubmit={onSubmit}
          buttonDisabled={fileHasUnwantedRequirements}
          buttonLabel="Save"
          formMethods={methods}
          status={!version && !error ? LOADING : UNLOADED}
        >
          {(version || error) && (
            <>
              <GeneralFieldsSection
                name={FIELD_VERSION}
                label="Version name"
                ruleName="version Name"
                validateValue="deployment version"
                namePlaceholder="Ex: my-deployment-version-1"
                nameDefaultValue={versionName}
                descriptionDefaultValue={version?.description}
              />
              <FormSection
                title="Deployment package"
                description={explanations.deployments.configuration}
              >
                <Grid container direction="column">
                  <Grid item>
                    <Radio
                      value={FIELD_EXISTING_PACKAGE}
                      register={
                        register({
                          required: true,
                        }) as any
                      }
                      name={FIELD_PACKAGE}
                      id={`${FIELD_PACKAGE}_${FIELD_EXISTING_PACKAGE}`}
                      label="Use current package"
                      defaultChecked
                      // @ts-ignore
                      error={errors?.[FIELD_PACKAGE]?.message !== undefined}
                    />
                  </Grid>
                  <Grid item>
                    <Radio
                      value={FIELD_NEW_PACKAGE}
                      register={register({}) as any}
                      name={FIELD_PACKAGE}
                      id={`${FIELD_PACKAGE}_${FIELD_NEW_PACKAGE}`}
                      label="Upload new package"
                      // @ts-ignore
                      error={errors?.[FIELD_PACKAGE]?.message !== undefined}
                    />
                  </Grid>
                  <FormHelperText
                    id={FIELD_PACKAGE}
                    // @ts-ignore
                    error={errors?.[FIELD_PACKAGE]?.message !== undefined}
                    // @ts-ignore
                    hidden={errors?.[FIELD_PACKAGE]?.message === undefined}
                  >
                    You should either use the current package nor upload a new
                    one
                  </FormHelperText>
                </Grid>
                {deploymentPackage === FIELD_NEW_PACKAGE && (
                  <Box ml={2}>
                    <UploadPackageField
                      hasRequirementsName={
                        FIELD_DEPLOYMENT_VERSION_FILE_HAS_REQUIREMENTS
                      }
                      hasUnwantedRequirements={fileHasUnwantedRequirements}
                      name={FIELD_DEPLOYMENT_VERSION_FILE}
                      rules={fileFieldRules}
                      label="Deployment package (.zip)"
                      passFormValidation={parentFileFieldValidationChecker}
                    />
                    {fileHasUnwantedRequirements && (
                      <ErrorAlert style={{ marginTop: 16 }}>
                        It&apos;s not allowed to upload a deployment package
                        with dependency info when you are using a custom
                        environment.
                      </ErrorAlert>
                    )}
                  </Box>
                )}

                {fileHasRequirements && !fileHasUnwantedRequirements ? (
                  <DependencyInfo />
                ) : null}
              </FormSection>

              <FormSection
                title="Environment settings"
                description={
                  explanations.deployments.versions.environmentSettings
                }
              >
                <DeploymentVersionInstanceType
                  defaultValue={defaultGPUInstanceModeEnabled}
                  disableGPUToggle={!gpuEnabled}
                  version={version}
                  isEditForm
                />
                <Grid item>
                  <Typography variant="h6">Select code environment</Typography>
                  <Box maxWidth={spacing[310]} mt={2}>
                    <CodeEnvironmentSelect
                      customEnvironmentsVisible={
                        (deploymentPackage === FIELD_NEW_PACKAGE &&
                          fileHasRequirements !== true) ||
                        (deploymentPackage === FIELD_EXISTING_PACKAGE &&
                          environmentOption?.explicit)
                      }
                      defaultEnvironment={version?.environment}
                      deployment={deploymentName}
                      disabled={deploymentPackage == FIELD_NEW_PACKAGE}
                      gpuEnabled={
                        gpuEnabled && (gpuInstanceModeEnabled as boolean)
                      }
                      name={FIELD_DEPLOYMENT_VERSION_ENVIRONMENT}
                      projectName={projectName}
                    />
                  </Box>
                </Grid>
              </FormSection>

              <RequestSettings
                defaultTime={version?.request_retention_time}
                defaultMode={version?.request_retention_mode}
                defaultBatchQueueSize={version?.maximum_queue_size_batch}
                defaultQueueSize={version?.maximum_queue_size_express}
              />

              <NetworkSettings defaultValue={version?.static_ip} />
              <PortForwarding />
              <DeploymentVersionAdvancedParameters version={version} />
              <DeploymentVersionScalingStrategy
                defaultValue={version?.scaling_strategy}
              />
              <DeploymentCreateEnvVars
                deploymentName={deploymentName}
                deploymentVersionName={versionName}
              />
              <FormSection title="Labels">
                <LabelsForm name={FIELD_LABELS} />
              </FormSection>
            </>
          )}
        </FormContainer>
      </PageContainer>
    </>
  );
};
