import { useCallback } from "react";
import { useDispatch } from "react-redux";

import {
  instancesUpdate,
  useInstancesList,
} from "libs/data/endpoints/instances/instances";
import {
  createErrorNotification,
  createSuccessNotification,
} from "libs/utilities/notifications";

import type { AxiosError } from "axios";
import type { InstanceUpdateBody } from "libs/data/models";

export const useEditInstance = (
  projectName: string,
  deploymentName: string,
  version: string
) => {
  const { mutate } = useInstancesList(projectName, deploymentName, version);
  const dispatch = useDispatch();

  return useCallback(
    async (instanceId: string, data: InstanceUpdateBody) => {
      try {
        await instancesUpdate(
          projectName,
          deploymentName,
          version,
          instanceId,
          data
        );
        await mutate();

        dispatch(createSuccessNotification("Instance is stopping"));
      } catch (e) {
        dispatch(createErrorNotification((e as AxiosError).message));
      }

      return;
    },
    [version, deploymentName, projectName, dispatch, mutate]
  );
};
