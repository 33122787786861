import { useTheme } from "@mui/material";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

export const IlluAfterSignUp = () => {
  const theme = useTheme() as AppThemeProps;

  return (
    <svg
      id="b15aa787-28cf-46ed-b363-0df1de40c89a"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 896.67538 462.2717"
      width="100%"
      height="100%"
    >
      <path
        d="M1047.147,681.03273H152.853a1.19069,1.19069,0,0,1,0-2.38137h894.294a1.19069,1.19069,0,0,1,0,2.38137Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.primary.light}
      />
      <circle
        cx="280.35323"
        cy="50.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="254.57993"
        cy="50.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="228.80662"
        cy="50.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="203.03332"
        cy="50.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="280.35323"
        cy="77.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="254.57993"
        cy="77.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="228.80662"
        cy="77.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="203.03332"
        cy="77.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="280.35323"
        cy="104.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="254.57993"
        cy="104.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="228.80662"
        cy="104.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="203.03332"
        cy="104.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="280.35323"
        cy="131.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="254.57993"
        cy="131.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="228.80662"
        cy="131.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="203.03332"
        cy="131.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="280.35323"
        cy="158.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="254.57993"
        cy="158.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="228.80662"
        cy="158.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="203.03332"
        cy="158.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="280.35323"
        cy="185.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="254.57993"
        cy="185.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="228.80662"
        cy="185.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="203.03332"
        cy="185.98023"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <path
        d="M375.19044,581.264v2a12.51087,12.51087,0,0,0,12.5,12.5h90a12.50461,12.50461,0,0,0,12.5-12.5v-2a12.41025,12.41025,0,0,0-2.90039-8,12.85917,12.85917,0,0,0-2.10987-2s-49.48-1.12-49.48-2.5H387.69044A12.51734,12.51734,0,0,0,375.19044,581.264Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.svgs.celebralGray}
      />
      <rect
        x="275.25169"
        y="376.40327"
        width="13"
        height="84"
        fill={theme.palette.svgs.celebralGray}
      />
      <path
        d="M476.914,678.81073c0,1.40463-19.69947.5433-44,.5433s-44,.86133-44-.5433,19.69948-12.54331,44-12.54331S476.914,677.4061,476.914,678.81073Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.svgs.celebralGray}
      />
      <path
        d="M459.041,579.56765a10.7427,10.7427,0,0,0-3.80711-16.02664L427.681,469.65577l-20.9809,10.27567,33.68091,89.055a10.80091,10.80091,0,0,0,18.66,10.58123Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.svgs.brownSkin}
      />
      <path
        d="M433.923,485.33463l-23.48261,6.35682a4.81687,4.81687,0,0,1-6.04231-4.08554l-2.83827-24.08746a13.37737,13.37737,0,0,1,25.8455-6.91525l9.674,22.1558a4.81688,4.81688,0,0,1-3.15629,6.57563Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.secondary.main}
      />
      <polygon
        points="313.516 450.101 325.776 450.1 331.609 418.304 313.514 418.304 313.516 450.101"
        fill={theme.palette.svgs.brownSkin}
      />
      <path
        d="M462.05094,664.96234l24.1438-.001h.001a15.38605,15.38605,0,0,1,15.38648,15.38623v.5l-39.53052.00146Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.primary.main}
      />
      <path
        d="M479.40867,651.91435q-.21423,0-.43018-.02051l-16.96655-1.23535a4.49994,4.49994,0,0,1-3.80933-6.0293l22.70655-51.01465a3.4981,3.4981,0,0,0-.19629-2.79882,3.45078,3.45078,0,0,0-2.21118-1.75977c-10.67725-2.791-38.072-10.22266-61.78638-18.918-10.15991-3.72558-16.55884-9.10937-19.0188-16.00195-3.24316-9.08692,1.55469-17.374,1.7605-17.72168l.16089-.27246,22.31469,2.02832,24.19117,2.05762,53.01342,28.42773a20.086,20.086,0,0,1,8.81861,25.78418l-24.44092,54.80762A4.49689,4.49689,0,0,1,479.40867,651.91435Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.primary.main}
      />
      <circle
        cx="274.15032"
        cy="193.81592"
        r="24.56103"
        fill={theme.palette.svgs.brownSkin}
      />
      <polygon
        points="306.516 449.101 318.776 449.1 324.609 417.304 306.514 417.304 306.516 449.101"
        fill={theme.palette.svgs.brownSkin}
      />
      <path
        d="M455.05094,663.96234l24.1438-.001h.001a15.38605,15.38605,0,0,1,15.38648,15.38623v.5l-39.53052.00146Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.primary.main}
      />
      <path
        d="M472.40867,654.91435q-.21423,0-.43018-.02051l-16.96655-1.23535a4.49994,4.49994,0,0,1-3.80933-6.0293l22.70655-51.01465a3.4981,3.4981,0,0,0-.19629-2.79882,3.45078,3.45078,0,0,0-2.21118-1.75977c-10.67725-2.791-38.072-10.22266-61.78638-18.918-10.15991-3.72558-16.55884-9.10937-19.0188-16.00195-3.24316-9.08692,1.55469-17.374,1.7605-17.72168l.16089-.27246,22.31469,2.02832,24.19117,2.05762,53.01342,28.42773a20.086,20.086,0,0,1,8.81861,25.78418l-24.44092,54.80762A4.49689,4.49689,0,0,1,472.40867,654.91435Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.primary.main}
      />
      <path
        d="M433.38743,451.16825l-26-9s-16.322,12.54-8.481,43.64857a77.01216,77.01216,0,0,1-3.40009,48.32025,49.7778,49.7778,0,0,1-2.61889,5.53118s29,35,56,9l-10.5-50.5S454.88743,464.66825,433.38743,451.16825Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.primary.main}
      />
      <path
        d="M428.862,438.07639c-3.49561-5.2323-6.25434-12.48755-2.40565-17.4659,3.79908-4.91417,11.29215-4.19018,17.11054-6.36466,8.104-3.02867,12.80409-12.5493,11.33825-21.07565s-8.31032-15.59442-16.464-18.48644-17.34839-1.95148-25.33312,1.37887c-9.82931,4.0997-18.26115,12.03028-21.79686,22.07625s-1.6456,22.10808,5.68929,29.82962c7.86381,8.27835,20.20556,10.48455,31.62276,10.35068"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.svgs.royalBlue}
      />
      <path
        d="M406.01515,393.44382c-4.40483,3.58587-11.12526,1.99318-15.85359-1.15387s-8.56507-7.62825-13.681-10.09566c-9.01922-4.35-19.92379-1.45825-28.70172,3.36009s-16.55915,11.475-25.83123,15.25617-21.10393,3.96808-28.12485-3.17161a25.732,25.732,0,0,0,37.7101,30.37144c10.15941-6.18838,15.77105-19.1637,27.16593-22.57932,6.3055-1.89008,13.07632-.36778,19.44917,1.28106s13.01783,3.43041,19.44912,2.02674,12.447-7.18312,11.62879-13.71476Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.svgs.royalBlue}
      />
      <path
        d="M485.96881,624.87071a167.50643,167.50643,0,0,1,0-50.97535,4.30257,4.30257,0,0,1,4.2371-3.6057h39.87252a4.24657,4.24657,0,0,1,3.35525,1.62624,4.31917,4.31917,0,0,1,.8074,3.70456,102.32847,102.32847,0,0,0-.0003,47.52515,4.31806,4.31806,0,0,1-.8071,3.70456,4.24657,4.24657,0,0,1-3.35525,1.62624H490.20591A4.30258,4.30258,0,0,1,485.96881,624.87071Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M517.80835,594.96627H496.88477a3.00328,3.00328,0,0,1-3-3v-1.17188a3.00328,3.00328,0,0,1,3-3h20.92358a3.00328,3.00328,0,0,1,3,3v1.17188A3.00328,3.00328,0,0,1,517.80835,594.96627Z"
        transform="translate(-151.66231 -218.86415)"
        fill="#fff"
      />
      <path
        d="M517.80835,607.311H496.88477a3.00328,3.00328,0,0,1-3-3v-1.17236a3.00328,3.00328,0,0,1,3-3h20.92358a3.00328,3.00328,0,0,1,3,3V604.311A3.00328,3.00328,0,0,1,517.80835,607.311Z"
        transform="translate(-151.66231 -218.86415)"
        fill="#fff"
      />
      <path
        d="M496.45406,575.06832a10.74266,10.74266,0,0,0-7.69048-14.56723L438.62823,476.477,420.88,491.66868l54.86227,77.816a10.80091,10.80091,0,0,0,20.71183,5.58367Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.svgs.brownSkin}
      />
      <path
        d="M448.5895,490.09915l-21.14972,12.02212a4.81687,4.81687,0,0,1-6.87143-2.44636l-8.76626-22.61447a13.37737,13.37737,0,0,1,23.29816-13.15318l14.9026,19.03623a4.81687,4.81687,0,0,1-1.41335,7.15566Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.primary.main}
      />
      <path
        d="M673.62956,519.6137h-406a6.5,6.5,0,1,0,0,13h11.5v141.5a6.5,6.5,0,0,0,13,0V545.10375l72.8711,132.07788a6.5,6.5,0,0,0,11.2583-6.5L300.08318,532.6137H648.12956v.08417l-76.1289,137.98376a6.49977,6.49977,0,1,0,11.25781,6.5L648.12956,559.6035V674.1137a6.5,6.5,0,0,0,13,0V536.04107l1.89112-3.42737h10.60888a6.5,6.5,0,0,0,0-13Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.primary.main}
      />
      <rect
        x="271.46726"
        y="296.74955"
        width="86"
        height="7"
        rx="3.5"
        fill={theme.palette.svgs.celebralGray}
      />
      <path
        d="M558.62956,522.6137h-89a6.50737,6.50737,0,0,1-6.5-6.5v-49a6.50736,6.50736,0,0,1,6.5-6.5h89a6.50736,6.50736,0,0,1,6.5,6.5v49A6.50737,6.50737,0,0,1,558.62956,522.6137Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.svgs.celebralGray}
      />
      <circle
        cx="362.46726"
        cy="272.74955"
        r="6"
        fill={theme.palette.svgs.paperWhite}
      />
      <path
        d="M626.55028,401.88558v-175.5a7.53,7.53,0,0,1,7.52143-7.52143H772.80028a7.53,7.53,0,0,1,7.52143,7.52143v175.5a7.53,7.53,0,0,1-7.52143,7.52143H634.07171A7.53,7.53,0,0,1,626.55028,401.88558Zm7.52143-181.35a5.85658,5.85658,0,0,0-5.85,5.85v175.5a5.85658,5.85658,0,0,0,5.85,5.85H772.80028a5.85658,5.85658,0,0,0,5.85-5.85v-175.5a5.85658,5.85658,0,0,0-5.85-5.85Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.svgs.celebralGray}
      />
      <path
        d="M711.21543,299.46186V263.42617a3.76488,3.76488,0,0,1,3.76071-3.76071h30.537a3.76489,3.76489,0,0,1,3.76072,3.76071v36.03569a3.76489,3.76489,0,0,1-3.76072,3.76071h-30.537A3.76488,3.76488,0,0,1,711.21543,299.46186Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M651.87971,316.17615V280.14046a3.7649,3.7649,0,0,1,3.76072-3.76072h30.537a3.76489,3.76489,0,0,1,3.76071,3.76072v36.03569a3.76488,3.76488,0,0,1-3.76071,3.76071h-30.537A3.76489,3.76489,0,0,1,651.87971,316.17615Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.svgs.celebralGray}
      />
      <path
        d="M699.51543,367.15472V331.119a3.76489,3.76489,0,0,1,3.76071-3.76072h30.537a3.7649,3.7649,0,0,1,3.76072,3.76072v36.03569a3.76489,3.76489,0,0,1-3.76072,3.76071h-30.537A3.76488,3.76488,0,0,1,699.51543,367.15472Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.primary.main}
      />
      <circle
        cx="780.74124"
        cy="11.82028"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="780.74124"
        cy="37.59358"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="780.74124"
        cy="63.36688"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="780.74124"
        cy="89.14018"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="753.74124"
        cy="11.82028"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="753.74124"
        cy="37.59358"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="753.74124"
        cy="63.36688"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="753.74124"
        cy="89.14018"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="726.74124"
        cy="11.82028"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="726.74124"
        cy="37.59358"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="726.74124"
        cy="63.36688"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="726.74124"
        cy="89.14018"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="699.74124"
        cy="11.82028"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="699.74124"
        cy="37.59358"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="699.74124"
        cy="63.36688"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="699.74124"
        cy="89.14018"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="672.74124"
        cy="11.82028"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="672.74124"
        cy="37.59358"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="672.74124"
        cy="63.36688"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="672.74124"
        cy="89.14018"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="645.74124"
        cy="11.82028"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="645.74124"
        cy="37.59358"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="645.74124"
        cy="63.36688"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <circle
        cx="645.74124"
        cy="89.14018"
        r="6.46689"
        fill={theme.palette.svgs.mist}
      />
      <path
        d="M823.45714,460.11655a11.00279,11.00279,0,0,1,7.76758-14.46387,10.58985,10.58985,0,0,1,1.45117-.23633l24.30274-42.59863-6.13672-10.54248,15.27539-11.78467,11.687,16.23194a11.14851,11.14851,0,0,1-.26025,13.30859L844.17931,452.745a10.50735,10.50735,0,0,1,.31055,1.03467A11.00313,11.00313,0,0,1,835.13,467.26938a10.706,10.706,0,0,1-1.34668.08447A11.036,11.036,0,0,1,823.45714,460.11655Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.svgs.pinkSkin}
      />
      <polygon
        points="624.38 450.888 612.12 450.887 606.288 403.599 624.382 403.6 624.38 450.888"
        fill={theme.palette.svgs.pinkSkin}
      />
      <path
        d="M603.36318,447.38395H627.007a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H588.47632a0,0,0,0,1,0,0v0A14.88686,14.88686,0,0,1,603.36318,447.38395Z"
        fill={theme.palette.primary.main}
      />
      <polygon
        points="725.38 450.888 713.12 450.887 707.288 403.599 725.382 403.6 725.38 450.888"
        fill={theme.palette.svgs.pinkSkin}
      />
      <path
        d="M704.36318,447.38395H728.007a0,0,0,0,1,0,0v14.88687a0,0,0,0,1,0,0H689.47632a0,0,0,0,1,0,0v0A14.88686,14.88686,0,0,1,704.36318,447.38395Z"
        fill={theme.palette.primary.main}
      />
      <path
        d="M869.51771,382.79326l-27.52466,10.45937-6.72-18.66664a11.16824,11.16824,0,0,1,5.002-13.49951h0a11.16822,11.16822,0,0,1,14.26248,2.78445Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M777.1007,631.45249l-23.08447-5.26514a4.98641,4.98641,0,0,1-3.812-5.61914c9.2627-58.70947,18.17432-93.09082,41.14014-136.75244.3208-.814,3.90674-17.33789,6.24707-28.28516a4.97165,4.97165,0,0,1,4.21387-5.02392l43.04443-6.62452a5.57031,5.57031,0,0,1,2.02149-.30419l.43017.08154-.00537.42334c-.00537.4375-.44092.50439-.60449.52978l-.57227.08789a6.68453,6.68453,0,0,1,1.77491,1.20655c18.85986,22.68365,28.667,97.03973,41.123,179.18457a4.48423,4.48423,0,0,1-1.30371,3.64746,4.93337,4.93337,0,0,1-3.5376,1.60058l-27.50146.67041a4.98232,4.98232,0,0,1-5.03516-4.21826L833.11535,519.743a3.97443,3.97443,0,0,0-7.49463-1.15576c-14.94434,30.43457-28.88086,56.75489-42.60645,109.26612a4.95254,4.95254,0,0,1-4.79248,3.72607A5.03,5.03,0,0,1,777.1007,631.45249Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.svgs.celebralGray}
      />
      <path
        d="M799.50793,456.75327a4.99864,4.99864,0,0,1-2.0835-3.54883c-3.52539-32.90967-1.06982-58.66406,7.50586-78.73486a24.99167,24.99167,0,0,1,16.56689-14.17969l22.24951-1.12305.11524.05567a26.00516,26.00516,0,0,1,14.21924,27.58838c-7.15625,18.86328-13.14844,38.50293-8.57862,54.34619a5.122,5.122,0,0,1-.48388,3.9834,4.8841,4.8841,0,0,1-3.11573,2.30908l-42.37841,10.08105a4.96422,4.96422,0,0,1-4.0166-.77734Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.secondary.main}
      />
      <path
        d="M789.93029,409.52573l-42.67432-33.666a10.51634,10.51634,0,0,1-1.03613.30567A11.02707,11.02707,0,0,1,737.402,374.474a10.91273,10.91273,0,0,1-4.62647-7.7334,11.02294,11.02294,0,0,1,7.209-11.63818,11.00362,11.00362,0,0,1,14.42627,7.8374,10.57293,10.57293,0,0,1,.229,1.45215l42.48145,24.50732,10.57177-6.08642,11.71094,15.332-16.41406,11.69922a10.942,10.942,0,0,1-13.05957-.31836Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.svgs.pinkSkin}
      />
      <path
        d="M824.61122,402.91955l-24.34868-16.55739,11.44288-16.20685a11.16823,11.16823,0,0,1,13.92862-3.64l0,0a11.16823,11.16823,0,0,1,5.89441,13.2826Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.secondary.main}
      />
      <circle
        cx="684.65901"
        cy="107.4314"
        r="24.56103"
        fill={theme.palette.svgs.pinkSkin}
      />
      <path
        d="M827.31647,322.63738c-6.31272,4.32051-14.60145,8.76174-21.04891,4.16075-4.23352-3.02109-5.56737-8.61545-3.8506-13.52487,3.08726-8.82856,11.56943-12.71531,19.68052-15.69458,10.54441-3.87305,22.02572-6.95424,32.91112-4.18055s20.5056,13.31292,18.54058,24.37293c-1.58023,8.89423-9.97,16.29492-8.779,25.24958,1.19867,9.01215,11.33379,13.75589,20.28149,15.36658s18.96038,2.12822,25.39074,8.5552c8.2031,8.1988,6.15464,22.85325-1.56414,31.50955s-19.36683,12.58011-30.6538,15.24757c-14.95454,3.53422-31.12906,5.3916-45.33209-.47378s-25.25566-21.65434-21.14126-36.45976c1.73791-6.2538,5.83191-11.5391,9.78854-16.68452s7.9631-10.526,9.47237-16.83887c1.25773-5.26073.32683-11.36869-3.16181-15.31484a4.437,4.437,0,0,1-.5944-5.18643Z"
        transform="translate(-151.66231 -218.86415)"
        fill={theme.palette.primary.main}
      />
    </svg>
  );
};
