import styled from "@emotion/styled";
import ClearIcon from "@mui/icons-material/Clear";
import Trash from "@mui/icons-material/DeleteRounded";
import FolderIcon from "@mui/icons-material/Folder";
import GetAppIcon from "@mui/icons-material/GetApp";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Search from "@mui/icons-material/Search";
import {
  Box,
  Grid,
  InputAdornment,
  Link,
  TablePagination,
  TextField,
  Typography,
  useTheme,
  IconButton as MuiIconButton,
} from "@mui/material";
import { useMemo } from "react";

import { spacing } from "assets/styles/theme";
import {
  DATE_TIME_FORMAT,
  getTzAwareDate,
  timeWithinLastMinute,
} from "libs/utilities/date-util";
import { bytesFormatter } from "libs/utilities/metrics-helper";

import {
  CopyToClipboardButton,
  Icon,
  IconButton,
  Loader,
} from "components/atoms";
import { BaseTable } from "components/molecules";

import type { File } from ".";
import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { BaseColumn } from "components/molecules/BaseTable";

type FilesExplorerProps = {
  currentFolder?: string;
  files?: File[];
  handleFolderClick: (name: string) => void;
  handlePageChange: (index: number) => void;
  onRowsPerPageChange?: (pageSize: number) => void;
  getFileURL: (name: string) => string;
  handleFileDelete: (file: File) => void;
  handleFileDownload: (name: string) => void;
  page: number;
  pageSize: number;
  disableNextPage: boolean;
  search: string;
  setSearch: (search: string) => void;
  selectedFile?: string;
};

export const FilesExplorer = ({
  currentFolder,
  files,
  handleFolderClick,
  handlePageChange,
  onRowsPerPageChange,
  getFileURL,
  handleFileDelete,
  handleFileDownload,
  page,
  pageSize,
  disableNextPage,
  search,
  setSearch,
  selectedFile,
}: FilesExplorerProps) => {
  const theme = useTheme() as AppThemeProps;

  const columns = useMemo(
    () => [
      {
        title: "Name",
        defaultSort: "asc",
        width: "50%",
        field: "file",
        nowrap: true,
        render: (rowData: File) => {
          const isNew =
            rowData.time_created &&
            timeWithinLastMinute(rowData.time_created) < 5;

          return (
            <Box display="flex" alignItems="center">
              <Icon
                component={
                  rowData.isFolder ? FolderIcon : InsertDriveFileOutlinedIcon
                }
                style={{
                  color: theme.palette.text.primary,
                  marginRight: spacing[12],
                }}
              />
              {rowData.isFolder ? (
                <Link
                  component={Typography}
                  variant="h5"
                  className="cursor-pointer"
                  onClick={() => handleFolderClick(rowData.file)}
                  sx={{ color: theme.palette.text.primary }}
                >
                  {rowData.file}
                </Link>
              ) : (
                <Typography
                  onClick={() => handleFileDownload(rowData.file)}
                  className="cursor-pointer"
                  variant="h5"
                  style={
                    isNew || rowData.file === selectedFile
                      ? {
                          color: theme.palette.secondary.main,
                          fontStyle: "italic",
                        }
                      : {}
                  }
                >
                  {rowData.file}
                </Typography>
              )}
            </Box>
          );
        },
      },
      {
        title: "Size",
        width: "10%",
        field: "size",
        render: (rowData: File) => bytesFormatter(rowData.size, "_"),
      },
      {
        title: "Created",
        width: "20%",
        field: "time_created",
        type: "datetime",
        nowrap: true,
        render: (rowData: File) =>
          rowData.time_created
            ? getTzAwareDate(rowData.time_created).format(DATE_TIME_FORMAT)
            : "_",
      },
      {
        disableClick: true,
        width: "20%",
        render: (rowData: File) =>
          !rowData.isFolder && (
            <ActionsContainer color={theme.palette.secondary.main}>
              <CopyToClipboardButton
                defaultLabel="Copy URI"
                contentToCopy={getFileURL(rowData.file)}
                htmlColor="primary"
                hoverColor="secondary"
              />
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleFileDownload(rowData.file);
                }}
                icon={GetAppIcon}
                hoverColor="secondary"
                htmlColor="primary"
                tooltip="Download"
              />
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleFileDelete(rowData);
                }}
                icon={Trash}
                hoverColor="secondary"
                htmlColor="primary"
                tooltip="Delete"
              />
            </ActionsContainer>
          ),
      },
    ],
    [
      handleFolderClick,
      getFileURL,
      handleFileDelete,
      handleFileDownload,
      theme,
      selectedFile,
    ]
  );

  return files ? (
    <Grid item xs={12}>
      <Grid display="flex" justifyContent="flex-end" item xs={12}>
        <TextField
          value={search || ""}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search sx={{ color: theme.palette.text.secondary }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <MuiIconButton onClick={() => setSearch("")}>
                  <ClearIcon sx={{ color: theme.palette.text.secondary }} />
                </MuiIconButton>
              </InputAdornment>
            ),
          }}
          placeholder="Search"
          id="standard-basic"
          sx={{
            width: 220,
          }}
          variant="standard"
        />
      </Grid>
      <BaseTable
        columns={columns as unknown as BaseColumn[]}
        data={generateTableData(files, currentFolder)}
        hasPagination={false}
        hasSearchBar={false}
        onRowClick={(_, row) => {
          if (row.isFolder) {
            handleFolderClick(row.file);
          } else {
            handleFileDownload(row.file);
          }
        }}
      />
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={-1}
        labelDisplayedRows={({ from, to }) => `${from}-${to}`}
        rowsPerPage={pageSize}
        page={page}
        onRowsPerPageChange={(event) =>
          onRowsPerPageChange?.(Number(event.target.value || 0))
        }
        onPageChange={(_event, page: number) => handlePageChange(page)}
        nextIconButtonProps={
          disableNextPage
            ? {
                disabled: disableNextPage,
              }
            : undefined
        }
      />
    </Grid>
  ) : (
    <Loader />
  );
};

const generateTableData = (
  fileItems: File[],
  currentFolder?: string
): File[] => {
  return fileItems.map((item) => ({
    ...item,
    file: currentFolder ? item.file.slice(currentFolder.length) : item.file,
  }));
};

const ActionsContainer = styled(Box)<{ color: string }>`
  display: flex;
  justify-content: flex-end;
  & .Action-icon {
    :hover {
      color: ${(props) => props.color};
    }
  }
`;
