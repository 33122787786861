/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * Programmatic interface to UbiOps
 * OpenAPI spec version: v2.1
 */

export type RevisionListStatus =
  typeof RevisionListStatus[keyof typeof RevisionListStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RevisionListStatus = {
  queued: "queued",
  building: "building",
  success: "success",
  failed: "failed",
  null: null,
} as const;
