import PipelineIcon from "@mui/icons-material/AccountTreeRounded";
import DeploymentIcon from "@mui/icons-material/WidgetsRounded";
import { Box, useTheme } from "@mui/material";
import { LogOut, LogIn } from "react-feather";

import { borderRadius, spacing } from "assets/styles/theme";

import {
  DetailsContainer,
  NodeContainer,
  NodeDetails,
  NodeName,
} from "./styles";
import {
  DIAGRAM_DRAG_ID_DEPLOYMENT,
  DIAGRAM_DRAG_ID_PIPELINE,
} from "../constants";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type {
  DeploymentList,
  PipelineList,
  PipelineVersionObjectList,
} from "libs/data/models";
import type { CSSProperties, DragEvent } from "react";

const getPipelineObjectFromSource = (
  object: DeploymentList | PipelineList,
  objectType: "deployment" | "pipeline"
): PipelineVersionObjectList => ({
  id: object?.id ?? "",
  name: object.name,
  reference_type: objectType,
  reference_name: object.name,
  version: null,
  input_type: object.input_type,
  // @ts-ignore
  input_fields: object.input_fields,
  output_type: object.output_type,
  // @ts-ignore
  output_fields: object.output_fields,
});

type DeploymentPipelineSidebarNodeProps = {
  object: DeploymentList | PipelineList;
  objectType: "deployment" | "pipeline";
  style?: CSSProperties;
  className?: string;
};

export const NodeDeploymentPipelineReadonly = ({
  object,
  style,
  className,
  objectType,
}: DeploymentPipelineSidebarNodeProps) => {
  const theme = useTheme() as AppThemeProps;

  const objectIsDeployment = objectType === "deployment";
  const id = objectIsDeployment
    ? DIAGRAM_DRAG_ID_DEPLOYMENT
    : DIAGRAM_DRAG_ID_PIPELINE;

  return (
    <NodeContainer
      backgroundColor={theme.palette.pipelineDiagram.nodeBackground}
      hoverColor={theme.palette.pipelineDiagram.nodeHover}
      outlineColor={theme.palette.specials.blue.bright}
      draggable
      onDragStart={(event: DragEvent<HTMLDivElement>) => {
        event.dataTransfer.setData(
          id,
          JSON.stringify(getPipelineObjectFromSource(object, objectType))
        );
      }}
      style={style}
      className={className}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          padding: spacing[4],
          background: objectIsDeployment
            ? theme.palette.pipelineDiagram.deploymentAccent
            : theme.palette.pipelineDiagram.pipelineAccent,
          borderTopLeftRadius: borderRadius[8],
          borderBottomLeftRadius: borderRadius[8],
        }}
      >
        <LogIn size={18} color={theme.palette.primary.contrastText} />
      </Box>

      <DetailsContainer
        color={
          objectIsDeployment
            ? theme.palette.pipelineDiagram.deploymentAccent
            : theme.palette.pipelineDiagram.pipelineAccent
        }
      >
        <NodeDetails>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
          >
            {" "}
            {objectType === "deployment" ? (
              <DeploymentIcon
                fontSize="medium"
                style={{
                  marginRight: spacing[8],
                  color: theme.palette.pipelineDiagram.deploymentAccent,
                }}
              />
            ) : (
              <PipelineIcon
                style={{
                  marginRight: spacing[8],
                  color: theme.palette.pipelineDiagram.pipelineAccent,
                }}
              />
            )}
            <NodeName>{object.name}</NodeName>
          </Box>
        </NodeDetails>
      </DetailsContainer>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          padding: spacing[4],
          background:
            objectType === "deployment"
              ? theme.palette.pipelineDiagram.deploymentAccent
              : theme.palette.pipelineDiagram.pipelineAccent,
          borderTopRightRadius: borderRadius[8],
          borderBottomRightRadius: borderRadius[8],
        }}
      >
        <LogOut size={18} color={theme.palette.primary.contrastText} />
      </Box>
    </NodeContainer>
  );
};
