import styled from "@emotion/styled";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import FileIcon from "@mui/icons-material/DescriptionOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { TreeView, TreeItem } from "@mui/lab";
import { Box, Typography, useTheme } from "@mui/material";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
// @ts-expect-error
import id from "uuid/v4";

import { BucketIcon } from "assets/images/BucketLogo";
import { spacing } from "assets/styles/theme";
import { WarningIcon } from "components/atoms/Icon/WarningIcon";
import { FILES_PERMISSIONS } from "libs/constants/permissions";
import {
  filesGet,
  filesList,
  useBucketsList,
} from "libs/data/endpoints/files/files";
import { validatePermissionsForUser } from "libs/data/endpoints/roles/roles";

import { ActionDialog, InfoTooltip, Loader, TextField } from "components/atoms";

import { FILE_REF } from "../constants";
import { destructFilePath } from "../utils";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { SyntheticEvent } from "react";

const StyledTreeView = styled(TreeView)<{ backgroundColor: string }>`
  "& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label":
    {
      backgroundColor: ${(props) => props.backgroundColor},
    },
  "& .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label":
    {
      backgroundColor: ${(props) => props.backgroundColor},
    },
  ".MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label": {
    backgroundColor: "unset",
  },
`;

const StyledTreeItem = styled(TreeItem)<{ backgroundColor: string }>`
  "& .MuiTreeItem-content ": {
    "& :hover": {
      backgroundColor: ${(props) => props.backgroundColor},
    },
  },
  "& .MuiTreeItem-content.icon": {
    "& :hover": {
      background: "initial",
      cursor: "initial",
    },
  },
  "& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label":
    {
      backgroundColor: ${(props) => props.backgroundColor},
    },
`;
interface TreeItemRecord {
  parentId: string | null;
  label: string;
  bucket: string;
  id: string;
  type: string;
  prefix: string;
}

interface TreeData {
  records: TreeItemRecord[];
  selected: string;
  expanded: string[];
}

export interface BucketTreeViewProps {
  projectName: string;
  onSelect: (bucketName?: string, prefix?: string, fileName?: string) => void;
  bucketsWithPermission?: string[];
  filesAreSelectable?: boolean;
  open: boolean;
  dialogTitle: string;
  actionButtonText: string;
  onClose: () => void;
  filterFiles?: string[];
}

export const BucketTreeView = ({
  bucketsWithPermission,
  projectName,
  onSelect,
  filesAreSelectable = true,
  open,
  onClose,
  actionButtonText,
  dialogTitle,
  filterFiles,
}: BucketTreeViewProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [fileUri, setFileUri] = useState("");
  const [actionDisabled, setActionDisabled] = useState(true);
  const [tree, setTree] = useState<TreeData>({
    records: [],
    selected: "",
    expanded: [],
  });
  const { data: buckets } = useBucketsList(projectName);
  const theme = useTheme() as AppThemeProps;

  useEffect(() => {
    if (!tree.records.length && buckets?.length) {
      setTree({
        records: buckets.map(({ name }) => ({
          type: "bucket",
          label: name,
          bucket: name,
          parentId: null,
          id: id(),
          prefix: "",
        })),
        selected: "",
        expanded: [],
      });
    }
  }, [buckets, tree.records.length, filesAreSelectable]);

  const collapseNode = (nodeId: string) => {
    setTree((tree) => {
      return {
        expanded: tree.expanded.filter((id) => id !== nodeId),
        selected: filesAreSelectable ? "" : nodeId,
        records: tree.records.filter((record) => record.parentId !== nodeId),
      };
    });
  };

  const expandBucketWithoutReadOrWrite = (
    node: TreeItemRecord,
    nodeId: string,
    entity: "deployment" | "user" = "user"
  ) => {
    const start = entity === "user" ? "You don't" : "The deployment doesn't";
    const label = filesAreSelectable
      ? `${start} have permission to view files in this bucket`
      : `${start} have permission to upload or view files in this bucket`;
    setTree((tree) => {
      return {
        selected: "",
        expanded: [...tree.expanded, nodeId],
        records: tree.records.concat({
          label: label,
          bucket: node.bucket,
          parentId: node.id,
          prefix: "",
          id: id(),
          type: "warning",
        }),
      };
    });
  };

  const expandBucketWithoutRead = (node: TreeItemRecord, nodeId: string) => {
    setTree((tree) => {
      return {
        selected: "",
        expanded: [...tree.expanded, nodeId],
        records: tree.records.concat({
          label: "You don't have permission to view files of this bucket",
          bucket: node.bucket,
          parentId: node.id,
          prefix: "",
          id: id(),
          type: "warning",
        }),
      };
    });
  };

  const handleSelect = async (
    _event: SyntheticEvent<Element, Event>,
    nodeId: string
  ) => {
    const currentNode = tree.records.find((record) => record.id === nodeId);
    const nodeType: string | undefined = currentNode?.type;
    const nodeIsAlreadyExpanded =
      tree.expanded.includes(nodeId) && nodeType !== "file";

    const parentNode = tree.records.find(
      (record) => record.id === currentNode?.parentId
    );
    setActionDisabled(true);
    if (!filesAreSelectable) setActionDisabled(false);
    if (error) setError("");
    if (nodeType === "warning") return;
    if (nodeIsAlreadyExpanded) {
      collapseNode(nodeId);
      currentNode &&
        setFileUri((fileUri) => {
          const index = fileUri.indexOf(currentNode.label);

          return fileUri.substring(0, index);
        });

      // when all buckets are closed disable action
      if (tree.expanded.length < 2) setActionDisabled(true);
    } else if (nodeType === "file") {
      setTree((tree) => {
        return {
          selected: filesAreSelectable ? nodeId : "",
          expanded: tree.expanded,
          records: setFilePrefix(tree.records, nodeId, parentNode),
        };
      });
      if (filesAreSelectable) {
        currentNode &&
          setFileUri(
            FILE_REF +
              currentNode?.bucket +
              "/" +
              currentNode?.prefix +
              currentNode?.label
          );
        setActionDisabled(false);
      }
    } else {
      setIsLoading(true);
      if (currentNode && nodeType === "bucket") {
        handleBucketSelect(currentNode, nodeId);
      }
      // The selected node is a folder here
      else {
        getFiles(nodeId).then((files) => {
          setTree((tree) => {
            return {
              selected: filesAreSelectable ? "" : nodeId,
              expanded: [...new Set([...tree.expanded, nodeId])],
              records: tree.records.concat(files),
            };
          });
          setIsLoading(false);
        });
        currentNode &&
          setFileUri(
            FILE_REF + currentNode?.bucket + "/" + currentNode?.prefix
          );
      }
    }
  };

  const checkPermissions = async (node: TreeItemRecord | string) => {
    const bucketName = typeof node === "string" ? node : node.label;
    const permissions = await validatePermissionsForUser(projectName, {
      resource: bucketName,
      resource_type: "bucket",
      permissions: [FILES_PERMISSIONS.create, FILES_PERMISSIONS.list],
    });

    return permissions.map((item) => item.success);
  };

  const handleBucketSelect = async (node: TreeItemRecord, nodeId: string) => {
    const [filesCreatePermission, filesListPermission] = await checkPermissions(
      node
    );
    if (!filesAreSelectable) setActionDisabled(!filesCreatePermission);

    if (!filesListPermission) {
      if (!filesCreatePermission) {
        expandBucketWithoutReadOrWrite(node, nodeId);
      } else {
        expandBucketWithoutRead(node, nodeId);
      }

      setIsLoading(false);
    } else if (filesListPermission) {
      const createPermissionWarningNode =
        !filesCreatePermission && !filesAreSelectable
          ? {
              label: "You don't have permission to upload files to this bucket",
              bucket: node.bucket,
              parentId: node.id,
              id: id(),
              type: "warning",
            }
          : null;

      if (
        bucketsWithPermission &&
        !bucketsWithPermission.includes(node.label)
      ) {
        expandBucketWithoutReadOrWrite(node, nodeId, "deployment");
      }

      getFiles(nodeId).then((files) => {
        setTree((tree) => {
          if (files.length === 0) {
            return {
              selected: "",
              expanded: [...tree.expanded, nodeId],
              records: tree.records.concat({
                label: "This bucket has no files",
                bucket: node.bucket,
                parentId: node.id,
                prefix: "",
                id: id(),
                type: "text",
              }),
            };
          } else {
            return {
              selected:
                filesCreatePermission && !filesAreSelectable ? nodeId : "",
              expanded: [...new Set([...tree.expanded, nodeId])],
              records: tree.records.concat(
                (
                  [createPermissionWarningNode].filter(
                    Boolean
                  ) as TreeItemRecord[]
                ).concat(files)
              ),
            };
          }
        });
        setIsLoading(false);
      });

      setFileUri(FILE_REF + node.label + "/");
    }
  };

  const setFilePrefix = (
    records: TreeItemRecord[],
    nodeId: string,
    parentNode?: TreeItemRecord
  ) => {
    const expandedNodeIndex = records.findIndex(
      (record) => record.id === nodeId
    );
    const correctFilePrefixRecords = records;
    if (parentNode) {
      correctFilePrefixRecords[expandedNodeIndex].prefix = parentNode.prefix;
    }

    return correctFilePrefixRecords;
  };

  const getAllFiles = useCallback(
    async (bucketName: string, prefix: string | undefined) => {
      const first = await filesList(projectName, bucketName, {
        prefix,
        delimiter: "/",
      });

      let continuationToken = first.continuation_token;

      while (continuationToken) {
        const additional = await filesList(projectName, bucketName, {
          prefix,
          delimiter: "/",
          continuation_token: continuationToken,
        });

        first.files = first.files.concat(additional.files);
        first.prefixes = first.prefixes.concat(additional.prefixes);

        continuationToken = additional.continuation_token;
      }

      return first;
    },
    [projectName]
  );

  const getFiles = async (parentId: string) => {
    const parent = tree.records.find(
      (record) => record.id === parentId
    ) as TreeItemRecord;
    const list = await getAllFiles(parent.bucket, parent.prefix);

    const files: TreeItemRecord[] = list.files.map(({ file }) => ({
      label: file.substring(parent.prefix?.length || 0),
      prefix: parent.prefix,
      parentId,
      bucket: parent.bucket,
      type: "file",
      id: id(),
    }));

    const directories: TreeItemRecord[] = list.prefixes.map((prefix) => ({
      label: prefix.substring(parent.prefix?.length || 0),
      parentId,
      bucket: parent.bucket,
      type: "directory",
      id: id(),
      prefix: parent.prefix + prefix.substring(parent.prefix?.length || 0),
    }));

    return directories.concat(files);
  };

  const handleFileUriChange = (text: string) => {
    setActionDisabled(true);
    if (text.length) validateURI(text);
    setFileUri(text);
  };

  const validateURI = debounce(async (text: string) => {
    let err = "";
    if (!text.startsWith(FILE_REF)) err = "URI format is incorrect";
    else {
      const { bucket, path, file } = destructFilePath(text);
      if (
        bucket &&
        bucketsWithPermission &&
        !bucketsWithPermission.includes(bucket)
      )
        err =
          "The deployment doesn't have permission to view files in this bucket";
      else
        await filesGet(projectName, bucket, path + file)
          .then(() => {
            err = "";
          })
          .catch((error) => (err = error.message));
    }
    setActionDisabled(!!err);
    setError(err);
  }, 1000);

  const submitSelection = () => {
    const selectedItem = tree.records.find((item) => item.id === tree.selected);
    if (selectedItem) {
      onSelect(
        selectedItem?.bucket,
        selectedItem?.prefix,
        filesAreSelectable && selectedItem?.type === "file"
          ? selectedItem.label
          : undefined
      );
    } else if (!error) {
      const { bucket, path, file } = destructFilePath(fileUri);
      onSelect(bucket, path, file);
    }
  };

  return (
    <ActionDialog
      open={open}
      dialogTitle={dialogTitle}
      onAction={submitSelection}
      actionButtonText={actionButtonText}
      disableActionButton={actionDisabled}
      onClose={onClose}
    >
      <Box display="flex" flexDirection="column">
        {isLoading && (
          <Box
            position="absolute"
            top="50%"
            right="50%"
            style={{ background: "transparent", zIndex: "10" }}
          >
            <Loader />
          </Box>
        )}
        {filesAreSelectable && (
          <>
            <Typography variant="h6">
              Enter a file URI in the textbox, or browse your buckets below.
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              width="95%"
              marginBottom={spacing[8]}
            >
              <TextField
                name={"uri"}
                size="small"
                label={"File uri"}
                error={!!error}
                helperText={error}
                value={fileUri}
                onChange={({ target: { value } }) => handleFileUriChange(value)}
              />
              <InfoTooltip>
                A file URI should be formatted as &apos;ubiops-file://
                &quot;bucket-name&quot;/ &quot;path-to-file-within-bucket&quot;
                &apos;.
              </InfoTooltip>
            </Box>
          </>
        )}
        <StyledTreeView
          backgroundColor={theme.palette.secondary.light}
          expanded={tree.expanded}
          selected={tree.selected}
          onNodeSelect={handleSelect}
        >
          {renderFolderStructure(
            theme.palette.secondary.light,
            theme.palette.error.main,
            tree.records,
            null,
            !filesAreSelectable,
            filterFiles
          )}
        </StyledTreeView>
      </Box>
    </ActionDialog>
  );
};

const renderLabel = (record: TreeItemRecord, errorColor: string) => (
  <Box
    display="flex"
    alignItems="center"
    style={
      record.type === "warning"
        ? {
            color: errorColor,
            cursor: "default",
            fontStyle: "italic",
          }
        : record.type === "text"
        ? {
            fontStyle: "italic",
          }
        : {}
    }
  >
    <Box display="flex" mr={spacing[6]} className="icon">
      {record.type === "warning" && (
        <WarningIcon
          color={errorColor}
          style={{
            height: spacing[20],
            width: spacing[20],
            cursor: "initial",
          }}
        />
      )}
      {record.type === "file" && <FileIcon fontSize="small" />}
      {record.type === "directory" && <FolderOpenIcon fontSize="small" />}
      {record.type === "bucket" && (
        <Box width={15}>
          <BucketIcon />
        </Box>
      )}
      {record.type === "text" && null}
    </Box>
    {record.label}
  </Box>
);

const renderFolderStructure = (
  backgroundColor: string,
  errorColor: string,
  records: TreeItemRecord[],
  parent: string | null,
  hideFiles = false,
  filterFiles: string[] = []
) => {
  return records
    .filter(({ parentId }) => parentId === parent)
    .filter(({ type }) => !hideFiles || type !== "file")
    .filter(
      ({ label, type }) =>
        type !== "file" ||
        !filterFiles.length ||
        filterFiles.some((term) => label.includes(term))
    )
    .map((item) => (
      <StyledTreeItem
        backgroundColor={backgroundColor}
        key={item.label}
        nodeId={item.id}
        label={renderLabel(item, errorColor)}
        collapseIcon={
          <ExpandMoreIcon
            style={{
              display:
                item.type === "file" ||
                item.type === "warning" ||
                item.type === "text"
                  ? "none"
                  : "",
            }}
          />
        }
        expandIcon={
          <ChevronRightIcon
            style={{
              display:
                item.type === "file" ||
                item.type === "warning" ||
                item.type === "text"
                  ? "none"
                  : "",
            }}
          />
        }
      >
        {renderFolderStructure(
          backgroundColor,
          errorColor,
          records,
          item.id,
          hideFiles,
          filterFiles
        )}
        {item.type !== "file" ? <TreeItem nodeId={id()} /> : null}
      </StyledTreeItem>
    ));
};
