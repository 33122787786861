import styled from "@emotion/styled";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import { Tooltip, useTheme } from "@mui/material";

import { borderRadius, getBorders, spacing } from "assets/styles/theme";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

type ActionButtonProps = {
  onDeleteClicked: () => void;
  onInfoClicked?: () => void;
  onEditClicked?: () => void;
  onErrorPolicyClicked?: () => void;
};

export const ActionButtons = ({
  onDeleteClicked,
  onInfoClicked,
  onEditClicked,
  onErrorPolicyClicked,
}: ActionButtonProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <ActionButtonsContainer
      backgroundColor={theme.palette.pipelineDiagram.nodeBackground}
      color={theme.palette.text.primary}
      hoverColor={theme.palette.secondary.main}
      theme={theme}
    >
      {onErrorPolicyClicked && (
        <Tooltip title="error policy" placement="top">
          <WarningIcon
            onClick={onErrorPolicyClicked}
            className="cursor-pointer"
            style={{ marginRight: spacing[4] }}
          />
        </Tooltip>
      )}
      {onInfoClicked && (
        <Tooltip title="info" placement="top">
          <InfoIcon
            onClick={onInfoClicked}
            className="cursor-pointer"
            style={{ marginRight: spacing[4] }}
          />
        </Tooltip>
      )}
      {onEditClicked && (
        <Tooltip title="edit" placement="top">
          <EditIcon onClick={onEditClicked} className="cursor-pointer" />
        </Tooltip>
      )}
      <Tooltip title="delete" placement="top">
        <DeleteIcon onClick={onDeleteClicked} className="cursor-pointer" />
      </Tooltip>
    </ActionButtonsContainer>
  );
};

const ActionButtonsContainer = styled.div<{
  backgroundColor: string;
  color: string;
  hoverColor: string;
  theme: AppThemeProps;
}>`
  display: flex;
  border-radius: ${borderRadius[8]};
  background: ${(props) => props.backgroundColor};
  border: ${(props) => getBorders(props.theme).tertiary};
  margin-bottom: 0.5rem;
  padding: ${spacing[6]};
  justify-content: space-between;
  align-items: center;
  cursor: auto;
  user-select: text;
  height: 35px;
  width: fit-content;
  .MuiSvgIcon-root {
    color: ${(props) => props.color};

    &:hover {
      color: ${(props) => props.hoverColor};
    }
  }
`;
