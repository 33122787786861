import Plus from "@mui/icons-material/AddBoxRounded";
import Trash from "@mui/icons-material/DeleteRounded";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import { readableColor } from "polished";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import { spacing } from "assets/styles/theme";
import { useStringToColor } from "assets/styles/theme/utils/useStringToColor";
import { FIELD_NAME } from "libs/constants/fields";
import { useProjectUpdate } from "libs/data/customized/projects";
import { useProjectsGet } from "libs/data/endpoints/projects/projects";
import { explanations } from "libs/utilities/explanations";
import validators from "libs/utilities/validators";

import {
  Card,
  Dialog,
  InfoTooltip,
  LabelChip,
  PrimaryButton,
} from "components/atoms";
import { BaseTable, FormikTextInput } from "components/molecules";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { BaseColumn, BaseRow } from "components/molecules/BaseTable";
import type { FormikProps, FormikState, FormikValues } from "formik";

export const CORSDomains = () => {
  const { organizationName, projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const { data: projectDetails, mutate } = useProjectsGet(projectName);
  const projectUpdate = useProjectUpdate(
    organizationName,
    "CORS domains were updated."
  );

  const theme = useTheme() as AppThemeProps;

  const [isEditDomainDialogOpen, setIsEditDomainDialogOpen] = useState(false);
  const [domainToDelete, setdomainToDelete] = useState("");
  const [addedDomains, setAddedDomains] = useState<string[]>([]);
  const [newDomainList, setNewDomainList] = useState<string[]>([]);

  const domains = useMemo(
    () =>
      projectDetails?.cors_origins?.map((origin) => ({ name: origin })) || [],
    [projectDetails?.cors_origins]
  );

  const handleDomainAdd = (
    values: { [FIELD_NAME]?: string },
    resetForm: (
      nextState?: Partial<FormikState<FormikValues>> | undefined
    ) => void
  ) => {
    const newDomains =
      values?.[FIELD_NAME]?.replace(/\s/g, "").split(",") || [];

    setAddedDomains([...addedDomains, ...newDomains]);
    resetForm();
  };

  const handleDomainDelete = async (label: string) => {
    await projectUpdate(projectName, {
      ...projectDetails,
      cors_origins: projectDetails?.cors_origins?.filter(
        (domain) => domain !== label
      ),
    });
    await mutate();
    setdomainToDelete("");
  };

  const handleDomainRemove = (label: string) => {
    setAddedDomains(addedDomains?.filter((domain) => domain !== label));
  };

  const handleSubmit = async () => {
    await projectUpdate(projectName, {
      ...projectDetails,
      cors_origins: [...(projectDetails?.cors_origins || []), ...newDomainList],
    });
    await mutate();
    setAddedDomains([]);
    setIsEditDomainDialogOpen(false);
  };

  const validate = (values: { [FIELD_NAME]?: string }) => {
    if (!values[FIELD_NAME]) return;
    const errors: { [FIELD_NAME]?: string } = {};

    const domains = values[FIELD_NAME]?.replace(/\s/g, "").split(",");
    if (
      !domains?.every((domain) => validators.callbackUrl.pattern.test(domain))
    )
      errors[FIELD_NAME] = "Invalid domain";
    else setNewDomainList(domains);

    return errors;
  };
  const columns = [
    {
      title: "Added domains",
      field: "name",
      render: (rowData: BaseRow & { name: string }) => (
        <DomainChip origin={rowData.name} />
      ),
    },
    {
      field: "",
      align: "right",

      render: (rowData: BaseRow & { name: string }) => (
        <IconButton
          color="primary"
          onClick={() => {
            setdomainToDelete(rowData.name);
          }}
        >
          <Trash />
        </IconButton>
      ),
    },
  ];

  return (
    <Grid item xs={12}>
      <Card>
        <BaseTable
          columns={columns as BaseColumn[]}
          data={domains}
          action={
            <PrimaryButton
              size="small"
              startIcon={<Plus />}
              onClick={() => setIsEditDomainDialogOpen(true)}
            >
              Add domain
            </PrimaryButton>
          }
        />
      </Card>
      <Dialog
        open={isEditDomainDialogOpen}
        onClose={() => {
          setAddedDomains([]);
          setIsEditDomainDialogOpen(false);
        }}
        title="Add domain(s)"
        Actions={
          <PrimaryButton
            onClick={handleSubmit}
            disabled={!newDomainList.length}
          >
            Add domain
          </PrimaryButton>
        }
      >
        {explanations.projects.domainDialogDescription}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          gap={2}
        >
          <Formik
            onSubmit={(values, actions) =>
              handleDomainAdd(values, actions.resetForm)
            }
            initialValues={{}}
            validateOnChange
            validate={validate}
          >
            {(control: FormikProps<FormikValues>) => {
              const { isValid, values, resetForm } = control;

              return (
                <>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    marginTop={spacing[16]}
                    width={"95%"}
                  >
                    <FormikTextInput
                      name={FIELD_NAME}
                      control={control}
                      label="Domain"
                      placeholder="https://my-website.com, https://example.com"
                      showErrors
                      validation={Yup.string().test(
                        "all-valid-domains",
                        validators.callbackUrl.message,
                        (value) => {
                          if (!value) return true;

                          const domains = value?.replace(/\s/g, "").split(",");
                          if (
                            domains?.every((domain) =>
                              validators.callbackUrl.pattern.test(domain)
                            )
                          )
                            return true;
                          else return false;
                        }
                      )}
                      onKeyUp={(e) => {
                        validate(values);
                        if (e.key === "Enter" && isValid) {
                          handleDomainAdd(values, resetForm);
                        }
                      }}
                    />
                    <InfoTooltip>
                      {explanations.projects.corsHeader}
                    </InfoTooltip>
                  </Box>
                </>
              );
            }}
          </Formik>
          {addedDomains.length ? (
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="body2" color={theme.palette.disabled.dark}>
                Added domains:
              </Typography>
              <Box display="flex" gap={2}>
                {addedDomains?.map((domain) => (
                  <LabelChip
                    key={domain}
                    label={domain}
                    capital={false}
                    onDelete={() => handleDomainRemove(domain)}
                  />
                ))}{" "}
              </Box>
            </Box>
          ) : null}
        </Box>
      </Dialog>
      <Dialog
        open={!!domainToDelete}
        onClose={() => setdomainToDelete("")}
        title="Remove domain"
        Actions={
          <PrimaryButton onClick={() => handleDomainDelete(domainToDelete)}>
            Remove
          </PrimaryButton>
        }
      >
        <Typography>
          Are you sure you want to remove domain
          <b>&rdquo;{domainToDelete}&rdquo;</b> from your list of allowed
          domains?
        </Typography>
      </Dialog>
    </Grid>
  );
};

const DomainChip = ({ origin }: { origin: string }) => {
  return (
    <Chip
      label={origin}
      style={{
        backgroundColor: useStringToColor(origin),
        color: readableColor(useStringToColor(origin)),
      }}
    />
  );
};
