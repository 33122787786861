/**
 * Generated by orval 🍺
 * Do not edit manually.
 * UbiOps
 * Programmatic interface to UbiOps
 * OpenAPI spec version: v2.1
 */

export type InstanceUpdateStatus =
  typeof InstanceUpdateStatus[keyof typeof InstanceUpdateStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InstanceUpdateStatus = {
  pending: "pending",
  initialising: "initialising",
  running: "running",
  stopping: "stopping",
  stopping_forced: "stopping_forced",
  stopped: "stopped",
  ready: "ready",
  failed: "failed",
  shutdown: "shutdown",
  terminated: "terminated",
} as const;
