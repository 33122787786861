import SecurityIcon from "@mui/icons-material/Security";
import { Box, useTheme } from "@mui/material";
import { useState, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Switch, useHistory, useRouteMatch } from "react-router-dom";

import { EngineeringTeamIllustration } from "assets/images/EngineeringTeam";
import { IlluIncorrectPermissions } from "assets/images/IlluIncorrectPermissions";
import { spacing } from "assets/styles/theme";
import { BaseUrlContext, RootUrlContext } from "libs/contexts";
import { TWO_FACTOR_AUTH_ERROR } from "libs/data/axios/constants";
import {
  useOrganizationsFeaturesGet,
  useOrganizationsGet,
  useOrganizationsList,
} from "libs/data/endpoints/organizations/organizations";
import { useProjectsList } from "libs/data/endpoints/projects/projects";
import { OrganizationDetailStatus } from "libs/data/models";
import { ENV_NAMES, env } from "libs/env";
import { useInterval } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import { routes } from "routes";
import {
  setOrganization,
  setOrganizationFeatures,
  setOrganizations,
  setProjects,
  useGetCurrentOrganization,
} from "store/features";

import {
  Dialog,
  DialogHeader,
  DialogHeaderTitle,
  DialogWarningHeader,
  FullScreenLoader,
  PrimaryButton,
} from "components/atoms";
import {
  MainLayout,
  NewAccountDialog,
  OrganizationSelectDialog,
} from "components/organisms";
import { Route } from "components/utilities";

import OrganizationRoutesContainer from "./:organizationName";
import { organizationsRoutes } from "./constants";

import type { OrganizationList } from "libs/data/models";

const basePath = routes.organizations[":organizationName"](":organizationName");
const baseUrl = routes.organizations;

const OrganizationsRoutesContainer = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const currentOrganization = useGetCurrentOrganization();

  const orgInLocalStorage = env.get(ENV_NAMES.LAST_SEEN_ORGANIZATION);
  const projectInLocalStorage = env.get(ENV_NAMES.LAST_SEEN_PROJECT);

  const { data: organizations, error: organizationsError } =
    useOrganizationsList({
      swr: { revalidateOnMount: true, revalidateOnFocus: true },
    });
  const organizationsLoading = !organizations && !organizationsError;

  const is2faError =
    organizationsError?.response?.data?.error_type === TWO_FACTOR_AUTH_ERROR;

  // last visited org stored in localstorage
  const savedOrganization = useMemo(
    () =>
      organizations?.find(({ name, id }) =>
        [name, id].includes(orgInLocalStorage)
      ),
    [orgInLocalStorage, organizations]
  );

  const [isNewAccountDialogOpen, setIsNewAccountDialogOpen] = useState(false);
  const [organizationName, setOrganizationName] = useState(
    (currentOrganization?.name || savedOrganization?.name) ?? ""
  );
  const [isSelectDialogOpen, setIsSelectDialogOpen] = useState(false);

  const { data: projects, error: projectsError } = useProjectsList(
    {
      organization: organizationName,
    },
    {
      swr: {
        enabled: Boolean(organizationName),
        revalidateOnMount: true,
        revalidateOnFocus: true,
      },
    }
  );
  const projectsLoading = !projects && !projectsError;

  const { data: selectedOrganization, mutate } =
    useOrganizationsGet(organizationName);

  const { data: organizationFeatures } =
    useOrganizationsFeaturesGet(organizationName);

  useEffect(() => {
    if (currentOrganization) {
      setOrganizationName(currentOrganization?.name);
    } else if (!currentOrganization && savedOrganization)
      setOrganizationName(savedOrganization.name);
  }, [currentOrganization, savedOrganization]);

  useEffect(() => {
    projects && dispatch(setProjects(projects));
    organizations && dispatch(setOrganizations(organizations));
  }, [projects, dispatch, organizations]);

  useEffect(() => {
    if (organizationFeatures)
      dispatch(setOrganizationFeatures(organizationFeatures));
  }, [organizationFeatures, dispatch]);

  useEffect(() => {
    if (selectedOrganization) dispatch(setOrganization(selectedOrganization));
  }, [selectedOrganization, dispatch]);

  const savedProject = useMemo(
    () => projects?.find(({ id }) => id === projectInLocalStorage),
    [projectInLocalStorage, projects]
  );

  // Handle redirect cases after login
  useEffect(() => {
    if (organizationsLoading) return; // Wait for organization data to load

    if (!organizations?.length) {
      // Case 1: New user with no organizations
      setIsNewAccountDialogOpen(true);
    } else if (!orgInLocalStorage) {
      // Case 2: User with no saved organization/project
      setIsSelectDialogOpen(true);
    } else if (savedOrganization && match.isExact) {
      // Case 3: User with saved organization/project
      if (savedProject)
        history.push(
          routes.organizations[":organizationName"](savedOrganization.name)
            .projects[":projectName"](savedProject.name)
            .dashboard.index()
        );
      else
        history.push(
          routes.organizations[":organizationName"](
            savedOrganization.name
          ).dashboard.index()
        );
    }
  }, [
    organizationsLoading,
    orgInLocalStorage,
    savedOrganization,
    savedProject,
    match.isExact,
    history,
    organizations?.length,
  ]);

  const selectOrganization = (organization: OrganizationList) => {
    setOrganizationName(organization.name);
  };

  const isOrgBeingVerified = selectedOrganization?.status === "pending";
  useInterval(
    () => {
      if (isOrgBeingVerified) mutate();
    },
    [isOrgBeingVerified, mutate],
    6000
  );

  const displayLoader =
    (Boolean(organizationName) && projectsLoading) || organizationsLoading;

  if (organizationsError && !is2faError) {
    return null;
  } else {
    return (
      <BaseUrlContext.Provider value={match.url}>
        <FullScreenLoader displayed={displayLoader} />
        <RootUrlContext.Provider
          value={
            currentOrganization
              ? baseUrl[":organizationName"](currentOrganization.name).index()
              : baseUrl.index()
          }
        >
          <MainLayout routes={organizationsRoutes}>
            {(!match.isExact || currentOrganization) && (
              <Switch>
                <Route
                  path={basePath.index()}
                  component={OrganizationRoutesContainer}
                />

                {currentOrganization && (
                  <Redirect
                    to={baseUrl[":organizationName"](
                      currentOrganization.name
                    ).index()}
                  />
                )}
              </Switch>
            )}
            <OrganizationSelectDialog
              onClose={() => setIsSelectDialogOpen(false)}
              onOrganizationSelection={selectOrganization}
              open={isSelectDialogOpen}
            />

            <NewAccountDialog
              open={isNewAccountDialogOpen}
              closeDialog={setIsNewAccountDialogOpen}
              setOrganizationName={setOrganizationName}
            />

            <Dialog
              title="Your account is being verified"
              onClose={() => {}}
              dismissible={false}
              container=".main-layout"
              open={isOrgBeingVerified}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap={2}
              >
                <EngineeringTeamIllustration />
                {explanations.organizationVerification}
              </Box>
            </Dialog>

            <Dialog
              open={
                currentOrganization?.status ===
                OrganizationDetailStatus.suspended_manual
              }
              dismissible={false}
              onClose={() => {}}
              container=".main-layout"
              Header={
                <DialogHeader
                  header={
                    <DialogHeaderTitle
                      style={{
                        color: theme.palette.error.main,
                        textAlign: "center",
                      }}
                    >
                      Your account is blocked
                    </DialogHeaderTitle>
                  }
                />
              }
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap={2}
                padding={spacing[8]}
              >
                <Box
                  width={"100%"}
                  display="flex"
                  justifyContent={"center"}
                  alignItems="center"
                >
                  <IlluIncorrectPermissions />
                </Box>
                {explanations.suspendedOrganization}
              </Box>
            </Dialog>
            <Dialog
              open={is2faError}
              dismissible={false}
              onClose={() => {}}
              container=".main-layout"
              Header={
                <DialogWarningHeader title="Two factor authentication required" />
              }
              Actions={
                <PrimaryButton
                  onClick={() =>
                    history.push({
                      pathname: routes.profile.twoFa.create.index(),
                      state: { forced2FA: true },
                    })
                  }
                  startIcon={<SecurityIcon />}
                >
                  Add 2FA device
                </PrimaryButton>
              }
            >
              {explanations.security.twoFactorAuthRequired}
            </Dialog>
          </MainLayout>
        </RootUrlContext.Provider>
      </BaseUrlContext.Provider>
    );
  }
};

export default OrganizationsRoutesContainer;
