import Edit from "@mui/icons-material/Edit";
import { Box, useTheme } from "@mui/material";
import { Formik } from "formik";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { DetailsContainer } from "components/molecules/PageLayout";
import { FIELD_PROJECT_NAME } from "libs/constants/fields";
import { useProjectUpdate } from "libs/data/customized/projects";
import {
  projectsUpdate,
  useProjectsGet,
} from "libs/data/endpoints/projects/projects";
import { explanations } from "libs/utilities/explanations";
import { createErrorNotification } from "libs/utilities/notifications";
import validators from "libs/utilities/validators";
import { routes } from "routes";

import {
  Dialog,
  PageTabs,
  PrimaryButton,
  TextButton,
  WarningText,
} from "components/atoms";
import { ControlledTextInput } from "components/molecules";

import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { AxiosError } from "axios";
import type { FormikProps, FormikValues } from "formik";
import type { ProjectDetail } from "libs/data/models";
import type { ReactNode } from "react";

type ProjectSettingsProps = { children: ReactNode };

export const ProjectSettingsOverview = ({ children }: ProjectSettingsProps) => {
  const theme = useTheme() as AppThemeProps;
  const { organizationName, projectName } =
    useParams<{
      projectName: string;
      environmentName: string;
      organizationName: string;
    }>();

  const [newProjectName, setNewProjectName] = useState<string>(projectName);
  const updateProject = useProjectUpdate(organizationName);

  const { data: currentProject, mutate } = useProjectsGet(projectName, {
    swr: { swrKey: `/projects/${newProjectName}` },
  });

  const dispatch = useDispatch();

  const [editNameDialogOpen, setEditNameDialogOpen] = useState<boolean>(false);

  const baseUrl = useMemo(
    () =>
      routes.organizations[":organizationName"](organizationName).projects[
        ":projectName"
      ](projectName).projectSettings,
    [organizationName, projectName]
  );
  const tabs = [
    {
      link: baseUrl.general.index(),
      label: "General",
    },
    {
      link: baseUrl.instanceTypes.index(),
      label: "Instance types",
    },
    {
      link: baseUrl.instanceTypeGroups.index(),
      label: "Instance type groups",
    },
    {
      link: baseUrl.environmentVariables.index(),
      label: "Environment variables",
    },
    {
      link: baseUrl.corsDomains.index(),
      label: "CORS domains",
    },
  ];

  const onSubmit = async () => {
    try {
      // making the change via orval:
      await projectsUpdate(projectName, {
        ...currentProject,
        name: newProjectName,
        cors_origins: currentProject?.cors_origins || [],
      })
        .then((response) => mutate({ response } as unknown as ProjectDetail))
        // updating the name
        .then(async () => {
          await updateProject(newProjectName, {
            name: newProjectName,
          });
        })
        // redirecting to new name URL
        .then(() => {
          location.replace(
            `/organizations/${organizationName}/projects/${newProjectName}/project-settings/general`
          );
        });
    } catch (e: unknown) {
      const error = e as AxiosError;
      dispatch(createErrorNotification(error.message));
    }
  };

  const validate = (values: { [key: string]: string }) => {
    const errors = {} as { [key: string]: string };

    if (!values[FIELD_PROJECT_NAME]) {
      errors[FIELD_PROJECT_NAME] = "required";
    } else errors === null;

    return errors;
  };

  return (
    <>
      <DetailsContainer
        title={projectName}
        actions={
          <TextButton
            color="secondary"
            onClick={() => {
              setEditNameDialogOpen(true);
            }}
            startIcon={<Edit />}
          >
            Edit
          </TextButton>
        }
      >
        <PageTabs tabs={tabs}>{children}</PageTabs>
      </DetailsContainer>
      {editNameDialogOpen && (
        <Formik
          initialValues={{ [FIELD_PROJECT_NAME]: projectName }}
          onSubmit={onSubmit}
          validate={validate}
        >
          {/** @ts-expect-error */}
          {(control: FormikProps<FormikValues>) => {
            const { submitForm } = control;

            return (
              <Dialog
                open={editNameDialogOpen}
                onClose={() => setEditNameDialogOpen(false)}
                title={"Edit project name"}
                Actions={
                  <PrimaryButton
                    onClick={submitForm}
                    disabled={!newProjectName.match(validators.name.pattern)}
                  >
                    Save
                  </PrimaryButton>
                }
              >
                <Box mb={2}>
                  <WarningText color={theme.palette.primary.main}>
                    <Box component="span">
                      {explanations.projects.nameUpdate}
                    </Box>
                  </WarningText>
                </Box>
                <ControlledTextInput
                  name={FIELD_PROJECT_NAME}
                  value={newProjectName}
                  onChange={setNewProjectName}
                  label="Project Name"
                  placeholder="example-project"
                  style={{ width: "50%" }}
                  error={
                    !newProjectName.match(validators.name.pattern) &&
                    "The name key can only consist of a-z, 0-9 and -. It cannot start or end a - . It must contain at least two characters"
                  }
                  showErrors
                />
              </Dialog>
            );
          }}
        </Formik>
      )}
    </>
  );
};
