import { useTheme } from "@emotion/react";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import {
  Box,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  LinearProgress,
  styled,
  linearProgressClasses,
} from "@mui/material";
import { readableColor } from "polished";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";

import { useGetPercentageColor } from "assets/styles/theme/utils/colorByPercentage";
import { OrganizationDetailStatus } from "libs/data/models";
import { env } from "libs/env";
import { ENV_NAMES } from "libs/env/env-names";
import { getTzAwareDate, DATE_FORMAT } from "libs/utilities/date-util";
import { roundAccurately } from "libs/utilities/metrics-helper";
import {
  useGetCurrentOrganization,
  useGetOrganizationCreditResetDates,
} from "store/features";

import { NavLink, WarningText } from "components/atoms";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

type ComputeUsageProgressBarProps = {
  usage?: number;
  maxUsage?: number;
  isSidebar?: boolean;
};

const ComputeUsageProgressBar = ({
  usage = 0,
  maxUsage: givenMaxUsage = 0,
  isSidebar = false,
}: ComputeUsageProgressBarProps) => {
  const history = useHistory();
  const currentOrgName = useGetCurrentOrganization()?.name;
  const theme = useTheme() as AppThemeProps;
  const [maxUsage, setMaxUsage] = useState(() => givenMaxUsage);
  const [showInfinity, setShowInfinity] = useState(false);

  const computeUsageDates = useGetOrganizationCreditResetDates();
  const isComputeDateValid = getTzAwareDate(
    computeUsageDates?.end_date
  ).isValid();

  const getPercentageColor = useGetPercentageColor();

  useEffect(() => {
    if (
      (env.get(ENV_NAMES.ON_PREMISE) && givenMaxUsage === null) ||
      !givenMaxUsage
    ) {
      setMaxUsage(999999);
      setShowInfinity(true);
    } else {
      setMaxUsage(givenMaxUsage);
      setShowInfinity(false);
    }
  }, [givenMaxUsage]);

  const usagePercentage = Math.round((usage / maxUsage) * 100);

  const organization = useGetCurrentOrganization();

  const redirectionLink = useMemo(
    () => `/organizations/${currentOrgName}/subscription`,
    [currentOrgName]
  );

  const handleRedirect = useCallback(() => {
    if (isSidebar) history.push(redirectionLink);
  }, [isSidebar, history, redirectionLink]);

  const roundedUsage = useMemo(() => roundAccurately(usage, 0), [usage]);

  const overage = useMemo(
    () => roundedUsage > maxUsage && Math.abs(roundedUsage - maxUsage),
    [maxUsage, roundedUsage]
  );

  const computeUsageEndDate = useMemo(
    () => getTzAwareDate(computeUsageDates?.end_date).format(DATE_FORMAT),
    [computeUsageDates]
  );

  const displayMessage = useMemo(
    () => !isSidebar && overage,
    [isSidebar, overage]
  );

  const hasSubscriptionEnded = getTzAwareDate().isAfter(
    getTzAwareDate(computeUsageDates?.end_date)
  );

  return usage !== null ? (
    <>
      {isSidebar && (
        <ListItem
          button
          component={NavLink}
          to={redirectionLink}
          className="sidebar-support__item__subscription"
        >
          <ListItemIcon className="sidebar-support__icon">
            <CreditCardIcon fontSize="small" color="inherit" />
          </ListItemIcon>
          <ListItemText>My subscription</ListItemText>
        </ListItem>
      )}

      <Box onClick={handleRedirect} sx={isSidebar ? { cursor: "pointer" } : {}}>
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Typography variant={isSidebar ? "caption" : "h5"}>
            Credit usage
          </Typography>
          <Typography variant={isSidebar ? "caption" : "h5"}>
            {roundAccurately(usage, 0)}
            {showInfinity ? ` / ∞ ` : maxUsage && ` / ${Math.round(maxUsage)} `}
            Units
          </Typography>
        </Box>
        {maxUsage && (
          <Box
            width="100%"
            mb={!isSidebar ? 3 : 1}
            position="relative"
            color={readableColor(getPercentageColor(usagePercentage))}
          >
            {!showInfinity && (
              <S_LinearProgress
                variant="determinate"
                value={Math.min(usagePercentage, 100)}
                theme={theme}
                barColor={getPercentageColor(usagePercentage)}
              />
            )}

            {!showInfinity && (
              <Typography
                component={Box}
                variant={isSidebar ? "body2" : "h6"}
                position="absolute"
                top="0"
                width="100%"
                textAlign="center"
                color={theme.palette.text.primary}
              >
                {usagePercentage}%
              </Typography>
            )}

            {!env.get(ENV_NAMES.ON_PREMISE) && isComputeDateValid && (
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mb={1}
              >
                {hasSubscriptionEnded ? (
                  <Typography
                    variant={isSidebar ? "caption" : "h6"}
                    color={"error"}
                  >
                    Subscription ended on
                  </Typography>
                ) : (
                  <Typography
                    variant={isSidebar ? "caption" : "h6"}
                    color={"textPrimary"}
                  >
                    Credits will reset on:
                  </Typography>
                )}
                <Typography
                  variant={isSidebar ? "caption" : "h6"}
                  color={
                    hasSubscriptionEnded
                      ? "error"
                      : hasSubscriptionEnded
                      ? "error"
                      : "textPrimary"
                  }
                >
                  {computeUsageEndDate}
                </Typography>
              </Box>
            )}
            {displayMessage &&
            organization?.status ===
              OrganizationDetailStatus.suspended_usage ? (
              <WarningText color={theme.palette.error.main}>
                Your subscription is currently suspended because you have passed
                your compute limit.
              </WarningText>
            ) : displayMessage ? (
              <WarningText color={theme.palette.error.main}>
                You have passed your compute limit by <b>{overage}</b> units,
                you will be billed for this on <b>{computeUsageEndDate}</b>.
              </WarningText>
            ) : null}
          </Box>
        )}
      </Box>
    </>
  ) : null;
};

const S_LinearProgress = styled(LinearProgress)(
  ({ theme, barColor }: { theme: AppThemeProps; barColor: string }) => ({
    height: 20,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.mode === "light"
          ? theme.palette.grey[200]
          : theme.palette.tertiary.main,
    },
    [`& .${linearProgressClasses.bar}`]: {
      backgroundColor: barColor,
    },
  })
);

export default ComputeUsageProgressBar;
