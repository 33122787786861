import { useTheme } from "@emotion/react";
import PipelineIcon from "@mui/icons-material/AccountTreeRounded";
import ErrorIcon from "@mui/icons-material/Error";
import DeploymentIcon from "@mui/icons-material/WidgetsRounded";
import { Box, IconButton, Link, Badge } from "@mui/material";

import { VariableIcon } from "assets/images/VariableIcon";
import { OperatorFunction } from "assets/images/operators/OperatorFunction";
import { spacing } from "assets/styles/theme";

import { Icon } from "components/atoms";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

export type SidebarLeftCollapsedProps = {
  warnings?: string[];
  errors?: string[];
  onClickFunctionIcon: () => void;
  onClickDeploymentIcon: () => void;
  onClickVariableIcon: () => void;
  onClickPipelineIcon: () => void;
};

export const SidebarLeftCollapsed = ({
  warnings = [],
  errors = [],
  onClickFunctionIcon,
  onClickDeploymentIcon,
  onClickVariableIcon,
  onClickPipelineIcon,
}: SidebarLeftCollapsedProps) => {
  const theme = useTheme() as AppThemeProps;

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height={`calc(100vh - ${spacing[164]})`}
    >
      {(!!warnings.length || !!errors.length) && (
        <IconButton onClick={onClickDeploymentIcon}>
          <Badge
            badgeContent={warnings.length + errors.length}
            showZero={!!warnings.length && !!errors.length}
            color="error"
            overlap="circular"
          >
            <ErrorIcon style={{ fontSize: 35 }} color="primary" />
          </Badge>
        </IconButton>
      )}
      <Icon
        component={DeploymentIcon}
        onClick={onClickDeploymentIcon}
        style={{
          height: spacing[44],
          width: spacing[44],
          color: theme.palette.specials.deployment,
        }}
      />

      <Link
        onClick={onClickFunctionIcon}
        sx={{ marginTop: spacing[16], width: spacing[44] }}
      >
        <OperatorFunction />
      </Link>
      <Link
        onClick={onClickVariableIcon}
        style={{ marginTop: spacing[16], cursor: "pointer" }}
      >
        <VariableIcon />
      </Link>
      <Icon
        component={PipelineIcon}
        onClick={onClickPipelineIcon}
        style={{
          height: spacing[44],
          width: spacing[44],
          marginTop: spacing[16],
          color: theme.palette.specials.pipelines,
        }}
      />
    </Box>
  );
};
