import PipelinesIcon from "@mui/icons-material/AccountTreeRounded";
import DeploymentsIcon from "@mui/icons-material/WidgetsRounded";
import { Grid, Typography, Link, Box, useTheme } from "@mui/material";
import { useContext, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

import { borderRadius, getShadows, spacing } from "assets/styles/theme";
import {
  DEPLOYMENT_PERMISSIONS,
  PIPELINE_PERMISSIONS,
} from "libs/constants/permissions";
import { BaseUrlContext } from "libs/contexts";
import {
  useDeploymentVersionsList,
  useDeploymentsList,
} from "libs/data/endpoints/deployments/deployments";
import { usePipelinesList } from "libs/data/endpoints/pipelines/pipelines";
import { useProjectsResourceUsage } from "libs/data/endpoints/projects/projects";
import { lastUpdated } from "libs/utilities/util-functions";
import { useGetCurrentOrganization } from "store/features";
import { useGetPermissions } from "store/features/permissions";
import { useGetThemeMode } from "store/features/themeMode";

import { Loader, NavLink } from "components/atoms";

import { TRAINING_DEPLOYMENT } from "../training/constants";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

const Resources = () => {
  const theme = useTheme() as AppThemeProps;
  // TO DO: optimise theme to avoid this useGetThemMode call
  const themeMode = useGetThemeMode();
  const baseUrl = useContext(BaseUrlContext);
  const history = useHistory();
  const { projectName } =
    useParams<{ organizationName: string; projectName: string }>();
  const { data: resources, error: resourcesError } =
    useProjectsResourceUsage(projectName);
  const resourcesLoading = !resources && !resourcesError;

  const currentOrganization = useGetCurrentOrganization();

  const [projectPermissions] = useGetPermissions();

  const { data: pipelines, error: pipelinesError } =
    usePipelinesList(projectName);
  const pipelinesLoading = !pipelines && !pipelinesError;

  const { data: deployments, error: deploymentsError } =
    useDeploymentsList(projectName);
  const deploymentsLoading = !deployments && !deploymentsError;

  const { data: experiments } = useDeploymentVersionsList(
    projectName,
    TRAINING_DEPLOYMENT
  );

  const recentDeployment = useMemo(
    () => (deployments?.length ? lastUpdated(deployments) : undefined),
    [deployments]
  );

  const recentPipeline = useMemo(
    () => (pipelines?.length ? lastUpdated(pipelines) : undefined),
    [pipelines]
  );

  const inactiveOrganization =
    currentOrganization?.status && currentOrganization?.status !== "active";

  const resourcesList = [
    {
      title: "Deployments",
      link: "deployments",
      amount: resources?.deployments ?? 0,
      permissions:
        !!projectPermissions[DEPLOYMENT_PERMISSIONS["list"]] &&
        !inactiveOrganization,
    },
    {
      title: "Pipelines",
      link: "pipelines",
      amount: resources?.pipelines ?? 0,
      permissions:
        !!projectPermissions[PIPELINE_PERMISSIONS["list"]] &&
        !inactiveOrganization,
    },
    {
      title: "Experiments",
      link: "training/experiments",
      amount: experiments?.length ?? 0,
      permissions:
        !!projectPermissions[DEPLOYMENT_PERMISSIONS["version_list"]] &&
        !inactiveOrganization,
    },
  ];

  const onCardClick = (permissions: boolean, path: string) => () => {
    permissions && history.push(`${baseUrl}/${path}`);
  };

  return resourcesLoading || deploymentsLoading || pipelinesLoading ? (
    <Grid container spacing={6}>
      <Grid item lg={12} md={12} xs={12}>
        <Loader />
      </Grid>
    </Grid>
  ) : (
    <Grid container spacing={2}>
      <Grid item container spacing={3}>
        <Grid
          container
          flexWrap={"nowrap"}
          padding={spacing[12]}
          gap={{ xs: 1, sm: 6, md: 4 }}
        >
          {resourcesList.map(({ title, amount, permissions, link }) => (
            <Grid
              key={title}
              container
              item
              xs
              flexDirection="column"
              borderRadius={borderRadius[10]}
              boxShadow={getShadows(theme).primary}
              sx={{
                height: "140px",
                background: theme.palette.tertiary.main,
                ":hover": { background: theme.palette.secondary.main },
                cursor: "pointer",
              }}
              onClick={onCardClick(permissions, link)}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                sx={{
                  borderTopRightRadius: borderRadius[10],
                  borderTopLeftRadius: borderRadius[10],
                  color: theme.palette.primary.contrastText,
                  flexGrow: 1,
                }}
              >
                <Typography variant="h1">{amount}</Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  borderBottomRightRadius: borderRadius[10],
                  borderBottomLeftRadius: borderRadius[10],
                  height: spacing[48],
                  background:
                    themeMode.mode === "dark"
                      ? theme.palette.background.active
                      : theme.palette.background.paper,
                }}
              >
                <Typography variant="h5">{title}</Typography>
              </Box>
            </Grid>
          ))}{" "}
        </Grid>
      </Grid>
      {(!!deployments?.length || !!pipelines?.length) && (
        <Grid item xs>
          <Typography variant="h5">Most recently used:</Typography>
          <Box display="flex" flexWrap="wrap" marginTop={spacing[8]}>
            {recentDeployment && (
              <Box display="flex" maxWidth={"50%"} marginRight={spacing[24]}>
                <DeploymentsIcon fontSize="small" />
                <Typography
                  variant="subtitle1"
                  style={{
                    marginLeft: spacing[8],
                    width: "95%",
                    display: "block",
                  }}
                >
                  <Link
                    component={NavLink}
                    variant="h5"
                    to={`${baseUrl}/deployments/${recentDeployment}`}
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      width: "100%",
                      display: "block",
                    }}
                    sx={{
                      color: theme.palette.text.primary,
                    }}
                  >
                    {recentDeployment}
                  </Link>
                </Typography>
              </Box>
            )}
            {recentPipeline && (
              <Box display="flex">
                <PipelinesIcon fontSize="small" />
                <Typography
                  variant="subtitle1"
                  style={{
                    marginLeft: spacing[8],
                    width: "95%",
                    display: "block",
                  }}
                >
                  <Link
                    component={NavLink}
                    variant="h5"
                    to={`${baseUrl}/pipelines/${recentPipeline}`}
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      width: "100%",
                      display: "block",
                    }}
                    sx={{
                      color: theme.palette.text.primary,
                    }}
                  >
                    {recentPipeline}
                  </Link>
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default Resources;
