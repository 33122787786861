import { useTheme } from "@mui/material";

import type { AppThemeProps } from "assets/styles/theme/theme.d";

export const IlluAddGraph = () => {
  const theme = useTheme() as AppThemeProps;

  return (
    <svg
      width="223"
      height="148"
      viewBox="0 0 223 148"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10263_26126)">
        <path
          d="M177.649 65.4965L178.311 60.24C178.546 58.3719 179.521 56.6729 181.021 55.5168C182.52 54.3607 184.422 53.8421 186.308 54.0751C188.194 54.3082 189.909 55.2738 191.076 56.7596C192.243 58.2453 192.766 60.1295 192.531 61.9975L191.869 67.254C191.837 67.5048 191.706 67.7329 191.505 67.8881C191.303 68.0434 191.048 68.1131 190.795 68.0821L178.485 66.5607C178.232 66.5291 178.001 66.3993 177.845 66.1998C177.688 66.0003 177.618 65.7474 177.649 65.4965Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M183.545 67.0646C186.446 67.0646 188.798 64.7346 188.798 61.8604C188.798 58.9862 186.446 56.6562 183.545 56.6562C180.644 56.6562 178.292 58.9862 178.292 61.8604C178.292 64.7346 180.644 67.0646 183.545 67.0646Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M176.576 60.4524C176.763 58.9754 177.535 57.6324 178.721 56.7182C179.907 55.8039 181.41 55.3931 182.902 55.5757L183.963 55.7068C185.454 55.8928 186.809 56.6571 187.732 57.832C188.655 59.0068 189.07 60.4964 188.886 61.9738L188.872 62.0789L186.63 61.8018L186.132 59.5853L185.712 61.6883L184.553 61.5451L184.302 60.4268L184.09 61.4878L176.562 60.5575L176.576 60.4524Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M157.852 80.4253C158.013 80.7376 158.241 81.0112 158.519 81.2268C158.798 81.4425 159.121 81.595 159.466 81.6735C159.811 81.7521 160.169 81.7548 160.515 81.6814C160.861 81.6081 161.186 81.4605 161.468 81.2491L166.24 84.0181L168.646 81.556L161.852 77.7394C161.462 77.2934 160.921 77.0046 160.33 76.9278C159.739 76.8509 159.141 76.9913 158.648 77.3224C158.155 77.6534 157.802 78.1522 157.655 78.7241C157.508 79.2959 157.579 79.9013 157.852 80.4253Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M173.49 85.4007C169.971 85.4007 163.777 83.8399 163.434 83.7527L163.326 83.7252L164.301 78.8362L173.76 80.6872L178.899 73.518L184.799 72.9238L184.635 73.1385C184.558 73.2385 176.953 83.1569 175.769 84.8207C175.47 85.2405 174.617 85.4007 173.49 85.4007Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M174.434 103.75L174.293 103.684C174.263 103.67 171.273 102.229 169.724 99.4158C168.183 96.6162 175.399 84.7404 175.903 83.9164L175.911 80.1449L178.506 73.2022L181.817 71.3486L178.975 77.9184L174.434 103.75Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M171.117 144.286L167.953 144.286L166.448 132.196L171.118 132.197L171.117 144.286Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M171.924 147.323L161.723 147.323V147.195C161.723 146.152 162.141 145.152 162.886 144.414C163.63 143.676 164.64 143.262 165.693 143.262H165.694L171.924 143.262L171.924 147.323Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M205.1 141.539L202.094 142.515L196.863 131.491L201.301 130.051L205.1 141.539Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M206.822 144.177L197.128 147.324L197.088 147.202C196.76 146.211 196.843 145.131 197.319 144.2C197.795 143.27 198.624 142.564 199.625 142.239L199.625 142.239L205.546 140.317L206.822 144.177Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M198.974 139.562L189.955 124.645L182.342 108.709L175.49 122.786L172.769 141.019L163.224 141.15L163.261 141.002L177.868 83.5049L189.308 85.198L188.787 92.6786L189.097 93.1156C191.681 96.7524 194.353 100.513 192.821 104.566L197.165 117.345L206.782 138.624L198.974 139.562Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M178.391 95.9667C176.733 95.9667 174.842 94.0916 175.823 90.8903L175.76 90.8447L178.116 80.53C176.06 77.6162 178.771 75.2114 179.025 74.9966L180.32 72.6886L186.03 69.1006L188.92 92.8846L188.877 92.9251C186.79 94.9032 179.781 95.9667 178.391 95.9667Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M190.086 101.333C189.859 101.337 189.633 101.313 189.412 101.262C189.189 101.207 188.83 100.927 188.274 99.1348C186.065 92.0101 183.457 70.5725 185.352 68.4748L185.396 68.4267L188.507 68.9403C188.765 68.7513 190.122 67.8128 191.374 67.976C191.632 68.0081 191.881 68.0923 192.104 68.2233C192.328 68.3542 192.522 68.5293 192.675 68.7375L192.696 68.7653L193.687 82.7333L197.318 99.171L197.231 99.2101C197.02 99.3053 192.486 101.333 190.086 101.333Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M187.995 95.874C188.348 95.8623 188.695 95.7739 189.01 95.6151C189.326 95.4564 189.602 95.231 189.82 94.955C190.038 94.6789 190.192 94.3589 190.272 94.0174C190.351 93.6759 190.354 93.3213 190.281 92.9786L194.835 89.8712L193.604 86.6689L187.235 91.1466C186.662 91.3075 186.168 91.671 185.847 92.1683C185.527 92.6655 185.401 93.262 185.495 93.8447C185.589 94.4274 185.895 94.9558 186.356 95.3298C186.816 95.7038 187.4 95.8974 187.995 95.874Z"
          fill={theme.palette.svgs.pinkSkin}
        />
        <path
          d="M193.332 92.3612L189.275 89.4143L194.977 81.7114L190.602 74.0614L192.559 68.5156L192.686 68.7545C192.745 68.8657 198.588 79.8941 199.608 81.6614C200.668 83.4999 193.7 91.9183 193.403 92.2756L193.332 92.3612Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M102.085 127.031L98.6327 125.591L96.2643 108.445H64.7254L62.1583 125.521L59.0695 127.051C58.9217 127.124 58.8032 127.245 58.7332 127.393C58.6632 127.541 58.6457 127.708 58.6837 127.868C58.7217 128.027 58.8128 128.169 58.9424 128.271C59.0719 128.372 59.2323 128.427 59.3976 128.427H101.801C101.97 128.427 102.134 128.369 102.265 128.263C102.396 128.157 102.486 128.009 102.52 127.845C102.553 127.68 102.528 127.509 102.449 127.361C102.37 127.213 102.241 127.096 102.085 127.031Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M158.132 110.727H3.20413C2.39127 110.725 1.61232 110.404 1.03819 109.834C0.464048 109.264 0.141607 108.491 0.141602 107.686V86.043H161.195V107.686C161.195 108.491 160.872 109.264 160.298 109.834C159.724 110.404 158.945 110.725 158.132 110.727Z"
          fill={theme.palette.svgs.mist}
        />
        <path
          d="M161.356 96.2715H0V3.66557C0.00113344 2.69372 0.391345 1.762 1.08503 1.07482C1.77871 0.387627 2.71922 0.00108993 3.70022 0H157.656C158.637 0.00108743 159.578 0.387624 160.271 1.07481C160.965 1.762 161.355 2.69372 161.356 3.66557V96.2715Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M151.719 89.5497H9.63678C8.88049 89.5488 8.15541 89.2508 7.62063 88.7211C7.08585 88.1913 6.78503 87.473 6.78418 86.7237V9.54678C6.78502 8.79753 7.08583 8.07921 7.62061 7.5494C8.15539 7.01959 8.88047 6.72156 9.63678 6.7207H151.719C152.475 6.72156 153.2 7.01959 153.735 7.5494C154.27 8.07921 154.571 8.79753 154.571 9.54678V86.7237C154.571 87.473 154.27 88.1913 153.735 88.7211C153.2 89.2508 152.475 89.5488 151.719 89.5497Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M120.525 128.606H40.6876C40.6058 128.608 40.5258 128.583 40.4599 128.535C40.394 128.487 40.3461 128.419 40.3235 128.341C40.3094 128.288 40.3077 128.232 40.3187 128.178C40.3296 128.125 40.3529 128.074 40.3866 128.031C40.4204 127.987 40.4638 127.952 40.5134 127.928C40.5631 127.904 40.6176 127.891 40.673 127.891H120.496C120.582 127.888 120.667 127.914 120.736 127.965C120.806 128.015 120.856 128.088 120.878 128.171C120.89 128.223 120.89 128.277 120.877 128.329C120.865 128.382 120.841 128.43 120.807 128.472C120.773 128.514 120.731 128.548 120.682 128.571C120.633 128.594 120.58 128.606 120.525 128.606Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M150.202 19.1982H115.897V45.1619H150.202V19.1982Z"
          fill={theme.palette.svgs.ghost}
        />
        <path
          d="M148.497 20.4883H117.602V43.8717H148.497V20.4883Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M133.593 37.8329C133.597 37.833 133.601 37.8329 133.605 37.8327C135.696 37.7243 137.668 36.8308 139.118 35.334C140.568 33.8372 141.388 31.8493 141.412 29.7748C141.435 27.7002 140.66 25.6946 139.244 24.166C137.828 22.6373 135.878 21.7003 133.789 21.5455C133.764 21.5435 133.738 21.5468 133.713 21.5552C133.689 21.5636 133.666 21.5769 133.647 21.5943C133.628 21.6114 133.613 21.6323 133.602 21.6555C133.592 21.6787 133.587 21.7038 133.586 21.7292L133.406 37.6445C133.406 37.6939 133.425 37.7416 133.46 37.7769C133.495 37.8122 133.543 37.8324 133.593 37.8329Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M127.132 24.1777C127.197 24.1785 127.258 24.2044 127.303 24.2498L132.833 29.853C132.855 29.8754 132.873 29.9019 132.885 29.9311C132.897 29.9603 132.903 29.9915 132.902 30.0229L132.816 37.6384C132.816 37.6711 132.809 37.7034 132.795 37.7332C132.782 37.7631 132.762 37.7899 132.738 37.8119C132.713 37.8341 132.685 37.8512 132.653 37.862C132.622 37.8728 132.588 37.8771 132.555 37.8746C131.018 37.7606 129.542 37.224 128.296 36.3252C127.049 35.4264 126.08 34.2009 125.497 32.7864C124.915 31.372 124.742 29.8246 124.998 28.3182C125.254 26.8119 125.93 25.4061 126.948 24.259C126.97 24.2343 126.997 24.2144 127.027 24.2004C127.057 24.1864 127.09 24.1787 127.123 24.1778C127.126 24.1777 127.129 24.1777 127.132 24.1777Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M132.755 21.4805C132.817 21.4812 132.876 21.5052 132.921 21.5475C132.945 21.57 132.964 21.5973 132.977 21.6274C132.989 21.6576 132.996 21.6901 132.995 21.7228L132.918 28.594C132.917 28.6413 132.902 28.6873 132.875 28.7264C132.848 28.7654 132.81 28.7957 132.766 28.8133C132.722 28.8309 132.673 28.8351 132.626 28.8254C132.58 28.8156 132.537 28.7924 132.503 28.7585L127.677 23.8691C127.654 23.8458 127.636 23.8182 127.625 23.7879C127.613 23.7575 127.607 23.7252 127.609 23.6927C127.61 23.6602 127.618 23.6283 127.632 23.5989C127.646 23.5695 127.666 23.5433 127.69 23.5218C129.094 22.2979 130.873 21.5787 132.74 21.4808C132.745 21.4805 132.751 21.4804 132.755 21.4805Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M130.269 41.1335C130.266 41.4234 130.176 41.7058 130.011 41.945C129.846 42.1842 129.612 42.3695 129.341 42.4774C129.069 42.5853 128.772 42.6111 128.485 42.5513C128.199 42.4916 127.937 42.3491 127.732 42.1418C127.528 41.9345 127.39 41.6718 127.336 41.3869C127.282 41.102 127.315 40.8076 127.43 40.541C127.545 40.2745 127.737 40.0477 127.982 39.8893C128.227 39.731 128.514 39.6482 128.807 39.6515C129.199 39.6563 129.573 39.815 129.847 40.0928C130.122 40.3707 130.273 40.7449 130.269 41.1335Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M134.497 41.1804C134.494 41.4703 134.404 41.7527 134.238 41.9919C134.073 42.2311 133.84 42.4164 133.568 42.5243C133.297 42.6322 132.999 42.6579 132.713 42.5982C132.426 42.5385 132.164 42.396 131.96 42.1887C131.755 41.9814 131.617 41.7187 131.563 41.4338C131.51 41.1488 131.542 40.8545 131.657 40.5879C131.772 40.3213 131.964 40.0946 132.209 39.9362C132.455 39.7779 132.742 39.6951 133.034 39.6983C133.426 39.7031 133.801 39.8619 134.075 40.1397C134.349 40.4176 134.501 40.7918 134.497 41.1804Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M138.724 41.2273C138.72 41.5171 138.63 41.7995 138.465 42.0387C138.3 42.278 138.067 42.4632 137.795 42.5712C137.523 42.6791 137.226 42.7048 136.939 42.6451C136.653 42.5853 136.391 42.4428 136.186 42.2356C135.982 42.0283 135.844 41.7656 135.79 41.4806C135.736 41.1957 135.769 40.9013 135.884 40.6348C135.999 40.3682 136.191 40.1414 136.436 39.9831C136.681 39.8247 136.968 39.742 137.261 39.7452C137.653 39.75 138.027 39.9087 138.301 40.1866C138.576 40.4644 138.728 40.8387 138.724 41.2273Z"
          fill={theme.palette.svgs.celebralGray}
        />
        <path
          d="M149.841 49.248H115.536V75.2117H149.841V49.248Z"
          fill={theme.palette.svgs.ghost}
        />
        <path
          d="M148.137 50.5381H117.241V73.9215H148.137V50.5381Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M122.483 58.2233C123.191 58.2233 123.766 57.6544 123.766 56.9525C123.766 56.2506 123.191 55.6816 122.483 55.6816C121.775 55.6816 121.2 56.2506 121.2 56.9525C121.2 57.6544 121.775 58.2233 122.483 58.2233Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M143.094 55.964C143.208 55.9638 143.322 55.986 143.427 56.0292C143.533 56.0724 143.629 56.1359 143.71 56.2159C143.791 56.296 143.855 56.3911 143.899 56.4957C143.943 56.6004 143.966 56.7126 143.966 56.8259C143.966 56.9392 143.943 57.0514 143.899 57.156C143.855 57.2607 143.791 57.3558 143.71 57.4358C143.629 57.5159 143.533 57.5793 143.427 57.6225C143.322 57.6658 143.208 57.6879 143.094 57.6877H129.602C129.372 57.6877 129.15 57.5969 128.987 57.4353C128.824 57.2737 128.732 57.0545 128.732 56.8259C128.732 56.5973 128.824 56.3781 128.987 56.2165C129.15 56.0548 129.372 55.964 129.602 55.964H143.094ZM143.094 55.7529H129.602C129.46 55.7527 129.319 55.7803 129.187 55.8341C129.056 55.888 128.936 55.967 128.835 56.0666C128.735 56.1663 128.655 56.2846 128.6 56.4149C128.546 56.5452 128.518 56.6848 128.518 56.8259C128.518 56.9669 128.546 57.1066 128.6 57.2368C128.655 57.3671 128.735 57.4855 128.835 57.5851C128.936 57.6848 129.056 57.7638 129.187 57.8176C129.319 57.8714 129.46 57.899 129.602 57.8988H143.094C143.236 57.899 143.377 57.8714 143.509 57.8176C143.641 57.7638 143.76 57.6848 143.861 57.5851C143.962 57.4855 144.042 57.3671 144.096 57.2368C144.151 57.1066 144.179 56.9669 144.179 56.8259C144.179 56.6848 144.151 56.5452 144.096 56.4149C144.042 56.2846 143.962 56.1663 143.861 56.0666C143.76 55.967 143.641 55.888 143.509 55.8341C143.377 55.7803 143.236 55.7527 143.094 55.7529Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M137.771 57.8988H127.614C127.327 57.8988 127.052 57.7858 126.848 57.5846C126.645 57.3833 126.531 57.1104 126.531 56.8259C126.531 56.5413 126.645 56.2684 126.848 56.0672C127.052 55.866 127.327 55.7529 127.614 55.7529H137.771C138.058 55.7529 138.334 55.866 138.537 56.0672C138.74 56.2684 138.854 56.5413 138.854 56.8259C138.854 57.1104 138.74 57.3833 138.537 57.5846C138.334 57.7858 138.058 57.8988 137.771 57.8988Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M122.483 63.5007C123.191 63.5007 123.766 62.9317 123.766 62.2298C123.766 61.528 123.191 60.959 122.483 60.959C121.775 60.959 121.2 61.528 121.2 62.2298C121.2 62.9317 121.775 63.5007 122.483 63.5007Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M143.094 61.2414C143.209 61.2412 143.322 61.2633 143.428 61.3066C143.534 61.3498 143.63 61.4132 143.711 61.4933C143.791 61.5733 143.856 61.6684 143.9 61.7731C143.943 61.8777 143.966 61.9899 143.966 62.1032C143.966 62.2165 143.943 62.3287 143.9 62.4334C143.856 62.538 143.791 62.6331 143.711 62.7132C143.63 62.7932 143.534 62.8567 143.428 62.8999C143.322 62.9431 143.209 62.9653 143.094 62.9651H129.603C129.372 62.9651 129.151 62.8743 128.987 62.7127C128.824 62.551 128.733 62.3318 128.733 62.1032C128.733 61.8746 128.824 61.6554 128.987 61.4938C129.151 61.3322 129.372 61.2414 129.603 61.2414H143.094ZM143.094 61.0303H129.603C129.315 61.0303 129.04 61.1433 128.837 61.3445C128.634 61.5458 128.52 61.8187 128.52 62.1032C128.52 62.3878 128.634 62.6607 128.837 62.8619C129.04 63.0631 129.315 63.1762 129.603 63.1762H143.094C143.382 63.1762 143.657 63.0631 143.86 62.8619C144.063 62.6607 144.177 62.3878 144.177 62.1032C144.177 61.8187 144.063 61.5458 143.86 61.3445C143.657 61.1433 143.382 61.0303 143.094 61.0303Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M141.606 63.1762H127.614C127.327 63.1762 127.052 63.0631 126.848 62.8619C126.645 62.6607 126.531 62.3878 126.531 62.1032C126.531 61.8187 126.645 61.5457 126.848 61.3445C127.052 61.1433 127.327 61.0303 127.614 61.0303H141.606C141.893 61.0303 142.169 61.1433 142.372 61.3445C142.575 61.5457 142.689 61.8187 142.689 62.1032C142.689 62.3878 142.575 62.6607 142.372 62.8619C142.169 63.0631 141.893 63.1762 141.606 63.1762Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M122.483 68.778C123.191 68.778 123.766 68.209 123.766 67.5072C123.766 66.8053 123.191 66.2363 122.483 66.2363C121.775 66.2363 121.2 66.8053 121.2 67.5072C121.2 68.209 121.775 68.778 122.483 68.778Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M143.094 66.5177C143.209 66.5175 143.322 66.5397 143.428 66.5829C143.534 66.6262 143.63 66.6896 143.711 66.7697C143.791 66.8497 143.856 66.9448 143.9 67.0494C143.943 67.1541 143.966 67.2663 143.966 67.3796C143.966 67.4929 143.943 67.6051 143.9 67.7097C143.856 67.8144 143.791 67.9095 143.711 67.9895C143.63 68.0696 143.534 68.133 143.428 68.1763C143.322 68.2195 143.209 68.2416 143.094 68.2415H129.603C129.372 68.2415 129.151 68.1507 128.987 67.989C128.824 67.8274 128.733 67.6082 128.733 67.3796C128.733 67.151 128.824 66.9318 128.987 66.7702C129.151 66.6085 129.372 66.5177 129.603 66.5177H143.094ZM143.094 66.3066H129.603C129.315 66.3066 129.04 66.4197 128.837 66.6209C128.634 66.8221 128.52 67.095 128.52 67.3796C128.52 67.6642 128.634 67.9371 128.837 68.1383C129.04 68.3395 129.315 68.4525 129.603 68.4525H143.094C143.382 68.4525 143.657 68.3395 143.86 68.1383C144.063 67.9371 144.177 67.6642 144.177 67.3796C144.177 67.095 144.063 66.8221 143.86 66.6209C143.657 66.4197 143.382 66.3066 143.094 66.3066Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M133.296 68.4525H127.614C127.472 68.4527 127.331 68.4252 127.199 68.3713C127.067 68.3175 126.948 68.2385 126.847 68.1388C126.746 68.0392 126.666 67.9208 126.612 67.7906C126.557 67.6603 126.529 67.5206 126.529 67.3796C126.529 67.2386 126.557 67.0989 126.612 66.9686C126.666 66.8383 126.746 66.72 126.847 66.6203C126.948 66.5207 127.067 66.4417 127.199 66.3879C127.331 66.334 127.472 66.3064 127.614 66.3066H133.296C133.583 66.3071 133.858 66.4203 134.061 66.6215C134.263 66.8226 134.377 67.0953 134.377 67.3796C134.377 67.6639 134.263 67.9365 134.061 68.1377C133.858 68.3389 133.583 68.4521 133.296 68.4525Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M107.953 23.792H12.1104V72.4528H107.953V23.792Z"
          fill={theme.palette.svgs.ghost}
        />
        <path
          d="M104.758 26.21H15.3057V70.0349H104.758V26.21Z"
          fill={theme.palette.svgs.paperWhite}
        />
        <path
          d="M93.6644 63.349H28.4551C28.4009 63.349 28.349 63.3277 28.3108 63.2898C28.2725 63.2518 28.251 63.2004 28.251 63.1468V32.1836C28.251 32.13 28.2725 32.0786 28.3107 32.0407C28.349 32.0027 28.4009 31.9814 28.4551 31.9814C28.5092 31.9814 28.5611 32.0027 28.5994 32.0407C28.6376 32.0786 28.6591 32.13 28.6591 32.1836V62.9446H93.6644C93.7185 62.9446 93.7704 62.9659 93.8087 63.0038C93.847 63.0418 93.8685 63.0932 93.8685 63.1468C93.8685 63.2004 93.847 63.2518 93.8087 63.2898C93.7704 63.3277 93.7185 63.349 93.6644 63.349Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M42.3874 61.1251H36.4564C36.2958 61.1249 36.1418 61.0616 36.0282 60.9491C35.9147 60.8366 35.8508 60.6841 35.8506 60.525V52.4253C35.8508 52.2662 35.9147 52.1137 36.0282 52.0012C36.1418 51.8887 36.2958 51.8254 36.4564 51.8252H42.3874C42.548 51.8254 42.702 51.8887 42.8156 52.0012C42.9291 52.1137 42.993 52.2662 42.9932 52.4253V60.525C42.993 60.6841 42.9291 60.8366 42.8156 60.9491C42.702 61.0616 42.548 61.1249 42.3874 61.1251Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M53.2029 61.125H47.2718C47.1112 61.1248 46.9572 61.0615 46.8437 60.949C46.7301 60.8365 46.6662 60.684 46.666 60.5249V44.7427C46.6662 44.5836 46.7301 44.431 46.8437 44.3185C46.9572 44.206 47.1112 44.1428 47.2718 44.1426H53.2029C53.3635 44.1428 53.5174 44.206 53.631 44.3185C53.7446 44.431 53.8085 44.5836 53.8087 44.7427V60.5249C53.8085 60.684 53.7446 60.8365 53.631 60.949C53.5174 61.0615 53.3635 61.1248 53.2029 61.125Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M64.0193 61.1251H58.0882C57.9276 61.1249 57.7736 61.0616 57.6601 60.9491C57.5465 60.8366 57.4826 60.6841 57.4824 60.525V52.4253C57.4826 52.2662 57.5465 52.1137 57.6601 52.0012C57.7736 51.8887 57.9276 51.8254 58.0882 51.8252H64.0193C64.1799 51.8254 64.3338 51.8886 64.4474 52.0011C64.561 52.1136 64.6249 52.2662 64.6251 52.4253V60.525C64.6249 60.6841 64.561 60.8366 64.4474 60.9491C64.3338 61.0616 64.1799 61.1249 64.0193 61.1251Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M74.8357 61.1254H68.9046C68.7477 61.1288 68.5958 61.0704 68.4822 60.963C68.3687 60.8556 68.3027 60.708 68.2988 60.5525V41.6834C68.3027 41.5279 68.3687 41.3803 68.4822 41.2729C68.5958 41.1656 68.7477 41.1071 68.9046 41.1105H74.8357C74.9926 41.1071 75.1445 41.1656 75.2581 41.2729C75.3716 41.3803 75.4376 41.5279 75.4415 41.6834V60.5525C75.4376 60.708 75.3716 60.8556 75.2581 60.963C75.1445 61.0704 74.9926 61.1288 74.8357 61.1254Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M85.6511 61.1249H79.7201C79.5595 61.1248 79.4055 61.0615 79.2919 60.949C79.1783 60.8365 79.1144 60.6839 79.1143 60.5248V36.2515C79.1144 36.0924 79.1783 35.9398 79.2919 35.8273C79.4055 35.7148 79.5595 35.6515 79.7201 35.6514H85.6511C85.8117 35.6515 85.9657 35.7148 86.0793 35.8273C86.1928 35.9398 86.2567 36.0924 86.2569 36.2515V60.5248C86.2567 60.6839 86.1928 60.8365 86.0793 60.949C85.9657 61.0615 85.8117 61.1248 85.6511 61.1249Z"
          fill={theme.palette.secondary.main}
        />
        <path
          d="M39.4217 49.3987C40.098 49.3987 40.6462 48.8556 40.6462 48.1857C40.6462 47.5157 40.098 46.9727 39.4217 46.9727C38.7455 46.9727 38.1973 47.5157 38.1973 48.1857C38.1973 48.8556 38.7455 49.3987 39.4217 49.3987Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M50.2381 41.5139C50.9144 41.5139 51.4626 40.9709 51.4626 40.3009C51.4626 39.631 50.9144 39.0879 50.2381 39.0879C49.5619 39.0879 49.0137 39.631 49.0137 40.3009C49.0137 40.9709 49.5619 41.5139 50.2381 41.5139Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M61.0536 49.3987C61.7298 49.3987 62.278 48.8556 62.278 48.1857C62.278 47.5157 61.7298 46.9727 61.0536 46.9727C60.3773 46.9727 59.8291 47.5157 59.8291 48.1857C59.8291 48.8556 60.3773 49.3987 61.0536 49.3987Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M71.87 37.6731C72.5462 37.6731 73.0944 37.13 73.0944 36.4601C73.0944 35.7902 72.5462 35.2471 71.87 35.2471C71.1937 35.2471 70.6455 35.7902 70.6455 36.4601C70.6455 37.13 71.1937 37.6731 71.87 37.6731Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M82.6854 33.2259C83.3616 33.2259 83.9098 32.6828 83.9098 32.0128C83.9098 31.3429 83.3616 30.7998 82.6854 30.7998C82.0091 30.7998 81.4609 31.3429 81.4609 32.0128C81.4609 32.6828 82.0091 33.2259 82.6854 33.2259Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M61.0798 48.4574L50.2382 40.4126L39.5444 48.3475L39.2998 48.0241L50.2382 39.9073L61.0283 47.9139L71.7509 36.2896L71.7937 36.2722L82.6098 31.9551L82.7624 32.3301L71.9894 36.6301L61.0798 48.4574Z"
          fill={theme.palette.primary.main}
        />
        <path
          d="M222.763 148H132.621C132.559 148 132.498 147.975 132.454 147.931C132.41 147.887 132.385 147.828 132.385 147.766C132.385 147.703 132.41 147.644 132.454 147.6C132.498 147.556 132.559 147.531 132.621 147.531H222.763C222.826 147.531 222.886 147.556 222.931 147.6C222.975 147.644 223 147.703 223 147.766C223 147.828 222.975 147.887 222.931 147.931C222.886 147.975 222.826 148 222.763 148Z"
          fill={theme.palette.svgs.celebralGray}
        />
      </g>
      <defs>
        <clipPath id="clip0_10263_26126">
          <rect width="223" height="148" fill={theme.palette.svgs.paperWhite} />
        </clipPath>
      </defs>
    </svg>
  );
};
