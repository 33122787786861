import { Box, FormHelperText, Grid, Typography, useTheme } from "@mui/material";
import { map } from "lodash";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";

import { spacing } from "assets/styles/theme";
import { AutoCompleteSelectHookForm } from "components/atoms/UncontrolledAutoComplete/AutoCompleteSelectHookForm";
import { PageHeader } from "components/molecules/PageLayout";
import { PortForwarding } from "components/molecules/PortForwarding";
import {
  FIELD_VERSION,
  FIELD_DEPLOYMENT_VERSION_ENVIRONMENT,
  FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE,
  FIELD_DEPLOYMENT_VERSION_FILE,
  FIELD_DESCRIPTION,
  FIELD_LABELS,
  FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY,
  FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY,
  FIELD_DEPLOYMENT_VERSION_IDLE_TIME,
  FIELD_RETENTION_MODE,
  FIELD_RETENTION_TIME,
  FIELD_PACKAGE,
  FIELD_EXISTING_PACKAGE,
  FIELD_NEW_PACKAGE,
  FIELD_DEPLOYMENT,
  FIELD_COPY_ENV_VARS,
  FIELD_SOURCE_DEPLOYMENT,
  FIELD_SOURCE_VERSION,
  FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS,
  FIELD_MAXIMUM_QUEUE_SIZE_BATCH,
  FIELD_STATIC_IP,
  FIELD_GPU_INSTANCE_ENABLED,
  FIELD_DEPLOYMENT_VERSION_FILE_HAS_REQUIREMENTS,
  FIELD_SCALING_STRATEGY,
  FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORTS,
  FIELD_INSTANCE_PROCESSES,
} from "libs/constants/fields";
import { useDeploymentVersionsCreate } from "libs/data/customized/deployment-versions/useDeploymentVersionsCreate";
import { useDeploymentVersionsFileUpload } from "libs/data/customized/deployment-versions/useDeploymentVersionsFileUpload";
import {
  useDeploymentVersionEnvironmentVariablesList,
  useDeploymentsList,
  useDeploymentVersionsGet,
  revisionsFileUpload,
  deploymentVersionEnvironmentVariablesCopy,
} from "libs/data/endpoints/deployments/deployments";
import { useEnvironmentsList } from "libs/data/endpoints/environments/environments";
import { useInstanceTypeGroupsGet } from "libs/data/endpoints/instances/instances";
import {
  useOrganizationsFeaturesGet,
  useOrganizationsResourceUsage,
} from "libs/data/endpoints/organizations/organizations";
import { useGoogleAnalytics } from "libs/hooks";
import { explanations } from "libs/utilities/explanations";
import {
  formatLabels,
  formatLabelsForRequest,
} from "libs/utilities/labels-util";
import { LOADED, LOADING } from "libs/utilities/request-statuses";
import { createId } from "libs/utilities/utils";
import validators from "libs/utilities/validators";
import { routes } from "routes";

import { FormTextField, Loader, Radio } from "components/atoms";
import {
  FormSection,
  DeploymentVersionAdvancedParameters,
  DeploymentVersionInstanceType,
  GeneralFieldsSection,
  PageContainer,
  NetworkSettings,
  UploadPackageField,
  CodeEnvironmentSelect,
  DependencyInfo,
  BaseTable,
} from "components/molecules";
import {
  columns as envVarsColumns,
  FormContainer,
  LabelsForm,
  RequestSettings,
  UpgradeSubscriptionDialog,
} from "components/organisms";

import { DeploymentVersionScalingStrategy } from "./DeploymentVersionScalingStrategy";

import type { DeploymentVersionDetailsRouteParams } from "./types";
import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { AutocompleteSelectOption } from "components/atoms/AutoCompleteSelect";
import type { BaseColumn } from "components/molecules/BaseTable";
import type { DeploymentVersionCreate } from "libs/data/models";

const formatVariables = (variables: any, filterCriterias: any) =>
  map(variables, (variable) => ({
    ...variable,
    value: variable.value ?? undefined,
  })).filter(
    ({ inheritance_type }) => !filterCriterias.includes(inheritance_type)
  );

const deploymentRules = { required: validators.required.message("deployment") };
const versionNameRules = {
  required: validators.required.message("version name"),
  pattern: {
    value: validators.name.pattern,
    message: validators.name.message("version name"),
  },
  validate: validators.name.value("deployment version"),
};

export const DeploymentVersionDuplicate = () => {
  useGoogleAnalytics();
  const history = useHistory();
  const theme = useTheme() as AppThemeProps;
  const { organizationName, projectName, deploymentName, versionName } =
    useParams<DeploymentVersionDetailsRouteParams>();

  const { data: version, error } = useDeploymentVersionsGet(
    projectName,
    deploymentName,
    versionName
  );
  const { data: availableEnvironments } = useEnvironmentsList(projectName);
  const { data: deployments, error: deploymentsError } =
    useDeploymentsList(projectName);
  const { data: currentInstanceType } = useInstanceTypeGroupsGet(
    projectName,
    version?.instance_type_group_id ?? ""
  );
  const { data: organizationFeatures } =
    useOrganizationsFeaturesGet(organizationName);
  const { data: organizationResources } =
    useOrganizationsResourceUsage(organizationName);
  const { data: versionEnvVarsToCopy } =
    useDeploymentVersionEnvironmentVariablesList(
      projectName,
      deploymentName,
      versionName
    );

  const fileUpload = useDeploymentVersionsFileUpload(
    projectName,
    deploymentName
  );

  const gpuEnabled: boolean = organizationFeatures?.resource_gpu ?? false;

  const [correctZipStructure, setCorrectZipStructure] = useState(false);
  const [variables, setVariables] = useState<any>(null);
  const [defaultGpuEnabled, setDefaultGpuEnabled] = useState(false);
  const [hasBaseEnvironment, setHasBaseEnvironment] = useState(false);

  const methods = useForm({
    mode: "onBlur",
  });
  const { control, register, setValue, errors, unregister } = methods;
  const deploymentPackage = useWatch({ control, name: FIELD_PACKAGE });

  const destinationDeployment: AutocompleteSelectOption | undefined = useWatch({
    control,
    name: FIELD_DEPLOYMENT,
  });

  const createVersion = useDeploymentVersionsCreate(
    projectName,
    destinationDeployment?.value as string
  );

  const fileHasRequirements = useWatch({
    control,
    name: FIELD_DEPLOYMENT_VERSION_FILE_HAS_REQUIREMENTS,
  });
  const gpuInstanceModeEnabled: boolean | undefined = useWatch({
    control,
    name: FIELD_GPU_INSTANCE_ENABLED,
  });

  const [isSubscriptionDialogOpen, setIsSubscriptionDialogOpen] =
    useState(false);

  useEffect(() => {
    if (deploymentPackage === FIELD_EXISTING_PACKAGE) {
      unregister(FIELD_DEPLOYMENT_VERSION_FILE);
    }
  }, [deploymentPackage, unregister]);

  // todo: make sure that if a user deleted a deployment in another screen, it is re-checked

  useEffect(() => {
    if (
      organizationResources?.deployment_versions &&
      organizationFeatures?.max_deployment_versions &&
      organizationResources?.deployment_versions >=
        organizationFeatures?.max_deployment_versions
    ) {
      setIsSubscriptionDialogOpen(true);
    }
  }, [
    organizationResources,
    organizationFeatures,
    setIsSubscriptionDialogOpen,
  ]);

  const currentEnvironmentDetails = useMemo(() => {
    return availableEnvironments?.find(
      (language) => language?.name === version?.environment
    );
  }, [availableEnvironments, version?.environment]);

  useEffect(() => {
    if (version) {
      setValue(FIELD_LABELS, formatLabels(version.labels ?? {}));

      setValue(
        FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY,
        version[FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY]
      );
      setValue(
        FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY,
        version[FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY]
      );
      setValue(
        FIELD_DEPLOYMENT_VERSION_IDLE_TIME,
        version[FIELD_DEPLOYMENT_VERSION_IDLE_TIME]
      );

      if (version?.maximum_queue_size_express) {
        setValue(
          FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS,
          version.maximum_queue_size_express
        );
      }
      if (version?.maximum_queue_size_batch) {
        setValue(
          FIELD_MAXIMUM_QUEUE_SIZE_BATCH,
          version.maximum_queue_size_batch
        );
      }
      if (version?.ports) {
        setValue(FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORTS, version.ports);
      }
    }
  }, [
    availableEnvironments,
    currentEnvironmentDetails,
    setValue,
    version,
    versionName,
  ]);

  useEffect(() => {
    const gpuEnabled =
      !!currentInstanceType?.instance_types?.[0].accelerator || 0 > 0;
    setDefaultGpuEnabled(gpuEnabled);
    setValue(FIELD_GPU_INSTANCE_ENABLED, gpuEnabled);
  }, [currentInstanceType, setValue]);

  useEffect(() => {
    if (version) {
      if (currentEnvironmentDetails?.gpu_required) {
        setDefaultGpuEnabled(true);
      }

      if (currentEnvironmentDetails && !currentEnvironmentDetails.implicit) {
        // In case the version currently does not have an implicit environment,
        // it means that there are no requirements within the deployment package.
        // This is used to determine whether the user can select a custom environment.
        setHasBaseEnvironment(true);
      } else if (currentEnvironmentDetails) {
        setHasBaseEnvironment(false);
      }
    }
  }, [currentEnvironmentDetails, version]);

  useEffect(() => {
    if (versionEnvVarsToCopy) {
      setVariables(formatVariables(versionEnvVarsToCopy, ["project"]));
    }
  }, [versionEnvVarsToCopy]);

  const onVersionCreate = async (data: any) => {
    const createVersionRequest: DeploymentVersionCreate = {
      [FIELD_VERSION]: data[FIELD_VERSION],
      [FIELD_DEPLOYMENT_VERSION_ENVIRONMENT]:
        data[FIELD_DEPLOYMENT_VERSION_ENVIRONMENT].value,
      [FIELD_RETENTION_MODE]: data[FIELD_RETENTION_MODE].value,
      [FIELD_RETENTION_TIME]: data[FIELD_RETENTION_TIME]?.value,
      [FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE]:
        data[FIELD_DEPLOYMENT_VERSION_INSTANCE_TYPE]?.value,
      [FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY]:
        data[FIELD_DEPLOYMENT_VERSION_MIN_DEPLOY],
      [FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY]:
        data[FIELD_DEPLOYMENT_VERSION_MAX_DEPLOY],
      [FIELD_DEPLOYMENT_VERSION_IDLE_TIME]:
        data[FIELD_DEPLOYMENT_VERSION_IDLE_TIME],
      [FIELD_DESCRIPTION]: data[FIELD_DESCRIPTION],
      [FIELD_LABELS]: formatLabelsForRequest(data[FIELD_LABELS]),
      [FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS]:
        data[FIELD_MAXIMUM_QUEUE_SIZE_EXPRESS],
      [FIELD_MAXIMUM_QUEUE_SIZE_BATCH]: data[FIELD_MAXIMUM_QUEUE_SIZE_BATCH],
      [FIELD_STATIC_IP]: data[FIELD_STATIC_IP],
      [FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORTS]:
        data[FIELD_DEPLOYMENT_VERSION_DEPLOYMENT_PORTS],
      [FIELD_SCALING_STRATEGY]: data[FIELD_SCALING_STRATEGY],
      [FIELD_INSTANCE_PROCESSES]: data[FIELD_INSTANCE_PROCESSES],
    };

    const response = await createVersion(createVersionRequest);

    if (!response) {
      return;
    }

    if (data[FIELD_COPY_ENV_VARS] === "true") {
      const request = {
        [FIELD_SOURCE_DEPLOYMENT]: deploymentName,
        [FIELD_SOURCE_VERSION]: versionName,
      };

      await deploymentVersionEnvironmentVariablesCopy(
        projectName,
        data[FIELD_DEPLOYMENT]?.value,
        data[FIELD_VERSION],
        request
      );
    }

    const formData = new FormData();

    if (data[FIELD_PACKAGE] === FIELD_NEW_PACKAGE) {
      const file = data[FIELD_DEPLOYMENT_VERSION_FILE];
      formData.append("file", file[0]);
      fileUpload(
        {
          version: data[FIELD_VERSION],
          deployment: version?.deployment as string,
          id: version?.id as string,
        },
        {
          file: file[0],
        },
        file[0].name
      );
    } else {
      await revisionsFileUpload(
        projectName,
        data[FIELD_DEPLOYMENT].value,
        data[FIELD_VERSION],
        {
          source_deployment: deploymentName,
          source_version: versionName,
        }
      );
    }

    history.push(
      routes.organizations[":organizationName"](organizationName)
        .projects[":projectName"](projectName)
        .deployments[":deploymentName"](data[FIELD_DEPLOYMENT].value)
        .versions[":versionName"](data[FIELD_VERSION])
        .index()
    );
  };

  const fileFieldRules = useMemo(() => {
    return {
      required: validators.required.message("version package"),
      validate: {
        correctZipStructure: () => correctZipStructure,
      },
    };
  }, [correctZipStructure]);

  const parentFileFieldValidationChecker = useCallback(
    // Workaround function to pass props from child to parent
    (flag: any) => setCorrectZipStructure(flag),
    []
  );

  const formLoading = useMemo(() => {
    return (!version && !error) || (!deployments && !deploymentsError);
  }, [deployments, deploymentsError, error, version]);

  const deployment = useMemo(() => {
    return deployments?.find(
      (deployment) => deployment.name === deploymentName
    );
  }, [deployments, deploymentName]);

  const options = useMemo(
    () =>
      deployments?.map(({ name }) => ({
        label: name,
        value: name,
      })),
    [deployments]
  );

  const defaultValue = useMemo(
    () =>
      deployment && {
        label: deployment?.name,
        value: deployment?.name,
      },
    [deployment]
  );

  return (
    <PageContainer>
      <PageHeader title="Duplicate version" />
      <FormContainer
        onSubmit={onVersionCreate}
        buttonLabel="Create"
        formMethods={methods}
        status={formLoading ? LOADING : LOADED}
      >
        {!formLoading ? (
          <>
            <GeneralFieldsSection
              description={explanations.deployments.templates}
              descriptionDefaultValue={version?.description}
              notes={
                <FormTextField
                  name={FIELD_VERSION}
                  defaultValue={`${versionName}-copy-${createId()}`}
                  label="Version name"
                  rules={versionNameRules}
                  placeholder="Ex: my-deployment-version-copy"
                />
              }
              duplicateOption={
                options &&
                defaultValue && (
                  <AutoCompleteSelectHookForm
                    name={FIELD_DEPLOYMENT}
                    label="Target deployment"
                    options={options || []}
                    defaultValue={defaultValue}
                    rules={deploymentRules}
                  />
                )
              }
            />
            <FormSection
              title="Deployment package"
              description={explanations.deployments.configuration}
            >
              <Grid container direction="column">
                <Grid item>
                  <Radio
                    value={FIELD_EXISTING_PACKAGE}
                    // @ts-ignore
                    register={register({
                      required: true,
                    })}
                    name={FIELD_PACKAGE}
                    id={`${FIELD_PACKAGE}_${FIELD_EXISTING_PACKAGE}`}
                    label="Use current package"
                    defaultChecked
                    // @ts-ignore
                    error={errors?.[FIELD_PACKAGE]?.message !== undefined}
                  />
                </Grid>
                <Grid item>
                  <Radio
                    value={FIELD_NEW_PACKAGE}
                    // @ts-ignore
                    register={register({
                      required: true,
                    })}
                    name={FIELD_PACKAGE}
                    id={`${FIELD_PACKAGE}_${FIELD_NEW_PACKAGE}`}
                    label="Upload new package"
                    // @ts-ignore
                    error={errors?.[FIELD_PACKAGE]?.message !== undefined}
                  />
                </Grid>
                <FormHelperText
                  id={FIELD_PACKAGE}
                  // @ts-ignore
                  error={errors?.[FIELD_PACKAGE]?.message !== undefined}
                  // @ts-ignore
                  hidden={errors?.[FIELD_PACKAGE]?.message === undefined}
                >
                  You should either use the current package nor upload a new one
                </FormHelperText>
              </Grid>
              {deploymentPackage === FIELD_NEW_PACKAGE && (
                <Box ml={2}>
                  <UploadPackageField
                    hasRequirementsName={
                      FIELD_DEPLOYMENT_VERSION_FILE_HAS_REQUIREMENTS
                    }
                    name={FIELD_DEPLOYMENT_VERSION_FILE}
                    rules={fileFieldRules}
                    label="Deployment package (.zip)"
                    passFormValidation={parentFileFieldValidationChecker}
                  />
                </Box>
              )}

              {fileHasRequirements ? <DependencyInfo /> : null}
            </FormSection>
            <FormSection
              title="Environment settings"
              description={
                explanations.deployments.versions.environmentSettings
              }
            >
              <DeploymentVersionInstanceType
                defaultValue={defaultGpuEnabled}
                disableGPUToggle={!gpuEnabled}
                version={version}
              />
              <Grid item>
                <Typography variant="h6">Select code environment</Typography>
                <Box maxWidth={spacing[310]} mt={2}>
                  <CodeEnvironmentSelect
                    customEnvironmentsVisible={
                      deploymentPackage === FIELD_NEW_PACKAGE
                        ? fileHasRequirements !== true
                        : hasBaseEnvironment
                    }
                    defaultEnvironment={
                      currentEnvironmentDetails?.implicit
                        ? currentEnvironmentDetails.base_environment
                        : version?.environment
                    }
                    deployment={deploymentName}
                    gpuEnabled={gpuEnabled && !!gpuInstanceModeEnabled}
                    name={FIELD_DEPLOYMENT_VERSION_ENVIRONMENT}
                    projectName={projectName}
                  />
                </Box>
              </Grid>
            </FormSection>

            <RequestSettings
              defaultMode={version?.request_retention_mode}
              defaultTime={version?.request_retention_time}
              defaultBatchQueueSize={version?.maximum_queue_size_batch}
              defaultQueueSize={version?.maximum_queue_size_express}
            />
            <NetworkSettings defaultValue={version?.static_ip} />
            <PortForwarding />
            <DeploymentVersionAdvancedParameters version={version} />
            <DeploymentVersionScalingStrategy
              defaultValue={version?.scaling_strategy}
            />
            {variables ? (
              <FormSection title="Environment variables">
                <Grid item>
                  <Typography variant="h4">
                    Would you like to copy the environment variables?
                  </Typography>
                </Grid>
                <Grid container direction="row" spacing={2}>
                  <Grid item>
                    <Radio
                      value="true"
                      // @ts-ignore
                      register={register({
                        validate: {
                          required: () => variables.length,
                        },
                      })}
                      name={FIELD_COPY_ENV_VARS}
                      id={`${FIELD_COPY_ENV_VARS}-true`}
                      label="Yes"
                      defaultChecked={!!variables.length}
                      disabled={!variables.length}
                    />
                  </Grid>
                  <Grid item>
                    <Radio
                      value="false"
                      // @ts-ignore
                      register={register({
                        validate: {
                          required: () => variables.length,
                        },
                      })}
                      name={FIELD_COPY_ENV_VARS}
                      id={`${FIELD_COPY_ENV_VARS}-false`}
                      label="No"
                      defaultChecked={!variables.length}
                      disabled={!variables.length}
                    />
                  </Grid>
                </Grid>

                <Grid item>
                  {variables.length ? (
                    <>
                      <Typography variant="subtitle1">
                        The following variables can be copied.
                      </Typography>
                      <BaseTable
                        columns={
                          envVarsColumns(
                            theme.palette.error.main
                          ) as BaseColumn[]
                        }
                        data={variables}
                        hasPagination={false}
                        hasSearchBar={false}
                      />
                    </>
                  ) : (
                    <Typography variant="subtitle1">
                      No environment variables to copy.
                    </Typography>
                  )}
                </Grid>
              </FormSection>
            ) : (
              <Loader />
            )}
            <FormSection
              title="Labels"
              description={explanations.labels.description("deploymentVersion")}
            >
              <LabelsForm name={FIELD_LABELS} />
            </FormSection>
          </>
        ) : (
          <Loader />
        )}
      </FormContainer>
      <UpgradeSubscriptionDialog
        open={isSubscriptionDialogOpen}
        onClose={() => setIsSubscriptionDialogOpen(false)}
      >
        You&apos;ve reached your current subscription&apos;s limit of{" "}
        <b>
          {organizationFeatures?.max_deployment_versions} maximum deployment
          versions
        </b>
        . Please upgrade your subscription.
      </UpgradeSubscriptionDialog>
    </PageContainer>
  );
};
