import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import {
  REQUEST_TASK,
  STRUCTURED_TYPE,
  TIME_OUT_BROWSER,
} from "libs/constants/constants";
import {
  pipelineRequestsStreamingCreate,
  pipelineVersionRequestsStreamingCreate,
  usePipelineVersionRequestsList,
} from "libs/data/endpoints/pipeline-requests/pipeline-requests";
import { createErrorNotification } from "libs/utilities/notifications";
import { ERROR, LOADED, LOADING } from "libs/utilities/request-statuses";
import { getRandomId } from "libs/utilities/utils";
import { addTask, updateTask } from "store/features/taskManager";

import type { PipelineRequestParameters } from "components/organisms";

import type { AxiosError } from "axios";
import type {
  DeploymentRequestCreateResponse,
  DeploymentRequestsCreateDataBody,
  PipelineRequestCreateResponse,
  PipelineRequestCreateResponseResult,
} from "libs/data/models";

export const usePipelineStreamingRequestCreate = (
  projectName: string,
  pipelineName: string,
  requestParameters: PipelineRequestParameters,
  requestTimeout: number,
  version?: string,
  isRetentionModeNone?: boolean
) => {
  const dispatch = useDispatch();
  const taskId = getRandomId();
  const { mutate } = usePipelineVersionRequestsList(
    projectName,
    pipelineName,
    version ?? "",
    undefined,
    { swr: { enabled: !!pipelineName } }
  );

  const [streamedResponse, setStreamedResponse] = useState(
    undefined as
      | {
          responseData: PipelineRequestCreateResponse | undefined;
          requestData: DeploymentRequestsCreateDataBody;
        }
      | undefined
  );

  const startStream = useCallback(
    async (data: DeploymentRequestsCreateDataBody) => {
      const result: {
        responseData: DeploymentRequestCreateResponse | undefined;
        requestData: DeploymentRequestsCreateDataBody;
      } = {
        responseData: undefined,
        requestData: data,
      };

      if (!isRetentionModeNone && requestParameters)
        dispatch(
          addTask({
            id: taskId,
            type: REQUEST_TASK,
            status: LOADING,
            message: `${requestParameters.type} request`,
            requestParameters: requestParameters,
          })
        );

      try {
        setStreamedResponse({
          ...result,
          responseData: {
            result: "" as unknown as PipelineRequestCreateResponseResult,
            id: "",
            status: "pending",
            timeout: requestTimeout,
            version,
            deployment: pipelineName,
            deployment_requests: [],
            operator_requests: [],
            pipeline_requests: [],
          } as PipelineRequestCreateResponse,
        });

        const headers = {
          "Content-Type":
            requestParameters?.inputType === STRUCTURED_TYPE
              ? "application/json"
              : "text/plain",
          Accept: "text/event-stream",
        };

        const response = version
          ? await pipelineVersionRequestsStreamingCreate(
              projectName,
              pipelineName,
              version,
              data,
              { timeout: requestTimeout },
              {
                timeout: TIME_OUT_BROWSER,
                responseType: "stream",
                adapter: "fetch",
                headers,
              }
            )
          : await pipelineRequestsStreamingCreate(
              projectName,
              pipelineName,
              data,
              { timeout: requestTimeout },
              {
                timeout: TIME_OUT_BROWSER,
                responseType: "stream",
                adapter: "fetch",
                headers,
              }
            );

        // @ts-ignore
        const reader = response.getReader();
        const decoder = new TextDecoder();
        let resultText = "";
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value, { stream: true });
          const lines = chunk.split("\n").filter(Boolean);

          for (const line of lines) {
            if (line.startsWith("data:")) {
              resultText += line.replace("data:", "");
              setStreamedResponse({
                ...result,
                responseData: {
                  result:
                    resultText as unknown as PipelineRequestCreateResponseResult,
                  id: "",
                  status: "processing",
                  request_data: data,
                  timeout: requestTimeout,
                  version,
                  pipeline: pipelineName,
                  deployment_requests: [],
                  operator_requests: [],
                  pipeline_requests: [],
                } as PipelineRequestCreateResponse,
              });
            }
            if (line.startsWith(`{"id":`)) {
              try {
                const finalResponse = JSON.parse(line);
                setStreamedResponse({
                  ...result,
                  responseData: {
                    ...finalResponse,
                    request_data: data,
                  },
                });
                dispatch(
                  updateTask({
                    id: taskId,
                    status: LOADED,
                    result: {
                      ...result,
                      responseData: { ...finalResponse, request_data: data },
                    },
                  })
                );
              } catch (err) {
                // throw silent error
              }
            }
          }
        }
      } catch (err: any) {
        if (err?.response) {
          const reader = err?.response.data.getReader();
          const decoder = new TextDecoder();

          let jsonString = "";
          // eslint-disable-next-line no-constant-condition
          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            jsonString += decoder.decode(value, { stream: true });
          }

          const jsonData = JSON.parse(jsonString);
          if (!jsonData?.error_message) {
            dispatch(createErrorNotification((err as AxiosError)?.message));
          }

          setStreamedResponse({
            ...result,
            responseData: jsonData as PipelineRequestCreateResponse,
          });
          dispatch(
            updateTask({
              id: taskId,
              status: ERROR,
              result: { ...result, responseData: jsonData },
            })
          );
        }
      } finally {
        mutate();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isRetentionModeNone,
      requestParameters,
      dispatch,
      taskId,
      version,
      pipelineName,
      projectName,
      mutate,
    ]
  );

  return { startStream, streamedResponse };
};
