import Plus from "@mui/icons-material/AddBoxRounded";
import MakeDefault from "@mui/icons-material/AssignmentTurnedInRounded";
import Trash from "@mui/icons-material/DeleteRounded";
import { Box, IconButton, Tooltip, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { useState, useContext, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
  CONTINUOUS_REQUEST_DELAY,
  UPLOAD_TASK,
} from "libs/constants/constants";
import { FIELD_DEFAULT_VERSION } from "libs/constants/fields";
import { DEPLOYMENT_PERMISSIONS } from "libs/constants/permissions";
import { BaseUrlContext } from "libs/contexts";
import { useDeploymentUpdate } from "libs/data/customized/deployment/useDeploymentUpdate";
import { useDeploymentVersionDelete } from "libs/data/customized/deployment-versions/useDeploymentVersionDelete";
import { usePermissionValidation } from "libs/data/customized/roles";
import { useDeploymentVersionsList } from "libs/data/endpoints/deployments/deployments";
import { useDeviceDetect, useInterval } from "libs/hooks";
import {
  getTzAwareDate,
  getTimeFromNow,
  DATE_TIME_FORMAT,
} from "libs/utilities/date-util";
import { explanations } from "libs/utilities/explanations";
import {
  formatLabelsForFilter,
  renderLabels,
} from "libs/utilities/labels-util";
import { LOADING } from "libs/utilities/request-statuses";
import { formatStatusLabel } from "libs/utilities/statuses";
import { TRAINING_DEPLOYMENT } from "pages/organizations/:organizationName/projects/:projectName/training/constants";
import { useGetCurrentOrganization } from "store/features";
import { useTaskManager } from "store/features/taskManager";

import {
  StatusIcon,
  HighlightedText,
  TableLink,
  DeleteDialog,
  ActionDialog,
  SecondaryButton,
  Loader,
} from "components/atoms";
import { BaseTable } from "components/molecules";
import type { LabelFilter } from "components/organisms";
import { onFilterAdd } from "components/organisms";

import type { DeploymentDetailsRouteParams } from "./types";
import type { AppThemeProps } from "assets/styles/theme/theme.d";
import type { BaseColumn } from "components/molecules/BaseTable";
import type { DeploymentVersionList } from "libs/data/models";
import type { UploadTask } from "store/features/taskManager";

export const DeploymentVersionsOverview = () => {
  const history = useHistory();
  const { isMobile } = useDeviceDetect();
  const theme = useTheme() as AppThemeProps;
  const baseUrl = useContext(BaseUrlContext);
  const { projectName, deploymentName } =
    useParams<DeploymentDetailsRouteParams>();
  const [filters, setFilters] = useState<LabelFilter[]>([]);
  const [defaultableVersion, setDefaultableVersion] =
    useState<DeploymentVersionList | null>(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deletableVersion, setDeletableVersion] =
    useState<DeploymentVersionList | null>(null);

  const { tasks } = useTaskManager();

  const organization = useGetCurrentOrganization();

  const {
    data: versions,
    error,
    mutate,
  } = useDeploymentVersionsList(
    projectName,
    deploymentName,
    filters?.length
      ? {
          labels: formatLabelsForFilter(filters),
        }
      : undefined,
    {
      swr: {
        swrKey: `/projects/${projectName}/deployments/${deploymentName}/versions`,
      },
    }
  );

  const isLoading = !error && !versions;

  const updateDeployment = useDeploymentUpdate(projectName, deploymentName);

  const currentlyUploadingIds = useMemo((): string[] => {
    if (!tasks?.length) return [];

    return tasks
      .filter(
        (task) =>
          (task as UploadTask).version?.deployment === deploymentName &&
          task.status === LOADING &&
          task.type === UPLOAD_TASK
      )
      .map((task) => (task as UploadTask)?.version?.version as string);
  }, [deploymentName, tasks]);

  useInterval(
    () => {
      const relevantVersions = (versions ?? []).filter((version) => {
        const createdRecently =
          moment().diff(version?.creation_date, "seconds") < 30;

        const editedRecently =
          moment().diff(version?.last_updated, "seconds") < 30;

        return (
          version.status !== "available" &&
          (version.status !== "unavailable" ||
            createdRecently ||
            editedRecently)
        );
      });

      if (relevantVersions.length) {
        mutate();
      }
    },
    [mutate, versions],
    CONTINUOUS_REQUEST_DELAY
  );

  const deleteVersion = useDeploymentVersionDelete(projectName, deploymentName);

  const [currentPermissions] = usePermissionValidation(
    projectName,
    Object.values(DEPLOYMENT_PERMISSIONS),
    deploymentName,
    "deployment"
  );

  const isTrainingDeployment = useMemo(
    () => deploymentName === TRAINING_DEPLOYMENT,
    [deploymentName]
  );

  const columns = useMemo(
    () =>
      [
        {
          title: "Status",
          width: "10%",
          field: "status",
          editable: "never",
          render: (rowData: DeploymentVersionList) => (
            <StatusIcon
              label={
                rowData
                  ? rowData.status === "unavailable" &&
                    currentlyUploadingIds.includes(rowData.version as string)
                    ? "uploading"
                    : formatStatusLabel(rowData.status)
                  : ""
              }
              status={
                rowData?.status === "unavailable" &&
                currentlyUploadingIds.includes(rowData.version as string)
                  ? "uploading"
                  : (rowData?.status as string)
              }
              displayError={true}
              // @ts-ignore
              errorMessage={rowData?.error_message}
            />
          ),
        },
        {
          title: "Version",
          width: "10%",
          field: "version",
          nowrap: true,
          render: ({
            version,
            default: default_version,
          }: DeploymentVersionList) => (
            <TableLink
              variant="bold"
              to={`${baseUrl}/versions/${version}`}
              style={
                default_version
                  ? {
                      display: "flex",
                      flexDirection: "column",
                    }
                  : {}
              }
            >
              {default_version && <HighlightedText>default</HighlightedText>}
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {version}
              </div>
            </TableLink>
          ),
        },
        !isMobile && {
          title: "Environment",
          width: "10%",
          field: "environment_display_name",
          render: ({ environment_display_name }: DeploymentVersionList) =>
            environment_display_name,
        },
        {
          title: "Minimum instances",
          width: "5%",
          field: "minimum_instances",
          render: ({ minimum_instances }: DeploymentVersionList) =>
            minimum_instances,
        },
        {
          title: "Maximum instances",
          width: "5%",
          field: "maximum_instances",
          render: ({ maximum_instances }: DeploymentVersionList) =>
            maximum_instances,
        },
        !isMobile && {
          title: "Created",
          width: "15%",
          field: "creation_date",
          type: "datetime",
          nowrap: true,
          render: (rowData: DeploymentVersionList) =>
            getTzAwareDate(rowData.creation_date).format(DATE_TIME_FORMAT),
        },
        !isMobile && {
          title: "Edited",
          width: "15%",
          field: "last_updated",
          type: "datetime",
          nowrap: true,
          render: (rowData: DeploymentVersionList) =>
            // @ts-ignore
            getTimeFromNow(rowData.last_updated),
          defaultSort: "desc",
        },
        {
          title: "Labels",
          width: "20%",
          field: "labels",
          // @ts-ignore
          render: renderLabels(onFilterAdd(setFilters)),
        },
        {
          disableClick: true,
          width: "10%",
          render: (rowData: DeploymentVersionList) => (
            <div className="actions_container">
              <Tooltip title="Make default">
                <span>
                  {/* @ts-ignore */}
                  <IconButton
                    status="none"
                    disabled={isTrainingDeployment || !!rowData?.default}
                    onClick={(e) => {
                      e.stopPropagation();
                      setDefaultableVersion(rowData);
                    }}
                  >
                    <MakeDefault
                      sx={{
                        color: rowData.default
                          ? theme.palette.text.secondary
                          : theme.palette.text.primary,
                      }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title="Delete">
                <span>
                  <IconButton
                    color="primary"
                    disabled={
                      isTrainingDeployment ||
                      !currentPermissions[
                        DEPLOYMENT_PERMISSIONS["version_delete"]
                      ]
                    }
                    onClick={(e) => {
                      if (
                        currentPermissions[
                          DEPLOYMENT_PERMISSIONS["version_delete"]
                        ]
                      )
                        e.stopPropagation();
                      setDeletableVersion(rowData);
                    }}
                  >
                    <Trash sx={{ color: theme.palette.text.primary }} />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          ),
        },
      ].filter(Boolean),
    [
      currentlyUploadingIds,
      baseUrl,
      isTrainingDeployment,
      theme.palette.text.secondary,
      theme.palette.text.primary,
      currentPermissions,
      isMobile,
    ]
  );

  const onRowClick = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    rowData: DeploymentVersionList
  ) => {
    const url = `${baseUrl}/versions/${rowData.version}`;
    if (e.metaKey || e.ctrlKey) {
      e.preventDefault();
      e.stopPropagation();
      window.open(url, "_blank");
    } else {
      history.push(url);
    }
  };

  const handleDefaultVersionChange = async (data: string) => {
    await updateDeployment({
      [FIELD_DEFAULT_VERSION]: data,
    });
    mutate();
  };

  const handleDeleteVersion = async () => {
    if (deletableVersion?.version) {
      setDeleteLoading(true);
      await deleteVersion(deletableVersion.version);
      setDeletableVersion(null);
      setDeleteLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <BaseTable
          columns={columns as BaseColumn[]}
          data={versions}
          onRowClick={onRowClick}
          filters={filters}
          setFilters={setFilters}
          defaultSortColumn="last_updated"
          defaultSortDirection="asc"
          header={
            <Box
              display="flex"
              flexDirection="column"
              flexWrap="wrap"
              width="fit-content"
              rowGap={2}
            >
              <Box mt={0.5}>
                <Typography variant="h3">Versions</Typography>
              </Box>
              <SecondaryButton
                size="small"
                startIcon={<Plus />}
                link={`${baseUrl}/versions/create`}
                disabled={
                  isTrainingDeployment ||
                  !currentPermissions[
                    DEPLOYMENT_PERMISSIONS["version_create"]
                  ] ||
                  organization?.status !== "active"
                }
              >
                Create version
              </SecondaryButton>
            </Box>
          }
        />
      )}

      <DeleteDialog
        loading={deleteLoading}
        open={!!deletableVersion}
        onClose={() => setDeletableVersion(null)}
        onDelete={handleDeleteVersion}
      >
        Are you sure you want to delete version {`"`}
        <b>{deletableVersion?.version}</b>
        {`"`}?
      </DeleteDialog>

      <ActionDialog
        actionButtonText="Make default"
        onClose={() => setDefaultableVersion(null)}
        onAction={() => {
          handleDefaultVersionChange(defaultableVersion?.version as string);
          setDefaultableVersion(null);
        }}
        open={!!defaultableVersion}
      >
        {explanations.defaultVersionDialog.message(defaultableVersion?.version)}
      </ActionDialog>
    </>
  );
};
